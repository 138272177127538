<form [formGroup]="form" (ngSubmit)="sendNotification()" #formRef="ngForm">
  <div fxLayout="column" class="app-area">
    <!-- HEADER ROW -->
    <div fxLayout="row">
      <div fxFlex fxFlexAlign="start start">
        <h1>Push-Nachricht</h1>
      </div>
      <div fxFlex style="text-align: right">
        <button mat-button fxFlexAlign="end end" type="submit">
          <mat-icon>send</mat-icon>
          Senden
        </button>
      </div>
    </div>

    <!-- Content -->
    <mat-card fxLayout="row" class="square-card mat-elevation-z0">
      <div fxLayout="row wrap" fxLayoutAlign="start start" style="width: 100%">
        <div fxFlex="100">
          <h4>Push-Nachricht</h4>
        </div>
        <div fxFlex="100">
          <mat-form-field appearance="outline">
            <mat-label>Titel</mat-label>
            <input
              matInput
              placeholder="Titel"
              type="text"
              formControlName="title"
            />
          </mat-form-field>
        </div>
        <div fxFlex="100">
          <mat-form-field appearance="outline">
            <mat-label>Untertitel</mat-label>
            <input
              matInput
              placeholder="Untertitel"
              type="text"
              formControlName="subtitle"
            />
          </mat-form-field>
        </div>
        <div fxFlex="100">
          <mat-form-field appearance="outline">
            <mat-label>Text</mat-label>
            <textarea
              matInput
              formControlName="body"
              placeholder="Text"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
  </div>
</form>
