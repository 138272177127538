import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeleteConfirmModalComponent } from 'src/app/_core/components/delete-confirm-modal/delete-confirm-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [DeleteConfirmModalComponent],
  exports: [DeleteConfirmModalComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule],
})
export class DeleteConfirmModalModule {}
