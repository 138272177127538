import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/_core/services/api/api.service';
import { OrganizationDto } from 'src/app/public-utility/organization/dtos/organization.dto';
import { SnackBarService } from 'src/app/_shared/services/snack-bar.service';
import { Organization } from 'src/app/public-utility/organization/models/organization';
import { OrganizationWithInitialUserInput } from 'src/app/public-utility/organization/dtos/organization-with-initial-user-input';
import { OrganizationInputDto } from 'src/app/public-utility/organization/dtos/organization-input.dto';
import { MailTemplateConfiguration } from 'src/app/_shared/models/mail-template-config';
import { CitylinkOrganization } from 'src/app/public-utility/organization/models/citylink-organization';
import { CitylinkOrganizationDto } from 'src/app/public-utility/organization/dtos/citylink-organization.dto';
import { OrganizationFilterInputDto } from 'src/app/public-utility/organization/dtos/organization-filter-input.dto';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(
    private apiService: ApiService,
    private snackBarService: SnackBarService
  ) {}

  getList(
    handleError = true,
    organizationFilter?: OrganizationFilterInputDto
  ): Promise<CitylinkOrganization[]> {
    return this.apiService
      .get<CitylinkOrganizationDto[]>(
        '/organizations',
        undefined,
        organizationFilter,
        handleError
      )
      .then((apiResult) => {
        if (!apiResult) {
          return [];
        }

        return apiResult.map((item) =>
          CitylinkOrganization.fromOrganizationOutputDto(item)
        );
      });
  }

  getById(id: number): Promise<CitylinkOrganization | void> {
    return this.apiService
      .get<CitylinkOrganizationDto>('/organizations/' + id)
      .then((result) => {
        if (!result) {
          return;
        }

        return CitylinkOrganization.fromOrganizationOutputDto(result);
      });
  }

  update(
    value: OrganizationInputDto,
    id: number
  ): Promise<Organization | void> {
    return this.apiService
      .patch<OrganizationDto>('/organizations/' + id, value)
      .then((result) => {
        this.snackBarService.saveSuccessful();
        if (result) {
          return Organization.fromOrganizationOutputDto(result);
        }
      });
  }

  create(value: OrganizationWithInitialUserInput): Promise<void> {
    return this.apiService.post('/organizations', value).then((_) => {
      this.snackBarService.saveSuccessful();
    });
  }

  delete(id: number): Promise<void> {
    return this.apiService.delete('/organizations/' + id).then((_) => {
      this.snackBarService.deleteSuccessful();
    });
  }

  resendInvitation(id: number): Promise<void> {
    return this.apiService.post(`/organizations/${id}/invite`, {}).then((_) => {
      this.snackBarService.showCustomMessage(
        'Einladung wurde erneut versendet'
      );
    });
  }

  getOwnOrganization(): Promise<Organization | void> {
    return this.apiService
      .get<OrganizationDto>('/organizations/own')
      .then((dto) => {
        if (!dto) {
          return;
        }
        return Organization.fromOrganizationOutputDto(dto);
      });
  }

  getMailTemplateConfiguration(): Promise<MailTemplateConfiguration | void> {
    return this.apiService.get<MailTemplateConfiguration>(
      '/organizations/own/mail-template-config'
    );
  }

  updateMailTemplateConfiguration(
    mailTemplateConfiguration: MailTemplateConfiguration
  ): Promise<void> {
    return this.apiService
      .patch(
        '/organizations/own/mail-template-config',
        mailTemplateConfiguration
      )
      .then((_) => {
        this.snackBarService.saveSuccessful();
      });
  }
}
