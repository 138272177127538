export enum MeasurementUnits {
  CELSIUS = 'celsius',
  FAHRENHEIT = 'fahrenheit',
  LUMEN = 'lumen',
  PERCENTAGE = 'percentage',
  HOURS = 'hours',
  MINUTES = 'minutes',
  SECONDS = 'seconds',
  MILLISECONDS = 'milliseconds',
  NANOSECONDS = 'nanoseconds',
  METER = 'meter',
  KILOMETERS_PER_HOURS = 'kilometersPerHour',
  GRAM = 'gram',
  KILOGRAM = 'kilogram',
  METRIC_TONS = 'metricTons',
  BAR = 'bar',
  DEGREE = 'degree',
  ANGLE = 'angle',
  VOLT = 'volt',
  M_A_H = 'mAh',
  DATE = 'date',
  COUNTS = 'counts',
  PPM = 'ppm',
  K_W_H = 'kWh',
  W_H = 'Wh',
  W = 'W',
  K_W = 'kW',
}

export function measurementUnitsAsArray(): Array<MeasurementUnits> {
  return [
    MeasurementUnits.CELSIUS,
    MeasurementUnits.FAHRENHEIT,
    MeasurementUnits.LUMEN,
    MeasurementUnits.PERCENTAGE,
    MeasurementUnits.HOURS,
    MeasurementUnits.MINUTES,
    MeasurementUnits.SECONDS,
    MeasurementUnits.MILLISECONDS,
    MeasurementUnits.NANOSECONDS,
    MeasurementUnits.METER,
    MeasurementUnits.KILOMETERS_PER_HOURS,
    MeasurementUnits.GRAM,
    MeasurementUnits.KILOGRAM,
    MeasurementUnits.METRIC_TONS,
    MeasurementUnits.BAR,
    MeasurementUnits.DEGREE,
    MeasurementUnits.ANGLE,
    MeasurementUnits.VOLT,
    MeasurementUnits.M_A_H,
    MeasurementUnits.DATE,
    MeasurementUnits.COUNTS,
    MeasurementUnits.PPM,
    MeasurementUnits.K_W_H,
    MeasurementUnits.W_H,
    MeasurementUnits.W,
    MeasurementUnits.K_W,
  ];
}

export function measurementUnitToLabel(
  enumValue: MeasurementUnits | string
): string {
  switch (enumValue) {
    case MeasurementUnits.CELSIUS:
      return 'Grad Celsius';
    case MeasurementUnits.FAHRENHEIT:
      return 'Grad Fahrenheit';
    case MeasurementUnits.LUMEN:
      return 'Lumen';
    case MeasurementUnits.PERCENTAGE:
      return 'Prozent';
    case MeasurementUnits.HOURS:
      return 'Stunden';
    case MeasurementUnits.MINUTES:
      return 'Minuten';
    case MeasurementUnits.SECONDS:
      return 'Sekunden';
    case MeasurementUnits.MILLISECONDS:
      return 'Millisekunden';
    case MeasurementUnits.NANOSECONDS:
      return 'Nanosekunden';
    case MeasurementUnits.METER:
      return 'Meter';
    case MeasurementUnits.KILOMETERS_PER_HOURS:
      return 'Kilometer pro Stunde';
    case MeasurementUnits.GRAM:
      return 'Gramm';
    case MeasurementUnits.KILOGRAM:
      return 'Kilogramm';
    case MeasurementUnits.METRIC_TONS:
      return 'Tonnen (Metrisch)';
    case MeasurementUnits.BAR:
      return 'Bar';
    case MeasurementUnits.DEGREE:
      return 'Grad (Geometrisch)';
    case MeasurementUnits.ANGLE:
      return 'Winkel';
    case MeasurementUnits.VOLT:
      return 'Volt';
    case MeasurementUnits.M_A_H:
      return 'mAh';
    case MeasurementUnits.COUNTS:
      return 'Anzahl';
    case MeasurementUnits.DATE:
      return 'Datum';
    case MeasurementUnits.PPM:
      return 'Anteile pro Millionen';
    case MeasurementUnits.K_W_H:
      return 'kWh';
      case MeasurementUnits.W_H:
      return 'Wh';
    case MeasurementUnits.W:
      return 'W';
    case MeasurementUnits.K_W:
      return 'kW';
    default:
      return '';
  }
}
