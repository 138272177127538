import { Component, Input, OnInit } from '@angular/core';
import { SensorEventType } from 'src/app/organization/dashboard/models/sensor-event-type';
import { SensorEventTypeHelper } from 'src/app/_shared/helpers/sensor-event-type-helper';

@Component({
  selector: 'app-event',
  templateUrl: './dashboard-current-event.component.html',
  styleUrls: ['./dashboard-current-event.component.scss'],
})
export class DashboardCurrentEventComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() body: string;
  @Input() date: Date;
  @Input() location: string;
  @Input() type: SensorEventType;
  @Input() showDivider: boolean;
  @Input() iconUrl: string;

  constructor() {}

  ngOnInit(): void {}

  isError() {
    return SensorEventTypeHelper.isError(this.type);
  }

  isWarning() {
    return SensorEventTypeHelper.isWarning(this.type);
  }

  isDefault() {
    return SensorEventTypeHelper.isDefault(this.type);
  }

  getSensorEventTypeText() {
    return SensorEventTypeHelper.getSensorTypeText(this.type);
  }
}
