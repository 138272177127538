import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MailTemplateSettingsComponent } from './mail-template-settings.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AddPlaceholderDirectiveModule } from 'src/app/_shared/directives/add-placeholder-directive/add-placeholder-directive.module';
import { MatCardModule } from '@angular/material/card';
import { EditorModule } from '@tinymce/tinymce-angular';

@NgModule({
  declarations: [MailTemplateSettingsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    AddPlaceholderDirectiveModule,
    MatCardModule,
    EditorModule,
  ],
})
export class MailTemplateSettingsModule {}
