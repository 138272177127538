import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardOverviewModule } from 'src/app/organization/dashboard/components/dashboard/dashboard-overview.module';

@NgModule({
  imports: [CommonModule, DashboardOverviewModule],
  providers: [],
  exports: [DashboardOverviewModule],
})
export class DashboardModule {}
