import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserGroup } from 'src/app/users/models/user-group';

@Component({
  selector: 'app-positioning-user-group-item',
  templateUrl: './positioning-user-group-item.component.html',
  styleUrls: ['./positioning-user-group-item.component.scss'],
})
export class PositioningUserGroupItemComponent implements OnInit {
  @Input() userGroup: UserGroup;
  @Output() removeUserGroupEvent = new EventEmitter<UserGroup>();

  constructor(private router: Router) {}

  ngOnInit(): void {}

  editUserGroup() {
    this.router.navigateByUrl(
      'organization/user-groups/edit/' + this.userGroup.id
    );
  }

  removeUserGroup() {
    this.removeUserGroupEvent.next(this.userGroup);
  }
}
