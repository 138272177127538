import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationAccountListItemComponent } from 'src/app/navigation/components/account-list-item/navigation-account-list-item.component';
import { MatListModule } from '@angular/material/list';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [NavigationAccountListItemComponent],
  exports: [NavigationAccountListItemComponent],
  imports: [
    CommonModule,
    MatListModule,
    FlexModule,
    MatIconModule,
    RouterModule,
    MatDialogModule,
    MatButtonModule,
    MatMenuModule,
  ],
})
export class NavigationAccountListItemModule {}
