import { Injectable } from '@angular/core';
import { NotificationAggregationOutput } from 'src/app/organization/dashboard/dtos/notification-aggregation-output.dto';
import { ApiService } from 'src/app/_core/services/api/api.service';
import { Notification } from 'src/app/organization/dashboard/models/notification';
import { NotificationOutputDto } from 'src/app/organization/dashboard/dtos/notification-output.dto';
import { WebsocketService } from 'src/app/_shared/services/websocket.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private apiService: ApiService,
    private websocketService: WebsocketService
  ) {}

  getNotifications(page?: number, sensorId?: number): Promise<Notification[]> {
    let headers = {};
    if (page) {
      // hint: header values must be a string value
      headers = {
        'X-Page-Index': '' + page,
      };
    }

    return this.apiService
      .get<NotificationOutputDto[]>(
        `/sensor-notifications`,
        { headers },
        { sensorId }
      )
      .then((result) => {
        if (!result) {
          return [];
        }

        return result.map((dto) => Notification.fromNotificationOutputDto(dto));
      });
  }

  getNotificationAggregation(
    from: Date,
    to: Date,
    range: number,
    aggregationType: moment.unitOfTime.Base
  ): Promise<Array<NotificationAggregationOutput> | void> {
    return this.apiService.post<Array<NotificationAggregationOutput>>(
      '/sensor-notifications',
      { from, to, range, aggregationType }
    );
  }

  getNotificationsObservable(limit = 100): Observable<Notification[]> {
    return this.websocketService.connect().pipe(
      map((value) => {
        return JSON.parse(value.data);
      }),
      filter((value) => {
        return value.event === 'notifications';
      }),
      map((value) => {
        value.payload.splice(limit);
        return value.payload;
      }),
      map((notifications) => {
        return notifications.map((item) =>
          Notification.fromNotificationOutputDto(item)
        );
      })
    );
  }
}
