export enum MeasurementValueTypes {
  BOOLEAN = 'boolean',
  /**
   * In nominal measurements, any values that cannot be assigned to Numerical or Boolean are stored
   * (at the time this comment is written)
   */
  NOMINAL = 'nominal',
  NUMERIC = 'numeric',
}

export function measurementValueTypesAsArray(): Array<MeasurementValueTypes> {
  return [
    MeasurementValueTypes.BOOLEAN,
    MeasurementValueTypes.NOMINAL,
    MeasurementValueTypes.NUMERIC,
  ];
}

export function measurementValueTypeToLabel(
  enumValue: MeasurementValueTypes | string
): string {
  switch (enumValue) {
    case MeasurementValueTypes.BOOLEAN:
      return 'Wahrheitswert (true/false oder 0/1)';
    case MeasurementValueTypes.NUMERIC:
      return 'Nummerisch';
    case MeasurementValueTypes.NOMINAL:
      return 'Unbekannt (Werte werden nicht verarbeitet)';
    default:
      return '';
  }
}
