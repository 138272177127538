<form [formGroup]="form" (ngSubmit)="save()">
  <div fxLayout="column" class="app-area">
    <!-- HEADER ROW -->
    <div fxLayout="row">
      <div fxFlex fxFlexAlign="start start">
        <h1>Nutzergruppeneinstellungen</h1>
      </div>
      <div fxFlex style="text-align: right">
        <button mat-button fxFlexAlign="end end" type="submit">
          <mat-icon>save</mat-icon>
          Speichern
        </button>
      </div>
    </div>

    <!-- Content -->
    <mat-card fxLayout="row" class="square-card mat-elevation-z0">
      <div fxLayout="row wrap" fxLayoutAlign="start start" style="width: 100%">
        <div fxFlex="100">
          <h4>Allgemeines</h4>
        </div>
        <div fxFlex="100">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input
              matInput
              placeholder="Name"
              type="text"
              formControlName="name"
            />
            <mat-error *ngIf="form.controls.name.hasError('maxlength')">
              Maximal 255 Zeichen!
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="100" style="margin-top: 2rem">
          <h4>Nutzer</h4>
        </div>
        <div
          fxFlex="100"
          fxLayout="row"
          fxLayoutAlign="center center"
          style="margin-bottom: 12px"
        >
          <div fxFlex="50">
            <app-multiple-user-select
              [users]="addableUsers"
              formControlName="selectedUsersToAdd"
            ></app-multiple-user-select>
          </div>
          <div fxFlex="50">
            <button
              mat-button
              style="margin-left: 24px; margin-top: -24px"
              type="button"
              (click)="addUsers()"
            >
              <mat-icon>add</mat-icon>
              Hinzufügen
            </button>
          </div>
        </div>
        <div fxFlex="100">
          <table mat-table [dataSource]="userDataSource" style="width: 100%">
            <ng-container matColumnDef="firstName">
              <td mat-cell *matCellDef="let element">
                {{ element.firstName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="lastName">
              <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
            </ng-container>

            <ng-container matColumnDef="username">
              <td mat-cell *matCellDef="let element">
                {{ element.username }}
              </td>
            </ng-container>

            <ng-container matColumnDef="delete">
              <td mat-cell *matCellDef="let element" class="icon-column">
                <button
                  mat-icon-button
                  type="button"
                  (click)="unselectUser(element)"
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr
              mat-row
              *matRowDef="let myDataArray; columns: displayedColumns"
            ></tr>
          </table>
        </div>
      </div>
    </mat-card>
  </div>
</form>
