<form [formGroup]="form" (ngSubmit)="save()">
  <h2 mat-dialog-title class="truncate-text">{{ getTitle() }}</h2>
  <mat-dialog-content>
    <div *ngIf="isShowHint()">
      Sensoren werden in einer Baumstruktur von Sensorgruppen organisiert. Bitte
      geben sie für das Wurzelelement einen Namen an. Dies ist gewöhnlich der
      Name das Unternehmens (z. B. "opwoco GmbH").
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="24px">
      <div fxFlex="100">
        <h4>Allgemein</h4>
      </div>
      <div fxFlex="100">
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input
            matInput
            placeholder="Name"
            type="text"
            formControlName="displayName"
          />
          <mat-error *ngIf="form.controls.displayName.hasError('maxlength')">
            Maximal 255 Zeichen!
          </mat-error>
        </mat-form-field>
      </div>
      <ng-container formGroupName="address">
        <div fxFlex="100">
          <mat-form-field appearance="outline">
            <mat-label>Straße und Hausnummer</mat-label>
            <input
              matInput
              placeholder="Straße und Hausnummer"
              type="text"
              formControlName="street"
            />
            <mat-error *ngIf="form.get('address.street').hasError('maxlength')">
              Maximal 255 Zeichen!
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="calc(50% - 24px)">
          <mat-form-field appearance="outline">
            <mat-label>Postleitzahl</mat-label>
            <input
              matInput
              placeholder="Postleitzahl"
              type="text"
              formControlName="zipCode"
            />
            <mat-error
              *ngIf="form.get('address.zipCode').hasError('maxlength')"
            >
              Maximal 5 Zeichen!
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="calc(50% - 24px)">
          <mat-form-field appearance="outline">
            <mat-label>Stadt</mat-label>
            <input
              matInput
              placeholder="Stadt"
              type="text"
              formControlName="city"
            />
            <mat-error *ngIf="form.get('address.city').hasError('maxlength')">
              Maximal 255 Zeichen!
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="100" fxLayoutAlign="center center">
          <button
            mat-raised-button
            class="upper-case"
            type="button"
            color="primary"
            style="margin-bottom: 24px"
            [disabled]="!enabled || !(data.parent && data.parent.address)"
            (click)="determineLocationFromPositioning()"
          >
            Von der Positionierung übernehmen
          </button>
          <span style="margin: 12px"></span>
          <button
            mat-raised-button
            class="upper-case"
            type="button"
            color="primary"
            style="margin-bottom: 24px"
            [disabled]="!enabled || !canGeocode()"
            (click)="geocodeAddress()"
          >
            Koordinaten ermitteln
          </button>
        </div>
        <div fxFlex="100">
          Hinweis: Eine Veränderung der Position auf der Karte beeinflusst nur
          die Koordinaten. Die eingetragene Adresse bleibt bestehen.
        </div>
        <div fxFlex="100">
          <google-map height="350px" width="100%" [options]="gmOptions">
            <map-polygon
              [options]="polygonOptions"
              [paths]="polygonPath"
            ></map-polygon>
          </google-map>
        </div>
      </ng-container>
      <div
        fxFlex="100"
        class="last-flex-element"
        formGroupName="geoAreaPolygon"
      >
        <mat-form-field appearance="outline" style="margin-top: 24px">
          <mat-label>Farbe</mat-label>
          <div
            style="width: 32px; height: 32px; border-radius: 6px"
            class="option-icon"
            [style.background]="form.value.geoAreaPolygon.color"
            *ngIf="form.value.geoAreaPolygon.color"
          ></div>
          <mat-select formControlName="color">
            <mat-option [value]="color" *ngFor="let color of colorOptions">
              <div
                [style.background]="color"
                style="width: 32px; height: 32px; border-radius: 6px"
              ></div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="100">
        <h3>Bild der Ebene</h3>
      </div>
      <div fxFlex="100" fxLayoutAlign="center start">
        <img
          [src]="image"
          class="image-preview"
          *ngIf="form.value.image"
          style="max-width: 100%"
        />
      </div>
      <div fxFlex="100" fxLayoutAlign="center start">
        <input
          style="display: none"
          type="file"
          (change)="onFileSelect($event)"
          accept="image/*"
          #hiddenfileinput
        />
        <button
          mat-button
          class="upper-case border-button"
          type="button"
          color="primary"
          [disabled]="!enabled"
          (click)="hiddenfileinput.click()"
        >
          Bild auswählen
        </button>
        <button
          mat-button
          color="warn"
          type="button"
          class="upper-case border-button"
          (click)="removeImage()"
          [disabled]="!enabled"
          *ngIf="form.value.image"
        >
          Bild entfernen
        </button>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-button
      mat-dialog-close
      type="button"
      [disabled]="savingInProgress"
    >
      Abbrechen
    </button>
    <button
      mat-button
      type="submit"
      [disabled]="!enabled || savingInProgress"
      [class.button-spinner]="savingInProgress"
    >
      Speichern
    </button>
  </mat-dialog-actions>
</form>
