import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/_core/services/api/api.service';
import { MeasurementAggregationInputDto } from 'src/app/_shared/dtos/measurements/measurement-aggregation-input.dto';
import { SharedSensorMeasurementsOutputDto } from 'src/app/_shared/dtos/shared-sensor-measurements-output.dto';
import { SensorPairingSharePatchDto } from 'src/app/organization/positioning/dtos/sensor-pairing-share-patch.dto';
import { SensorPairingShareOutputDto } from 'src/app/organization/positioning/dtos/sensor-pairing-share-output.dto';
import { SensorPairingSharePostDto } from 'src/app/organization/positioning/dtos/sensor-pairing-share-post.dto';

@Injectable({
  providedIn: 'root',
})
export class SensorPairingShareService {
  constructor(private apiService: ApiService) {}

  loadSensorShares(sensorId: number) {
    return this.apiService
      .get<SensorPairingShareOutputDto[]>(`/sensors/${sensorId}/shares`)
      .then((apiResult) => {
        if (!apiResult) {
          return [];
        }
        return apiResult;
      });
  }

  create(dto: SensorPairingSharePostDto) {
    return this.apiService.post<SensorPairingShareOutputDto>(
      '/sensors/shares',
      dto
    );
  }

  update(id, dto: SensorPairingSharePatchDto) {
    return this.apiService.patch<SensorPairingShareOutputDto>(
      `/sensors/shares/${id}`,
      dto
    );
  }

  delete(id) {
    return this.apiService.delete(`/sensors/shares/${id}`);
  }

  loadMeasurements(
    sensorPairingShareIdentifier: string,
    measurementAggregationInputDto: MeasurementAggregationInputDto
  ): Promise<void | SharedSensorMeasurementsOutputDto> {
    return this.apiService.get<SharedSensorMeasurementsOutputDto>(
      `/sensors/shares/measurements/${sensorPairingShareIdentifier}`,
      undefined,
      measurementAggregationInputDto
    );
  }
}
