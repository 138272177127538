import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-opened-icon',
  template: `
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      [style.height]="size + 'px'"
      [style.width]="size + 'px'"
    >
      <rect
        width="28"
        height="28"
        rx="6"
        [attr.fill]="backgroundColor ? backgroundColor : '#A370D6'"
      />
      <path
        d="M12.1663 6.66659V4.83325L21.333 7.58325V20.4166L12.1663 23.1666V21.3333V6.66659ZM12.1663 6.66659H7.12467V14.4583M8.95801 16.7499L4.83301 17.2083M9.41634 19.2708L5.29134 20.6458M9.87467 21.7916L7.12467 23.6249M14.9163 13.8899V14.1099L15.833 13.9999L14.9163 13.8899Z"
        [attr.stroke]="'white'"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [],
})
export class OpenedIconComponent implements OnInit {
  @Input() backgroundColor: string;
  @Input() size = 128;

  constructor() {}

  ngOnInit(): void {}
}
