import { forIn } from 'lodash';

export enum Roles {
  CITYLINK_OWNER = 'citylink_owner',
  CITYLINK_EDITOR = 'citylink_editor',
  CITYLINK_VIEWER = 'citylink_viewer',
  ORGANIZATION_OWNER = 'organization_owner',
  ORGANIZATION_EDITOR = 'organization_editor',
  ORGANIZATION_VIEWER = 'organization_viewer',
}

export function roleToLabelText(role: Roles) {
  switch (role) {
    case Roles.CITYLINK_OWNER:
      return 'Inhaber';
    case Roles.ORGANIZATION_OWNER:
      return 'Admin';
    case Roles.CITYLINK_EDITOR:
      return 'Admin';
    case Roles.ORGANIZATION_EDITOR:
      return 'Bearbeiter';
    case Roles.CITYLINK_VIEWER:
    case Roles.ORGANIZATION_VIEWER:
      return 'Betrachter';
  }
}

export function getIndexByRole(roleNameToCheck?: string) {
  let index = -1;
  let hierarchyIndex = Number.MAX_SAFE_INTEGER;
  forIn(Roles, (roleName) => {
    index++;
    if (roleName === roleNameToCheck) {
      hierarchyIndex = index;
    }
  });

  return hierarchyIndex;
}
