import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PositioningSensorDetailCardComponent } from './positioning-sensor-detail-card.component';
import { FlexModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { SensorMeasurementsChartModule } from 'src/app/organization/positioning/components/sensor-measurements-chart/sensor-measurements-chart.module';
import { MatTabsModule } from '@angular/material/tabs';
import { DashboardCurrentEventModule } from 'src/app/organization/dashboard/components/current-events/event/dashboard-current-event.module';
import { DateTimeModalModule } from 'src/app/_shared/components/date-time-modal/date-time-modal.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [PositioningSensorDetailCardComponent],
  exports: [PositioningSensorDetailCardComponent],
  imports: [
    CommonModule,
    FlexModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDialogModule,
    MatCardModule,
    SensorMeasurementsChartModule,
    MatTabsModule,
    DashboardCurrentEventModule,
    DateTimeModalModule,
    MatProgressSpinnerModule,
  ],
})
export class PositioningSensorDetailCardModule {}
