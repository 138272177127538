import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditSensorModalComponent } from 'src/app/organization/positioning/components/edit-sensor-modal/edit-sensor-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MultipleNotificationConfigSelectModule } from 'src/app/_shared/components/form-controls/multiple-notification-config-select/multiple-notification-config-select.module';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [EditSensorModalComponent],
  exports: [EditSensorModalComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    FlexModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MultipleNotificationConfigSelectModule,
    MatTableModule,
    MatIconModule,
    GoogleMapsModule,
    MatAutocompleteModule,
  ],
})
export class EditSensorModalModule {}
