import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicUtilityOrganizationModule } from 'src/app/public-utility/organization/public-utility-organization.module';
import { PublicUtilityDashboardModule } from 'src/app/public-utility/public-utility-dashboard/public-utility-dashboard.module';
import { SensorTypesModule } from 'src/app/public-utility/sensor-types/sensor-types.module';
import { PublicUtilityLegalSettingsModule } from 'src/app/public-utility/legal-settings/public-utility-legal-settings.module';
import { MeasurementParseErrorsModule } from 'src/app/public-utility/measurement-parse-errors/measurement-parse-errors.module';
import { GlobalPushModule } from 'src/app/public-utility/global-push/components/global-push.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PublicUtilityOrganizationModule,
    PublicUtilityDashboardModule,
    SensorTypesModule,
    PublicUtilityLegalSettingsModule,
    MeasurementParseErrorsModule,
    GlobalPushModule,
  ],
  exports: [
    PublicUtilityOrganizationModule,
    PublicUtilityDashboardModule,
    SensorTypesModule,
    PublicUtilityLegalSettingsModule,
    MeasurementParseErrorsModule,
    GlobalPushModule,
  ],
})
export class PublicUtilityModule {}
