import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SensorCsvImportComponent } from './sensor-csv-import.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [SensorCsvImportComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    FlexModule,
    MatTableModule,
    MatCheckboxModule,
    FormsModule,
    MatTooltipModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
  ],
})
export class SensorCsvImportModule {}
