import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { OrganizationService } from 'src/app/_shared/services/organization.service';
import { CitylinkOrganization } from 'src/app/public-utility/organization/models/citylink-organization';
import { MeasurementParseErrorService } from 'src/app/public-utility/measurement-parse-errors/services/measurement-parse-error.service';
import { MeasurementParseErrorGroupOutputDto } from 'src/app/public-utility/measurement-parse-errors/dto/measurement-parse-error.output';
import { MeasurementParseErrorPaginatedOutputDto } from 'src/app/public-utility/measurement-parse-errors/dto/measurement-parse-error-paginated.output';
import { MeasurementParseErrorGroupDetailOutputDto } from 'src/app/public-utility/measurement-parse-errors/dto/measurement-parse-error-detail.output';

@Injectable({
  providedIn: 'root',
})
export class MeasurementParseErrorGroupsDetailResolver
  implements Resolve<void | MeasurementParseErrorGroupDetailOutputDto> {
  constructor(
    private measurementParseErrorService: MeasurementParseErrorService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<void | MeasurementParseErrorGroupDetailOutputDto> {
    const id = Number(route.params.id);
    return this.measurementParseErrorService.getById(id);
  }
}
