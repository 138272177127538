import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from 'src/app/users/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserOutputDto } from 'src/app/users/dtos/user-output.dto';
import { MatDialog } from '@angular/material/dialog';
import { EditUserModalComponent } from 'src/app/users/components/edit-user-modal/edit-user-modal.component';
import { EditMode } from 'src/app/organization/positioning/components/edit-sensor-group-node-modal/edit-sensor-group-node-modal.component';
import { DeleteConfirmModalComponent } from 'src/app/_core/components/delete-confirm-modal/delete-confirm-modal.component';
import { Roles, roleToLabelText } from 'src/app/_core/models/roles';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';
import { User } from 'src/app/users/models/user';
import { UserFilterInputDto } from 'src/app/users/dtos/user-filter-input.dto';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-users',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
  enabled = false;
  displayedColumns = ['firstName', 'lastName', 'username', 'chips', 'options'];
  matTableDataSource = new MatTableDataSource<User>();
  userFilterDto: UserFilterInputDto = {};
  filterChangedSubject: Subject<void> = new Subject<void>();
  roleOptions = [];

  constructor(
    protected userService: UserService,
    protected router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.matTableDataSource.data = this.activatedRoute.snapshot.data.userList;

    const currentUserSession = this.authenticationService.getCurrentUserSession();
    // @ts-ignore
    this.enabled = [
      Roles.ORGANIZATION_OWNER,
      Roles.ORGANIZATION_EDITOR,
      Roles.CITYLINK_OWNER,
      Roles.CITYLINK_EDITOR,
    ].includes(currentUserSession?.role);
    this.initializeRoleOptions();

    this.filterChangedSubject.pipe(debounceTime(300)).subscribe(() => {
      this.userService.getUserList(true, this.userFilterDto).then((results) => {
        this.matTableDataSource.data = results;
      });
    });
  }

  deleteUser(element: UserOutputDto) {
    this.dialog
      .open(DeleteConfirmModalComponent)
      .afterClosed()
      .toPromise()
      .then((result) => {
        if (result === 'delete') {
          this.userService.deleteUser(element.id).then((deleted) => {
            this.refreshUserList();
          });
        }
      });
  }

  refreshUserList() {
    this.userService.getUserList().then((result) => {
      this.matTableDataSource.data = result;
    });
  }

  onAddClicked() {
    this.dialog
      .open(EditUserModalComponent, {
        width: '620px',
        data: { mode: EditMode.CREATE },
      })
      .afterClosed()
      .toPromise()
      .then((_) => {
        this.refreshUserList();
      });
  }

  resendInvitation(element: User) {
    this.userService.resendInvitation(element.id);
  }

  getRoleText(role) {
    return roleToLabelText(role.name);
  }

  isCurrentUser(user: User) {
    const profile = this.userService.loadProfileFromSession();
    if (!profile) {
      return false;
    }

    return profile.id === user.id;
  }

  updateUser(user: User) {
    if (this.isCurrentUser(user)) {
      return;
    }
    this.dialog
      .open(EditUserModalComponent, {
        width: '620px',
        data: { mode: EditMode.EDIT, user },
      })
      .afterClosed()
      .toPromise()
      .then((_) => {
        this.refreshUserList();
      });
  }

  initializeRoleOptions() {
    const role = this.authenticationService.getCurrentUserSession().role;
    switch (role) {
      case Roles.CITYLINK_EDITOR:
        this.roleOptions = [Roles.CITYLINK_VIEWER];
        break;
      case Roles.CITYLINK_OWNER:
        this.roleOptions = [Roles.CITYLINK_EDITOR, Roles.CITYLINK_VIEWER];
        break;
      case Roles.ORGANIZATION_EDITOR:
        this.roleOptions = [Roles.ORGANIZATION_VIEWER];
        break;
      case Roles.ORGANIZATION_OWNER:
        this.roleOptions = [
          Roles.ORGANIZATION_EDITOR,
          Roles.ORGANIZATION_VIEWER,
        ];
        break;
    }
  }

  getTextForRoleEnum(roleOption: Roles) {
    return roleToLabelText(roleOption);
  }

  onClearFilterClicked() {
    this.userFilterDto = {};
    this.filterChangedSubject.next();
  }

  isFilter() {
    return (
      this.userFilterDto.role ||
      this.userFilterDto.username ||
      this.userFilterDto.firstName ||
      this.userFilterDto.firstName
    );
  }
}
