import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { NotificationConfigIconOutputDto } from 'src/app/organization/notification-configuration/dto/notification-config-icon-output.dto';
import { NotificationConfigService } from 'src/app/organization/notification-configuration/services/notification-config.service';
import { SensorTypeConfig } from 'src/app/_shared/models/sensor-type-config';
import { SensorTypesService } from 'src/app/public-utility/sensor-types/services/sensor-types.service';
import { ApiService } from 'src/app/_core/services/api/api.service';
import { NotificationConfig } from 'src/app/organization/notification-configuration/models/notification-config';
import { InfoTextOutputDto } from 'src/app/organization/notification-configuration/dto/info-text-output.dto';
import { InfoTextService } from 'src/app/organization/notification-configuration/services/info-text.service';

@Injectable({
  providedIn: 'root',
})
export class InfoTextListResolver implements Resolve<InfoTextOutputDto[]> {
  constructor(private infoTextService: InfoTextService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<InfoTextOutputDto[]> {
    return this.infoTextService.getInfoTextList();
  }
}
