import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { OrganizationService } from 'src/app/_shared/services/organization.service';
import { Subject } from 'rxjs';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';
import { Roles } from 'src/app/_core/models/roles';
import { ErrorService } from 'src/app/_shared/services/error.service';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { DisposeBag } from '@ronas-it/dispose-bag/dist/src';

@Component({
  selector: 'app-mail-template-settings',
  templateUrl: './mail-template-settings.component.html',
  styleUrls: ['./mail-template-settings.component.scss'],
})
export class MailTemplateSettingsComponent implements OnInit, OnDestroy {
  @ViewChild('sensorNotificationEditor')
  sensorNotificationEditor: EditorComponent;
  @ViewChild('userInviteEditor') userInviteEditor: EditorComponent;
  @ViewChild('passwordResetEditor') passwordResetEditor: EditorComponent;

  sensorNotificationPlaceholderSubject = new Subject<string>();
  userInvitePlaceholderSubject = new Subject<string>();
  passwordResetPlaceholderSubject = new Subject<string>();
  disposeBag = new DisposeBag();

  tinyMceOptions = {
    base_url: '/tinymce',
    suffix: '.min',
    height: 500,
    menubar: false,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'media table paste code help',
    ],
    toolbar:
      'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code | help',
  };
  form: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private organizationService: OrganizationService,
    private authenticationService: AuthenticationService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    const templateConfig = this.activatedRoute.snapshot?.data?.templateConfig;

    if (templateConfig) {
      this.form = new FormGroup({
        sensorNotificationSubject: new FormControl(
          templateConfig.sensorNotificationSubject,
          [Validators.maxLength(255)]
        ),
        sensorNotification: new FormControl(templateConfig.sensorNotification, [
          Validators.maxLength(65535),
        ]),
        userInviteSubject: new FormControl(templateConfig.userInviteSubject, [
          Validators.maxLength(255),
        ]),
        userInvite: new FormControl(templateConfig.userInvite, [
          Validators.maxLength(65535),
        ]),
        passwordResetSubject: new FormControl(
          templateConfig.passwordResetSubject,
          [Validators.maxLength(255)]
        ),
        passwordReset: new FormControl(templateConfig.passwordReset, [
          Validators.maxLength(65535),
        ]),
      });
    } else {
      this.form = new FormGroup({
        sensorNotificationSubject: new FormControl(undefined, [
          Validators.maxLength(255),
        ]),
        sensorNotification: new FormControl('', [Validators.maxLength(65535)]),
        userInviteSubject: new FormControl(undefined, [
          Validators.maxLength(255),
        ]),
        userInvite: new FormControl(undefined, [Validators.maxLength(65535)]),
        passwordResetSubject: new FormControl(undefined, [
          Validators.maxLength(255),
        ]),
        passwordReset: new FormControl('', [Validators.maxLength(65535)]),
      });
    }

    this.disposeBag.add(
      this.sensorNotificationPlaceholderSubject.subscribe((placeholder) => {
        this.sensorNotificationEditor.editor.execCommand(
          'mceInsertContent',
          false,
          placeholder
        );
      })
    );
    this.disposeBag.add(
      this.userInvitePlaceholderSubject.subscribe((placeholder) => {
        this.userInviteEditor.editor.execCommand(
          'mceInsertContent',
          false,
          placeholder
        );
      })
    );
    this.disposeBag.add(
      this.passwordResetPlaceholderSubject.subscribe((placeholder) => {
        this.passwordResetEditor.editor.execCommand(
          'mceInsertContent',
          false,
          placeholder
        );
      })
    );
  }

  ngOnDestroy() {
    this.disposeBag.unsubscribe();
  }

  save() {
    if (!this.form.valid) {
      this.errorService.showError({
        userErrorMessage:
          'Ihre EIngabe ist nicht gültig. Bitte überprüfen sie Ihre Eingabe.',
      });
      return;
    }

    this.organizationService.updateMailTemplateConfiguration(this.form.value);
  }

  isOrganizationUser() {
    // @ts-ignore
    return [Roles.ORGANIZATION_OWNER, Roles.ORGANIZATION_EDITOR].includes(
      this.authenticationService.getCurrentUserSession().role
    );
  }
}
