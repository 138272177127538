<div
  fxLayout="column"
  class="group-row"
  [class.hover-pointer]="true"
  (click)="onClick()"
  [draggable]="enabled"
  (dragenter)="onDragEnter($event)"
  (dragleave)="onDragLeave($event)"
  (drop)="onDrop($event)"
  (dragstart)="onDragStart($event)"
  (dragend)="onDragEnd($event)"
  (dragover)="$event.preventDefault()"
  #groupRow
>
  <div
    fxFlex="100"
    fxLayout="row"
    [class.selected]="sensorGroupNode.selected"
    class="selection-wrapper"
  >
    <div fxFlex="40px" fxFlexAlign="center">
      <mat-icon class="drag-indicator">drag_indicator</mat-icon>
    </div>
    <div fxLayout="column" fxFlex="calc(100%-80px)">
      <div class="group-title truncate-text">
        {{ sensorGroupNode.displayName }}
      </div>
      <div class="hint-text">
        {{ getGroupChildrenCount(sensorGroupNode) }} Unterebenen,
        {{ getSensorCount(sensorGroupNode) }} Sensoren
      </div>
    </div>
    <div fxFlex="40px" fxFlexAlign="center" style="text-align: right">
      <button
        mat-icon-button
        class="options-button"
        [matMenuTriggerFor]="menu"
        (click)="$event.stopPropagation()"
      >
        <mat-icon class="options">more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="editGroup(sensorGroupNode)">
          <mat-icon>create</mat-icon>
          Bearbeiten
        </button>
        <button mat-menu-item (click)="removeGroup()" [disabled]="!enabled">
          <mat-icon>delete_outline</mat-icon>
          Unwiederruflich löschen
        </button>
      </mat-menu>
    </div>
  </div>
</div>
