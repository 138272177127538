import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddPlaceholderDirective } from 'src/app/_shared/directives/add-placeholder-directive/add-placeholder.directive';

@NgModule({
  declarations: [AddPlaceholderDirective],
  exports: [AddPlaceholderDirective],
  imports: [CommonModule],
})
export class AddPlaceholderDirectiveModule {}
