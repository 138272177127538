import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LegalSettingsService } from 'src/app/public-utility/legal-settings/services/legal-settings.service';
import { ActivatedRoute } from '@angular/router';
import { LegalOutputDto } from 'src/app/public-utility/legal-settings/dto/legal-output.dto';

@Component({
  selector: 'app-legal-settings',
  templateUrl: './legal-settings.component.html',
  styleUrls: ['./legal-settings.component.scss'],
})
export class LegalSettingsComponent implements OnInit {
  form: any;

  constructor(
    private legalSettingsService: LegalSettingsService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const privacy: LegalOutputDto = this.activatedRoute.snapshot.data.data
      .privacy;
    const imprint: LegalOutputDto = this.activatedRoute.snapshot.data.data
      .imprint;
    const gtc: LegalOutputDto = this.activatedRoute.snapshot.data.data.gtc;

    this.form = new FormGroup({
      privacy: new FormGroup({
        url: new FormControl(privacy?.url, [Validators.maxLength(255)]),
      }),
      imprint: new FormGroup({
        url: new FormControl(imprint?.url, [Validators.maxLength(255)]),
      }),
      gtc: new FormGroup({
        url: new FormControl(gtc?.url, [Validators.maxLength(255)]),
      }),
    });
  }

  save() {
    const privacy = this.form.value.privacy;
    const imprint = this.form.value.imprint;
    const gtc = this.form.value.gtc;
    this.legalSettingsService.savePrivacy(privacy);
    this.legalSettingsService.saveImprint(imprint);
    this.legalSettingsService.saveGtc(gtc);
  }
}
