import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Roles } from 'src/app/_core/models/roles';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';
import { SensorTypeConfig } from 'src/app/_shared/models/sensor-type-config';
import { Operator, OperatorBase } from 'src/app/_core/models/operator';
import { MeasurementValueTypes } from 'src/app/_shared/models/measurement-value-types';
import { SensorSelectModalComponent } from 'src/app/organization/notification-configuration/components/sensor-select-modal/sensor-select-modal.component';
import { Sensor } from 'src/app/_shared/models/sensor';
import { MatDialog } from '@angular/material/dialog';
import { GoogleMapsService } from 'src/app/_shared/services/google-maps.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SensorStateIndicatorConfigurationService } from 'src/app/organization/sensor-state-indicator-configuration/services/sensor-state-indicator-configuration.service';
import { SensorStateIndicatorConfigurationPostDto } from 'src/app/organization/sensor-state-indicator-configuration/dto/sensor-state-indicator-configuration-post.dto';
import { SensorStateIndicatorConfigurationPatchDto } from 'src/app/organization/sensor-state-indicator-configuration/dto/sensor-state-indicator-configuration-patch.dto';
import { SensorStateIndicatorConfigurationOutputDto } from 'src/app/organization/sensor-state-indicator-configuration/dto/sensor-state-indicator-configuration-output.dto';
import { DisposeBag } from '@ronas-it/dispose-bag/dist/src';
import { SensorState } from 'src/app/_shared/models/sensor-state';

@Component({
  selector: 'app-sensor-state-indicator-configuration-edit',
  templateUrl: './sensor-state-indicator-configuration-edit.component.html',
  styleUrls: ['./sensor-state-indicator-configuration-edit.component.scss'],
})
export class SensorStateIndicatorConfigurationEditComponent
  implements OnInit, OnDestroy {
  form: FormGroup;
  edit = false;
  buttonSaveLoading = false;
  enabled = false;
  sensorTypeConfigs: SensorTypeConfig[] = [];
  sensors: Sensor[] = [];
  sensorStateIndicatorConfiguration: SensorStateIndicatorConfigurationOutputDto;
  disposeBag = new DisposeBag();

  constructor(
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private googleMapsService: GoogleMapsService,
    private domSanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private sensorStateIndicatorConfigurationService: SensorStateIndicatorConfigurationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const currentUserSession = this.authenticationService.getCurrentUserSession();
    // @ts-ignore
    this.enabled = [
      Roles.ORGANIZATION_OWNER,
      Roles.ORGANIZATION_EDITOR,
    ].includes(currentUserSession?.role);
    this.sensorTypeConfigs =
      this.route.snapshot.data?.data?.sensorTypeConfigs || [];
    this.sensors = this.route.snapshot.data?.data?.sensors || [];

    if (this.route.snapshot.data.data.sensorStateIndicatorConfiguration) {
      this.initEdit();
    } else {
      this.initCreate();
    }

    this.disposeBag.add(
      this.form.controls.red.valueChanges.subscribe((value) =>
        this.patchGreenValueIfNecessary(value)
      )
    );
    this.disposeBag.add(
      this.form.controls.redOperator.valueChanges.subscribe((_) => {
        setTimeout(
          () => this.patchGreenValueIfNecessary(this.form.value.red),
          2
        );
      })
    );
  }

  ngOnDestroy() {
    this.disposeBag.unsubscribe();
  }

  save() {
    const dto:
      | SensorStateIndicatorConfigurationPostDto
      | SensorStateIndicatorConfigurationPatchDto = {
      title: this.form.value.title,
      operator: this.form.value.redOperator,
      red: this.form.value.red,
      green: this.form.value.green,
      sensorIds: this.form.value.sensorIds,
      sensorTypeConfigId: this.form.value.sensorTypeConfig.id,
      sensorPropertyConfigId: this.form.value.sensorPropertyConfig.id,
    };

    if (this.edit) {
      this.sensorStateIndicatorConfigurationService
        .patch(
          dto as SensorStateIndicatorConfigurationPatchDto,
          this.sensorStateIndicatorConfiguration.id
        )
        .then(async (_) => {
          await new Promise((resolve) => setTimeout(resolve, 1000)).then(
            (__) => {
              this.router.navigateByUrl(
                'organization/sensor-state-indicator-configuration'
              );
            }
          );
        });
    } else {
      this.sensorStateIndicatorConfigurationService
        .post(dto as SensorStateIndicatorConfigurationPostDto)
        .then(async (_) => {
          await new Promise((resolve) => setTimeout(resolve, 1000)).then(
            (__) => {
              this.router.navigateByUrl(
                'organization/sensor-state-indicator-configuration'
              );
            }
          );
        });
    }
  }

  filterOperators() {
    if (
      !this.form.controls.sensorPropertyConfig.value ||
      !this.form.controls.sensorTypeConfig.value
    ) {
      return [];
    }

    // FIXME Code-Duplication! Very Bad!
    const sensorPropertyConfig = this.form.controls.sensorPropertyConfig.value;
    switch (sensorPropertyConfig.propertyType) {
      case MeasurementValueTypes.BOOLEAN:
        return [OperatorBase.DIFF, OperatorBase.EQUAL, OperatorBase.NOT_EQUAL];
      case MeasurementValueTypes.NOMINAL:
        return [OperatorBase.DIFF, OperatorBase.EQUAL, OperatorBase.NOT_EQUAL];
      case MeasurementValueTypes.NUMERIC:
        return Operator.entries();
    }
  }

  getOperatorLabel(type: OperatorBase) {
    return Operator.translate(type);
  }

  determineValueTextfieldType() {
    if (
      !this.form.controls.sensorPropertyConfig.value ||
      this.form.controls.sensorPropertyConfig.value.propertyType ===
        MeasurementValueTypes.NUMERIC
    ) {
      return 'number';
    }

    return 'text';
  }

  showValueTextField() {
    if (
      !this.form.controls.sensorPropertyConfig.value ||
      !this.form.controls.sensorTypeConfig.value
    ) {
      return true;
    }

    if (
      this.form.controls.sensorPropertyConfig.value.propertyType ===
        MeasurementValueTypes.NUMERIC ||
      this.form.controls.sensorPropertyConfig.value.propertyType ===
        MeasurementValueTypes.NOMINAL
    ) {
      return true;
    }

    return false;
  }

  addSensorClicked() {
    this.dialog
      .open(SensorSelectModalComponent, {
        width: '600px',
        data: {
          sensors: this.sensors.filter((item) => {
            const alreadySelected = this.form.value.sensorIds.includes(item.id);
            const correctSensorType =
              item.sensorTypeConfig &&
              this.form.value.sensorTypeConfig &&
              item.sensorTypeConfig.id === this.form.value.sensorTypeConfig.id;
            return !alreadySelected && correctSensorType;
          }),
        },
      })
      .afterClosed()
      .toPromise()
      .then((res) => {
        const selectedSensors = this.form.value.sensorIds
          ? this.form.value.sensorIds
          : [];
        if (Array.isArray(res)) {
          res.forEach((item) => selectedSensors.push(item));
        } else if (res !== null && res !== undefined) {
          selectedSensors.push(res);
        }
        this.form.patchValue({
          sensorIds: selectedSensors,
        });
      });
  }

  selectedSensors() {
    if (Array.isArray(this.sensors)) {
      return this.sensors.filter((item) => {
        return this.form.value.sensorIds?.includes(item.id);
      });
    }
    return [];
  }

  hasSensorCorrectSensorType(sensor: Sensor) {
    const sensorTypeConfig = this.form.value.sensorTypeConfig;

    if (!sensorTypeConfig || !sensor.sensorTypeConfig) {
      return false;
    }

    return sensor.sensorTypeConfig.id !== sensorTypeConfig.id;
  }

  removeSensor(sensor: Sensor) {
    const selectedSensors = this.form.value.sensorIds
      ? this.form.value.sensorIds
      : [];
    selectedSensors.splice(selectedSensors.indexOf(sensor.id), 1);
    this.form.patchValue({
      sensorIds: selectedSensors,
    });
  }

  redMapIcon() {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(
      this.googleMapsService.generateMarkerIcon(SensorState.RED, 'point')
    );
  }

  yellowMapIcon() {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(
      this.googleMapsService.generateMarkerIcon(SensorState.YELLOW, 'point')
    );
  }

  greenMapIcon() {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(
      this.googleMapsService.generateMarkerIcon(SensorState.GREEN, 'point')
    );
  }

  selectedOperatorToLabel() {
    if (
      this.form.value.redOperator !== undefined &&
      this.form.value.redOperator !== null
    ) {
      return Operator.translate(this.form.value.redOperator);
    }

    return '';
  }

  oppositeOfSelectedOperatorToLabel() {
    if (
      this.form.value.redOperator !== undefined &&
      this.form.value.redOperator !== null
    ) {
      return Operator.translate(Operator.opposite(this.form.value.redOperator));
    }
    return '';
  }

  isNumericalOperator() {
    return [
      OperatorBase.GREATER_THAN_OR_EQUAL,
      OperatorBase.GREATER_THAN,
      OperatorBase.LESS_THAN_OR_EQUAL,
      OperatorBase.LESS_THAN,
    ].includes(this.form.controls.redOperator.value);
  }

  private patchGreenValueIfNecessary(value: any) {
    if (!this.form.value.redOperator) {
      return;
    }

    switch (this.form.value.redOperator) {
      case OperatorBase.DIFF:
      case OperatorBase.EQUAL:
      case OperatorBase.NOT_EQUAL:
        this.form.patchValue({
          green: !value,
        });
        break;
    }
  }

  greenValueDisabled() {
    switch (this.form.value.redOperator) {
      case OperatorBase.DIFF:
      case OperatorBase.EQUAL:
      case OperatorBase.NOT_EQUAL:
        return true;
      default:
        return false;
    }
  }

  private initCreate() {
    this.form = new FormGroup({
      title: new FormControl(undefined, [
        Validators.required,
        Validators.maxLength(255),
      ]),
      sensorTypeConfig: new FormControl(undefined, [Validators.required]),
      sensorPropertyConfig: new FormControl(undefined, [Validators.required]),
      redOperator: new FormControl(undefined, [Validators.required]),
      red: new FormControl(undefined, []),
      greenOperator: new FormControl(undefined),
      green: new FormControl(undefined, []),
      sensorIds: new FormControl([]),
    });
  }

  private initEdit() {
    this.edit = true;
    this.sensorStateIndicatorConfiguration = this.route.snapshot.data.data.sensorStateIndicatorConfiguration;
    const sensorTypeConfig = this.sensorTypeConfigs?.filter(
      (item) =>
        item.id === this.sensorStateIndicatorConfiguration.sensorTypeConfig?.id
    )[0];
    const sensorPropertyConfig = sensorTypeConfig?.sensorPropertyConfigs?.filter(
      (item) =>
        item.id ===
        this.sensorStateIndicatorConfiguration.sensorPropertyConfig?.id
    )[0];

    this.form = new FormGroup({
      title: new FormControl(this.sensorStateIndicatorConfiguration.title, [
        Validators.required,
        Validators.maxLength(255),
      ]),
      sensorTypeConfig: new FormControl(sensorTypeConfig, [
        Validators.required,
      ]),
      sensorPropertyConfig: new FormControl(sensorPropertyConfig, [
        Validators.required,
      ]),
      redOperator: new FormControl(
        this.sensorStateIndicatorConfiguration.operator,
        [Validators.required]
      ),
      red: new FormControl(this.sensorStateIndicatorConfiguration.red, []),
      greenOperator: new FormControl(undefined),
      green: new FormControl(this.sensorStateIndicatorConfiguration.green, []),
      sensorIds: new FormControl(
        this.sensorStateIndicatorConfiguration.sensorIds
      ),
    });
  }

  sensorTypeToLabel(sensorType: SensorTypeConfig) {
    return `${sensorType.displayName} (${sensorType.niotaDeviceTypeId})`;
  }
}
