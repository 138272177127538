import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-imprint',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss'],
})
export class LegalComponent implements OnInit {
  legalUrl: SafeResourceUrl;

  constructor(
    private activatedRoute: ActivatedRoute,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.legalUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
      environment.apiUrl +
        '/legal/' +
        this.activatedRoute.snapshot.data.context +
        '.html'
    );
  }
}
