import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationAccountListItemModule } from 'src/app/navigation/components/account-list-item/navigation-account-list-item.module';
import { NavigationLogoutListItemModule } from 'src/app/navigation/components/logout-list-item/navigation-logout-list-item.module';
import { NavigationMenuListItemModule } from 'src/app/navigation/components/menu-list-item/navigation-menu-list-item.module';

@NgModule({
  declarations: [],
  exports: [],
  imports: [
    CommonModule,
    NavigationAccountListItemModule,
    NavigationLogoutListItemModule,
    NavigationMenuListItemModule,
  ],
})
export class NavigationModule {}
