import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SensorService } from 'src/app/_shared/services/sensor.service';
import { SensorInputDto } from 'src/app/_shared/dtos/sensor-input.dto';
import { ActivatedRoute, Router } from '@angular/router';
import { CitylinkSensorDetail } from 'src/app/public-utility/sensors/models/citylink-sensor-detail';
import { Organization } from 'src/app/public-utility/organization/models/organization';
import { OrganizationSelectModalComponent } from 'src/app/public-utility/sensors/components/organization-select-modal/organization-select-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { HexValidator } from 'src/app/_shared/validators/hex-validator';

@Component({
  selector: 'app-sensor-edit',
  templateUrl: './sensor-edit.component.html',
  styleUrls: ['./sensor-edit.component.scss'],
})
export class SensorEditComponent implements OnInit {
  form: FormGroup;
  sensorTypeConfigs = [];
  isCreate = true;
  organizations: Organization[] = [];

  constructor(
    private sensorService: SensorService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.sensorTypeConfigs = this.activatedRoute.snapshot.data.data.sensorTypeConfigs;
    this.organizations = this.activatedRoute.snapshot.data.data.organizations;

    if (this.activatedRoute.snapshot.data.data.sensor) {
      const sensor: CitylinkSensorDetail = this.activatedRoute.snapshot.data
        .data.sensor;
      this.isCreate = false;

      const selectedSensorTypeConfig = this.sensorTypeConfigs.filter(
        (value) => value.id === sensor.sensorTypeConfig?.id
      )[0];

      this.form = new FormGroup({
        id: new FormControl(sensor.id),
        title: new FormControl(sensor.displayName, [
          Validators.required,
          Validators.maxLength(255),
        ]),
        eui: new FormControl(sensor.metaData.eui, [
          Validators.required,
          HexValidator.hex,
          Validators.minLength(16),
          Validators.maxLength(16),
        ]),
        sensorTypeConfig: new FormControl(selectedSensorTypeConfig, [
          Validators.required,
        ]),
        organizationIds: new FormControl(
          sensor.organizations
            ? sensor.organizations.map((item) => item.id)
            : []
        ),
        // Niota stuff
        applyChangesInIotPlatform: new FormControl(
          sensor.applyChangesInIotPlatform
        ),
        applSessionKey: new FormControl(
          {
            value: sensor.applSessionKey,
            disabled: !sensor.applyChangesInIotPlatform,
          },
          sensor.applyChangesInIotPlatform
            ? [
                Validators.required,
                HexValidator.hex,
                Validators.minLength(32),
                Validators.maxLength(32),
              ]
            : undefined
        ),
        applKey: new FormControl(
          {
            value: sensor.applKey,
            disabled: !sensor.applyChangesInIotPlatform,
          },
          [HexValidator.hex, Validators.minLength(32), Validators.maxLength(32)]
        ),
        deviceAddress: new FormControl(
          {
            value: sensor.deviceAddress,
            disabled: !sensor.applyChangesInIotPlatform,
          },
          sensor.applyChangesInIotPlatform
            ? [
                Validators.required,
                HexValidator.hex,
                Validators.minLength(8),
                Validators.maxLength(8),
              ]
            : undefined
        ),
        description: new FormControl({
          value: sensor.description,
          disabled: !sensor.applyChangesInIotPlatform,
        }),
        netwSessionKey: new FormControl(
          {
            value: sensor.netwSessionKey,
            disabled: !sensor.applyChangesInIotPlatform,
          },
          [HexValidator.hex, Validators.minLength(32), Validators.maxLength(32)]
        ),
      });
    } else {
      this.form = new FormGroup({
        title: new FormControl(undefined, [
          Validators.required,
          Validators.maxLength(255),
        ]),
        eui: new FormControl(undefined, [
          Validators.required,
          Validators.minLength(16),
          Validators.maxLength(32),
          HexValidator.hex,
        ]),
        sensorTypeConfig: new FormControl(undefined, [Validators.required]),
        organizationIds: new FormControl([]),
        // Niota stuff
        applyChangesInIotPlatform: new FormControl(false),
        applSessionKey: new FormControl({ value: undefined, disabled: true }),
        applKey: new FormControl({ value: undefined, disabled: true }),
        deviceAddress: new FormControl({ value: undefined, disabled: true }),
        description: new FormControl({ value: undefined, disabled: true }),
        netwSessionKey: new FormControl({ value: undefined, disabled: true }),
      });
    }
  }

  save() {
    if (!this.form.valid) {
      return;
    }

    const dto: SensorInputDto = {
      title: this.form.value.title,
      eui: this.form.value.eui,
      organizationIds: this.form.value.organizationIds || [],
      applyChangesInIotPlatform: this.form.value.applyChangesInIotPlatform,
      sensorTypeConfig: this.form.value.sensorTypeConfig.id,
      applSessionKey: this.form.value.applSessionKey,
      applKey: this.form.value.applKey,
      deviceAddress: this.form.value.deviceAddress,
      description: this.form.value.description,
      netwSessionKey: this.form.value.netwSessionKey,
    };

    if (this.isCreate) {
      this.sensorService.create(dto).then((_) => {
        this.router.navigateByUrl('/public-utility/sensors');
      });
    } else {
      this.sensorService.update(dto, this.form.value.id).then((_) => {
        this.router.navigateByUrl('/public-utility/sensors');
      });
    }
  }

  updateValidators() {
    if (!this.form.value.applyChangesInIotPlatform) {
      this.form.controls.applSessionKey.setValidators([]);
      this.form.controls.applSessionKey.disable();
      this.form.controls.applKey.setValidators([]);
      this.form.controls.applKey.disable();
      this.form.controls.deviceAddress.setValidators([]);
      this.form.controls.deviceAddress.disable();
      this.form.controls.description.setValidators([]);
      this.form.controls.description.disable();
      this.form.controls.netwSessionKey.setValidators([]);
      this.form.controls.netwSessionKey.disable();
    } else {
      this.form.controls.applSessionKey.setValidators([
        /*Validators.required,*/ HexValidator.hex,
        Validators.minLength(32),
        Validators.maxLength(32),
      ]);
      this.form.controls.applSessionKey.enable();
      this.form.controls.applKey.setValidators([
        Validators.required,
        HexValidator.hex,
        Validators.minLength(32),
        Validators.maxLength(32),
      ]);
      this.form.controls.applKey.enable();
      this.form.controls.deviceAddress.setValidators([
        Validators.required,
        HexValidator.hex,
        Validators.minLength(8),
        Validators.maxLength(8),
      ]);
      this.form.controls.deviceAddress.enable();
      this.form.controls.description.setValidators([]);
      this.form.controls.description.enable();
      this.form.controls.netwSessionKey.setValidators([
        /*Validators.required,*/ HexValidator.hex,
        Validators.minLength(32),
        Validators.maxLength(32),
      ]);
      this.form.controls.netwSessionKey.enable();
    }
    this.form.updateValueAndValidity();
  }

  addOrganizationClicked() {
    this.dialog
      .open(OrganizationSelectModalComponent, {
        width: '600px',
        data: {
          organizations: this.organizations.filter(
            (item) => !this.form.value.organizationIds.includes(item.id)
          ),
        },
      })
      .afterClosed()
      .toPromise()
      .then((res) => {
        const selectedOrganizations = this.form.value.organizationIds
          ? this.form.value.organizationIds
          : [];
        if (Array.isArray(res)) {
          res.forEach((item) => selectedOrganizations.push(item));
        } else if (res !== null && res !== undefined) {
          selectedOrganizations.push(res);
        }
        this.form.patchValue({
          organizationIds: selectedOrganizations,
        });
      });
  }

  selectedOrganizations(): Organization[] {
    if (Array.isArray(this.organizations)) {
      return this.organizations.filter((item) => {
        return this.form.value.organizationIds?.includes(item.id);
      });
    }
    return [];
  }

  removeOrganization(organization: Organization) {
    const selectedOrganizations = this.form.value.organizationIds
      ? this.form.value.organizationIds
      : [];
    selectedOrganizations.splice(
      selectedOrganizations.indexOf(organization.id),
      1
    );
    this.form.patchValue({
      organizationIds: selectedOrganizations,
    });
  }
}
