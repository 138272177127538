import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SensorTypeConfig } from 'src/app/_shared/models/sensor-type-config';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SensorInputDto } from 'src/app/_shared/dtos/sensor-input.dto';
import { SensorService } from 'src/app/_shared/services/sensor.service';
import { ErrorService } from 'src/app/_shared/services/error.service';
import { CitylinkSensorDetail } from 'src/app/public-utility/sensors/models/citylink-sensor-detail';
import { Organization } from 'src/app/public-utility/organization/models/organization';
import { HexValidator } from 'src/app/_shared/validators/hex-validator';
import { SnackBarService } from 'src/app/_shared/services/snack-bar.service';

@Component({
  selector: 'app-sensor-list-edit',
  templateUrl: './sensor-list-edit.component.html',
  styleUrls: ['./sensor-list-edit.component.scss'],
})
export class SensorListEditComponent implements OnInit {
  sensorTypeConfigs: SensorTypeConfig[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns = [
    'warning',
    'displayName',
    'eui',
    'sensorTypeConfig',
    'organizations',
    'applSessionKey',
    'applKey',
    'deviceAddress',
    'netwSessionKey',
    'description',
    'applyChangesInIotPlatform',
  ];
  @ViewChild(MatTable, { static: false }) table;
  form: FormGroup;
  organizations: Organization[] = [];

  constructor(
    private route: ActivatedRoute,
    private sensorService: SensorService,
    private errorService: ErrorService,
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    this.sensorTypeConfigs = this.route.snapshot.data.data.sensorTypeConfigs;
    this.organizations = this.route.snapshot.data.data.organizations;
    const sensorsToShow: CitylinkSensorDetail[] = this.route.snapshot.data.data
      .sensors;

    const sensorFormGroups = sensorsToShow.map((sensor) => {
      return new FormGroup({
        id: new FormControl(sensor.id),
        warning: new FormControl(false),
        customWarningMessage: new FormControl(undefined),
        displayName: new FormControl(sensor.displayName),
        eui: new FormControl(sensor.metaData.eui),
        sensorTypeConfig: new FormControl(
          this.sensorTypeConfigs.filter(
            (sensorTypeConfig) =>
              sensorTypeConfig.niotaDeviceTypeId ===
              sensor.sensorTypeConfig?.niotaDeviceTypeId
          )[0]
        ),
        organizationIds: new FormControl(
          sensor.organizations.map((orga) => orga.id)
        ),
        applSessionKey: new FormControl(sensor.applSessionKey),
        applKey: new FormControl(sensor.applKey),
        deviceAddress: new FormControl(sensor.deviceAddress),
        netwSessionKey: new FormControl(sensor.netwSessionKey),
        description: new FormControl(sensor.description),
        applyChangesInIotPlatform: new FormControl(
          Boolean(sensor.applyChangesInIotPlatform)
        ),
      });
    });
    sensorFormGroups.forEach((item) => this.validateRow(item));

    this.form = this.formBuilder.group({
      sensors: this.formBuilder.array(sensorFormGroups),
    });

    this.dataSource.data = (this.form.get('sensors') as FormArray).controls;
  }

  validateRow(rowData) {
    if (
      !rowData.value.displayName ||
      !rowData.value.eui ||
      !rowData.value.sensorTypeConfig
    ) {
      rowData.patchValue({
        warning: true,
        customWarningMessage:
          'Anzeigename, EUI und ein Sensortyp müssen angegeben werden!',
      });
      return;
    }

    if (rowData.value.applyChangesInIotPlatform) {
      if (
        !rowData.value.applSessionKey ||
        (rowData.value.applSessionKey.length !== 32 &&
          HexValidator.hex(rowData.get('applSessionKey') as FormControl)) ||
        !rowData.value.applKey ||
        (rowData.value.applKey.length !== 32 &&
          HexValidator.hex(rowData.get('applKey') as FormControl)) ||
        !rowData.value.deviceAddress ||
        (rowData.value.deviceAddress.length !== 8 &&
          HexValidator.hex(rowData.get('deviceAddress') as FormControl)) ||
        !rowData.value.netwSessionKey ||
        (rowData.value.netwSessionKey.length !== 32 &&
          HexValidator.hex(rowData.get('netwSessionKey') as FormControl))
      ) {
        rowData.patchValue({
          warning: true,
          customWarningMessage:
            'applSessionKey (32 Hex-Zeichen), applKey (32 Hex-Zeichen), deviceAddress (8 Hex-Zeichen) und netwSessionKey (32 Hex-Zeichen) müssen angegeben werden!',
        });
        return;
      }
    }
    rowData.patchValue({
      warning: false,
    });
  }

  isSaveable() {
    return (
      Array.isArray(this.dataSource.data) &&
      this.dataSource.data.length > 0 &&
      this.dataSource.data.reduce(
        (currentValue, item) => !item.value.warning || currentValue,
        true
      )
    );
  }

  async doSave() {
    const saved = [];
    const errorSaved = [];

    const promises = [];
    for (const rowData of this.dataSource.data) {
      if (rowData.value.warning) {
        continue;
      }

      const dto: SensorInputDto = {
        title: rowData.value.displayName || undefined,
        eui: rowData.value.eui || undefined,
        organizationIds: rowData.value.organizationIds || [],
        applKey: rowData.value.applKey || undefined,
        applSessionKey: rowData.value.applSessionKey || undefined,
        deviceAddress: rowData.value.deviceAddress || undefined,
        netwSessionKey: rowData.value.netwSessionKey || undefined,
        sensorTypeConfig: rowData.value.sensorTypeConfig?.id || undefined,
        applyChangesInIotPlatform:
          rowData.value.applyChangesInIotPlatform || false,
        description: rowData.value.description || undefined,
      };
      promises.push(
        this.sensorService
          .update(dto, rowData.value.id, false, false)
          .then((_) => {
            saved.push(rowData.value);
          })
          .catch((err) => {
            errorSaved.push(rowData.value);
            console.log(JSON.stringify(err));
            if (err.error) {
              const errorMessage = this.errorService.determineCitylinkErrorMessage(
                err.error
              );
              rowData.patchValue({
                warning: true,
                customWarningMessage:
                  errorMessage.userErrorMessage +
                  (errorMessage.technicalErrorMessage
                    ? '\n Techn. Fehlermeldung:' +
                      errorMessage.technicalErrorMessage
                    : ''),
              });
            } else {
              rowData.patchValue({
                warning: true,
              });
            }
          })
      );
    }
    await Promise.all(promises);

    this.snackBarService.showCustomMessage(
      `${saved.length} Sensoren erfolgreich Gespeichert. ${errorSaved.length} Sensoren nicht erfolgreich gespeichert.`
    );

    if (
      this.dataSource.data.filter((item) => item.value.warning).length === 0
    ) {
      new Promise((resolve) => setTimeout(resolve, 500)).then((_) => {
        this.router.navigateByUrl('/public-utility/sensors');
      });
    }

    for (const importedSensor of saved) {
      const index = this.form.value.sensors
        .map((item) => item.eui)
        .indexOf(importedSensor.eui);
      const sensorsFormArray = this.form.controls.sensors as FormArray;
      if (index >= 0) {
        sensorsFormArray.removeAt(index);
      }
    }

    this.dataSource.data = (this.form.get('sensors') as FormArray).controls;
    this.table.renderRows();
  }
}
