<div fxLayout="row" style="margin-top: 16px">
  <div fxFlex="48px" fxLayoutAlign="center center" class="event">
    <img *ngIf="iconUrl" [attr.src]="iconUrl" class="notification-icon" />
  </div>
  <div fxFlex fxLayout="row wrap" class="event">
    <div fxFlex="60" class="event-title">{{ title }}</div>
    <div fxFlex="40" class="event-time">
      {{ date | date: "dd.MM.yyyy HH:mm:ss" }}
    </div>
    <div fxFlex="100" class="event-subtitle">{{ subtitle }}</div>
    <div fxFlex="75" class="event-location">{{ location }}</div>
    <div
      fxFlex="25"
      class="event-type"
      color="red"
      [class.text-danger]="isError()"
      [class.text-warning]="isWarning()"
      [class.text-muted]="isDefault()"
    >
      {{ getSensorEventTypeText() }}
    </div>
  </div>
</div>
<mat-divider class="event-divider" *ngIf="showDivider"></mat-divider>
