import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-water-leakage-icon',
  template: `
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      [style.height]="size + 'px'"
      [style.width]="size + 'px'"
    >
      <rect
        width="28"
        height="28"
        rx="6"
        [attr.fill]="backgroundColor ? backgroundColor : '#1AABE9'"
      />
      <path
        d="M22.25 15.8334C22.25 9.41675 14 3.91675 14 3.91675C14 3.91675 5.75 9.41675 5.75 15.8334C5.75 18.0215 6.61919 20.1199 8.16637 21.667C9.71354 23.2142 11.812 24.0834 14 24.0834C16.188 24.0834 18.2865 23.2142 19.8336 21.667C21.3808 20.1199 22.25 18.0215 22.25 15.8334Z"
        [attr.stroke]="'white'"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.0279 19.5555C9.99645 18.5006 9.41699 17.0699 9.41699 15.578C9.41699 13.3905 11.2503 11.3594 12.6253 9.875"
        [attr.stroke]="'white'"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [],
})
export class WaterLeakageIconComponent implements OnInit {
  @Input() backgroundColor: string;
  @Input() size = 128;

  constructor() {}

  ngOnInit(): void {}
}
