<app-loading-indicator *ngIf="routingInProgress"></app-loading-indicator>
<mat-sidenav-container>
  <mat-sidenav
    #drawer
    mode="side"
    opened="true"
    disableClose="true"
    role="navigation"
    class="sidenav"
    *ngIf="showSideNav"
  >
    <mat-nav-list>
      <img [src]="logo" class="company-logo" />
      <img
        src="assets/logo_CityLink_white.svg"
        class="company-logo"
        *ngIf="!logo"
      />
      <app-account-list-item></app-account-list-item>
      <app-menu-list-item
        *ngFor="let item of navItems"
        [item]="item"
      ></app-menu-list-item>
      <mat-divider></mat-divider>
      <app-logout-list-item (click)="logout()"></app-logout-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="app-view">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
