import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DateTimeModalComponent } from './date-time-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
  declarations: [DateTimeModalComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    FlexModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
    MatNativeDateModule,
  ],
  exports: [DateTimeModalComponent],
  providers: [DatePipe],
})
export class DateTimeModalModule {}
