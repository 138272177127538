<div fxLayout="column" class="app-area">
  <!-- HEADER ROW -->
  <div fxLayout="row">
    <div fxFlex fxFlexAlign="start start">
      <h1>Sensoren Bearbeiten</h1>
    </div>
    <div fxFlex fxLayoutAlign="end center">
      <button mat-button type="button" (click)="doSave()">
        <mat-icon>save</mat-icon>
        Speichern
      </button>
    </div>
  </div>

  <div class="square-card" style="height: 100%">
    <div fxLayout="row wrap">
      <div fxFlex="100" [formGroup]="form" style="overflow: scroll">
        <table mat-table [dataSource]="dataSource" style="width: 100%">
          <ng-container matColumnDef="warning">
            <th mat-header-cell *matHeaderCellDef style="width: 40px"></th>
            <td mat-cell *matCellDef="let element" style="width: 40px">
              <mat-icon
                *ngIf="
                  element.value.warning && !element.value.customWarningMessage
                "
                class="text-warning"
                matTooltip="Die Daten sind unvollständig und können nicht gespeichert werden!"
                >warning_amber
              </mat-icon>
              <mat-icon
                *ngIf="
                  element.value.warning && element.value.customWarningMessage
                "
                class="text-danger"
                [matTooltip]="element.value.customWarningMessage"
                >warning_amber
              </mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="displayName">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-form-field appearance="standard">
                <input
                  matInput
                  placeholder="Name"
                  type="text"
                  name="displayName"
                  formControlName="displayName"
                  (ngModelChange)="validateRow(element)"
                />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="eui">
            <th mat-header-cell *matHeaderCellDef>eui</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-form-field appearance="standard">
                <input
                  matInput
                  placeholder="EUI"
                  type="text"
                  name="eui"
                  formControlName="eui"
                  (ngModelChange)="validateRow(element)"
                />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="sensorTypeConfig">
            <th mat-header-cell *matHeaderCellDef>Sensortyp</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-select
                formControlName="sensorTypeConfig"
                style="min-width: 200px"
                placeholder="Sensortyp"
                (ngModelChange)="validateRow(element)"
              >
                <mat-option
                  *ngFor="let sensorTypeConfig of sensorTypeConfigs"
                  [value]="sensorTypeConfig"
                  >{{ sensorTypeConfig.displayName }} ({{
                    sensorTypeConfig.niotaDeviceTypeId
                  }})
                </mat-option>
              </mat-select>
            </td>
          </ng-container>

          <ng-container matColumnDef="organizations">
            <th mat-header-cell *matHeaderCellDef>Organisationen</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-select
                formControlName="organizationIds"
                style="min-width: 350px"
                placeholder="Organisationen"
                multiple
              >
                <mat-option
                  *ngFor="let organization of organizations"
                  [value]="organization.id"
                  >{{ organization.name }}
                </mat-option>
              </mat-select>
            </td>
          </ng-container>

          <ng-container matColumnDef="applSessionKey">
            <th mat-header-cell *matHeaderCellDef>applSessionKey</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-form-field appearance="standard">
                <input
                  matInput
                  placeholder="applSessionKey"
                  type="text"
                  name="applSessionKey"
                  formControlName="applSessionKey"
                  (ngModelChange)="validateRow(element)"
                />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="applKey">
            <th mat-header-cell *matHeaderCellDef>applKey</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-form-field appearance="standard">
                <input
                  matInput
                  placeholder="applKey"
                  type="text"
                  name="applKey"
                  formControlName="applKey"
                  (ngModelChange)="validateRow(element)"
                />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="deviceAddress">
            <th mat-header-cell *matHeaderCellDef>deviceAddress</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-form-field appearance="standard">
                <input
                  matInput
                  placeholder="deviceAddress"
                  type="text"
                  name="deviceAddress"
                  formControlName="deviceAddress"
                  (ngModelChange)="validateRow(element)"
                />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="netwSessionKey">
            <th mat-header-cell *matHeaderCellDef>netwSessionKey</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-form-field appearance="standard">
                <input
                  matInput
                  placeholder="netwSessionKey"
                  type="text"
                  name="netwSessionKey"
                  formControlName="netwSessionKey"
                  (ngModelChange)="validateRow(element)"
                />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>description</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-form-field appearance="standard">
                <input
                  matInput
                  placeholder="description"
                  type="text"
                  name="description"
                  formControlName="description"
                  (ngModelChange)="validateRow(element)"
                />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="applyChangesInIotPlatform">
            <th mat-header-cell *matHeaderCellDef style="width: 80px">
              Synchronisation mit niota
              <mat-icon
                inline
                style="
                  margin-left: 8px;
                  font-size: 20px;
                  vertical-align: bottom;
                  width: 16px;
                "
                matTooltip="Die in Citylink hinterlegten Werte werden in das niota-System übertragen.
              Bei der Erzeugung wird ein Device im niota-System erzeugt oder ein bestehendes Device mit der identischen
              EUI aktualisiert. Bei Änderungen oder einer Löschung werden die Daten ebenfalls in das niota-System
              übertragen."
              >
                info_outlined
              </mat-icon>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [formGroup]="element"
              style="width: 80px; text-align: center"
            >
              <mat-checkbox
                class="checkbox-center"
                color="primary"
                formControlName="applyChangesInIotPlatform"
                (ngModelChange)="validateRow(element)"
              ></mat-checkbox>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let myDataArray; columns: displayedColumns"
          ></tr>
        </table>
      </div>
    </div>
  </div>
</div>
