import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ttn',
  template: `
    <svg
      width="53"
      height="12"
      viewBox="0 0 53 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.1991 1.95725e-05C14.3596 1.95725e-05 16.1386 1.63308 16.3689 3.73212C16.7525 3.52818 17.1903 3.41267 17.655 3.41267C18.5371 3.41267 19.322 3.82871 19.8242 4.47525L19.8311 4.47529L19.8312 4.48435C20.1879 4.94755 20.4 5.52781 20.4 6.15763C20.4 6.3669 20.3766 6.57069 20.3323 6.76654C21.4689 6.9984 22.324 8.00382 22.324 9.20899C22.324 10.572 21.2301 11.6795 19.8723 11.7015L19.8311 11.7019L3.12951 11.6751C3.00387 11.6928 2.87549 11.7019 2.74498 11.7019C1.22895 11.7019 0 10.4729 0 8.95688C0 7.44088 1.22899 6.21189 2.74498 6.21189C2.74803 6.21189 2.75107 6.2119 2.75412 6.21191L2.75403 6.20357C2.75403 4.14692 4.42056 2.47971 6.47634 2.47971C7.10075 2.47971 7.68926 2.63352 8.20605 2.90531C8.75 1.21945 10.3321 0 12.1991 0L12.1991 1.95725e-05ZM17.7865 1.80027C18.0895 1.8225 18.3884 1.87462 18.6793 1.95548C19.264 2.11798 19.8109 2.39487 20.2885 2.7682C20.7662 3.14156 21.167 3.60536 21.4661 4.13366C21.6149 4.39657 21.7377 4.67402 21.8326 4.96282C21.9058 5.18558 21.7846 5.42548 21.5619 5.49865C21.3393 5.57183 21.0994 5.45058 21.0263 5.22783C20.949 4.99243 20.8488 4.76629 20.7275 4.55203C20.4836 4.12102 20.1563 3.74231 19.766 3.43727C19.3758 3.13224 18.9293 2.90615 18.4522 2.7736C18.2253 2.71055 17.9927 2.66895 17.7567 2.6496L17.7245 2.6471C17.4907 2.62995 17.3151 2.42649 17.3322 2.19264C17.3493 1.95879 17.5527 1.78313 17.7864 1.80027L17.7865 1.80027ZM18.003 0.323716C18.3903 0.352118 18.7722 0.41873 19.1441 0.522081C19.8915 0.72977 20.5904 1.08369 21.201 1.56097C21.8116 2.03824 22.324 2.63112 22.7062 3.30633C22.8964 3.64234 23.0534 3.99695 23.1747 4.366C23.2479 4.58875 23.1267 4.82866 22.904 4.90183C22.6839 4.97419 22.4469 4.85646 22.3709 4.63847L22.3684 4.631C22.2647 4.31531 22.1304 4.01205 21.9677 3.72467C21.6406 3.14674 21.2017 2.63894 20.6785 2.23C20.1553 1.82105 19.5566 1.51793 18.917 1.34015C18.5989 1.25176 18.2723 1.19479 17.941 1.1705C17.7073 1.15336 17.5317 0.949891 17.5488 0.716041C17.5659 0.482191 17.7692 0.30653 18.003 0.323675L18.003 0.323716Z"
        [attr.fill]="color"
      />
      <path
        d="M45.5462 1.13202C45.4383 0.971615 45.2578 0.874772 45.064 0.874772L45.0639 0.874756H43.5999L43.5776 0.875178C43.2665 0.886964 43.0179 1.14411 43.0179 1.4596V11.1172L43.0183 11.1397C43.03 11.4523 43.2859 11.7021 43.5999 11.7021H45.0627L45.085 11.7017C45.3961 11.6899 45.6447 11.4327 45.6447 11.1172L45.6447 11.1108L45.5784 5.08865L49.5391 11.4282L49.5535 11.4501C49.6618 11.6074 49.8405 11.7021 50.0321 11.7021H51.5142L51.5365 11.7017C51.8476 11.6899 52.0962 11.4328 52.0962 11.1173V1.45963L52.0958 1.4372C52.0841 1.12459 51.8281 0.874782 51.5142 0.874782H50.055L50.0327 0.875204C49.7216 0.88699 49.473 1.14414 49.473 1.45963L49.4731 1.46962L49.5794 7.72231L45.5598 1.1533L45.5462 1.13202Z"
        [attr.fill]="color"
      />
      <path
        d="M33.2728 1.43717C33.2611 1.12457 33.0047 0.87476 32.6901 0.87476L32.6903 0.874756H26.8602L26.8379 0.875178C26.5262 0.886964 26.2771 1.14411 26.2771 1.4596V2.71878L26.2775 2.74121C26.2893 3.05382 26.5457 3.30363 26.8602 3.30363H28.4051V11.1173L28.4055 11.1397C28.4173 11.4523 28.6736 11.7021 28.9882 11.7021H30.5767L30.5991 11.7017C30.9107 11.6899 31.1598 11.4328 31.1598 11.1173V3.30363H32.6901L32.7125 3.30321C33.0242 3.29142 33.2732 3.03427 33.2732 2.71879V1.45961L33.2728 1.43717Z"
        [attr.fill]="color"
      />
      <path
        d="M41.6432 1.43717C41.6315 1.12457 41.3751 0.87476 41.0605 0.87476L41.0607 0.874756H35.2306L35.2083 0.875178C34.8966 0.886964 34.6475 1.14411 34.6475 1.4596V2.71878L34.6479 2.74121C34.6597 3.05382 34.9161 3.30363 35.2306 3.30363H36.7755V11.1173L36.7759 11.1397C36.7877 11.4523 37.0441 11.7021 37.3586 11.7021H38.9471L38.9695 11.7017C39.2812 11.6899 39.5302 11.4328 39.5302 11.1173V3.30363H41.0605L41.0829 3.30321C41.3946 3.29142 41.6437 3.03427 41.6437 2.71879V1.45961L41.6432 1.43717Z"
        [attr.fill]="color"
      />
    </svg>
  `,
  styles: [],
})
export class TTNLogoComponent implements OnInit {
  @Input() color: string;

  constructor() {}

  ngOnInit(): void {}
}
