import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NiotaLogoComponent } from 'src/app/public-utility/sensors/components/connection-logos/niota-logo.component';
import { MqttLogoComponent } from 'src/app/public-utility/sensors/components/connection-logos/mqtt-logo.component';
import { TTNLogoComponent } from 'src/app/public-utility/sensors/components/connection-logos/ttn-logo.component';

@NgModule({
  declarations: [NiotaLogoComponent, MqttLogoComponent, TTNLogoComponent],
  exports: [NiotaLogoComponent, MqttLogoComponent, TTNLogoComponent],
  imports: [CommonModule],
})
export class ConnectionLogosModule {}
