import { SensorGroupNode } from 'src/app/_shared/models/sensor-group-node';
import { SensorOutputDto } from 'src/app/_shared/dtos/sensor-output.dto';
import { Notification } from 'src/app/organization/dashboard/models/notification';
import { SensorTypeConfig } from 'src/app/_shared/models/sensor-type-config';
import { SensorSourceOfGeneration } from 'src/app/_shared/enum/sensor-source-of-generation';
import { SensorState } from 'src/app/_shared/models/sensor-state';
import { Address } from 'src/app/_shared/models/address';
import { Coordinate } from 'src/app/_shared/models/coordinate';

export class Sensor {
  id: number;
  displayName: string;
  sourceOfGeneration: SensorSourceOfGeneration;
  address: Address;
  coordinate: Coordinate;
  sensorGroupNode: SensorGroupNode;
  sensorGroupNodeId: number;
  sensorTypeConfig: SensorTypeConfig;
  notifications: Array<Notification>;
  notificationConfigIds: number[];
  organizationIds: number[];
  notificationInfos: Array<{ type: string; count: number }> = [];
  metaData: {
    eui: string;
  };
  sensorState?: SensorState;

  static fromSensorOutputDto(dto: SensorOutputDto): Sensor {
    const result = new Sensor();

    result.id = dto.id;
    result.displayName = dto.displayName;
    result.sourceOfGeneration = dto.sourceOfGeneration;

    if (dto.sensorGroupNode) {
      result.sensorGroupNode = SensorGroupNode.fromSensorGroupNodeOutputDto(
        dto.sensorGroupNode
      );
    }
    result.notificationInfos = dto.notificationInfos || [];
    result.metaData = dto.metaData;
    if (dto.address) {
      result.address = Address.fromAddressDto(dto.address);
    }
    if (dto.coordinate) {
      result.coordinate = Coordinate.fromCoordinateDto(dto.coordinate);
    }
    if (dto.notifications) {
      result.notifications = dto.notifications
        .map((notificationDto) =>
          Notification.fromNotificationOutputDto(notificationDto)
        )
        .reverse();
    }

    if (dto.sensorTypeConfig) {
      result.sensorTypeConfig = SensorTypeConfig.fromSensorTypeOutputDto(
        dto.sensorTypeConfig
      );
    }

    result.notificationConfigIds = dto.notificationConfigIds
      ? dto.notificationConfigIds
      : [];

    result.sensorState = dto.sensorState;

    return result;
  }

  getLocationString(): string | null {
    if (this.address) {
      return (
        this.address.street +
        ', ' +
        this.address.zipCode +
        ' ' +
        this.address.city
      );
    }

    return null;
  }
}
