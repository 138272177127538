import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  saveSuccessful() {
    this.snackBar.open('Speicherung erfolgreich', null, { duration: 2000 });
  }

  saveSuccessfulAndLogout() {
    this.snackBar.open(
      'Speicherung erfolgreich. Sie werden nun ausgeloggt. Loggen Sie sich mit Ihrem neuen Passwort ein.',
      null,
      { duration: 4000 }
    );
  }

  deleteSuccessful() {
    this.snackBar.open('Löschung erfolgreich', null, { duration: 2000 });
  }

  showCustomMessage(message: string) {
    this.snackBar.open(message, null, { duration: 5000 });
  }
}
