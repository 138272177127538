import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { UserService } from 'src/app/users/services/user.service';
import { User } from 'src/app/users/models/user';

@Injectable({
  providedIn: 'root',
})
export class UserListResolver implements Resolve<User[]> {
  constructor(private userService: UserService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<Array<User>> {
    return this.userService.getUserList();
  }
}
