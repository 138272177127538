<mat-form-field
  style="width: 100%"
  [appearance]="appearance"
  class="filterable-select"
>
  <mat-label>{{ label }}</mat-label>
  <mat-select
    [(ngModel)]="value"
    #selectModel="ngModel"
    placeholder="{{ label }}"
    [multiple]="multiple"
    [disabled]="disabled"
  >
    <mat-option>
      <ngx-mat-select-search [formControl]="filterCtrl"></ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let obj of filtered | async" [value]="obj">
      {{ objToLabel(obj) }}
    </mat-option>
  </mat-select>
  <button
    *ngIf="isValue()"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="clear(); $event.stopPropagation()"
    [ngStyle]="{ transform: appearance == 'outline' ? 'translateY(-20%)' : '' }"
    style="width: 30px; height: 30px"
    [disabled]="disabled"
  >
    <mat-icon inline color="">close</mat-icon>
  </button>
</mat-form-field>
