import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from 'src/app/_core/components/login/login.component';
import { RoleGuard } from 'src/app/_core/guards/role.guard';
import { Roles } from 'src/app/_core/models/roles';
import { OrganizationSettingsComponent } from 'src/app/_shared/components/organization-settings/organization-settings.component';
import { OwnOrganizationResolver } from 'src/app/_shared/resolvers/own-organization.resolver';
import { OrganizationDashboardResolver } from 'src/app/organization/dashboard/resolvers/organization-dashboard.resolver';
import { PublicUtilityDashboardOverviewComponent } from 'src/app/public-utility/public-utility-dashboard/components/public-utility-dashboard-overview/public-utility-dashboard-overview.component';
import { SensorTypeConfigListComponent } from 'src/app/public-utility/sensor-types/components/sensor-type-config-list/sensor-type-config-list.component';
import { PositioningOverviewComponent } from 'src/app/organization/positioning/components/positioning/positioning-overview.component';
import { NotificationConfigurationListComponent } from 'src/app/organization/notification-configuration/components/notification-configuration-list/notification-configuration-list.component';
import { UserListComponent } from 'src/app/users/components/user-list/user-list.component';
import { AlreadySignedInGuard } from 'src/app/_core/guards/already-signed-in.guard';
import { AuthGuard } from 'src/app/_core/guards/auth.guard';
import { NotificationConfigurationEditComponent } from 'src/app/organization/notification-configuration/components/notification-configuration-edit/notification-configuration-edit.component';
import { DashboardOverviewComponent } from 'src/app/organization/dashboard/components/dashboard/dashboard-overview.component';
import { UserConfirmationComponent } from 'src/app/_core/components/user-confirmation/user-confirmation.component';
import { UserPasswordResetComponent } from 'src/app/_core/components/user-password-reset/user-password-reset.component';
import { RequestNewPasswordComponent } from 'src/app/_core/components/request-new-password/request-new-password.component';
import { PublicUtilityDashboardDataResolver } from 'src/app/public-utility/public-utility-dashboard/resolvers/public-utility-dashboard-data-resolver';
import { OrganizationListResolver } from 'src/app/public-utility/organization/resolvers/organization-list-resolver';
import { OrganizationListComponent } from 'src/app/public-utility/organization/components/organization-list/organization-list.component';
import { UserListResolver } from 'src/app/users/resolvers/user-list-resolver';
import { OrganizationMacListResolver } from 'src/app/public-utility/organization/resolvers/organization-mac-list-resolver';
import { OrganizationMacListComponent } from 'src/app/public-utility/organization/components/organization-mac-list/organization-mac-list.component';
import { UserSettingsComponent } from 'src/app/_core/components/user-settings/user-settings.component';
import { ProfileResolver } from 'src/app/_core/resolvers/profile.resolver';
import { MailTemplateSettingsComponent } from 'src/app/_shared/components/mail-template-settings/mail-template-settings.component';
import { MailTemplateConfigurationResolver } from 'src/app/_shared/resolvers/mail-template-configuration.resolver';
import { UserGroupListComponent } from 'src/app/users/components/user-group-list/user-group-list.component';
import { UserGroupListResolver } from 'src/app/users/resolvers/user-group-list-resolver';
import { UserGroupEditComponent } from 'src/app/users/components/user-group-edit/user-group-edit.component';
import { OrganizationResolver } from 'src/app/public-utility/organization/resolvers/organization-resolver';
import { SensorTypeConfigEditComponent } from 'src/app/public-utility/sensor-types/components/sensor-type-config-edit/sensor-type-config-edit.component';
import { SensorTypeConfigResolver } from 'src/app/public-utility/sensor-types/resolvers/sensor-type-config-resolver.service';
import { SensorsListComponent } from 'src/app/public-utility/sensors/components/sensors-list/sensors-list.component';
import { SensorTypeConfigsResolver } from 'src/app/public-utility/sensors/resolvers/sensor-type-config-list.resolver';
import { SensorListResolver } from 'src/app/public-utility/sensor-types/resolvers/sensor-list-resolver';
import { SensorEditComponent } from 'src/app/public-utility/sensors/components/sensor-edit/sensor-edit.component';
import { SensorCsvImportComponent } from 'src/app/public-utility/sensors/components/sensor-csv-import/sensor-csv-import.component';
import { SensorListEditComponent } from 'src/app/public-utility/sensors/components/sensor-list-edit/sensor-list-edit.component';
import { SensorListEditResolver } from 'src/app/public-utility/sensor-types/resolvers/sensor-list-edit-resolver';
import { SensorQrCodeListComponent } from 'src/app/public-utility/sensors/components/sensor-qr-code-list/sensor-qr-code-list.component';
import { UserUsernameConfirmationComponent } from 'src/app/_core/components/user-username-confirmation/user-username-confirmation.component';
import { LegalSettingsComponent } from 'src/app/public-utility/legal-settings/components/legal-settings/legal-settings.component';
import { LegalComponent } from 'src/app/_shared/components/imprint/legal.component';
import { SensorEditResolver } from 'src/app/public-utility/sensor-types/resolvers/sensor-edit-resolver';
import { SensorCreateResolver } from 'src/app/public-utility/sensor-types/resolvers/sensor-create-resolver';
import { LegalSettingsResolver } from 'src/app/public-utility/legal-settings/resolver/legal-settings.resolver';
import { PositioningOverviewResolver } from 'src/app/organization/positioning/resolvers/positioning-overview.resolver';
import { NotificationConfigurationCreateResolver } from 'src/app/organization/notification-configuration/resolvers/notification-configuration-create.resolver';
import { NotificationConfigurationEditResolver } from 'src/app/organization/notification-configuration/resolvers/notification-configuration-edit.resolver';
import { UserGroupEditResolver } from 'src/app/users/resolvers/user-group-edit.resolver';
import { UserGroupCreateResolver } from 'src/app/users/resolvers/user-group-create.resolver';
import { SensorStateIndicatorConfigurationListComponent } from 'src/app/organization/sensor-state-indicator-configuration/components/sensor-state-indicator-configuration-list/sensor-state-indicator-configuration-list.component';
import { SensorStateIndicatorConfigurationEditComponent } from 'src/app/organization/sensor-state-indicator-configuration/components/sensor-state-indicator-configuration-edit/sensor-state-indicator-configuration-edit.component';
import { SensorStateIndicatorConfigurationCreateResolver } from 'src/app/organization/sensor-state-indicator-configuration/resolver/sensor-state-indicator-configuration-create.resolver';
import { SensorStateIndicatorConfigurationListResolver } from 'src/app/organization/sensor-state-indicator-configuration/resolver/sensor-state-indicator-configuration-list.resolver';
import { SensorStateIndicatorConfigurationEditResolver } from 'src/app/organization/sensor-state-indicator-configuration/resolver/sensor-state-indicator-configuration-edit.resolver';
import { NotificationMuteSettingsComponent } from 'src/app/organization/notification-mute/components/notification-mute/notification-mute-settings.component';
import { NotificationConfigurationListResolver } from 'src/app/organization/notification-configuration/resolvers/notification-configuration-list.resolver';
import { MeasurementParseErrorGroupsListComponent } from 'src/app/public-utility/measurement-parse-errors/components/measurement-parse-error-list/measurement-parse-error-groups-list.component';
import { MeasurementParseErrorGroupsListResolver } from 'src/app/public-utility/measurement-parse-errors/resolvers/measurement-parse-errors-group-list-resolver.service';
import { MeasurementParseErrorGroupsDetailComponent } from 'src/app/public-utility/measurement-parse-errors/components/measurement-parse-error-detail/measurement-parse-error-groups-detail.component';
import { MeasurementParseErrorGroupsDetailResolver } from 'src/app/public-utility/measurement-parse-errors/resolvers/measurement-parse-detail.resolver';
import { InfoTextListComponent } from 'src/app/organization/notification-configuration/components/info-text-list/info-text-list.component';
import { InfoTextListResolver } from 'src/app/organization/notification-configuration/resolvers/info-text-list.resolver';
import { GlobalPushComponent } from 'src/app/public-utility/global-push/components/global-push.component';
import { SharedSensorComponent } from 'src/app/_shared/components/shared-sensor/shared-sensor.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'users-confirmation', component: UserConfirmationComponent },
  {
    path: 'username-confirmation',
    component: UserUsernameConfirmationComponent,
  },
  { path: 'users-password-reset', component: UserPasswordResetComponent },
  { path: 'request-new-password', component: RequestNewPasswordComponent },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AlreadySignedInGuard],
  },
  { path: 'imprint', component: LegalComponent, data: { context: 'imprint' } },
  { path: 'privacy', component: LegalComponent, data: { context: 'privacy' } },
  {
    path: 'user-settings',
    component: UserSettingsComponent,
    canActivate: [AuthGuard],
    resolve: {
      profile: ProfileResolver,
    },
  },
  {
    path: 'public-utility',
    canActivateChild: [AuthGuard, RoleGuard],
    data: {
      roles: [
        Roles.CITYLINK_OWNER,
        Roles.CITYLINK_EDITOR,
        Roles.CITYLINK_VIEWER,
      ],
    },
    children: [
      {
        path: 'dashboard',
        component: PublicUtilityDashboardOverviewComponent,
        resolve: {
          data: PublicUtilityDashboardDataResolver,
        },
      },
      {
        path: 'sensors',
        children: [
          {
            path: '',
            component: SensorsListComponent,
            resolve: {
              data: SensorListResolver,
            },
          },
          {
            path: 'create',
            component: SensorEditComponent,
            resolve: {
              data: SensorCreateResolver,
            },
          },
          {
            path: 'edit/:id',
            component: SensorEditComponent,
            resolve: {
              data: SensorEditResolver,
            },
          },
          {
            path: 'csv-import',
            component: SensorCsvImportComponent,
            resolve: {
              sensorTypeConfigs: SensorTypeConfigsResolver,
            },
          },
          {
            path: 'list-edit',
            component: SensorListEditComponent,
            resolve: {
              data: SensorListEditResolver,
            },
          },
          {
            path: 'qr-codes',
            component: SensorQrCodeListComponent,
          },
        ],
      },
      {
        path: 'sensor-types',
        children: [
          {
            path: '',
            component: SensorTypeConfigListComponent,
            resolve: {
              sensorTypes: SensorTypeConfigsResolver,
            },
          },
          {
            path: 'edit/:id',
            component: SensorTypeConfigEditComponent,
            resolve: {
              sensorType: SensorTypeConfigResolver,
            },
          },
        ],
      },
      {
        path: 'organizations',
        children: [
          {
            path: 'create',
            component: OrganizationSettingsComponent,
          },
          {
            path: 'edit/:id',
            component: OrganizationSettingsComponent,
            resolve: {
              organization: OrganizationResolver,
            },
          },
          {
            path: '',
            component: OrganizationListComponent,
            resolve: {
              organizations: OrganizationListResolver,
            },
          },
          {
            path: ':id',
            component: OrganizationMacListComponent,
            resolve: {
              organization: OrganizationMacListResolver,
            },
          },
        ],
      },
      {
        path: 'users',
        component: UserListComponent,
        resolve: {
          userList: UserListResolver,
        },
      },
      {
        path: 'settings',
        component: OrganizationSettingsComponent,
        resolve: {
          organization: OwnOrganizationResolver,
        },
      },
      {
        path: 'mail-template',
        component: MailTemplateSettingsComponent,
        resolve: {
          templateConfig: MailTemplateConfigurationResolver,
        },
      },
      {
        path: 'legal-settings',
        component: LegalSettingsComponent,
        resolve: {
          data: LegalSettingsResolver,
        },
      },
      {
        path: 'measurement-parse-error-groups',
        children: [
          {
            path: '',
            component: MeasurementParseErrorGroupsListComponent,
            resolve: {
              data: MeasurementParseErrorGroupsListResolver,
            },
          },
          {
            path: ':id',
            component: MeasurementParseErrorGroupsDetailComponent,
            resolve: {
              data: MeasurementParseErrorGroupsDetailResolver,
            },
          },
        ],
      },
      {
        path: 'global-push',
        component: GlobalPushComponent,
      },
    ],
  },
  {
    path: 'organization',
    canActivateChild: [AuthGuard, RoleGuard],
    data: {
      roles: [
        Roles.ORGANIZATION_OWNER,
        Roles.ORGANIZATION_EDITOR,
        Roles.ORGANIZATION_VIEWER,
      ],
    },
    children: [
      {
        path: 'dashboard',
        component: DashboardOverviewComponent,
        resolve: {
          treeRoot: OrganizationDashboardResolver,
        },
      },
      {
        path: 'positioning',
        component: PositioningOverviewComponent,
        resolve: {
          data: PositioningOverviewResolver,
        },
      },
      {
        path: 'alarm-configuration',
        children: [
          {
            path: '',
            component: NotificationConfigurationListComponent,
            resolve: {
              data: NotificationConfigurationListResolver,
            },
          },
          {
            path: 'create',
            component: NotificationConfigurationEditComponent,
            resolve: {
              data: NotificationConfigurationCreateResolver,
            },
          },
          {
            path: 'edit/:id',
            component: NotificationConfigurationEditComponent,
            resolve: {
              data: NotificationConfigurationEditResolver,
            },
          },
        ],
      },
      {
        path: 'sensor-state-indicator-configuration',
        children: [
          {
            path: '',
            component: SensorStateIndicatorConfigurationListComponent,
            resolve: {
              data: SensorStateIndicatorConfigurationListResolver,
            },
          },
          {
            path: 'create',
            component: SensorStateIndicatorConfigurationEditComponent,
            resolve: {
              data: SensorStateIndicatorConfigurationCreateResolver,
            },
          },
          {
            path: 'edit/:id',
            component: SensorStateIndicatorConfigurationEditComponent,
            resolve: {
              data: SensorStateIndicatorConfigurationEditResolver,
            },
          },
        ],
      },
      {
        path: 'settings',
        component: OrganizationSettingsComponent,
        resolve: {
          organization: OwnOrganizationResolver,
        },
      },
      {
        path: 'mail-template',
        component: MailTemplateSettingsComponent,
        resolve: {
          templateConfig: MailTemplateConfigurationResolver,
        },
      },
      {
        path: 'users',
        component: UserListComponent,
        resolve: {
          userList: UserListResolver,
        },
      },
      {
        path: 'user-groups',
        children: [
          {
            path: '',
            component: UserGroupListComponent,
            resolve: {
              userGroupList: UserGroupListResolver,
            },
          },
          {
            path: 'edit/:id',
            component: UserGroupEditComponent,
            resolve: {
              data: UserGroupEditResolver,
            },
          },
          {
            path: 'create',
            component: UserGroupEditComponent,
            resolve: {
              data: UserGroupCreateResolver,
            },
          },
        ],
      },
      {
        path: 'notification-mute/:notificationConfigId',
        component: NotificationMuteSettingsComponent,
        resolve: {
          notificationConfigList: NotificationConfigurationListResolver,
        },
      },
      {
        path: 'info-text',
        children: [
          {
            path: '',
            component: InfoTextListComponent,
            resolve: {
              infoTextList: InfoTextListResolver,
            },
          },
        ],
      },
    ],
  },
  {
    path: 'shared-sensor/:sensorPairingShareIdentifier',
    component: SharedSensorComponent,
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
