import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationLogoutListItemComponent } from 'src/app/navigation/components/logout-list-item/navigation-logout-list-item.component';
import { MatListModule } from '@angular/material/list';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [NavigationLogoutListItemComponent],
  exports: [NavigationLogoutListItemComponent],
  imports: [CommonModule, MatListModule, FlexModule, MatIconModule],
})
export class NavigationLogoutListItemModule {}
