import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EditMode } from 'src/app/organization/positioning/components/edit-sensor-group-node-modal/edit-sensor-group-node-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Roles, roleToLabelText } from 'src/app/_core/models/roles';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';
import { UserService } from 'src/app/users/services/user.service';
import { UserInputDto } from 'src/app/users/dtos/user-input.dto';
import { User } from 'src/app/users/models/user';

@Component({
  selector: 'app-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrls: ['./edit-user-modal.component.scss'],
})
export class EditUserModalComponent implements OnInit {
  form: FormGroup;
  mode: EditMode;
  roleOptions: Array<Roles> = [];
  user: User;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private authenticationService: AuthenticationService,
    public dialogRef: MatDialogRef<EditUserModalComponent>,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.user = this.data.user;
    this.initializeRoleOptions();
    this.initializeFormGroup();
  }

  private initializeFormGroup() {
    if (this.user) {
      this.form = new FormGroup({
        firstName: new FormControl(this.user.firstName, [
          Validators.required,
          Validators.maxLength(255),
        ]),
        lastName: new FormControl(this.user.lastName, [
          Validators.required,
          Validators.maxLength(255),
        ]),
        username: new FormControl(this.user.username, [
          Validators.required,
          Validators.maxLength(255),
        ]),
        role: new FormControl(this.user.role?.name, [Validators.required]),
      });
    } else {
      this.form = new FormGroup({
        firstName: new FormControl('', [
          Validators.required,
          Validators.maxLength(255),
        ]),
        lastName: new FormControl('', [
          Validators.required,
          Validators.maxLength(255),
        ]),
        username: new FormControl('', [
          Validators.required,
          Validators.maxLength(255),
        ]),
        role: new FormControl('', [Validators.required]),
      });
    }
  }

  initializeRoleOptions() {
    const role = this.authenticationService.getCurrentUserSession().role;
    switch (role) {
      case Roles.CITYLINK_EDITOR:
        this.roleOptions = [Roles.CITYLINK_VIEWER];
        break;
      case Roles.CITYLINK_OWNER:
        this.roleOptions = [Roles.CITYLINK_EDITOR, Roles.CITYLINK_VIEWER];
        break;
      case Roles.ORGANIZATION_EDITOR:
        this.roleOptions = [Roles.ORGANIZATION_VIEWER];
        break;
      case Roles.ORGANIZATION_OWNER:
        this.roleOptions = [
          Roles.ORGANIZATION_EDITOR,
          Roles.ORGANIZATION_VIEWER,
        ];
        break;
    }
  }

  save() {
    if (this.form.valid) {
      const dto: UserInputDto = this.form.value;

      if (this.user) {
        this.userService
          .update(this.user.id, dto)
          .then((_) => this.dialogRef.close(_));
      } else {
        this.userService.inviteUser(dto).then((_) => this.dialogRef.close(_));
      }
    }
  }

  getTitle() {
    if (this.user) {
      return 'Nutzer bearbeiten';
    } else {
      return 'Nutzer einladen';
    }
  }

  getTextForRoleEnum(roleOption: Roles) {
    return roleToLabelText(roleOption);
  }
}
