<h2 mat-dialog-title>Organisation hinzufügen</h2>
<mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label>Organisationen</mat-label>
    <mat-select [(ngModel)]="selectedOrganizations" multiple>
      <mat-option
        *ngFor="let organization of this.organizations"
        [value]="organization.id"
      >
        {{ organization.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Abbrechen</button>
    <button mat-button [matDialogClose]="selectedOrganizations">
      Hinzufügen
    </button>
  </mat-dialog-actions>
</mat-dialog-content>
