import { UserGroupOutputDto } from 'src/app/users/dtos/user-group-output.dto';
import { UserGroupInputDto } from 'src/app/users/dtos/user-group-input.dto';

export class UserGroup {
  id: number;
  name: string;
  userIds: number[];

  static fromUserGroupOutputDto(dto: UserGroupOutputDto) {
    const result = new UserGroup();
    result.id = dto.id;
    result.name = dto.name;
    result.userIds = dto.userIds;
    return result;
  }

  toInputDto(): UserGroupInputDto {
    const result = new UserGroupInputDto();
    result.name = this.name;
    result.userIds = this.userIds;
    return result;
  }
}
