import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { DashboardSiteMapComponent } from 'src/app/organization/dashboard/components/site-map/dashboard-site-map.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { DashboardSiteMapGroupItemModule } from 'src/app/organization/dashboard/components/site-map/group-item/dashboard-site-map-group-item.module';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatCardModule } from '@angular/material/card';
import { DashboardSiteMapSensorItemModule } from 'src/app/organization/dashboard/components/site-map/sensor-item/dashboard-site-map-sensor-item.module';

@NgModule({
  declarations: [DashboardSiteMapComponent],
  exports: [DashboardSiteMapComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    FlexModule,
    GoogleMapsModule,
    DashboardSiteMapGroupItemModule,
    MatCardModule,
    DashboardSiteMapSensorItemModule,
  ],
})
export class DashboardSiteMapModule {}
