import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationConfigurationEditComponent } from 'src/app/organization/notification-configuration/components/notification-configuration-edit/notification-configuration-edit.component';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsModule } from 'src/app/organization/notification-configuration/components/icons/icons.module';
import { SensorSelectModalModule } from 'src/app/organization/notification-configuration/components/sensor-select-modal/sensor-select-modal.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  MAT_COLOR_FORMATS,
  NGX_MAT_COLOR_FORMATS,
  NgxMatColorPickerModule,
} from 'src/app/_shared/angular-material-color-picker';
import { MatCardModule } from '@angular/material/card';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FilterableSelectModule } from 'src/app/_shared/components/filterable-select/filterable-select.module';

@NgModule({
  declarations: [NotificationConfigurationEditComponent],
  exports: [NotificationConfigurationEditComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatTableModule,
    FlexModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatSortModule,
    MatMenuModule,
    RouterModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    IconsModule,
    NgxMatColorPickerModule,
    SensorSelectModalModule,
    MatTooltipModule,
    MatCardModule,
    NgxMatSelectSearchModule,
    FilterableSelectModule,
  ],
  providers: [{ provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS }],
})
export class NotificationConfigurationEditModule {}
