export class ChangeUserProfileDto {
  username: string;
  newPassword?: string;
  currentPassword: string;
  firstName?: string;
  lastName?: string;
  sendEmailNotifications?: boolean;
  sendPushNotifications?: boolean;

  constructor(
    username: string,
    currentPassword: string,
    newPassword?: string,
    firstName?: string,
    lastName?: string,
    sendEmailNotifications?: boolean,
    sendPushNotifications?: boolean
  ) {
    this.username = username;
    this.currentPassword = currentPassword;
    this.newPassword = newPassword;
    this.firstName = firstName;
    this.lastName = lastName;
    this.sendEmailNotifications = sendEmailNotifications;
    this.sendPushNotifications = sendPushNotifications;
  }
}
