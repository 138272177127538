import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/_core/services/api/api.service';
import { MeasurementAggregationInputDto } from 'src/app/_shared/dtos/measurements/measurement-aggregation-input.dto';
import { MeasurementIntervalOutputDto } from 'src/app/_shared/dtos/measurements/measurement-interval-output.dto';

@Injectable({
  providedIn: 'root',
})
export class MeasurementsService {
  constructor(private apiService: ApiService) {}

  loadMeasurements(
    sensorId: number,
    measurementAggregationInput: MeasurementAggregationInputDto = {}
  ): Promise<any> {
    return this.apiService.post<MeasurementIntervalOutputDto[]>(
      `/sensors/${sensorId}/measurement-groups`,
      measurementAggregationInput
    );
  }
}
