import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditUserModalComponent } from 'src/app/users/components/edit-user-modal/edit-user-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { UsernameConfirmationHintModule } from 'src/app/users/components/username-confirmation-hint/username-confirmation-hint.module';

@NgModule({
  declarations: [EditUserModalComponent],
  exports: [EditUserModalComponent],
  imports: [
    CommonModule,
    FlexModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    UsernameConfirmationHintModule,
  ],
})
export class EditUserModalModule {}
