import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationMuteSettingsModule } from 'src/app/organization/notification-mute/components/notification-mute/notification-mute-settings.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, NotificationMuteSettingsModule],
  exports: [NotificationMuteSettingsModule],
})
export class NotificationMuteModule {}
