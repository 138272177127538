<form [formGroup]="form" (ngSubmit)="submit()">
  <div fxLayout="column" class="app-area">
    <!-- HEADER ROW -->
    <div fxLayout="row">
      <div fxFlex fxFlexAlign="start start">
        <h1>Benachrichtigungen Stummschalten</h1>
      </div>
      <div fxFlex style="text-align: right">
        <button mat-button fxFlexAlign="end end" type="submit">
          <mat-icon>save</mat-icon>
          Speichern
        </button>
      </div>
    </div>

    <!-- Content -->
    <mat-card
      fxLayout="row wrap"
      fxLayoutGap="24px"
      class="square-card mat-elevation-z0"
      style="height: 100%"
    >
      <div fxFlex="calc(100%-24px)"><h2>Stummschaltungsoptionen</h2></div>
      <div fxFlex="calc(50%-24px)">
        <mat-form-field appearance="outline">
          <mat-label>Alarmkonfiguration</mat-label>
          <mat-select formControlName="notificationConfig">
            <mat-option
              *ngFor="let notificationConfig of notificationConfigs"
              [value]="notificationConfig"
              >{{ notificationConfig.title }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="calc(50%-24px)"></div>
      <div fxFlex="calc(35%-24px)">
        <mat-form-field appearance="outline">
          <mat-label>Stummschalten bis</mat-label>
          <input
            matInput
            [matDatepicker]="pickerDateUntil"
            formControlName="dateUntil"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="pickerDateUntil"
          ></mat-datepicker-toggle>
          <mat-datepicker #pickerDateUntil></mat-datepicker>
        </mat-form-field>
      </div>
      <div fxFlex="calc(15%-24px)">
        <mat-form-field appearance="outline">
          <mat-label>Uhrzeit</mat-label>
          <input matInput type="time" formControlName="timeUntil" />
        </mat-form-field>
      </div>
      <div fxFlex="calc(50%)"></div>
    </mat-card>
  </div>
</form>
