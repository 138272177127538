import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { DashboardSiteMapGroupItemComponent } from 'src/app/organization/dashboard/components/site-map/group-item/dashboard-site-map-group-item.component';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { DashboardSiteMapSensorItemModule } from 'src/app/organization/dashboard/components/site-map/sensor-item/dashboard-site-map-sensor-item.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [DashboardSiteMapGroupItemComponent],
  exports: [DashboardSiteMapGroupItemComponent],
  imports: [
    CommonModule,
    MatListModule,
    FlexModule,
    MatIconModule,
    BrowserAnimationsModule,
    DashboardSiteMapSensorItemModule,
  ],
})
export class DashboardSiteMapGroupItemModule {}
