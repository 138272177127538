<form [formGroup]="form" (ngSubmit)="save()" class="content">
  <div fxLayout="column" class="app-area">
    <!-- HEADER ROW -->
    <div fxLayout="row">
      <div fxFlex fxFlexAlign="start start">
        <h1>Ampelkonfiguration {{ edit ? "bearbeiten" : "hinzufügen" }}</h1>
      </div>
      <div fxFlex style="text-align: right">
        <button
          mat-button
          fxFlexAlign="end end"
          [disabled]="buttonSaveLoading || !enabled"
        >
          <mat-icon>save</mat-icon>
          Speichern
        </button>
      </div>
    </div>

    <div fxLayout="row" style="height: 100%">
      <mat-card fxFlex="450px" class="square-card mat-elevation-z0">
        <div fxLayout="row">
          <div fxFlex>
            <h4 class="square-card-title">Ampelkonfiguration</h4>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="12px">
          <div fxFlex="100">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Titel</mat-label>
              <input
                matInput
                placeholder="Titel"
                type="text"
                formControlName="title"
              />
              <mat-error *ngIf="form.get('title').hasError('maxlength')">
                Maximal 255 Zeichen!
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="100">
            <app-filterable-select
              appearance="outline"
              label="Sensortyp"
              formControlName="sensorTypeConfig"
              [objects]="sensorTypeConfigs"
              [objToLabel]="sensorTypeToLabel"
            >
            </app-filterable-select>
          </div>
          <div fxFlex="calc(50% - 12px)">
            <mat-form-field appearance="outline">
              <mat-label>Sensorwert</mat-label>
              <mat-select
                formControlName="sensorPropertyConfig"
                [disabled]="!form.controls.sensorTypeConfig.value"
              >
                <mat-option
                  *ngFor="
                    let sensorPropertyConfig of form.controls.sensorTypeConfig
                      .value?.sensorPropertyConfigs
                  "
                  [value]="sensorPropertyConfig"
                  >{{ sensorPropertyConfig.displayName }} ({{
                    sensorPropertyConfig.propertyName
                  }})
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="calc(50% - 12px)">
            <mat-form-field appearance="outline">
              <mat-label>Vergleichsoperation</mat-label>
              <mat-select
                formControlName="redOperator"
                [disabled]="
                  !form.controls.sensorTypeConfig.value ||
                  !form.controls.sensorPropertyConfig.value
                "
              >
                <mat-option
                  *ngFor="let type of filterOperators()"
                  [value]="type"
                  >{{ getOperatorLabel(type) }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <!-- state indicator start -->
          <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-around center">
            <div fxFlex="calc(10% -12px)">
              <img [src]="redMapIcon()" style="padding-bottom: 1em" />
            </div>
            <div fxFlex="calc(60%-12px)" style="padding-bottom: 1.34375em">
              falls Sensorwert {{ selectedOperatorToLabel() }}
            </div>
            <div fxFlex="calc(30%-12px)">
              <!-- For numerical Values -->
              <mat-form-field
                appearance="outline"
                style="width: 100%"
                class="value-form-component"
                *ngIf="showValueTextField()"
              >
                <mat-label>Wert</mat-label>
                <input
                  matInput
                  placeholder="0"
                  [attr.type]="determineValueTextfieldType()"
                  formControlName="red"
                  [readonly]="!form.controls.sensorTypeConfig.value"
                />
              </mat-form-field>

              <!-- for Boolean Values -->
              <mat-form-field
                appearance="outline"
                *ngIf="!showValueTextField()"
              >
                <mat-label>Wert</mat-label>
                <mat-select
                  formControlName="red"
                  [disabled]="
                    !form.controls.sensorTypeConfig.value ||
                    !form.controls.sensorPropertyConfig.value
                  "
                >
                  <mat-option
                    *ngFor="let value of [true, false]"
                    [value]="value"
                    >{{ value }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div
            fxFlex="100"
            fxLayout="row"
            fxLayoutAlign="space-around center"
            style="height: 82px"
            *ngIf="isNumericalOperator()"
          >
            <div fxFlex="calc(10% -12px)">
              <img [src]="yellowMapIcon()" style="padding-bottom: 1em" />
            </div>
            <div fxFlex="calc(60%-12px)" style="padding-bottom: 1.34375em">
              falls Sensorwert zwischen den für Rot und Grün angegebenen Werten
            </div>
            <div fxFlex="calc(30% -12px)"></div>
          </div>

          <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-around center">
            <div fxFlex="calc(10% -12px)">
              <img [src]="greenMapIcon()" style="padding-bottom: 1em" />
            </div>
            <div fxFlex="calc(60%-12px)" style="padding-bottom: 1.34375em">
              falls Sensorwert {{ oppositeOfSelectedOperatorToLabel() }}
            </div>
            <div fxFlex="calc(30%-12px)" class="last-flex-element">
              <!-- For numeric values -->
              <mat-form-field
                appearance="outline"
                style="width: 100%"
                class="value-form-component"
                *ngIf="showValueTextField()"
              >
                <mat-label>Wert</mat-label>
                <input
                  matInput
                  placeholder="0"
                  [attr.type]="determineValueTextfieldType()"
                  formControlName="green"
                  [readonly]="greenValueDisabled()"
                />
              </mat-form-field>

              <!-- for Boolean Values -->
              <mat-form-field
                appearance="outline"
                *ngIf="!showValueTextField()"
              >
                <mat-label>Wert</mat-label>
                <mat-select
                  formControlName="green"
                  [disabled]="greenValueDisabled()"
                >
                  <mat-option
                    *ngFor="let value of [true, false]"
                    [value]="value"
                    >{{ value }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!-- state indicator end -->
      </mat-card>
      <mat-card
        fxFlex="450px"
        class="square-card mat-elevation-z0"
        style="overflow: scroll"
      >
        <div fxLayout="row">
          <div fxFlex>
            <h4 class="square-card-title">Zugewiesene Sensoren</h4>
          </div>
          <div fxFlex fxLayoutAlign="end">
            <button
              mat-icon-button
              (click)="addSensorClicked()"
              type="button"
              [disabled]="!enabled"
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
        <div fxLayout="row wrap">
          <ng-container *ngFor="let sensor of selectedSensors(); let i = index">
            <div fxFlex="100" fxLayout="row">
              <div fxLayout="column" fxFlex="grow">
                <div class="sensor-title truncate-text">
                  {{ sensor.displayName }}
                  <small *ngIf="sensor.group"
                    >({{ sensor.group.displayName }} )</small
                  >
                </div>
              </div>
              <div
                fxFlex="40px"
                fxFlexAlign="center"
                style="text-align: right"
                *ngIf="hasSensorCorrectSensorType(sensor)"
              >
                <mat-icon
                  class="text-danger"
                  style="margin: 8px"
                  matTooltip="Dieser Sensor passt nicht zum angegebenen Sensortyp!"
                  >error_outline
                </mat-icon>
              </div>
              <div fxFlex="40px" fxFlexAlign="center" style="text-align: right">
                <button
                  mat-icon-button
                  class="options-button"
                  (click)="removeSensor(sensor)"
                  type="button"
                  [disabled]="!enabled"
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </mat-card>
    </div>
  </div>
</form>
