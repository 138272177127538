import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { OrganizationSettingsComponent } from 'src/app/_shared/components/organization-settings/organization-settings.component';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatColorPickerModule } from 'src/app/_shared/angular-material-color-picker';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [OrganizationSettingsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    FlexModule,
    MatButtonModule,
    MatInputModule,
    NgxMatColorPickerModule,
    MatSelectModule,
    MatCardModule,
  ],
  exports: [OrganizationSettingsComponent],
})
export class OrganizationSettingsComponentModule {}
