import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { InfoTextOutputDto } from 'src/app/organization/notification-configuration/dto/info-text-output.dto';
import { sensorConfigTechnicalNameToLabel } from 'src/app/_shared/models/sensor-config-technical-name';
import { ActivatedRoute } from '@angular/router';
import { EditSensorModalComponent } from 'src/app/organization/positioning/components/edit-sensor-modal/edit-sensor-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { InfoTextEditModalComponent } from 'src/app/organization/notification-configuration/components/info-text-edit-modal/info-text-edit-modal.component';
import { InfoTextService } from 'src/app/organization/notification-configuration/services/info-text.service';

@Component({
  selector: 'app-info-text-list',
  templateUrl: './info-text-list.component.html',
  styleUrls: ['./info-text-list.component.scss'],
})
export class InfoTextListComponent implements OnInit {
  matTableDataSource: MatTableDataSource<InfoTextOutputDto> = new MatTableDataSource<InfoTextOutputDto>();
  displayedColumns = ['technicalName', 'description', 'options'];

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private infoTextService: InfoTextService
  ) {}

  ngOnInit(): void {
    this.matTableDataSource.data = this.activatedRoute.snapshot.data.infoTextList;
  }

  technicalNameToLabel(technicalName: any) {
    return sensorConfigTechnicalNameToLabel(technicalName);
  }

  openEdit(element: InfoTextOutputDto) {
    this.dialog
      .open(InfoTextEditModalComponent, {
        width: '620px',
        data: element,
      })
      .afterClosed()
      .toPromise()
      .then((_) => {
        this.infoTextService.getInfoTextList().then((res) => {
          this.matTableDataSource.data = res;
        });
      });
  }
}
