import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {GatewaysService} from '../../services/gateways.service';
import {GoogleMapsService} from '../../../../_shared/services/google-maps.service';
import {MapInfoWindow, MapMarker} from '@angular/google-maps';
import {GatewaylistItemOutputDto} from '../../dto/gatewaylist-item-output-dto';

@Component({
  selector: 'app-gateways-map',
  templateUrl: './gateways-map.component.html',
  styleUrls: ['./gateways-map.component.scss']
})
export class GatewaysMapComponent implements OnInit {
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;

  gmOptions: google.maps.MapOptions;
  places: Array<{
    markerPosition: google.maps.LatLngLiteral;
    markerOptions: google.maps.MarkerOptions;
    gateway: GatewaylistItemOutputDto
  }> = [];
  gatewayInfo: GatewaylistItemOutputDto;

  constructor(private gatewaysService: GatewaysService, private googleMapsService: GoogleMapsService) {
  }

  ngOnInit(): void {
    this.gmOptions = {
      center: {lat: Number(environment.lat), lng: Number(environment.lng)},
      zoom: Number(environment.zoom),
      streetViewControl: false,
    };

    this.gatewaysService.getGateways().then(gateways => {
      if (!gateways) {
        return;
      }

      for (const gateway of gateways.data) {
        if (!gateway.coordinates?.lng || !gateway.coordinates?.lat) {
          continue;
        }

        this.places.push({
          markerPosition: {
            lat: gateway.coordinates.lat,
            lng: gateway.coordinates.lng,
          },
          markerOptions: {
            title: gateway.title,
            icon: {
              url: this.buildMarkerIconUrl()
            },
          },
          gateway
        });
      }
    });
  }

  openInfoWindow(marker: MapMarker, gateway: GatewaylistItemOutputDto) {
    this.gatewayInfo = gateway;
    this.infoWindow.open(marker);
  }

  formatEui(eui: string) {
    const parts = eui.match(/.{1,2}/g);
    const formattedEui = parts.join(':');
    return formattedEui;
  }

  private buildMarkerIconUrl() {
    return this.googleMapsService.generateMarkerIcon(undefined, 'point');
  }
}
