import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { SensorService } from 'src/app/_shared/services/sensor.service';
import { SensorTypeConfig } from 'src/app/_shared/models/sensor-type-config';
import { CitylinkOrganization } from 'src/app/public-utility/organization/models/citylink-organization';
import { OrganizationService } from 'src/app/_shared/services/organization.service';
import { SensorTypesService } from 'src/app/public-utility/sensor-types/services/sensor-types.service';
import { ApiService } from 'src/app/_core/services/api/api.service';

export interface SensorCreateResolverResult {
  organizations: CitylinkOrganization[];
  sensorTypeConfigs: SensorTypeConfig[];
}

@Injectable({
  providedIn: 'root',
})
export class SensorCreateResolver
  implements Resolve<SensorCreateResolverResult | void> {
  constructor(
    private sensorsService: SensorService,
    private organizationsService: OrganizationService,
    private sensorTypesService: SensorTypesService,
    private apiService: ApiService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<SensorCreateResolverResult | void> {
    const promises = [];
    promises.push(this.organizationsService.getList(false));
    promises.push(this.sensorTypesService.getSensorTypeConfigs(false));

    return Promise.all(promises)
      .then((allPromisesResult) => {
        return {
          organizations: allPromisesResult[0],
          sensorTypeConfigs: allPromisesResult[1],
        } as SensorCreateResolverResult;
      })
      .catch((err) => {
        this.apiService.handleError(err);
        throw err;
      });
  }
}
