import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SensorsListComponent } from './sensors-list.component';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { ConnectionLogosModule } from 'src/app/public-utility/sensors/components/connection-logos/connection-logos.module';
import { LoadingIndicatorModule } from 'src/app/_core/components/loading-indicator/loading-indicator.module';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FilterableSelectModule } from 'src/app/_shared/components/filterable-select/filterable-select.module';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  declarations: [SensorsListComponent],
  exports: [SensorsListComponent],
  imports: [
    CommonModule,
    FlexModule,
    MatButtonModule,
    MatTableModule,
    MatIconModule,
    MatMenuModule,
    MatChipsModule,
    MatCheckboxModule,
    FormsModule,
    MatCardModule,
    ConnectionLogosModule,
    LoadingIndicatorModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    FilterableSelectModule,
    MatPaginatorModule,
  ],
})
export class SensorsListModule {}
