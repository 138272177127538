import {
  AfterViewInit,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  NgModel,
} from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { MatFormFieldAppearance } from '@angular/material/form-field/form-field';

@Component({
  selector: 'app-filterable-select',
  templateUrl: './filterable-select.component.html',
  styleUrls: ['./filterable-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterableSelectComponent),
      multi: true,
    },
  ],
})
export class FilterableSelectComponent<T>
  implements OnInit, ControlValueAccessor, AfterViewInit, OnChanges {
  @ViewChild('selectModel') selectModel: NgModel;

  /**
   * The FormControl musst be passed, so that the validators can be passed to the input model of this custom ControlValueAccessor.
   */
  @Input() formControl: AbstractControl;
  // tslint:disable-next-line:variable-name
  @Input() objects: Array<T>;
  @Input() objToLabel: (obj: T) => string;
  @Input() label: string;
  @Input() multiple = false;
  @Input() disabled = false;
  @Input() appearance: MatFormFieldAppearance = 'standard';

  public filterCtrl: FormControl = new FormControl();
  public filtered: ReplaySubject<T[]> = new ReplaySubject<T[]>(1);
  val: T | T[] | void;

  ngOnInit() {
    if (this.objects) {
      this.filtered.next(this.objects.slice());
    }

    // listen for search field value changes
    this.filterCtrl.valueChanges.subscribe(() => this.filterObjects());
  }

  ngOnChanges() {
    this.filtered.next(this.objects.slice());
  }

  ngAfterViewInit(): void {
    if (this.formControl) {
      this.selectModel.control.setValidators(this.formControl.validator);
      this.selectModel.control.setAsyncValidators(
        this.formControl.asyncValidator
      );
    }
  }

  protected filterObjects() {
    if (!this.objects) {
      return;
    }
    // get the search keyword
    let search = this.filterCtrl.value;
    if (!search) {
      this.filtered.next(this.objects.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filtered.next(
      this.objects.filter((obj) =>
        this.objToLabel(obj).toLowerCase().includes(search)
      )
    );
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  constructor() {}

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    this.value = value;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  isValue() {
    if (this.multiple) {
      return Array.isArray(this.value) && this.value.length > 0;
    } else {
      return !!this.value;
    }
  }

  clear() {
    if (this.multiple) {
      this.value = [];
    } else {
      this.value = undefined;
    }
  }
}
