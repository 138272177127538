<form [formGroup]="form" (ngSubmit)="close()">
  <div mat-dialog-content fxLayout="row wrap" fxLayoutGap="24px grid">
    <div fxFlex="50">
      <mat-form-field appearance="outline">
        <mat-label>Datum</mat-label>
        <input matInput [matDatepicker]="date" formControlName="date" />
        <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
        <mat-datepicker #date></mat-datepicker>
      </mat-form-field>
    </div>
    <div fxFlex="50">
      <mat-form-field appearance="outline">
        <mat-label>Uhrzeit</mat-label>
        <input matInput type="time" formControlName="time" />
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button>Ok</button>
  </div>
</form>
