<div fxLayout="column" class="app-area">
  <!-- HEADER ROW -->
  <div fxLayout="row" class="app-area-header">
    <div fxFlex fxFlexAlign="start start">
      <h1>QR-Codes</h1>
    </div>
    <div fxFlex style="text-align: right">
      <button
        mat-stroked-button
        fxFlexAlign="end end"
        (click)="onPrintClicked()"
        style="margin-right: 24px"
      >
        <mat-icon>print</mat-icon>
        <span style="margin-left: 12px">Drucken</span>
      </button>
    </div>
  </div>

  <mat-card fxLayout="row wrap" class="square-card mat-elevation-z0">
    <div
      fxFlex="25"
      fxLayout="column"
      *ngFor="let eui of euis"
      style="margin-bottom: 48px"
    >
      <qrcode-svg
        [value]="eui"
        style="max-width: 200px; margin: auto"
      ></qrcode-svg>
      <div style="text-align: center">EUI: {{ eui }}</div>
    </div>
  </mat-card>
</div>
