<mat-form-field appearance="outline" style="width: 100%">
  <mat-label>Nutzergruppen</mat-label>
  <mat-select
    [(ngModel)]="value"
    #selectModel="ngModel"
    placeholder="Nutzergruppe"
    multiple
  >
    <mat-option>
      <ngx-mat-select-search
        [formControl]="userGroupFilterCtrl"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option
      *ngFor="let userGroup of filteredUserGroups | async"
      [value]="userGroup"
    >
      {{ userGroupToString(userGroup) }}
    </mat-option>
  </mat-select>
  <button
    mat-button
    *ngIf="isEmptySelection()"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="value = []; $event.stopPropagation()"
    style="
      transform: translateY(-17%);
      color: rgba(0, 0, 0, 0.54);
      width: 30px;
      height: 30px;
    "
  >
    <mat-icon inline color="">close</mat-icon>
  </button>
</mat-form-field>
