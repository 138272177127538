import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { fillParentContainer } from 'fancy-textfill';

@Component({
  selector: 'app-dashboard-number-card',
  templateUrl: './dashboard-number-card.component.html',
  styleUrls: ['./dashboard-number-card.component.scss'],
})
export class DashboardNumberCardComponent implements AfterViewInit {
  @Input() title: string;
  @Input() value: string;
  @ViewChild('valueElement') valueElement;

  constructor() {}

  ngAfterViewInit(): void {
    fillParentContainer(this.valueElement.nativeElement, { multiline: false });
  }
}
