import { SensorEventType } from 'src/app/organization/dashboard/models/sensor-event-type';

export class SensorEventTypeHelper {
  static isError(type: SensorEventType) {
    return type === SensorEventType.error;
  }

  static isWarning(type: SensorEventType) {
    return type === SensorEventType.warning;
  }

  static isDefault(type: SensorEventType) {
    return type === SensorEventType.default;
  }

  static getSensorTypeText(type: SensorEventType): string | null {
    switch (type) {
      case SensorEventType.default:
        return 'Info';
      case SensorEventType.warning:
        return 'Warnung';
      case SensorEventType.error:
        return 'Alarm';
      default:
        return null;
    }
  }
}
