export enum SensorTypeEnum {
  motion = 'motion',
  multi = 'multi',
  water = 'water',
  smoke = 'fire',
  temperature = 'temperature',
  voltage = 'voltage',
  light = 'light',
  carbon_dioxide = 'carbon_dioxide',
}

export class SensorTypeNameMapper {
  static getSensorTypeNameBySensorType(sensorType?: string | SensorTypeEnum) {
    switch (sensorType) {
      case SensorTypeEnum.multi:
        return 'Multisensoren';
      case SensorTypeEnum.motion:
        return 'Bewegungssensoren';
      case SensorTypeEnum.water:
        return 'Flüssigkeitssensoren';
      case SensorTypeEnum.smoke:
        return 'Rauchsensoren';
      case SensorTypeEnum.temperature:
        return 'Temperaturesensoren';
      case SensorTypeEnum.voltage:
        return 'Spannungssensoren';
      case SensorTypeEnum.light:
        return 'Helligkeitssensoren';
      case SensorTypeEnum.carbon_dioxide:
        return 'Kohlenstoffdioxidsensoren';
      default:
        return 'unbekannt';
    }
  }
}
