<div fxLayout="column" class="app-area">
  <!-- HEADER ROW -->
  <div fxLayout="row">
    <div fxFlex fxFlexAlign="start start">
      <h1>Sensorfehler</h1>
    </div>
  </div>

  <!-- Content -->
  <mat-card
    fxLayout="row wrap"
    class="square-card mat-elevation-z0"
    style="height: 100%; overflow: scroll"
    fxLayoutAlign="start start"
  >
    <div fxFlex="100" fxLayout="row" dir="rtl">
      <button
        mat-icon-button
        fxFlexAlign="end end"
        (click)="onClearFilterClicked()"
        [disabled]="!isFilter()"
      >
        <mat-icon>settings_backup_restore</mat-icon>
      </button>
    </div>
    <div class="table-wrapper">
      <table mat-table [dataSource]="tableDataSource" style="width: 100%">
        <ng-container matColumnDef="sensorTitle">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter-input">
              <mat-label>Sensorname</mat-label>
              <input
                matInput
                type="text"
                [(ngModel)]="measurementParseErrorFilter.sensorName"
                (ngModelChange)="filterChangedSubject.next()"
                name="sensorName"
              />
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.sensor.displayName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="sensorEUI">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter-input">
              <mat-label>EUI</mat-label>
              <input
                matInput
                type="text"
                [(ngModel)]="measurementParseErrorFilter.sensorEUI"
                (ngModelChange)="filterChangedSubject.next()"
                name="eui"
              />
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.sensor.metaData.eui }}
          </td>
        </ng-container>

        <ng-container matColumnDef="sensorProperty">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter-input">
              <mat-label>Eigenschaft</mat-label>
              <input
                matInput
                type="text"
                [(ngModel)]="measurementParseErrorFilter.sensorPropertyName"
                (ngModelChange)="filterChangedSubject.next()"
                name="sensorPropertyName"
              />
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.sensorPropertyConfig.displayName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Zuletzt aufgetreten</th>
          <td mat-cell *matCellDef="let element">
            {{ toDate(element.latest) | date: "dd.MM.yyyy HH:mm" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="errorCount">
          <th mat-header-cell *matHeaderCellDef>Insgesamt aufgetreten</th>
          <td mat-cell *matCellDef="let element">
            {{ element.errorCount }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: displayedColumns"
          class="clickable"
          [routerLink]="
            '/public-utility/measurement-parse-error-groups/' + element.id
          "
        ></tr>
      </table>
    </div>
    <div fxFlex="100">
      <mat-paginator
        (page)="pageChangeEvent($event)"
        [length]="totalLength"
        [pageSize]="pageSize"
        [pageSizeOptions]="[100]"
        hidePageSize
        showFirstLastButtons
      >
      </mat-paginator>
    </div>
  </mat-card>
</div>
