import {MeasurementUnits} from "./measurement-units";

export enum MeasurementUnitSymbols {
  CELSIUS = '°C',
  FAHRENHEIT = '°F',
  LUMEN = 'lm',
  PERCENTAGE = '%',
  HOURS = 'h',
  MINUTES = 'min',
  SECONDS = 's',
  MILLISECONDS = 'ms',
  NANOSECONDS = 'ns',
  METER = 'm',
  KILOMETERS_PER_HOURS = 'km',
  GRAM = 'gr',
  KILOGRAM = 'kg',
  METRIC_TONS = 't',
  BAR = 'bar',
  DEGREE = '°',
  VOLT = 'v',
  M_A_H = 'mAh',
  PPM = 'ppm',
  K_W_H = 'kWh',
  W_H = 'Wh',
  W = 'W',
  K_W = 'kW',
}
export function measurementUnitsSymbolAsArray(): Array<MeasurementUnitSymbols> {
  return [
    MeasurementUnitSymbols.CELSIUS,
    MeasurementUnitSymbols.FAHRENHEIT,
    MeasurementUnitSymbols.LUMEN,
    MeasurementUnitSymbols.PERCENTAGE,
    MeasurementUnitSymbols.HOURS,
    MeasurementUnitSymbols.MINUTES,
    MeasurementUnitSymbols.SECONDS,
    MeasurementUnitSymbols.MILLISECONDS,
    MeasurementUnitSymbols.NANOSECONDS,
    MeasurementUnitSymbols.METER,
    MeasurementUnitSymbols.KILOMETERS_PER_HOURS,
    MeasurementUnitSymbols.GRAM,
    MeasurementUnitSymbols.KILOGRAM,
    MeasurementUnitSymbols.METRIC_TONS,
    MeasurementUnitSymbols.BAR,
    MeasurementUnitSymbols.DEGREE,
    MeasurementUnitSymbols.VOLT,
    MeasurementUnitSymbols.M_A_H,
    MeasurementUnitSymbols.PPM,
    MeasurementUnitSymbols.K_W_H,
    MeasurementUnitSymbols.W_H,
    MeasurementUnitSymbols.W,
    MeasurementUnitSymbols.K_W,
  ];
}
export function measurementUnitSymbolToLabel(
  enumValue: MeasurementUnitSymbols | string
): string {
  switch (enumValue) {
    case MeasurementUnitSymbols.CELSIUS:
      return '°C';
    case MeasurementUnitSymbols.FAHRENHEIT:
      return '°F';
    case MeasurementUnitSymbols.LUMEN:
      return 'lm';
    case MeasurementUnitSymbols.PERCENTAGE:
      return '%';
    case MeasurementUnitSymbols.HOURS:
      return 'h';
    case MeasurementUnitSymbols.MINUTES:
      return 'min';
    case MeasurementUnitSymbols.SECONDS:
      return 's';
    case MeasurementUnitSymbols.MILLISECONDS:
      return 'ms';
    case MeasurementUnitSymbols.NANOSECONDS:
      return 'ns';
    case MeasurementUnitSymbols.METER:
      return 'm';
    case MeasurementUnitSymbols.KILOMETERS_PER_HOURS:
      return 'km';
    case MeasurementUnitSymbols.GRAM:
      return 'gr';
    case MeasurementUnitSymbols.KILOGRAM:
      return 'kg';
    case MeasurementUnitSymbols.METRIC_TONS:
      return 't';
    case MeasurementUnitSymbols.BAR:
      return 'bar';
    case MeasurementUnitSymbols.DEGREE:
      return '°';
    case MeasurementUnitSymbols.VOLT:
      return 'v';
    case MeasurementUnitSymbols.M_A_H:
      return 'mAh';
    case MeasurementUnitSymbols.PPM:
      return 'ppm';
    case MeasurementUnits.K_W_H:
      return 'kWh';
    case MeasurementUnits.W_H:
      return 'Wh';
    case MeasurementUnits.W:
      return 'W';
    case MeasurementUnits.K_W:
      return 'kW';
    default:
      return '';
  }
}
