import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { NavigationModule } from 'src/app/navigation/navigation.module';
import { MatIconModule } from '@angular/material/icon';
import { CoreModule } from 'src/app/_core/core.module';
import { NavigationAccountListItemModule } from 'src/app/navigation/components/account-list-item/navigation-account-list-item.module';
import { NavigationMenuListItemModule } from 'src/app/navigation/components/menu-list-item/navigation-menu-list-item.module';
import { NavigationLogoutListItemModule } from 'src/app/navigation/components/logout-list-item/navigation-logout-list-item.module';
import { PublicUtilityModule } from 'src/app/public-utility/public-utility.module';
import { MatSelectModule } from '@angular/material/select';
import { OrganizationModule } from 'src/app/organization/organization.module';
import { UserPasswordResetModule } from 'src/app/_core/components/user-password-reset/user-password-reset.module';
import { UserConfirmationModule } from 'src/app/_core/components/user-confirmation/user-confirmation.module';
import { RequestNewPasswordModule } from 'src/app/_core/components/request-new-password/request-new-password.module';
import { RouterModule } from '@angular/router';
import { PublicUtilityDashboardModule } from 'src/app/public-utility/public-utility-dashboard/public-utility-dashboard.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { UsersModule } from 'src/app/users/users.module';
import { UserSettingsModule } from 'src/app/_core/components/user-settings/user-settings.module';
import { SharedModule } from 'src/app/_shared/shared.module';
import { LoadingIndicatorModule } from 'src/app/_core/components/loading-indicator/loading-indicator.module';
import { CityLinkSensorsModule } from 'src/app/public-utility/sensors/city-link-sensors.module';
import { UserUsernameConfirmationModule } from 'src/app/_core/components/user-username-confirmation/user-username-confirmation.module';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlDe } from 'src/app/_shared/pagination/mat-paginator-intl-de';

registerLocaleData(localeDe);

@NgModule({
  declarations: [AppComponent],
  imports: [
    /**
     * Feature Modules
     */
    CoreModule,
    SharedModule,
    NavigationModule,
    UserConfirmationModule,
    UserUsernameConfirmationModule,
    UserPasswordResetModule,
    RequestNewPasswordModule,
    UserSettingsModule,
    PublicUtilityModule,
    RouterModule,
    PublicUtilityDashboardModule,
    OrganizationModule,
    UsersModule,
    CityLinkSensorsModule,

    /**
     * Modules Needed by AppComponent
     */
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    NavigationAccountListItemModule,
    NavigationMenuListItemModule,
    NavigationLogoutListItemModule,
    MatSelectModule,
    MatSnackBarModule,
    LoadingIndicatorModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlDe },
  ],
})
export class AppModule {}
