import { SensorTypeConfig } from 'src/app/_shared/models/sensor-type-config';
import { Organization } from 'src/app/public-utility/organization/models/organization';
import { CitylinkSensorDetailOutputDto } from 'src/app/public-utility/sensors/dto/citylink-sensor-detail-output-dto';

export class CitylinkSensorDetail {
  id: number;
  displayName: string;
  sensorTypeConfig: SensorTypeConfig;
  organizations: Organization[];
  applyChangesInIotPlatform: boolean;
  metaData: {
    eui: string;
    niotaId: number;
  };
  applSessionKey: string;
  applKey: string;
  deviceAddress: string;
  description: string;
  netwSessionKey: string;

  static fromCitylinkSensorDetailOutputDto(
    dto: CitylinkSensorDetailOutputDto
  ): CitylinkSensorDetail {
    const result = new CitylinkSensorDetail();

    result.id = dto.id;
    result.displayName = dto.displayName;
    result.applyChangesInIotPlatform = dto.applyChangesInIotPlatform;
    if (dto.sensorTypeConfig) {
      result.sensorTypeConfig = SensorTypeConfig.fromSensorTypeOutputDto(
        dto.sensorTypeConfig
      );
    }

    result.organizations = dto.organizations.map((item) =>
      Organization.fromOrganizationOutputDto(item)
    );

    result.metaData = dto.metaData;
    if (dto.niotaData && dto.niotaData.data) {
      result.applSessionKey = dto.niotaData.data.applSessionKey;
      result.applKey = dto.niotaData.data.applKey;
      result.deviceAddress = dto.niotaData.data.deviceAddress;
      result.description = dto.niotaData.data.description;
      result.netwSessionKey = dto.niotaData.data.netwSessionKey;
    }

    return result;
  }
}
