import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-temperature-icon',
  template: `
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      [style.height]="size + 'px'"
      [style.width]="size + 'px'"
    >
      <rect
        width="28"
        height="28"
        rx="6"
        [attr.fill]="backgroundColor ? backgroundColor : '#FFA337'"
      />
      <path
        d="M15.8337 16.5301V6.20841C15.8337 5.60063 15.5922 5.01773 15.1624 4.58796C14.7327 4.15819 14.1498 3.91675 13.542 3.91675C12.9342 3.91675 12.3513 4.15819 11.9215 4.58796C11.4918 5.01773 11.2503 5.60063 11.2503 6.20841V16.5301C10.5145 17.0217 9.95629 17.737 9.65814 18.5702C9.36 19.4034 9.33772 20.3105 9.5946 21.1573C9.85149 22.0042 10.3739 22.746 11.0847 23.2732C11.7955 23.8003 12.657 24.0849 13.542 24.0849C14.427 24.0849 15.2885 23.8003 15.9993 23.2732C16.7101 22.746 17.2325 22.0042 17.4894 21.1573C17.7463 20.3105 17.724 19.4034 17.4258 18.5702C17.1277 17.737 16.5695 17.0217 15.8337 16.5301Z"
        [attr.stroke]="'white'"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [],
})
export class TemperatureIconComponent implements OnInit {
  @Input() backgroundColor: string;
  @Input() size = 128;

  constructor() {}

  ngOnInit(): void {}
}
