import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SensorGroupNodeService } from 'src/app/_shared/services/sensor-group-node.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SensorGroupNode } from 'src/app/_shared/models/sensor-group-node';
import { Roles } from 'src/app/_core/models/roles';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';
import { SnackBarService } from 'src/app/_shared/services/snack-bar.service';
import { GoogleMap, MapPolygon } from '@angular/google-maps';
import { DisposeBag } from '@ronas-it/dispose-bag/dist/src';
import { SensorGroupNodeInputDto } from 'src/app/_shared/dtos/sensor-group-node-input.dto';
import { GoogleMapsService } from 'src/app/_shared/services/google-maps.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ErrorService } from 'src/app/_shared/services/error.service';
import { environment } from 'src/environments/environment';

export enum EditMode {
  CREATE,
  EDIT,
  RENAME_ROOT,
}

interface EditSensorGroupNodeModalData {
  parent: SensorGroupNode;
  mode: EditMode;
  sensorGroupNode: SensorGroupNode;
}

@Component({
  selector: 'app-create-group-modal',
  templateUrl: './edit-sensor-group-node-modal.component.html',
  styleUrls: ['./edit-sensor-group-node-modal.component.scss'],
})
export class EditSensorGroupNodeModalComponent
  implements OnInit, OnDestroy, AfterViewInit {
  enabled = false;
  form: FormGroup;
  mode: EditMode;
  sensorGroupNode: SensorGroupNode;
  savingInProgress = false;
  disposeBag = new DisposeBag();
  colorOptions = [
    '#DC52F2',
    '#B27CF8',
    '#7F8DF6',
    '#1AABE9',
    '#3CB4CE',
    '#1BD3DF',
    '#33D4A4',
    '#5BD960',
    '#A8DE0D',
    '#FFC90A',
    '#FFA337',
    '#FB5F57',
    '#EA5280',
    '#AAAAAA',
  ];

  readonly gmOptions: google.maps.MapOptions = {
    streetViewControl: false,
  };
  drawingManager: google.maps.drawing.DrawingManager;
  geocoder = new google.maps.Geocoder();

  @ViewChild(GoogleMap, { static: false }) googleMap: GoogleMap;
  @ViewChild(MapPolygon, { static: false }) mapPolygon: MapPolygon;

  polygonOptions?: google.maps.PolygonOptions;
  polygonPath: Array<{ lat: number; lng: number }> = [];

  image: SafeUrl;

  constructor(
    private sensorGroupNodeService: SensorGroupNodeService,
    @Inject(MAT_DIALOG_DATA) public data: EditSensorGroupNodeModalData,
    public dialogRef: MatDialogRef<EditSensorGroupNodeModalComponent>,
    private authenticationService: AuthenticationService,
    private snackBarService: SnackBarService,
    private errorService: ErrorService,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnDestroy() {
    this.disposeBag.unsubscribe();
  }

  ngOnInit(): void {
    this.mode = this.data.mode ? this.data.mode : EditMode.CREATE;
    this.sensorGroupNode = this.data.sensorGroupNode;

    this.form = new FormGroup({
      id: new FormControl(this.sensorGroupNode?.id),
      displayName: new FormControl(this.sensorGroupNode?.displayName, [
        Validators.required,
        Validators.maxLength(255),
      ]),
      address: new FormGroup({
        city: new FormControl(this.sensorGroupNode?.address?.city, [
          Validators.maxLength(255),
        ]),
        street: new FormControl(this.sensorGroupNode?.address?.street, [
          Validators.maxLength(255),
        ]),
        zipCode: new FormControl(this.sensorGroupNode?.address?.zipCode, [
          Validators.maxLength(5),
        ]),
      }),
      geoAreaPolygon: new FormGroup({
        color: new FormControl(this.sensorGroupNode?.geoAreaPolygon?.color),
      }),
      image: new FormControl(),
    });

    this.polygonOptions = GoogleMapsService.buildPolygonOptions(
      true,
      true,
      this.sensorGroupNode?.geoAreaPolygon?.color
    );

    if (Array.isArray(this.sensorGroupNode?.geoAreaPolygon?.path)) {
      this.polygonPath = this.sensorGroupNode?.geoAreaPolygon?.path;
    }

    if (
      this.sensorGroupNode?.id !== undefined &&
      this.sensorGroupNode?.id !== null
    ) {
      this.sensorGroupNodeService
        .loadImage(this.sensorGroupNode.id)
        .then((result) => {
          if (result && result.image) {
            this.image = this.domSanitizer.bypassSecurityTrustUrl(result.image);
            this.form.patchValue({
              image: result.image,
            });
          }
        });
    }

    this.form.get('geoAreaPolygon.color').valueChanges.subscribe((newColor) => {
      this.drawingManager.setOptions(
        this.createDrawingManagerOptions(newColor)
      );
      this.polygonOptions = GoogleMapsService.buildPolygonOptions(
        true,
        true,
        newColor
      );
    });

    const currentUserSession = this.authenticationService.getCurrentUserSession();
    // @ts-ignore
    this.enabled = [
      Roles.ORGANIZATION_OWNER,
      Roles.ORGANIZATION_EDITOR,
    ].includes(currentUserSession?.role);
    if (!this.enabled) {
      this.form.disable();
    }
  }

  ngAfterViewInit() {
    this.initDrawingManager(this.googleMap);

    if (this.polygonPath.length > 0) {
      GoogleMapsService.centerAndZoomMap(
        this.googleMap,
        GoogleMapsService.polygonPathToBounds(this.polygonPath)
      );
    } else {
      GoogleMapsService.centerAndZoomMap(
        this.googleMap,
        null,
        { lat: Number(environment.lat), lng: Number(environment.lng) },
        Number(environment.zoom)
      );
    }
    this.addPolygonEditListeners();
  }

  private addPolygonEditListeners() {
    this.mapPolygon?.polygon?.getPaths()?.forEach((path, index) => {
      google.maps.event.clearListeners(path, 'insert_at');
      google.maps.event.clearListeners(path, 'remove_at');
      google.maps.event.clearListeners(path, 'set_at');

      google.maps.event.addListener(path, 'insert_at', () => {
        // @ts-ignore
        this.polygonPath = path.getArray() || [];
      });

      google.maps.event.addListener(path, 'remove_at', () => {
        // @ts-ignore
        this.polygonPath = path.getArray() || [];
      });

      google.maps.event.addListener(path, 'set_at', () => {
        // @ts-ignore
        this.polygonPath = path.getArray() || [];
      });
    });

    if (this.mapPolygon?.polygon) {
      google.maps.event.clearListeners(this.mapPolygon.polygon, 'dragend');
      google.maps.event.addListener(this.mapPolygon.polygon, 'dragend', () => {
        // @ts-ignore
        this.polygonPath = this.mapPolygon.polygon.getPaths().je[0].je;
      });
    }
  }

  getTitle(): string {
    switch (this.mode) {
      case EditMode.CREATE:
        return 'Gruppe anlegen';
      case EditMode.EDIT:
        return 'Gruppe bearbeiten';
      case EditMode.RENAME_ROOT:
        return 'Wurzelebene benennen';
    }
  }

  isShowHint(): boolean {
    return EditMode.RENAME_ROOT === this.mode;
  }

  save() {
    if (!this.form.valid) {
      return;
    }
    this.savingInProgress = true;

    if (this.mode === EditMode.CREATE) {
      this.createNewGroup();
    } else {
      this.updateGroup();
    }
  }

  updateGroup() {
    const dto = this.createDtoFromFormData();
    this.sensorGroupNodeService.update(dto, this.form.value.id).then((res) => {
      this.savingInProgress = false;
      this.dialogRef.close(res);
    });
  }

  createNewGroup() {
    const dto = this.createDtoFromFormData();
    this.sensorGroupNodeService.create(dto).then((result) => {
      this.savingInProgress = false;
      this.dialogRef.close(result);
    });
  }

  private createDtoFromFormData() {
    const formValue = this.form.value;
    const dto: SensorGroupNodeInputDto = {
      displayName: formValue.displayName,
      parent: this.data.parent?.id,
      address: {
        street: formValue.address.street,
        zipCode: formValue.address.zipCode,
        city: formValue.address.city,
      },
      geoAreaPolygon: {
        color: formValue.geoAreaPolygon.color,
        path: this.polygonPath,
      },
      image: this.form.value.image,
    };
    return dto;
  }

  canGeocode() {
    return (
      this.form.value.address.street &&
      this.form.value.address.zipCode &&
      this.form.value.address.city
    );
  }

  determineLocationFromPositioning() {
    const address = this.data.parent.address;
    if (address) {
      this.form.patchValue({
        address: {
          street: address.street,
          zipCode: address.zipCode,
          city: address.city,
        },
      });
    }
    const parentGeoAreaPolygon = this.data.parent.geoAreaPolygon;
    if (parentGeoAreaPolygon) {
      this.polygonPath = parentGeoAreaPolygon.path.slice();
      this.form.patchValue({
        geoAreaPolygon: {
          color: parentGeoAreaPolygon.color,
        },
      });

      GoogleMapsService.centerAndZoomMap(
        this.googleMap,
        GoogleMapsService.polygonPathToBounds(this.polygonPath)
      );
    }

    setTimeout(() => this.addPolygonEditListeners(), 50);
  }

  geocodeAddress() {
    const geocodeRequest: google.maps.GeocoderRequest = {
      address:
        this.form.value.address.street +
        ', ' +
        this.form.value.address.zipCode +
        ' ' +
        this.form.value.address.city,
    };
    this.geocoder.geocode(
      geocodeRequest,
      (
        results: google.maps.GeocoderResult[],
        status: google.maps.GeocoderStatus
      ) => {
        if (status !== google.maps.GeocoderStatus.OK) {
          this.snackBarService.showCustomMessage(
            'Die Koordinaten konnten nicht ermittelt werden'
          );
          return;
        }
        GoogleMapsService.centerAndZoomMap(
          this.googleMap,
          null,
          {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          },
          18
        );
      }
    );
  }

  initDrawingManager(map: GoogleMap) {
    const drawingOptions = this.createDrawingManagerOptions();
    this.drawingManager = new google.maps.drawing.DrawingManager(
      drawingOptions
    );
    this.drawingManager.setMap(map.googleMap);

    google.maps.event.addListener(
      this.drawingManager,
      'overlaycomplete',
      (event) => {
        this.polygonPath = event.overlay?.getPath()?.getArray() || [];
        event.overlay.setMap(null);
        this.drawingManager.setDrawingMode(null);
        this.addPolygonEditListeners();
      }
    );
  }

  private createDrawingManagerOptions(color = '#AAAAAA') {
    return {
      drawingMode: google.maps.drawing.OverlayType.MARKER,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
      },
      polygonOptions: GoogleMapsService.buildPolygonOptions(
        false,
        false,
        color
      ),
    } as google.maps.drawing.DrawingManagerOptions;
  }

  onFileSelect(event: any) {
    const files: FileList = event.target.files;
    const reader = new FileReader();
    try {
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        if (
          new TextEncoder().encode(reader.result as string).length > 16777215
        ) {
          this.errorService.showError({
            userErrorMessage:
              'Das gewählte Bild überschreitet die maximale Größe von 16MB.',
          });
          return;
        }
        this.image = this.domSanitizer.bypassSecurityTrustUrl(
          reader.result as string
        );
        this.form.patchValue({
          image: reader.result,
        });
      };
    } catch (e) {}
  }

  removeImage() {
    this.image = undefined;
    this.form.patchValue({
      image: null,
    });
  }
}
