import { User } from 'src/app/users/models/user';
import { Address } from 'src/app/_shared/models/address';
import { CitylinkOrganizationDto } from 'src/app/public-utility/organization/dtos/citylink-organization.dto';

export class CitylinkOrganization {
  id: number;
  name: string;
  address: Address;
  owner?: User;
  sensorCount: number;
  adminCount: number;
  userCount: number;
  notificationConfigCount: number;
  logo?: string;
  sensorMac: string[] = [];

  static fromOrganizationOutputDto(
    dto: CitylinkOrganizationDto
  ): CitylinkOrganization {
    const result = new CitylinkOrganization();

    result.id = dto.id;
    result.name = dto.name;
    result.logo = dto.logo;
    result.address = dto.address
      ? Address.fromAddressDto(dto.address)
      : undefined;
    result.owner = dto.owner ? User.fromUserOutputDto(dto.owner) : undefined;
    result.sensorMac = dto.sensorMac;
    result.sensorCount = dto.sensorCount;
    result.adminCount = dto.adminCount;
    result.userCount = dto.userCount;
    result.notificationConfigCount = dto.notificationConfigCount;

    return result;
  }
}
