import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { OrganizationService } from 'src/app/_shared/services/organization.service';
import { CitylinkOrganization } from 'src/app/public-utility/organization/models/citylink-organization';

@Injectable({
  providedIn: 'root',
})
export class OrganizationResolver
  implements Resolve<CitylinkOrganization | void> {
  constructor(private organizationService: OrganizationService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<CitylinkOrganization | void> {
    const id = Number(route.paramMap.get('id'));
    return this.organizationService.getById(id);
  }
}
