import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { SensorTypeConfig } from 'src/app/_shared/models/sensor-type-config';
import { SensorTypesService } from 'src/app/public-utility/sensor-types/services/sensor-types.service';
import { ApiService } from 'src/app/_core/services/api/api.service';
import { Sensor } from 'src/app/_shared/models/sensor';
import { SensorService } from 'src/app/_shared/services/sensor.service';
import { SensorStateIndicatorConfigurationOutputDto } from 'src/app/organization/sensor-state-indicator-configuration/dto/sensor-state-indicator-configuration-output.dto';
import { SensorStateIndicatorConfigurationService } from 'src/app/organization/sensor-state-indicator-configuration/services/sensor-state-indicator-configuration.service';

export interface SensorStateIndicatorConfigurationListResolverResult {
  sensorStateIndicatorConfigs: SensorStateIndicatorConfigurationOutputDto[];
  sensorTypeConfigs: SensorTypeConfig[];
}

@Injectable({
  providedIn: 'root',
})
export class SensorStateIndicatorConfigurationListResolver
  implements
    Resolve<void | SensorStateIndicatorConfigurationListResolverResult> {
  constructor(
    private sensorTypesService: SensorTypesService,
    private sensorStateIndicatorConfigurationService: SensorStateIndicatorConfigurationService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<void | SensorStateIndicatorConfigurationListResolverResult> {
    const promises = [];
    promises.push(this.sensorStateIndicatorConfigurationService.getList());
    promises.push(this.sensorTypesService.getSensorTypeConfigs());

    return Promise.all(promises).then((res) => {
      return {
        sensorStateIndicatorConfigs: res[0],
        sensorTypeConfigs: res[1],
      };
    });
  }
}
