import { UserOutputDto } from 'src/app/users/dtos/user-output.dto';
import { UserInputDto } from 'src/app/users/dtos/user-input.dto';

export class User {
  id: number;
  username: string;
  firstName: string;
  lastName: string;
  invitedByUserId?: number;
  role: { id: number; name: string };
  organizationId: number;
  emailConfirmed: boolean;
  sendEmailNotifications: boolean;
  sendPushNotifications: boolean;
  migrationUsername?: string;

  static fromUserOutputDto(dto: UserOutputDto): User {
    const result = new User();

    result.id = dto.id;
    result.username = dto.username;
    result.firstName = dto.firstName;
    result.lastName = dto.lastName;
    result.invitedByUserId = dto.invitedByUserId;
    result.role = dto.role;
    result.organizationId = dto.organizationId;
    result.emailConfirmed = dto.emailConfirmed;
    result.sendEmailNotifications = dto.sendEmailNotifications;
    result.sendPushNotifications = dto.sendPushNotifications;
    result.migrationUsername = dto.migrationUsername;

    return result;
  }

  toUserInputDto(): UserInputDto {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      role: this.role ? this.role.name : undefined,
      username: this.username,
    };
  }
}
