import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { OrganizationService } from 'src/app/_shared/services/organization.service';
import { Organization } from 'src/app/public-utility/organization/models/organization';
import { PublicUtilityDashboardDataDto } from 'src/app/public-utility/public-utility-dashboard/dto/public-utility-dashboard-data.dto';
import { PublicUtilityDashboardService } from 'src/app/public-utility/public-utility-dashboard/services/public-utility-dashboard.service';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/_core/services/api/api.service';

export interface PublicUtilityDashboardDataResolverResult {
  dashboardData?: PublicUtilityDashboardDataDto;
  organizations: Organization[];
}

@Injectable({
  providedIn: 'root', // https://github.com/angular/angular-cli/issues/10170#issuecomment-380673276
})
export class PublicUtilityDashboardDataResolver
  implements Resolve<void | PublicUtilityDashboardDataResolverResult> {
  constructor(
    private publicUtilityDashboardService: PublicUtilityDashboardService,
    private organizationService: OrganizationService,
    private apiService: ApiService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<void | PublicUtilityDashboardDataResolverResult> {
    const promises = [];

    promises.push(this.publicUtilityDashboardService.getDashboardData(false));
    promises.push(this.organizationService.getList(false));

    return Promise.all(promises)
      .then((result) => {
        return {
          dashboardData: result[0],
          organizations: result[1],
        } as PublicUtilityDashboardDataResolverResult;
      })
      .catch((err) => {
        this.apiService.handleError(err);
        throw err;
      });
  }
}
