import { Component, Input, OnInit } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { SensorGroupNode } from 'src/app/_shared/models/sensor-group-node';
import { SensorEventType } from 'src/app/organization/dashboard/models/sensor-event-type';
import { SensorGroupNodeView } from 'src/app/organization/positioning/services/positioning.service';

@Component({
  selector: 'app-tree-item',
  templateUrl: './dashboard-site-map-group-item.component.html',
  styleUrls: ['./dashboard-site-map-group-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ]),
  ],
})
export class DashboardSiteMapGroupItemComponent implements OnInit {
  @Input()
  item: SensorGroupNodeView;

  @Input()
  depth: number;
  paddingLeft = 0;

  constructor() {}

  ngOnInit(): void {
    this.paddingLeft = 8 * this.depth;
  }

  onItemSelected() {
    if (
      (this.item.children && this.item.children.length) ||
      (this.item.sensors && this.item.sensors.length)
    ) {
      this.item.selected = !this.item.selected;
    }
  }

  hasWarnings() {
    return this.hasEvents(this.item, SensorEventType.warning);
  }

  hasAlarms() {
    return this.hasEvents(this.item, SensorEventType.error);
  }

  /**
   * Recursive search for event.
   */
  private hasEvents(item: SensorGroupNode, type: SensorEventType): boolean {
    let hasEvent =
      item.sensors
        ?.map((sensor) => sensor.notifications)
        .reduce((a, b) => a.concat(b), [])
        .filter((event) => event.type === type).length > 0;

    if (!item.children) {
      return hasEvent;
    }

    for (const child of item.children) {
      hasEvent = hasEvent || this.hasEvents(child, type);
    }

    return hasEvent;
  }
}
