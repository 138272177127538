import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/_core/services/api/api.service';
import { PublicUtilityDashboardDataDto } from 'src/app/public-utility/public-utility-dashboard/dto/public-utility-dashboard-data.dto';

@Injectable({
  providedIn: 'root',
})
export class PublicUtilityDashboardService {
  constructor(private apiService: ApiService) {}

  getDashboardData(
    handleErrors = true
  ): Promise<PublicUtilityDashboardDataDto | void> {
    return this.apiService.get<PublicUtilityDashboardDataDto>(
      '/dashboard',
      undefined,
      undefined,
      handleErrors
    );
  }
}
