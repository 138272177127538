<div fxLayout="column" class="app-area">
  <!-- HEADER ROW -->
  <div fxLayout="row">
    <div fxFlex fxFlexAlign="start start">
      <h1>Alarmkonfigurationen</h1>
    </div>
  </div>

  <!-- Content -->
  <mat-card
    fxLayout="row wrap"
    fxLayoutAlign="start start"
    class="square-card mat-elevation-z0"
    style="height: 100%"
  >
    <div fxFlex="100" fxLayout="row" dir="rtl">
      <button
        mat-icon-button
        fxFlexAlign="end end"
        routerLink="create"
        [disabled]="!enabled"
      >
        <mat-icon>add</mat-icon>
      </button>
      <button
        mat-icon-button
        fxFlexAlign="end end"
        (click)="onClearFilterClicked()"
        [disabled]="!isFilter()"
      >
        <mat-icon>settings_backup_restore</mat-icon>
      </button>
    </div>
    <div fxFlex="100" class="table-wrapper">
      <table mat-table [dataSource]="matTableDataSource" style="width: 100%">
        <ng-container matColumnDef="icon">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <img
              *ngIf="element.iconUrl"
              [attr.src]="element.iconUrl"
              style="width: 32px; height: 32px"
            />
          </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter-input">
              <mat-label>Name</mat-label>
              <input
                matInput
                type="text"
                [(ngModel)]="notificationConfigFilter.title"
                (ngModelChange)="filterChangedSubject.next()"
                name="title"
              />
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.title }}</td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field style="margin-top: 8px">
              <mat-label>Benachrichtigungstyp</mat-label>
              <mat-select
                [(ngModel)]="notificationConfigFilter.type"
                (ngModelChange)="filterChangedSubject.next()"
              >
                <mat-option
                  [value]="type"
                  *ngFor="let type of notificationTypes()"
                  >{{ translateNotificationType(type) }}</mat-option
                >
              </mat-select>
              <button
                *ngIf="notificationConfigFilter.type"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="
                  notificationConfigFilter.type = undefined;
                  filterChangedSubject.next();
                  $event.stopPropagation()
                "
                style="width: 30px; height: 30px"
              >
                <mat-icon inline color="">close</mat-icon>
              </button>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ translateNotificationType(element.type) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="sensorTypeConfig">
          <th mat-header-cell *matHeaderCellDef>
            <app-filterable-select
              [(ngModel)]="notificationConfigFilter.sensorTypeConfig"
              (ngModelChange)="filterChangedSubject.next()"
              [objects]="sensorTypeConfigs"
              label="Sensortyp"
              [objToLabel]="sensorTypeToLabel"
            ></app-filterable-select>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.sensorTypeConfig?.displayName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="sensorPropertyConfig">
          <th mat-header-cell *matHeaderCellDef>
            <app-filterable-select
              class="filter-select"
              [disabled]="!notificationConfigFilter.sensorTypeConfig"
              [(ngModel)]="notificationConfigFilter.sensorPropertyConfig"
              label="Sensoreigenschaft"
              [objects]="
                notificationConfigFilter.sensorTypeConfig
                  ?.sensorPropertyConfigs || []
              "
              [objToLabel]="sensorPropertyConfigToLabel"
              (ngModelChange)="filterChangedSubject.next()"
            ></app-filterable-select>
          </th>
          <td mat-cell *matCellDef="let element">
            <div>
              <mat-chip-list class="float-left">
                <mat-chip
                  disabled
                  class="green-chip"
                  *ngIf="!isConfigurationIncomplete(element)"
                >
                  {{ element.sensorPropertyConfig?.displayName }} ({{
                    element.sensorPropertyConfig?.technicalName
                  }})
                </mat-chip>
              </mat-chip-list>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="rule">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field style="margin-top: 8px">
              <mat-label>Operator</mat-label>
              <mat-select
                [(ngModel)]="notificationConfigFilter.operator"
                (ngModelChange)="filterChangedSubject.next()"
              >
                <mat-option
                  *ngFor="let operator of operators()"
                  [value]="operator"
                  >{{ getOperatorLabel(operator) }}</mat-option
                >
              </mat-select>
              <button
                *ngIf="notificationConfigFilter.operator"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="
                  notificationConfigFilter.operator = undefined;
                  filterChangedSubject.next();
                  $event.stopPropagation()
                "
                style="width: 30px; height: 30px"
              >
                <mat-icon inline color="">close</mat-icon>
              </button>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="rule-column">
              <div>
                <mat-chip-list class="float-left">
                  <mat-chip disabled class="blue-chip">
                    {{ getOperatorLabel(element.operator) }}
                  </mat-chip>
                </mat-chip-list>
              </div>
              <div>
                <mat-chip-list
                  class="float-left"
                  *ngIf="element.value !== null && element.value !== undefined"
                >
                  <mat-chip disabled class="violet-chip">
                    {{ element.value }}
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="sensorCount">
          <th mat-header-cell *matHeaderCellDef>Sensoren</th>
          <td mat-cell *matCellDef="let element">
            <mat-chip-list class="float-left">
              <mat-chip disabled class="grey-chip">
                {{ element.sensorIds?.length }}
              </mat-chip>
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container matColumnDef="configurationIncompleteHint">
          <th mat-header-cell *matHeaderCellDef class="icon-column"></th>
          <td mat-cell *matCellDef="let element" class="icon-column">
            <mat-icon
              class="text-danger"
              *ngIf="isConfigurationIncomplete(element)"
              style="margin: 8px"
              matTooltip="Die Konfiguration dieser Alarmkonfiguration ist unvollständig. Die Alarmkonfiguration wird nicht angewendet."
            >
              error_outline
            </mat-icon>
          </td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef class="icon-column"></th>
          <td mat-cell *matCellDef="let element" class="icon-column">
            <button
              mat-icon-button
              style="float: right"
              [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                (click)="openEditNotificationConfig(element)"
              >
                Bearbeiten
              </button>
              <button
                mat-menu-item
                (click)="deleteNotificationConfig(element)"
                [disabled]="!enabled"
              >
                Löschen
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="openEditNotificationConfig(row)"
        ></tr>
      </table>
    </div>
  </mat-card>
</div>
