import { Component, Input, OnInit } from '@angular/core';
import { SensorEvent } from 'src/app/_shared/models/sensor-event';
import { Sensor } from 'src/app/_shared/models/sensor';
import { SensorEventTypeHelper } from 'src/app/_shared/helpers/sensor-event-type-helper';

@Component({
  selector: 'app-sensor-item',
  templateUrl: './dashboard-site-map-sensor-item.component.html',
  styleUrls: ['./dashboard-site-map-sensor-item.component.scss'],
})
export class DashboardSiteMapSensorItemComponent implements OnInit {
  @Input()
  paddingLeft: number;

  @Input()
  sensor: Sensor;

  constructor() {}

  ngOnInit(): void {}

  isError(event: SensorEvent) {
    return SensorEventTypeHelper.isError(event.type);
  }

  isWarning(event: SensorEvent) {
    return SensorEventTypeHelper.isWarning(event.type);
  }

  isDefault(event: SensorEvent) {
    return SensorEventTypeHelper.isDefault(event.type);
  }

  countEvents(sensor: Sensor) {
    return (
      sensor.notificationInfos
        ?.map((item) => item.count)
        .reduce((prev, current) => prev + current, 0) || 0
    );
  }
}
