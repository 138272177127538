import { NotificationOutputDto } from 'src/app/organization/dashboard/dtos/notification-output.dto';
import * as moment from 'moment';
import { Moment } from 'moment';

export class Notification {
  id: number;
  title: string;
  sensorId: number;
  subtitle: string;
  body: string;
  date: Moment;
  icon: string;
  type: string;
  location: string;

  static fromNotificationOutputDto(dto: NotificationOutputDto): Notification {
    const result = new Notification();

    result.id = dto.id;
    result.title = dto.title;
    result.sensorId = dto.sensorId;
    result.subtitle = dto.subtitle;
    result.body = dto.body;
    result.date = moment(dto.date);
    result.type = dto.type;
    result.location = dto.location;
    result.icon = dto.icon;

    return result;
  }
}
