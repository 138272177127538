import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { OrganizationService } from 'src/app/_shared/services/organization.service';

@Injectable({
  providedIn: 'root', // https://github.com/angular/angular-cli/issues/10170#issuecomment-380673276
})
export class MailTemplateConfigurationResolver implements Resolve<void | any> {
  constructor(private organizationService: OrganizationService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<void | any> {
    return this.organizationService.getMailTemplateConfiguration();
  }
}
