import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { User } from 'src/app/users/models/user';
import { UserService } from 'src/app/users/services/user.service';

@Injectable({
  providedIn: 'root', // https://github.com/angular/angular-cli/issues/10170#issuecomment-380673276
})
export class ProfileResolver implements Resolve<void | User> {
  constructor(private userService: UserService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<void | User> {
    return this.userService.getUserProfile().catch((error) => {
      console.log(error);
    });
  }
}
