import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationConfig } from 'src/app/organization/notification-configuration/models/notification-config';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationMuteService } from 'src/app/organization/notification-mute/services/notification-mute.service';
import { NotificationMutePostDto } from 'src/app/organization/notification-mute/dto/notification-mute.post.dto';

@Component({
  selector: 'app-notification-mute',
  templateUrl: './notification-mute-settings.component.html',
  styleUrls: ['./notification-mute-settings.component.scss'],
})
export class NotificationMuteSettingsComponent implements OnInit {
  form: FormGroup;
  notificationConfigs: NotificationConfig[] = [];

  constructor(
    private route: ActivatedRoute,
    private notificationMuteService: NotificationMuteService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.notificationConfigs = this.route.snapshot.data.notificationConfigList;
    const notificationConfigIdToSelect = Number(
      this.route.snapshot.params.notificationConfigId
    );
    const notificationConfigToSelect = this.notificationConfigs.filter(
      (it) => it.id === notificationConfigIdToSelect
    )[0];

    this.form = new FormGroup({
      notificationConfig: new FormControl(notificationConfigToSelect, [
        Validators.required,
      ]),
      dateUntil: new FormControl(undefined, Validators.required),
      timeUntil: new FormControl(undefined, Validators.required),
    });
  }

  submit() {
    const until: Date = this.form.value.dateUntil;
    until.setHours(this.form.value.timeUntil.split(':')[0]);
    until.setMinutes(this.form.value.timeUntil.split(':')[1]);

    const dto: NotificationMutePostDto = {
      notificationConfigId: this.form.value.notificationConfig.id,
      until,
    };

    this.notificationMuteService.create(dto).then((res) => {
      this.router.navigateByUrl('organization/dashboard');
    });
  }
}
