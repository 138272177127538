<div
  fxLayout="column"
  class="sensor-row"
  [class.hover-pointer]="true"
  [draggable]="enabled"
  (dragenter)="onDragEnter($event)"
  (dragleave)="onDragLeave($event)"
  (drop)="onDrop($event)"
  (dragstart)="onDragStart($event)"
  (dragend)="onDragEnd($event)"
  (dragover)="$event.preventDefault()"
  #sensorRow
>
  <div
    fxFlex="100"
    fxLayout="row"
    [class.selected]="positioningService.selectedSensor === sensor"
    class="selection-wrapper"
    (click)="onClick()"
  >
    <div fxFlex="40px" fxFlexAlign="center">
      <mat-icon class="drag-indicator">drag_indicator</mat-icon>
    </div>
    <div fxLayout="column" fxFlex="grow">
      <div class="group-title truncate-text">{{ sensor.displayName }}</div>
      <div class="sensor-events">
        <span class="alarm" *ngIf="hasAlarms()"
          >{{ alarmCount() }} Alarme
        </span>
        <span *ngIf="hasAlarms() && hasWarnings()" class="no-events"
          >,&nbsp;</span
        >
        <span class="warning" *ngIf="hasWarnings()"
          >{{ warningsCount() }} Warnungen
        </span>
        <span class="no-events" *ngIf="!hasAlarms() && !hasWarnings()"
          >Keine Ereignisse
        </span>
        <span class="no-events">in den letzten 24 Stunden.</span>
      </div>
    </div>
    <div fxFlex="40px" fxFlexAlign="center" style="text-align: right">
      <button
        mat-icon-button
        class="options-button"
        [matMenuTriggerFor]="menu"
        (click)="$event.stopPropagation()"
      >
        <mat-icon class="options">more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="editSensor()">
          <mat-icon>create</mat-icon>
          Bearbeiten
        </button>
        <button mat-menu-item (click)="removeSensor()" [disabled]="!enabled">
          <mat-icon>delete_outline</mat-icon>
          Entfernen
        </button>
      </mat-menu>
    </div>
  </div>
</div>
