import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SensorsListModule } from 'src/app/public-utility/sensors/components/sensors-list/sensors-list.module';
import { SensorEditModule } from 'src/app/public-utility/sensors/components/sensor-edit/sensor-edit.module';
import { SensorCsvImportModule } from 'src/app/public-utility/sensors/components/sensor-csv-import/sensor-csv-import.module';
import { SensorListEditModule } from 'src/app/public-utility/sensors/components/sensor-list-edit/sensor-list-edit.module';
import { SensorQrCodeListModule } from 'src/app/public-utility/sensors/components/sensor-qr-code-list/sensor-qr-code-list.module';
import { OrganizationSelectModalModule } from 'src/app/public-utility/sensors/components/organization-select-modal/organization-select-modal.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SensorsListModule,
    SensorEditModule,
    SensorCsvImportModule,
    SensorListEditModule,
    SensorQrCodeListModule,
    OrganizationSelectModalModule,
  ],
  exports: [
    SensorsListModule,
    SensorEditModule,
    SensorCsvImportModule,
    SensorListEditModule,
    SensorQrCodeListModule,
    OrganizationSelectModalModule,
  ],
})
export class CityLinkSensorsModule {}
