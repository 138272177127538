import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SensorQrCodeListComponent } from './sensor-qr-code-list.component';
import { FlexModule } from '@angular/flex-layout';
import { QRCodeSVGModule } from 'ngx-qrcode-svg';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [SensorQrCodeListComponent],
  imports: [
    CommonModule,
    FlexModule,
    QRCodeSVGModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
  ],
})
export class SensorQrCodeListModule {}
