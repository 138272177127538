<mat-card fxFlex="370px" class="square-card mat-elevation-z0">
  <div fxLayout="row">
    <div fxFlex>
      <h4 class="square-card-title">
        {{ sensor.displayName }} ({{ sensor.sensorTypeConfig?.displayName }})
      </h4>
    </div>
    <div fxFlex fxLayoutAlign="end">
      <button mat-icon-button class="btn-edit" (click)="editSensor()">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
  </div>
  <div fxLayout="row wrap" style="margin: 12px">
    <div fxFlex="100">
      <img [src]="image" *ngIf="image" style="max-width: 354px" />
    </div>

    <!-- Measurements Start -->
    <mat-tab-group
      [disableRipple]="true"
      style="width: 100%; margin-top: 12px"
      (selectedTabChange)="tabChanged($event)"
      [(selectedIndex)]="selectedTabIndex"
    >
      <mat-tab label="Messungen">
        <div
          fxFlex="100"
          fxLayout="row"
          style="margin-top: 12px; margin-bottom: 12px"
        >
          <button
            mat-button
            fxFlex="45"
            (click)="changeMeasurementAggregationFrom()"
          >
            {{ measurementAggregationInputDto.from | date: "dd.MM.yyyy HH:mm" }}
          </button>
          <div fxFlex="10" style="text-align: center">
            <div style="margin-top: 7px">-</div>
          </div>
          <button
            mat-button
            fxFlex="45"
            (click)="changeMeasurementAggregationTo()"
          >
            {{ measurementAggregationInputDto.to | date: "dd.MM.yyyy HH:mm" }}
          </button>
        </div>
        <div fxFlex="100" *ngFor="let measurent of measurements">
          <app-sensor-measurements-chart
            [measurementIntervalData]="measurent"
          ></app-sensor-measurements-chart>
        </div>
        <div
          *ngIf="!measurements?.length && !loading"
          fxFlex="100"
          style="text-align: center; margin-top: 12px"
        >
          <span class="light-text">Keine Daten vorhanden</span>
        </div>
        <div
          *ngIf="loading"
          fxFlex="100"
          style="text-align: center; margin-top: 12px"
          fxLayout="row"
          fxLayoutAlign="space-around center"
        >
          <mat-spinner></mat-spinner>
        </div>
      </mat-tab>
      <mat-tab label="Meldungen">
        <app-event
          *ngFor="let notification of notifications; let i = index"
          [date]="notification.date"
          [title]="notification.title"
          [subtitle]="notification.subtitle"
          [body]="notification.body"
          [type]="notification.type"
          [location]="notification.location"
          [showDivider]="i < notifications.length - 1"
          [iconUrl]="notification.icon"
        ></app-event>
        <div
          *ngIf="!notifications?.length && !loading"
          fxFlex="100"
          style="text-align: center; margin-top: 24px"
        >
          <span class="light-text">Keine Meldungen vorhanden</span>
        </div>
        <div
          *ngIf="loading"
          fxFlex="100"
          style="margin-top: 12px"
          fxLayout="row"
          fxLayoutAlign="space-around center"
        >
          <mat-spinner></mat-spinner>
        </div>
      </mat-tab>
      <mat-tab label="Freigaben">
        <div
          *ngIf="loading"
          fxFlex="100"
          style="margin-top: 12px"
          fxLayout="row"
          fxLayoutAlign="space-around center"
        >
          <mat-spinner></mat-spinner>
        </div>

        <div
          *ngIf="!loading"
          style="margin-top: 12px"
          fxFlex="100"
          fxLayout="row wrap"
        >
          <div
            fxLayout="row"
            fxFlex="100"
            style="margin-top: 16px"
            *ngFor="
              let sensorPairingShare of sensorPairingShares;
              let i = index
            "
          >
            <div fxFlex="grow" fxLayout="column">
              <div class="share-title">Freigabe {{ i + 1 }}</div>
              <div class="share-light-text">
                Erstellt von {{ sensorPairingShare.createdBy.firstName }}
                {{ sensorPairingShare.createdBy.lastName }} am
                {{ sensorPairingShare.createdAt | date: "dd.MM.yyyy" }}
              </div>
              <div class="share-light-text">
                Ablaufdatum
                {{ sensorPairingShare.expirationDate | date: "dd.MM.yyyy" }}
              </div>
            </div>
            <div fxFlex="40px" fxFlexAlign="center" style="text-align: right">
              <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
                (click)="$event.stopPropagation()"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button
                  mat-menu-item
                  (click)="copyFrontendUrlIntoClipboard(sensorPairingShare)"
                >
                  <mat-icon>content_copy</mat-icon> In die Zwischenablage
                  kopieren
                </button>
                <button
                  mat-menu-item
                  (click)="openSharedSensorView(sensorPairingShare)"
                >
                  <mat-icon>ios_share</mat-icon> Öffnen
                </button>
                <button mat-menu-item (click)="openEdit(sensorPairingShare)">
                  <mat-icon>edit</mat-icon> Bearbeiten
                </button>
                <button mat-menu-item (click)="delete(sensorPairingShare)">
                  <mat-icon>delete</mat-icon> Löschen
                </button>
              </mat-menu>
            </div>
          </div>
        </div>

        <div fxFlex="100" style="text-align: center; margin-top: 12px">
          <button
            mat-raised-button
            color="primary"
            (click)="openCreateShareModal()"
          >
            Freigabe erstellen
          </button>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</mat-card>
