import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/_core/services/api/api.service';
import { NotificationConfig } from 'src/app/organization/notification-configuration/models/notification-config';
import { SnackBarService } from 'src/app/_shared/services/snack-bar.service';
import { NotificationConfigIconOutputDto } from 'src/app/organization/notification-configuration/dto/notification-config-icon-output.dto';
import { NotificationConfigOutputDto } from 'src/app/organization/notification-configuration/dto/notification-config-output.dto';
import { NotificationConfigInputDto } from 'src/app/organization/notification-configuration/dto/notification-config-input.dto';
import { NotificationConfigurationFilterInputDto } from 'src/app/organization/notification-configuration/dto/notification-configuration-filter-input.dto';

@Injectable({
  providedIn: 'root',
})
export class NotificationConfigService {
  constructor(
    private apiService: ApiService,
    private snackBarService: SnackBarService
  ) {}

  getNotificationConfigList(
    filterInput?: NotificationConfigurationFilterInputDto
  ): Promise<NotificationConfig[]> {
    return this.apiService
      .get<NotificationConfigOutputDto[]>(
        '/sensor-notifications/configs',
        undefined,
        filterInput
      )
      .then((result) => {
        if (!result) {
          return [];
        }
        return result.map((dto) =>
          NotificationConfig.fromNotificationConfigOutputDto(dto)
        );
      });
  }

  getById(id: number, handleErrors = true): Promise<NotificationConfig | void> {
    return this.apiService
      .get<NotificationConfigOutputDto>(
        '/sensor-notifications/configs/' + id,
        undefined,
        undefined,
        handleErrors
      )
      .then((dto) => {
        if (!dto) {
          return;
        }
        return NotificationConfig.fromNotificationConfigOutputDto(dto);
      });
  }

  deleteNotificationConfig(id: number): Promise<void> {
    return this.apiService
      .delete('/sensor-notifications/configs/' + id)
      .then((_) => {
        this.snackBarService.deleteSuccessful();
      });
  }

  createNotificationConfig(dto: NotificationConfigInputDto): Promise<void> {
    return this.apiService
      .post('/sensor-notifications/configs', dto)
      .then((_) => {
        this.snackBarService.saveSuccessful();
      });
  }

  updateNotificationConfig(dto: NotificationConfigInputDto): Promise<void> {
    return this.apiService
      .patch('/sensor-notifications/configs/' + dto.id, dto)
      .then((_) => {
        this.snackBarService.saveSuccessful();
      });
  }

  getIconList(handleErrors = true): Promise<NotificationConfigIconOutputDto[]> {
    return this.apiService
      .get<NotificationConfigIconOutputDto[]>(
        '/sensor-notifications/configs/icons',
        undefined,
        undefined,
        handleErrors
      )
      .then((result) => {
        if (!result) {
          return [];
        }

        return result;
      });
  }
}
