import { Component, OnInit } from '@angular/core';
import { SensorTypeConfig } from 'src/app/_shared/models/sensor-type-config';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { Roles } from 'src/app/_core/models/roles';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';
import { SensorTypesService } from 'src/app/public-utility/sensor-types/services/sensor-types.service';
import { debounceTime } from 'rxjs/operators';
import { SensorFilterInputDto } from 'src/app/public-utility/sensors/dto/sensor-filter-input-dto';
import { Subject } from 'rxjs';
import { SensorTypeConfigFilterInputDto } from 'src/app/public-utility/sensor-types/dto/sensor-type-config-filter-input.dto';

@Component({
  selector: 'app-sensor-types',
  templateUrl: './sensor-type-config-list.component.html',
  styleUrls: ['./sensor-type-config-list.component.scss'],
})
export class SensorTypeConfigListComponent implements OnInit {
  tableDataSource: MatTableDataSource<SensorTypeConfig> = new MatTableDataSource<SensorTypeConfig>();
  displayedColumns = [
    'name',
    'sensorTypePropertyConfigs',
    'niotaDeviceTypeId',
    'options',
  ];
  enabled = false;
  importInProgress = false;

  sensorTypeConfigFilterInput: SensorTypeConfigFilterInputDto = {
    name: undefined,
    niotaDeviceTypeId: undefined,
    sensorPropertyConfig: undefined,
  };
  filterChangedSubject = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private sensorTypesService: SensorTypesService
  ) {}

  ngOnInit(): void {
    this.tableDataSource.data = this.activatedRoute?.snapshot?.data?.sensorTypes;
    this.enabled = [Roles.CITYLINK_OWNER, Roles.CITYLINK_EDITOR].includes(
      this.authenticationService.getCurrentUserSession()?.role
    );

    this.filterChangedSubject.pipe(debounceTime(300)).subscribe(() => {
      this.sensorTypesService
        .getSensorTypeConfigs(true, this.sensorTypeConfigFilterInput)
        .then((results) => {
          this.tableDataSource.data = results;
        });
    });
  }

  onImportClicked() {
    this.importInProgress = true;
    this.sensorTypesService
      .importSensorTypesFromNiota()
      .then((_) => {
        this.sensorTypesService.getSensorTypeConfigs().then((result) => {
          this.tableDataSource.data = result;
        });
      })
      .finally(() => {
        this.importInProgress = false;
      });
  }

  onClearFilterClicked() {
    this.sensorTypeConfigFilterInput = {};
    this.filterChangedSubject.next();
  }

  isFilter() {
    return (
      this.sensorTypeConfigFilterInput.sensorPropertyConfig ||
      this.sensorTypeConfigFilterInput.niotaDeviceTypeId ||
      this.sensorTypeConfigFilterInput.name
    );
  }
}
