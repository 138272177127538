import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { SensorTypeConfig } from 'src/app/_shared/models/sensor-type-config';
import { SensorTypesService } from 'src/app/public-utility/sensor-types/services/sensor-types.service';

@Injectable({
  providedIn: 'root',
})
export class SensorTypeConfigsResolver implements Resolve<SensorTypeConfig[]> {
  constructor(private sensorTypesService: SensorTypesService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<SensorTypeConfig[]> {
    return this.sensorTypesService.getSensorTypeConfigs().catch((err) => []);
  }
}
