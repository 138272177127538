import { PolygonDto } from 'src/app/_shared/dtos/polygon.dto';

export class Polygon {
  color: string;
  path: { lat: number; lng: number }[];

  static fromPolygonDto(geoAreaPolygon: PolygonDto) {
    const result = new Polygon();
    result.color = geoAreaPolygon.color;
    result.path = geoAreaPolygon.path;
    return result;
  }
}
