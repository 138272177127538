import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/_core/services/api/api.service';
import { NotificationConfig } from 'src/app/organization/notification-configuration/models/notification-config';
import { SnackBarService } from 'src/app/_shared/services/snack-bar.service';
import { NotificationConfigIconOutputDto } from 'src/app/organization/notification-configuration/dto/notification-config-icon-output.dto';
import { NotificationConfigOutputDto } from 'src/app/organization/notification-configuration/dto/notification-config-output.dto';
import { NotificationConfigInputDto } from 'src/app/organization/notification-configuration/dto/notification-config-input.dto';
import { NotificationConfigurationFilterInputDto } from 'src/app/organization/notification-configuration/dto/notification-configuration-filter-input.dto';
import { InfoTextOutputDto } from 'src/app/organization/notification-configuration/dto/info-text-output.dto';
import { InfoTextInputDto } from 'src/app/organization/notification-configuration/dto/info-text-input.dto';

@Injectable({
  providedIn: 'root',
})
export class InfoTextService {
  constructor(
    private apiService: ApiService,
    private snackBarService: SnackBarService
  ) {}

  getInfoTextList(): Promise<InfoTextOutputDto[]> {
    return this.apiService
      .get<InfoTextOutputDto[]>('/sensor-notifications/info-text')
      .then((res) => {
        if (!res) {
          return [];
        }
        return res;
      });
  }

  update(id: number, dto: InfoTextInputDto) {
    return this.apiService.patch(`/sensor-notifications/info-text/${id}`, dto);
  }
}
