<div fxLayout="column" fxLayoutAlign="center center" class="full-page">
  <div fxLayout="column" fxLayoutAlign="center center" class="login-content">
    <img class="logo" />

    <h1 class="login-title">Anmelden</h1>

    <form (ngSubmit)="onSubmit()" #f="ngForm">
      <mat-card
        class="login-card square-card mat-elevation-z"
        fxLayout="column"
        fxLayoutAlign="center stretch"
      >
        <div *ngIf="loginError" class="text-danger error-card">
          {{ loginError }}
        </div>
        <mat-form-field appearance="outline">
          <mat-label>E-Mail</mat-label>
          <input
            matInput
            placeholder="E-Mail"
            type="email"
            name="email"
            ngModel
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Passwort</mat-label>
          <input
            matInput
            placeholder="Passwort"
            type="password"
            name="password"
            ngModel
          />
        </mat-form-field>
        <button
          mat-button
          color="primary"
          class="upper-case forgot-password"
          type="button"
          (click)="forgotPassword()"
        >
          Passwort vergessen
        </button>
        <button
          mat-flat-button
          color="primary"
          type="submit"
          class="upper-case"
          [disabled]="loginInProcess"
          [class.button-spinner]="loginInProcess"
        >
          Anmelden
        </button>
      </mat-card>
    </form>
    <div fxLayout="row" fxLayoutAlign="center center" class="link-row">
      <div><a class="upper-case" routerLink="/imprint">Impressum</a></div>
      <div class="divider">&nbsp;</div>
      <div><a class="upper-case" routerLink="/privacy">Datenschutz</a></div>
    </div>
  </div>
</div>
