<form [formGroup]="form" (ngSubmit)="save()">
  <h2 mat-dialog-title>Hinweistext bearbeiten</h2>
  <mat-dialog-content>
    <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="24px">
      <div fxFlex="100">
        <mat-form-field appearance="outline">
          <mat-label>Eigenschaft</mat-label>
          <mat-select formControlName="technicalName" disabled>
            <mat-option
              *ngFor="let technicalName of technicalNames()"
              [value]="technicalName"
              >{{ technicalNameToLabel(technicalName) }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="100" class="last-flex-element">
        <mat-form-field appearance="outline">
          <mat-label>Hinweistext</mat-label>
          <textarea
            matInput
            formControlName="description"
            placeholder="Hinweistext"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-button
      mat-dialog-close
      type="button"
      [disabled]="loadingInProcess"
      [class.button-spinner]="loadingInProcess"
    >
      Abbrechen
    </button>
    <button
      mat-button
      type="submit"
      [disabled]="loadingInProcess"
      [class.button-spinner]="loadingInProcess"
    >
      Speichern
    </button>
  </mat-dialog-actions>
</form>
