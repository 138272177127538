import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectUserGroupsAndUsersModalComponent } from 'src/app/organization/positioning/components/select-user-groups-and-users-modal/select-user-groups-and-users-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MultipleUserGroupSelectModule } from 'src/app/_shared/components/form-controls/multiple-user-group-select/multiple-user-group-select.module';
import { MultipleUserSelectModule } from 'src/app/_shared/components/form-controls/multiple-user-select/multiple-user-select.module';

@NgModule({
  declarations: [SelectUserGroupsAndUsersModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    FlexModule,
    MatButtonModule,
    MultipleUserGroupSelectModule,
    MultipleUserSelectModule,
  ],
})
export class SelectUserGroupsAndUsersModalModule {}
