import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SensorStateIndicatorConfigurationListComponent } from './sensor-state-indicator-configuration-list.component';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { FilterableSelectModule } from 'src/app/_shared/components/filterable-select/filterable-select.module';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [SensorStateIndicatorConfigurationListComponent],
  imports: [
    CommonModule,
    FlexModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatTableModule,
    MatMenuModule,
    MatChipsModule,
    MatSelectModule,
    FormsModule,
    FilterableSelectModule,
    MatInputModule,
  ],
})
export class SensorStateIndicatorConfigurationListModule {}
