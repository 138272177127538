<form [formGroup]="form" (ngSubmit)="saveNotificationConfig()" class="content">
  <div fxLayout="column" class="app-area">
    <!-- HEADER ROW -->
    <div fxLayout="row">
      <div fxFlex fxFlexAlign="start start">
        <h1>Alarmkonfiguration {{ edit ? "bearbeiten" : "hinzufügen" }}</h1>
      </div>
      <div fxFlex style="text-align: right">
        <button
          mat-button
          fxFlexAlign="end end"
          [disabled]="buttonSaveLoading || !enabled"
        >
          <mat-icon>save</mat-icon>
          Speichern
        </button>
      </div>
    </div>

    <div fxLayout="row" style="height: 100%">
      <mat-card fxFlex="450px" class="square-card mat-elevation-z0">
        <div fxLayout="row">
          <div fxFlex>
            <h4 class="square-card-title">Alarmkonfiguration</h4>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="12px">
          <div fxFlex="100">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Titel</mat-label>
              <input
                matInput
                placeholder="Titel"
                type="text"
                formControlName="title"
              />
              <mat-error *ngIf="form.get('title').hasError('maxlength')">
                Maximal 255 Zeichen!
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="100">
            <app-filterable-select
              formControlName="sensorTypeConfig"
              [objects]="sensorTypeConfigs"
              label="Sensortyp"
              [objToLabel]="sensorTypeToLabel"
              appearance="outline"
            ></app-filterable-select>
          </div>
          <div fxFlex="100">Wenn Sensorwert</div>
          <div fxFlex="calc(50% - 12px)">
            <mat-form-field appearance="outline">
              <mat-label>Sensorwert</mat-label>
              <mat-select
                formControlName="sensorPropertyConfig"
                [disabled]="!form.controls.sensorTypeConfig.value"
              >
                <mat-option
                  *ngFor="
                    let sensorPropertyConfig of form.controls.sensorTypeConfig
                      .value?.sensorPropertyConfigs
                  "
                  [value]="sensorPropertyConfig"
                  >{{ sensorPropertyConfig.displayName }} ({{
                    sensorPropertyConfig.propertyName
                  }})
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="calc(25% - 12px)">
            <mat-form-field appearance="outline">
              <mat-label>Operator</mat-label>
              <mat-select
                formControlName="operator"
                [disabled]="
                  !form.controls.sensorTypeConfig.value ||
                  !form.controls.sensorPropertyConfig.value
                "
              >
                <mat-option
                  *ngFor="let type of filterOperators()"
                  [value]="type"
                  >{{ getOperatorLabel(type) }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="calc(25% - 12px)">
            <mat-form-field
              appearance="outline"
              style="width: 100%"
              class="value-form-component"
              *ngIf="showValueTextField()"
            >
              <mat-label>Wert</mat-label>
              <input
                matInput
                placeholder="0"
                [attr.type]="determineValueTextfieldType()"
                formControlName="value"
                [readonly]="!form.controls.sensorTypeConfig.value"
              />
            </mat-form-field>
            <mat-form-field appearance="outline" *ngIf="!showValueTextField()">
              <mat-label>Wert</mat-label>
              <mat-select
                formControlName="value"
                [disabled]="
                  !form.controls.sensorTypeConfig.value ||
                  !form.controls.sensorPropertyConfig.value
                "
              >
                <mat-option
                  *ngFor="let value of determinePossibleValuesForOperator()"
                  [value]="value"
                  >{{ value }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div class="hint-wrapper">
            <mat-hint>{{ getHint() }} </mat-hint>
          </div>
          <div fxFlex="100" style="padding-top: 24px">Dann erzeuge</div>
          <div fxFlex="100">
            <mat-form-field appearance="outline">
              <mat-label>Wiederholung</mat-label>
              <mat-select formControlName="notificationRepetitionType">
                <mat-option
                  *ngFor="let repetitionType of notificationRepetitionTypes"
                  [value]="repetitionType"
                  >{{ getRepetitionTypeLabel(repetitionType) }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="100" class="last-flex-element">
            <mat-form-field appearance="outline">
              <mat-label>Benachrichtigungstyp</mat-label>
              <mat-select formControlName="type">
                <mat-option
                  *ngFor="let type of alarmConfigType.entries()"
                  [value]="type"
                  >{{ translateNotificationType(type) }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="100" class="hint-wrapper">
            <mat-hint
              *ngIf="
                form.value.type === notificationConfigurationTypeBase.ERROR
              "
              >Hinweis: Für Alarme werden E-Mails und Push-Benachrichtigungen
              versendet
            </mat-hint>
            <mat-hint
              *ngIf="
                form.value.type === notificationConfigurationTypeBase.WARNING
              "
              >Hinweis: Für Warnungen werden keine E-Mails und
              Push-Benachrichtigungen versendet
            </mat-hint>
          </div>
        </div>
        <div fxLayout="row" style="margin-top: 24px" fxLayoutGap="12px">
          <div fxFlex>
            <h4 class="square-card-title">Icon Konfiguration</h4>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="12px">
          <div fxFlex="calc(70 - 12px)">
            <mat-form-field appearance="outline" class="icon-select">
              <mat-label>Icon</mat-label>
              <app-brightness-icon
                *ngIf="form.value.iconIdentifier === 'brightness'"
                size="32"
                class="option-icon"
              ></app-brightness-icon>
              <app-fallback-icon
                *ngIf="form.value.iconIdentifier === 'fallback'"
                size="32"
                class="option-icon"
              ></app-fallback-icon>
              <app-fire-alarm-icon
                *ngIf="form.value.iconIdentifier === 'fireAlarm'"
                size="32"
                class="option-icon"
              ></app-fire-alarm-icon>
              <app-humidity-icon
                *ngIf="form.value.iconIdentifier === 'humidity'"
                size="32"
                class="option-icon"
              ></app-humidity-icon>
              <app-motion-icon
                *ngIf="form.value.iconIdentifier === 'motion'"
                size="32"
                class="option-icon"
              ></app-motion-icon>
              <app-opened-icon
                *ngIf="form.value.iconIdentifier === 'opened'"
                size="32"
                class="option-icon"
              ></app-opened-icon>
              <app-parking-icon
                *ngIf="form.value.iconIdentifier === 'parking'"
                size="32"
                class="option-icon"
              ></app-parking-icon>
              <app-temperature-icon
                *ngIf="form.value.iconIdentifier === 'temperature'"
                size="32"
                class="option-icon"
              ></app-temperature-icon>
              <app-water-leakage-icon
                *ngIf="form.value.iconIdentifier === 'waterLeakage'"
                size="32"
                class="option-icon"
              ></app-water-leakage-icon>
              <app-co2-icon
                *ngIf="form.value.iconIdentifier === 'co2'"
                size="32"
                class="option-icon"
              ></app-co2-icon>
              <mat-select formControlName="iconIdentifier">
                <mat-option value="brightness" class="icon-option">
                  <app-brightness-icon size="32"></app-brightness-icon>
                  <span class="option-text">Helligkeit</span>
                </mat-option>
                <mat-option value="fallback" class="icon-option">
                  <app-fallback-icon size="32"></app-fallback-icon>
                  <span class="option-text">Fallback</span>
                </mat-option>
                <mat-option value="fireAlarm" class="icon-option">
                  <app-fire-alarm-icon size="32"></app-fire-alarm-icon>
                  <span class="option-text">Feuer</span>
                </mat-option>
                <mat-option value="humidity" class="icon-option">
                  <app-humidity-icon size="32"></app-humidity-icon>
                  <span class="option-text">Feuchtigkeit</span>
                </mat-option>
                <mat-option value="motion" class="icon-option">
                  <app-motion-icon size="32"></app-motion-icon>
                  <span class="option-text">Bewegung</span>
                </mat-option>
                <mat-option value="opened" class="icon-option">
                  <app-opened-icon size="32"></app-opened-icon>
                  <span class="option-text">Öffnung</span>
                </mat-option>
                <mat-option value="parking" class="icon-option">
                  <app-parking-icon size="32"></app-parking-icon>
                  <span class="option-text">Parken</span>
                </mat-option>
                <mat-option value="temperature" class="icon-option">
                  <app-temperature-icon size="32"></app-temperature-icon>
                  <span class="option-text">Temperatur</span>
                </mat-option>
                <mat-option value="waterLeakage" class="icon-option">
                  <app-water-leakage-icon size="32"></app-water-leakage-icon>
                  <span class="option-text">Wasser</span>
                </mat-option>
                <mat-option value="co2" class="icon-option">
                  <app-co2-icon size="32"></app-co2-icon>
                  <span class="option-text">Co2</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="calc(30 - 12px)">
            <mat-form-field appearance="outline">
              <mat-label>Hintergrundfarbe</mat-label>
              <div
                style="width: 32px; height: 32px; border-radius: 6px"
                class="option-icon"
                [style.background]="form.controls.iconBackgroundColor.value"
                *ngIf="form.controls.iconBackgroundColor.value"
              ></div>
              <mat-select formControlName="iconBackgroundColor">
                <mat-option [value]="color" *ngFor="let color of colorOptions">
                  <div
                    [style.background]="color"
                    style="width: 32px; height: 32px; border-radius: 6px"
                  ></div>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="100" style="margin-top: 48px; text-align: center">
            <app-brightness-icon
              *ngIf="form.value.iconIdentifier === 'brightness'"
              [backgroundColor]="form.value.iconBackgroundColor"
            ></app-brightness-icon>
            <app-fallback-icon
              *ngIf="form.value.iconIdentifier === 'fallback'"
              [backgroundColor]="form.value.iconBackgroundColor"
            ></app-fallback-icon>
            <app-fire-alarm-icon
              *ngIf="form.value.iconIdentifier === 'fireAlarm'"
              [backgroundColor]="form.value.iconBackgroundColor"
            ></app-fire-alarm-icon>
            <app-humidity-icon
              *ngIf="form.value.iconIdentifier === 'humidity'"
              [backgroundColor]="form.value.iconBackgroundColor"
            ></app-humidity-icon>
            <app-motion-icon
              *ngIf="form.value.iconIdentifier === 'motion'"
              [backgroundColor]="form.value.iconBackgroundColor"
            ></app-motion-icon>
            <app-opened-icon
              *ngIf="form.value.iconIdentifier === 'opened'"
              [backgroundColor]="form.value.iconBackgroundColor"
            ></app-opened-icon>
            <app-parking-icon
              *ngIf="form.value.iconIdentifier === 'parking'"
              [backgroundColor]="form.value.iconBackgroundColor"
            ></app-parking-icon>
            <app-temperature-icon
              *ngIf="form.value.iconIdentifier === 'temperature'"
              [backgroundColor]="form.value.iconBackgroundColor"
            ></app-temperature-icon>
            <app-water-leakage-icon
              *ngIf="form.value.iconIdentifier === 'waterLeakage'"
              [backgroundColor]="form.value.iconBackgroundColor"
            ></app-water-leakage-icon>
            <app-co2-icon
              *ngIf="form.value.iconIdentifier === 'co2'"
              [backgroundColor]="form.value.iconBackgroundColor"
            ></app-co2-icon>
          </div>
        </div>
      </mat-card>
      <mat-card
        fxFlex="450px"
        class="square-card mat-elevation-z0"
        style="overflow: scroll"
      >
        <div fxLayout="row">
          <div fxFlex>
            <h4 class="square-card-title">Zugewiesene Sensoren</h4>
          </div>
          <div fxFlex fxLayoutAlign="end">
            <button
              mat-icon-button
              (click)="addSensorClicked()"
              type="button"
              [disabled]="!enabled"
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
        <div fxLayout="row wrap">
          <ng-container *ngFor="let sensor of selectedSensors(); let i = index">
            <div fxFlex="100" fxLayout="row">
              <div fxLayout="column" fxFlex="grow">
                <div class="sensor-title truncate-text">
                  {{ sensor.displayName }}
                  <small *ngIf="sensor.group"
                    >({{ sensor.group.displayName }} )</small
                  >
                </div>
              </div>
              <div
                fxFlex="40px"
                fxFlexAlign="center"
                style="text-align: right"
                *ngIf="hasSensorCorrectSensorType(sensor)"
              >
                <mat-icon
                  class="text-danger"
                  style="margin: 8px"
                  matTooltip="Dieser Sensor passt nicht zum angegebenen Sensortyp!"
                  >error_outline
                </mat-icon>
              </div>
              <div fxFlex="40px" fxFlexAlign="center" style="text-align: right">
                <button
                  mat-icon-button
                  class="options-button"
                  (click)="removeSensor(sensor)"
                  type="button"
                  [disabled]="!enabled"
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </mat-card>
    </div>
  </div>
</form>
