<div fxLayout="column" fxLayoutAlign="center center" class="full-page">
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    class="password-reset-content"
  >
    <h1 class="login-title">Passwort vergessen</h1>

    <form (ngSubmit)="onSubmit()" #f="ngForm">
      <div
        class="change-password-card"
        fxLayout="column"
        fxLayoutAlign="center stretch"
      >
        <div *ngIf="requestPasswordError" class="text-danger error-card">
          {{ requestPasswordError }}
        </div>
        <div *ngIf="requestPasswordSuccess" class="text-success">
          {{ requestPasswordSuccess }}
        </div>
        <mat-form-field appearance="outline">
          <mat-label>Nutzername</mat-label>
          <input
            matInput
            placeholder="Nutzername"
            type="email"
            name="username"
            ngModel
          />
        </mat-form-field>
        <div fxLayout="column" fxLayoutAlign="center stretch">
          <button
            mat-flat-button
            color="primary"
            type="submit"
            class="upper-case"
          >
            OK
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
