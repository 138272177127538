import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sensor } from 'src/app/_shared/models/sensor';

@Component({
  selector: 'app-sensor-select-modal',
  templateUrl: './sensor-select-modal.component.html',
  styleUrls: ['./sensor-select-modal.component.scss'],
})
export class SensorSelectModalComponent implements OnInit {
  sensors: Sensor[];
  selectedSensor: Sensor;

  constructor(@Inject(MAT_DIALOG_DATA) private data: { sensors: Sensor[] }) {}

  ngOnInit(): void {
    this.sensors = this.data.sensors;
  }
}
