import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DisposeBag } from '@ronas-it/dispose-bag/dist/src';

@Component({
  selector: 'app-sensor-qr-code-list',
  templateUrl: './sensor-qr-code-list.component.html',
  styleUrls: ['./sensor-qr-code-list.component.scss'],
})
export class SensorQrCodeListComponent implements OnInit, OnDestroy {
  disposeBag = new DisposeBag();
  euis: string[] = [];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.disposeBag.add(
      this.route.queryParams.subscribe((params) => {
        if (params.euis) {
          this.euis = params.euis.split(',');
        }
      })
    );
  }

  ngOnDestroy() {
    this.disposeBag.unsubscribe();
  }

  onPrintClicked() {
    window.print();
  }
}
