<form [formGroup]="form" (ngSubmit)="save()">
  <h2 mat-dialog-title>{{ getTitle() }}</h2>
  <mat-dialog-content>
    <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="24px">
      <div fxFlex="100">
        <app-username-confirmation-hint
          [user]="user"
        ></app-username-confirmation-hint>
      </div>
      <div fxFlex="100">
        <h4>Nutzerdaten</h4>
      </div>
      <div fxFlex="calc(50% - 24px)">
        <mat-form-field appearance="outline">
          <mat-label>Vorname</mat-label>
          <input
            matInput
            placeholder="Vorname"
            type="text"
            formControlName="firstName"
          />
          <mat-error *ngIf="form.get('firstName').hasError('maxlength')">
            Maximal 255 Zeichen!
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="calc(50% - 24px)">
        <mat-form-field appearance="outline">
          <mat-label>Nachname</mat-label>
          <input
            matInput
            placeholder="Nachname"
            type="text"
            formControlName="lastName"
          />
          <mat-error *ngIf="form.get('lastName').hasError('maxlength')">
            Maximal 255 Zeichen!
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="100">
        <mat-form-field appearance="outline">
          <mat-label>E-Mail</mat-label>
          <input
            matInput
            placeholder="E-Mail"
            type="text"
            formControlName="username"
          />
          <mat-error *ngIf="form.get('username').hasError('maxlength')">
            Maximal 255 Zeichen!
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="100" class="last-flex-element">
        <mat-form-field appearance="outline">
          <mat-label>Rolle</mat-label>
          <mat-select formControlName="role">
            <mat-option
              *ngFor="let roleOption of roleOptions"
              [value]="roleOption"
              >{{ getTextForRoleEnum(roleOption) }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Abbrechen</button>
    <button mat-button type="submit">Speichern</button>
  </mat-dialog-actions>
</form>
