import { SensorTypeOutputDto } from 'src/app/_shared/dtos/sensor-type-output.dto';
import { SensorPropertyConfig } from 'src/app/_shared/models/sensor-property-config';

export class SensorTypeConfig {
  id: number;
  displayName: string;
  niotaDeviceTypeId: number;
  sensorPropertyConfigs: SensorPropertyConfig[];

  static fromSensorTypeOutputDto(dto: SensorTypeOutputDto): SensorTypeConfig {
    const result = new SensorTypeConfig();

    result.id = dto.id;
    result.displayName = dto.displayName;
    result.niotaDeviceTypeId = dto.niotaDeviceTypeId;
    result.sensorPropertyConfigs = dto.sensorPropertyConfigs
      ? dto.sensorPropertyConfigs.map((item) =>
          SensorPropertyConfig.fromSensorPropertyOutputDto(item)
        )
      : [];

    return result;
  }
}
