import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fallback-icon',
  template: `
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      [style.height]="size + 'px'"
      [style.width]="size + 'px'"
    >
      <rect
        width="28"
        height="28"
        rx="6"
        [attr.fill]="backgroundColor ? backgroundColor : '#B3B3B3'"
      />
      <path
        d="M19.5 10.3333C19.5 8.87456 18.9205 7.47561 17.8891 6.44416C16.8576 5.41271 15.4587 4.83325 14 4.83325C12.5413 4.83325 11.1424 5.41271 10.1109 6.44416C9.07946 7.47561 8.5 8.87456 8.5 10.3333C8.5 16.7499 5.75 18.5833 5.75 18.5833H22.25C22.25 18.5833 19.5 16.7499 19.5 10.3333Z"
        [attr.stroke]="'white'"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.5857 22.25C15.4246 22.5278 15.1933 22.7584 14.9149 22.9187C14.6366 23.079 14.3211 23.1634 13.9999 23.1634C13.6787 23.1634 13.3632 23.079 13.0849 22.9187C12.8065 22.7584 12.5752 22.5278 12.4141 22.25"
        [attr.stroke]="'white'"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [],
})
export class FallbackIconComponent implements OnInit {
  @Input() backgroundColor: string;
  @Input() size = 128;

  constructor() {}

  ngOnInit(): void {}
}
