import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoTextListComponent } from './info-text-list.component';
import { FlexModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { InfoTextEditModalModule } from 'src/app/organization/notification-configuration/components/info-text-edit-modal/info-text-edit-modal.module';

@NgModule({
  declarations: [InfoTextListComponent],
  imports: [
    CommonModule,
    FlexModule,
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    InfoTextEditModalModule,
  ],
})
export class InfoTextListModule {}
