<div style="margin-top: 12px; margin-bottom: 12px" fxLayout="row wrap">
  <div fxFlex="50">
    {{ measurementIntervalData.displayName }}
  </div>
  <div fxFlex="50" style="text-align: right; color: #a8de0d">
    {{ measurementIntervalData.latestMeasurement.value.value }}
    {{ measurementIntervalData.latestMeasurement.value.unit.symbol }}
  </div>
  <div class="light-text">
    Zuletzt:
    {{
      measurementIntervalData.latestMeasurement.dateTime | date: "dd.MM HH:mm"
    }}
  </div>
</div>
<div style="display: block">
  <canvas
    baseChart
    style="height: 100px"
    [datasets]="lineChartData"
    [options]="lineChartOptions"
    [colors]="lineChartColors"
    [legend]="lineChartLegend"
    [chartType]="lineChartType"
    [plugins]="lineChartPlugins"
  >
  </canvas>
</div>
