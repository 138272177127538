<div fxLayout="column" class="app-area">
  <!-- HEADER ROW -->
  <div fxLayout="row">
    <div fxFlex fxFlexAlign="start start">
      <h1>Nutzerverwaltung</h1>
    </div>
  </div>

  <!-- Content -->
  <mat-card
    fxLayout="row wrap"
    fxLayoutAlign="start start"
    class="square-card mat-elevation-z0"
    style="height: 100%"
  >
    <div fxFlex="100" fxLayout="row" dir="rtl">
      <button
        mat-icon-button
        fxFlexAlign="end end"
        (click)="onAddClicked()"
        [disabled]="!enabled"
      >
        <mat-icon>add</mat-icon>
      </button>
      <button
        mat-icon-button
        fxFlexAlign="end end"
        (click)="onClearFilterClicked()"
        [disabled]="!isFilter()"
      >
        <mat-icon>settings_backup_restore</mat-icon>
      </button>
    </div>
    <div class="table-wrapper">
      <table mat-table [dataSource]="matTableDataSource" style="width: 100%">
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter-input">
              <mat-label>Vorname</mat-label>
              <input
                matInput
                type="text"
                [(ngModel)]="userFilterDto.firstName"
                (ngModelChange)="filterChangedSubject.next()"
                name="firstName"
              />
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
        </ng-container>

        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter-input">
              <mat-label>Nachname</mat-label>
              <input
                matInput
                type="text"
                [(ngModel)]="userFilterDto.lastName"
                (ngModelChange)="filterChangedSubject.next()"
                name="lastName"
              />
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
        </ng-container>

        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter-input">
              <mat-label>E-Mail</mat-label>
              <input
                matInput
                type="email"
                [(ngModel)]="userFilterDto.username"
                (ngModelChange)="filterChangedSubject.next()"
                name="username"
              />
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.username }}
          </td>
        </ng-container>

        <ng-container matColumnDef="chips">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field style="width: 100%">
              <mat-label>Rolle</mat-label>
              <mat-select
                [(ngModel)]="userFilterDto.role"
                (ngModelChange)="filterChangedSubject.next()"
              >
                <mat-option
                  *ngFor="let roleOption of roleOptions"
                  [value]="roleOption"
                  >{{ getTextForRoleEnum(roleOption) }}</mat-option
                >
              </mat-select>
              <button
                *ngIf="userFilterDto.role"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="
                  userFilterDto.role = undefined;
                  this.filterChangedSubject.next();
                  $event.stopPropagation()
                "
                style="width: 30px; height: 30px"
              >
                <mat-icon inline color="">close</mat-icon>
              </button>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-chip-list class="float-right">
              <mat-chip class="grey-chip" disabled *ngIf="element.role">{{
                getRoleText(element.role)
              }}</mat-chip>
              <mat-chip
                class="green-chip"
                disabled
                *ngIf="isCurrentUser(element)"
                >Dieser Nutzer
              </mat-chip>
              <mat-chip
                class="blue-chip"
                disabled
                *ngIf="!element.emailConfirmed"
                >Eingeladen
              </mat-chip>
              <mat-chip
                class="orange-chip"
                disabled
                *ngIf="element.migrationUsername"
                matTooltip="Verifizierung der E-Mail Änderung ausstehend"
                matTooltipPosition="above"
                matTooltipClass="tooltip-orange"
              >
                <mat-icon aria-hidden="false" aria-label="verify"
                  >error_outline
                </mat-icon>
                <span>E-Mail verifizieren</span>
              </mat-chip>
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button
              *ngIf="!isCurrentUser(element)"
              mat-icon-button
              style="float: right"
              [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                (click)="updateUser(element)"
                [disabled]="!enabled"
              >
                Bearbeiten
              </button>
              <button
                mat-menu-item
                (click)="resendInvitation(element)"
                [disabled]="!enabled"
                *ngIf="!element.emailConfirmed"
              >
                Einladung erneut versenden
              </button>
              <button
                mat-menu-item
                (click)="deleteUser(element)"
                [disabled]="!enabled"
              >
                Löschen
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let myDataArray; columns: displayedColumns"
          (click)="updateUser(myDataArray)"
        ></tr>
      </table>
    </div>
  </mat-card>
</div>
