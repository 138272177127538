import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { SensorGroupNode } from 'src/app/_shared/models/sensor-group-node';
import { SensorGroupNodeService } from 'src/app/_shared/services/sensor-group-node.service';

@Injectable({
  providedIn: 'root', // https://github.com/angular/angular-cli/issues/10170#issuecomment-380673276
})
export class OrganizationDashboardResolver
  implements Resolve<void | SensorGroupNode> {
  constructor(private sensorGroupNodeService: SensorGroupNodeService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<void | SensorGroupNode> {
    return this.sensorGroupNodeService.getTree();
  }
}
