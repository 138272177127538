<mat-card
  class="orange mat-elevation-z0"
  *ngIf="user && user.migrationUsername"
>
  <div class="icon">
    <mat-icon aria-hidden="false" aria-label="verify" class="vertical-bottom"
      >error_outline</mat-icon
    >
  </div>
  <div fxFlex>
    <p>Die E-Mail Adresse des Nutzers würde geändert auf:</p>
    <p class="bold">{{ user.migrationUsername }}</p>
    <p>
      Eine Bestätigungsmail wurde an die o.g. E-Mail Adresse versandt und muss
      noch bestätigt werden.
    </p>
  </div>
</mat-card>
