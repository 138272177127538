<form [formGroup]="form" (ngSubmit)="save()">
  <div fxLayout="column" class="app-area">
    <!-- HEADER ROW -->
    <div fxLayout="row">
      <div fxFlex fxFlexAlign="start start">
        <h1>Sensortypeinstellungen</h1>
      </div>
      <div fxFlex style="text-align: right">
        <button mat-button fxFlexAlign="end end" type="submit">
          <mat-icon>save</mat-icon>
          Speichern
        </button>
      </div>
    </div>

    <!-- Content -->
    <mat-card fxLayout="column" class="square-card mat-elevation-z0">
      <div
        fxLayout="row wrap"
        fxLayoutAlign="start start"
        fxLayoutGap="24px grid"
        style="width: 100%"
      >
        <div fxFlex="100">
          <h4>Allgemeines</h4>
        </div>
        <div fxFlex="50">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input
              matInput
              placeholder="Name"
              type="text"
              formControlName="name"
            />
            <mat-error *ngIf="form.get('name').hasError('maxlength')">
              Maximal 255 Zeichen!
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="50">
          <mat-form-field appearance="outline">
            <mat-label>Sensortyp-ID</mat-label>
            <input
              matInput
              placeholder="Sensortyp-ID"
              type="number"
              formControlName="niotaDeviceTypeId"
            />
          </mat-form-field>
        </div>
      </div>
      <div fxFlex="100">
        <h4>Eigenschaften</h4>
      </div>
      <div fxFlex="100">
        <table mat-table [dataSource]="dataSource" style="width: 100%">
          <ng-container matColumnDef="warning">
            <th mat-header-cell *matHeaderCellDef style="width: 40px"></th>
            <td
              mat-cell
              *matCellDef="let element"
              [formGroup]="element"
              style="width: 40px"
            >
              <mat-icon
                *ngIf="element.value.warning"
                class="text-warning"
                [matTooltip]="element.value.warningMessage"
                >warning_amber
              </mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="displayName">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-form-field appearance="standard">
                <input
                  matInput
                  placeholder="Name"
                  type="text"
                  name="displayName"
                  formControlName="displayName"
                />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="technicalName">
            <th mat-header-cell *matHeaderCellDef>Techn. Name</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-select
                formControlName="technicalName"
                style="min-width: 200px"
                placeholder="techn. Name"
                (ngModelChange)="validateRow(element)"
              >
                <mat-option
                  *ngFor="let technicalName of technicalNameOptions()"
                  [value]="technicalName"
                  >{{ technicalNameToLabel(technicalName) }}</mat-option
                >
              </mat-select>
            </td>
          </ng-container>

          <ng-container matColumnDef="propertyName">
            <th mat-header-cell *matHeaderCellDef>Eigenschaft</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-form-field appearance="standard">
                <input
                  matInput
                  placeholder="Eigenschaft"
                  type="text"
                  name="propertyName"
                  formControlName="propertyName"
                />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="propertyType">
            <th mat-header-cell *matHeaderCellDef>Eigenschaftstyp</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-select
                formControlName="propertyType"
                style="min-width: 200px"
                placeholder="Eigenschaftstyp"
                (ngModelChange)="validateRow(element)"
              >
                <mat-option
                  *ngFor="let propertyType of propertyTypeOptions()"
                  [value]="propertyType"
                  >{{ propertyTypeToLabel(propertyType) }}</mat-option
                >
              </mat-select>
            </td>
          </ng-container>

          <ng-container matColumnDef="unit">
            <th mat-header-cell *matHeaderCellDef>Einheit</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-select
                formControlName="unit"
                style="min-width: 200px"
                placeholder="Einheit"
                (ngModelChange)="validateRow(element)"
              >
                <mat-option *ngFor="let unit of unitOptions()" [value]="unit">{{
                  unitToLabel(unit)
                }}</mat-option>
              </mat-select>
            </td>
          </ng-container>

          <ng-container matColumnDef="symbol">
            <th mat-header-cell *matHeaderCellDef>Symbol</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-select
                formControlName="symbol"
                style="min-width: 200px"
                placeholder="Symbol"
              >
                <mat-option
                  *ngFor="let symbol of unitSymbolOptions()"
                  [value]="symbol"
                  >{{ unitSymbolToLabel(symbol) }}</mat-option
                >
              </mat-select>
            </td>
          </ng-container>

          <ng-container matColumnDef="optional">
            <th mat-header-cell *matHeaderCellDef>Optional</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-checkbox
                class="checkbox-center"
                color="primary"
                formControlName="optional"
              ></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="onDisplayName">
            <th mat-header-cell *matHeaderCellDef>
              <ng-container *ngIf="anyBooleanRow()"
                >Titel für "true"</ng-container
              >
            </th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-form-field
                appearance="standard"
                *ngIf="isBooleanRow(element)"
              >
                <input
                  matInput
                  placeholder="Titel"
                  type="text"
                  formControlName="onDisplayName"
                />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="offDisplayName">
            <th mat-header-cell *matHeaderCellDef>
              <ng-container *ngIf="anyBooleanRow()"
                >Titel für "false"</ng-container
              >
            </th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-form-field
                appearance="standard"
                *ngIf="isBooleanRow(element)"
              >
                <input
                  matInput
                  placeholder="Titel"
                  type="text"
                  formControlName="offDisplayName"
                />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="removeButton">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element; let i = index">
              <button
                mat-icon-button
                color="warn"
                type="button"
                (click)="removeSensorPropertyConfig(i)"
              >
                <mat-icon>delete_outline</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
        </table>
      </div>
      <div fxFlex="100" fxLayoutAlign="center center" fxLayout="column">
        <button
          mat-raised-button
          color="primary"
          style="margin: auto"
          type="button"
          (click)="addPropertyConfigRow()"
        >
          <mat-icon>add</mat-icon>
          Eigenschaft hinzufügen
        </button>
      </div>
    </mat-card>
  </div>
</form>
