import { Injectable } from '@angular/core';
import { NotificationMutePostDto } from 'src/app/organization/notification-mute/dto/notification-mute.post.dto';
import { ApiService } from 'src/app/_core/services/api/api.service';
import { SnackBarService } from 'src/app/_shared/services/snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationMuteService {
  constructor(
    private apiService: ApiService,
    private snackBarService: SnackBarService
  ) {}

  create(dto: NotificationMutePostDto) {
    return this.apiService
      .post('/sensor-notifications/mute', dto)
      .then((res) => {
        this.snackBarService.showCustomMessage(
          'Stummschaltung erfolgreich gespeichert.'
        );
        return res;
      });
  }
}
