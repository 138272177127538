import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LegalSettingsModule } from './components/legal-settings/legal-settings.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, LegalSettingsModule],
  exports: [LegalSettingsModule],
})
export class PublicUtilityLegalSettingsModule {}
