import { Component, OnInit } from '@angular/core';
import { OrganizationDto } from 'src/app/public-utility/organization/dtos/organization.dto';
import { ActivatedRoute } from '@angular/router';
import { OrganizationService } from 'src/app/_shared/services/organization.service';
import { Roles } from 'src/app/_core/models/roles';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';

@Component({
  selector: 'app-organization-mac-list',
  templateUrl: './organization-mac-list.component.html',
  styleUrls: ['./organization-mac-list.component.scss'],
})
export class OrganizationMacListComponent implements OnInit {
  enabled = false;
  organization: OrganizationDto;

  constructor(
    private activatedRoute: ActivatedRoute,
    private organizationService: OrganizationService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.organization = this.activatedRoute.snapshot.data.organization;

    const currentUserSession = this.authenticationService.getCurrentUserSession();
    // @ts-ignore
    this.enabled = [Roles.CITYLINK_EDITOR, Roles.CITYLINK_OWNER].includes(
      currentUserSession?.role
    );
  }
}
