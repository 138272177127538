<form
  [formGroup]="profileForm"
  *ngIf="profileForm"
  (ngSubmit)="save()"
  #f="ngForm"
>
  <div fxLayout="column" class="app-area">
    <!-- HEADER ROW -->
    <div fxLayout="row">
      <div fxFlex fxFlexAlign="start start">
        <h1>Benutzereinstellungen</h1>
      </div>
      <div fxFlex style="text-align: right">
        <button mat-button fxFlexAlign="end end" type="submit">
          <mat-icon>save</mat-icon>
          Speichern
        </button>
      </div>
    </div>

    <!-- Content -->
    <mat-card
      fxLayout="row"
      class="square-card mat-elevation-z0"
      style="height: 100%"
    >
      <div
        fxLayout="row wrap"
        fxLayoutAlign="start start"
        fxLayoutGap="24px"
        id="card-content"
      >
        <div fxFlex="100"><h2>Benutzereinstellungen</h2></div>

        <!-- Username Hint -->
        <div fxFlex="100">
          <app-username-confirmation-hint
            [user]="user"
          ></app-username-confirmation-hint>
        </div>

        <!-- User information Content -->
        <div fxFlex="100">
          <h4>Benutzerinformationen</h4>
        </div>
        <div fxFlex="calc(33.3% - 24px)">
          <mat-form-field appearance="outline">
            <mat-label>Benutzername</mat-label>
            <input
              matInput
              placeholder="Benutzername"
              type="text"
              formControlName="username"
            />
            <mat-error
              *ngIf="profileForm.get('username').hasError('maxlength')"
            >
              Maximal 255 Zeichen!
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="calc(33.3% - 24px)">
          <mat-form-field appearance="outline">
            <mat-label>Vorname</mat-label>
            <input
              matInput
              placeholder="Vorname"
              type="text"
              formControlName="firstName"
            />
            <mat-error
              *ngIf="profileForm.get('firstName').hasError('maxlength')"
            >
              Maximal 255 Zeichen!
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="calc(33.3% - 24px)">
          <mat-form-field appearance="outline">
            <mat-label>Nachname</mat-label>
            <input
              matInput
              placeholder="Nachname"
              type="text"
              formControlName="lastName"
            />
            <mat-error
              *ngIf="profileForm.get('lastName').hasError('maxlength')"
            >
              Maximal 255 Zeichen!
            </mat-error>
          </mat-form-field>
        </div>

        <!-- User information Content -->
        <ng-container *ngIf="isOrganizationUser()">
          <div fxFlex="100">
            <h4>Benachrichtungseinstellungen</h4>
          </div>
          <div fxFlex="calc(50% - 24px)">
            <mat-form-field appearance="outline">
              <mat-label>E-Mail</mat-label>
              <mat-select formControlName="sendEmailNotifications">
                <mat-option [value]="true">aktiviert</mat-option>
                <mat-option [value]="false">deaktiviert</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="calc(50% - 24px)">
            <mat-form-field appearance="outline">
              <mat-label>Push</mat-label>
              <mat-select formControlName="sendPushNotifications">
                <mat-option [value]="true">aktiviert</mat-option>
                <mat-option [value]="false">deaktiviert</mat-option>
              </mat-select>
            </mat-form-field>
            <span
              >Hinweis: Mit dieser Einstellung wird festgelegt, ob
              Push-Benachrichtigungen erzeugt und versendet werden. Damit
              erzeugte Push-Benachrichtigungen auf dem Smartphone angezeigt
              werden, müssen Push-Benachrichtigungen für die Citylink-App aktiv
              sein.</span
            >
          </div>
        </ng-container>
        <!-- User information Content -->
        <div fxFlex="100">
          <h4>Passwort</h4>
        </div>
        <div fxFlex="calc(33.3% - 24px)" *ngIf="!isChangePassword">
          <button
            mat-button
            color="primary"
            type="button"
            class="upper-case"
            (click)="onChangePassword()"
          >
            Passwort ändern
          </button>
        </div>
        <ng-container *ngIf="isChangePassword">
          <div fxFlex="calc(33.3% - 24px)">
            <mat-form-field appearance="outline">
              <mat-label>Aktuelles Passwort</mat-label>
              <input
                matInput
                placeholder="Aktuelles Passwort"
                type="password"
                formControlName="currentPassword"
              />
              <mat-error *ngIf="profileForm.get('currentPassword').invalid"
                >Aktuelles Passwort ist zu kurz</mat-error
              >
            </mat-form-field>
          </div>
          <div fxFlex="calc(66.7% - 24px)"></div>
          <div fxFlex="calc(33.3% - 24px)">
            <mat-form-field appearance="outline">
              <mat-label>Neues Passwort</mat-label>
              <input
                matInput
                placeholder="Neues Passwort"
                type="password"
                formControlName="newPassword"
              />
              <mat-error
                *ngIf="profileForm.get('newPassword').hasError('minlength')"
                >Mindestens 8 Zeichen</mat-error
              >
              <mat-error
                *ngIf="profileForm.get('newPassword').hasError('strong')"
                >Mindestens ein Groß- und Kleinbuchstabe sowie eine
                Zahl.</mat-error
              >
            </mat-form-field>
          </div>
          <div fxFlex="calc(66.7% - 24px)"></div>
          <div fxFlex="calc(33.3% - 24px)">
            <mat-form-field appearance="outline">
              <mat-label>Neues Passwort wdh.</mat-label>
              <input
                matInput
                placeholder="Neues Passwort wdh."
                type="password"
                formControlName="confirmPassword"
              />
              <mat-error
                *ngIf="
                  profileForm.get('confirmPassword').hasError('notSame') ||
                  profileForm.get('confirmPassword').hasError('required')
                "
              >
                Das Passwort wdh. entspricht nicht dem neuem Passwort, oder das
                aktuelle Passwort ist fehlerhaft.
              </mat-error>
              <mat-error
                *ngIf="profileForm.get('confirmPassword').hasError('minlength')"
                >Mindestens 8 Zeichen</mat-error
              >
              <mat-error
                *ngIf="profileForm.get('confirmPassword').hasError('strong')"
                >Mindestens ein Groß- und Kleinbuchstabe sowie eine
                Zahl.</mat-error
              >
            </mat-form-field>
          </div>
          <div fxFlex="calc(66.7%)"></div>
        </ng-container>
      </div>
    </mat-card>
  </div>
</form>
