<div fxLayout="column" class="app-area">
  <!-- HEADER ROW -->
  <div fxLayout="row">
    <div fxFlex fxFlexAlign="start start">
      <h1>Organisationen verwalten</h1>
    </div>
  </div>

  <!-- Content -->
  <mat-card
    fxLayout="row wrap"
    fxLayoutAlign="start start"
    class="square-card mat-elevation-z0"
    style="height: 100%"
  >
    <div fxFlex="100" fxLayout="row" dir="rtl">
      <button
        mat-icon-button
        fxFlexAlign="end end"
        routerLink="create"
        [disabled]="!enabled"
      >
        <mat-icon>add</mat-icon>
      </button>
      <button
        mat-icon-button
        fxFlexAlign="end end"
        (click)="onClearFilterClicked()"
        [disabled]="!isFilter()"
      >
        <mat-icon>settings_backup_restore</mat-icon>
      </button>
    </div>
    <div class="table-wrapper">
      <table
        mat-table
        [dataSource]="organizationDataSource"
        style="width: 100%"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter-input">
              <mat-label>Name</mat-label>
              <input
                matInput
                type="text"
                [(ngModel)]="organizationFilter.name"
                (ngModelChange)="filterChangedSubject.next()"
                name="firstName"
              />
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter-input">
              <mat-label>Adresse</mat-label>
              <input
                matInput
                type="text"
                [(ngModel)]="organizationFilter.address"
                (ngModelChange)="filterChangedSubject.next()"
                name="address"
              />
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ addressString(element) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="owner">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter-input">
              <mat-label>Ansprechpartner</mat-label>
              <input
                matInput
                type="text"
                [(ngModel)]="organizationFilter.owner"
                (ngModelChange)="filterChangedSubject.next()"
                name="address"
              />
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ ownerString(element) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="invite">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field style="width: 100%" class="filter-select">
              <mat-label>Status</mat-label>
              <mat-select
                [(ngModel)]="organizationFilter.ownerEmailConfirmed"
                (ngModelChange)="filterChangedSubject.next()"
              >
                <mat-option [value]="true">Aktiv</mat-option>
                <mat-option [value]="false">Eingeladen</mat-option>
              </mat-select>
              <button
                *ngIf="
                  organizationFilter.ownerEmailConfirmed !== null &&
                  organizationFilter.ownerEmailConfirmed !== undefined
                "
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="
                  organizationFilter.ownerEmailConfirmed = undefined;
                  this.filterChangedSubject.next();
                  $event.stopPropagation()
                "
                style="width: 30px; height: 30px"
              >
                <mat-icon inline color="">close</mat-icon>
              </button>
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-chip-list>
              <mat-chip
                disabled
                class="blue-chip"
                *ngIf="!element.owner?.emailConfirmed"
                >Eingeladen
              </mat-chip>
              <mat-chip
                disabled
                class="green-chip"
                *ngIf="element.owner?.emailConfirmed"
                >Aktiv
              </mat-chip>
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container matColumnDef="sensorCount">
          <th mat-header-cell *matHeaderCellDef>Sensoren</th>
          <td mat-cell *matCellDef="let element">
            <mat-chip-list>
              <mat-chip class="green-chip" disabled>{{
                element.sensorCount
              }}</mat-chip>
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container matColumnDef="userCount">
          <th mat-header-cell *matHeaderCellDef>Nutzer</th>
          <td mat-cell *matCellDef="let element">
            <mat-chip-list>
              <mat-chip class="blue-chip" disabled>{{
                element.userCount
              }}</mat-chip>
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container matColumnDef="notificationConfigCount">
          <th mat-header-cell *matHeaderCellDef>Alarmkonfigurationen</th>
          <td mat-cell *matCellDef="let element">
            <mat-chip-list>
              <mat-chip class="violet-chip" disabled>{{
                element.notificationConfigCount
              }}</mat-chip>
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" style="text-align: right">
            <button
              mat-icon-button
              style="float: right"
              [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [routerLink]="'edit/' + element.id">
                Bearbeiten
              </button>
              <button mat-menu-item (click)="openOrganizationMacList(element)">
                Details
              </button>
              <button
                mat-menu-item
                (click)="resendInvitation(element)"
                [disabled]="!enabled"
              >
                Einladung erneut senden
              </button>
              <button
                mat-menu-item
                color="warn"
                (click)="delete(element)"
                [disabled]="!enabled"
              >
                Löschen
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let myDataArray; columns: displayedColumns"
          [routerLink]="'edit/' + myDataArray.id"
        ></tr>
      </table>
    </div>
  </mat-card>
</div>
