import {
  AfterViewInit,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  NG_VALUE_ACCESSOR,
  NgModel,
} from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { NotificationConfig } from 'src/app/organization/notification-configuration/models/notification-config';

@Component({
  selector: 'app-multiple-notification-config-select',
  templateUrl: './multiple-notification-config-select.component.html',
  styleUrls: ['./multiple-notification-config-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleNotificationConfigSelectComponent),
      multi: true,
    },
  ],
})
export class MultipleNotificationConfigSelectComponent
  implements OnInit, OnChanges, AfterViewInit {
  private val: any;
  /**
   * The FormControl must be passed, so that the validators can be passed to the input model of this custom ControlValueAccessor.
   */
  @Input() formControl: AbstractControl;
  @Input() notificationConfigs: Array<NotificationConfig> = [];
  public notificationConfigFilterCtrl: FormControl = new FormControl();
  public filteredNotificationConfigs: ReplaySubject<
    NotificationConfig[]
  > = new ReplaySubject<NotificationConfig[]>(1);
  @ViewChild('selectModel') selectModel: NgModel;

  ngOnInit() {
    // load the initial NotificationConfig list
    if (this.notificationConfigs) {
      this.filteredNotificationConfigs.next(this.notificationConfigs.slice());
    }

    // listen for search field value changes
    this.notificationConfigFilterCtrl.valueChanges.subscribe(() =>
      this.filterNotificationConfigs()
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    // load the initial NotificationConfig list
    if (this.notificationConfigs) {
      this.filteredNotificationConfigs.next(this.notificationConfigs.slice());
    }
  }

  ngAfterViewInit(): void {
    if (this.formControl) {
      this.selectModel.control.setValidators(this.formControl.validator);
      this.selectModel.control.setAsyncValidators(
        this.formControl.asyncValidator
      );
    }
  }

  protected filterNotificationConfigs() {
    if (!this.notificationConfigs) {
      return;
    }
    // get the search keyword
    let search = this.notificationConfigFilterCtrl.value;
    if (!search) {
      this.filteredNotificationConfigs.next(this.notificationConfigs.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredNotificationConfigs.next(
      this.notificationConfigs.filter((notificationConfig) =>
        notificationConfig.title.toLowerCase().includes(search)
      )
    );
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  isEmptySelection(): boolean {
    return Array.isArray(this.value) && this.value.length > 0;
  }
}
