<div fxLayout="column" class="app-area">
  <!-- HEADER ROW -->
  <div fxLayout="row">
    <div fxFlex fxFlexAlign="start start">
      <h1>Positionierung</h1>
    </div>
  </div>

  <!-- LOCATION INDICATOR ROW -->
  <div fxLayout="row" fxFlexAlign="start center">
    <ng-container
      *ngFor="
        let selectedGroup of positioningService.selectedGroups(
          positioningService.treeRoot
        );
        let i = index
      "
    >
      <span *ngIf="i > 0"
        ><mat-icon style="padding: 0 8px">play_arrow</mat-icon></span
      >
      <span
        class="location-indicator-row-item upper-case truncate-text"
        (click)="scrollTo(i)"
        >{{ selectedGroup.displayName }}</span
      >
    </ng-container>
  </div>

  <div fxLayout="row" class="scroll-content" #scrollContainer>
    <!-- Cards for selected Groups -->
    <ng-container
      *ngFor="
        let selectedGroup of positioningService.selectedGroups(
          positioningService.treeRoot
        )
      "
    >
      <app-group-card
        [sensorGroupNode]="selectedGroup"
        [allUserGroups]="allUserGroups"
        [allUsers]="allUsers"
        [enabled]="enabled"
      ></app-group-card>
    </ng-container>

    <app-positioning-sensor-detail-card
      *ngIf="positioningService.selectedSensor"
      [sensor]="positioningService.selectedSensor"
    ></app-positioning-sensor-detail-card>
  </div>
</div>
