<form [formGroup]="form" (ngSubmit)="save()">
  <div fxLayout="column" class="app-area">
    <!-- HEADER ROW -->
    <div fxLayout="row">
      <div fxFlex fxFlexAlign="start start">
        <h1>Organisationseinstellungen</h1>
      </div>
      <div fxFlex style="text-align: right">
        <button mat-button fxFlexAlign="end end" type="submit">
          <mat-icon>save</mat-icon>
          Speichern
        </button>
      </div>
    </div>

    <!-- Content -->
    <mat-card class="square-card mat-elevation-z0">
      <!-- Datenschutz -->
      <div
        fxLayout="row wrap"
        fxLayoutAlign="start start"
        style="width: 100%"
        formGroupName="privacy"
      >
        <div fxFlex="100"><h2>Datenschutz</h2></div>
        <div fxFlex="100">
          <mat-form-field appearance="outline">
            <mat-label>URL</mat-label>
            <input
              matInput
              placeholder="URL"
              type="text"
              formControlName="url"
            />
            <mat-error
              *ngIf="form.controls.privacy.controls.url.hasError('maxlength')"
            >
              Maximal 255 Zeichen!
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- Impressum -->
      <div
        fxLayout="row wrap"
        fxLayoutAlign="start start"
        style="width: 100%"
        formGroupName="imprint"
      >
        <div fxFlex="100"><h2>Impressum</h2></div>
        <div fxFlex="100">
          <mat-form-field appearance="outline">
            <mat-label>URL</mat-label>
            <input
              matInput
              placeholder="URL"
              type="text"
              formControlName="url"
            />
            <mat-error
              *ngIf="form.controls.imprint.controls.url.hasError('maxlength')"
            >
              Maximal 255 Zeichen!
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- AGBs -->
      <div
        fxLayout="row wrap"
        fxLayoutAlign="start start"
        style="width: 100%"
        formGroupName="gtc"
      >
        <div fxFlex="100"><h2>Allgemeine Geschäftsbedingungen</h2></div>
        <div fxFlex="100">
          <mat-form-field appearance="outline">
            <mat-label>URL</mat-label>
            <input
              matInput
              placeholder="Titel"
              type="text"
              formControlName="url"
            />
            <mat-error
              *ngIf="form.controls.privacy.controls.url.hasError('maxlength')"
            >
              Maximal 255 Zeichen!
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </mat-card>
  </div>
</form>
