<div fxLayout="column" class="app-area">
  <!-- HEADER ROW -->
  <div fxLayout="row">
    <div fxFlex fxFlexAlign="start start">
      <h1>Hinweistexte</h1>
    </div>
  </div>

  <!-- Content -->
  <mat-card
    fxLayout="row wrap"
    fxLayoutAlign="start start"
    class="square-card mat-elevation-z0"
    style="height: 100%"
  >
    <div class="table-wrapper">
      <table mat-table [dataSource]="matTableDataSource" style="width: 100%">
        <ng-container matColumnDef="technicalName">
          <th mat-header-cell *matHeaderCellDef>Eigenschaft</th>
          <td mat-cell *matCellDef="let element">
            {{ technicalNameToLabel(element.technicalName) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Hinweistext</th>
          <td mat-cell *matCellDef="let element">
            {{ element.description }}
          </td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                (click)="openEdit(element); $event.stopPropagation()"
              >
                Bearbeiten
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let myDataArray; columns: displayedColumns"
          (click)="openEdit(myDataArray)"
        ></tr>
      </table>
    </div>
  </mat-card>
</div>
