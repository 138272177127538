import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultipleUserGroupSelectComponent } from './multiple-user-group-select.component';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [MultipleUserGroupSelectComponent],
  exports: [MultipleUserGroupSelectComponent],
  imports: [
    CommonModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class MultipleUserGroupSelectModule {}
