<div fxLayout="column" class="app-area">
  <!-- HEADER ROW -->
  <div fxLayout="row">
    <div fxFlex fxFlexAlign="start start">
      <h1>Sensortypen</h1>
    </div>
    <div fxFlex style="text-align: right">
      <button
        mat-stroked-button
        fxFlexAlign="end end"
        (click)="onImportClicked()"
        [disabled]="!enabled || importInProgress"
        [class.button-spinner]="importInProgress"
        style="margin-right: 24px"
      >
        <mat-icon>download</mat-icon>
        <span style="margin-left: 12px">Import aus niota</span>
      </button>
    </div>
  </div>

  <!-- Content -->
  <mat-card
    fxLayout="row wrap"
    fxLayoutAlign="center start"
    class="square-card mat-elevation-z0"
    style="height: 100%; overflow: scroll"
  >
    <div fxFlex="100" fxLayout="row" dir="rtl">
      <button
        mat-icon-button
        fxFlexAlign="end end"
        (click)="onClearFilterClicked()"
        [disabled]="!isFilter()"
      >
        <mat-icon>settings_backup_restore</mat-icon>
      </button>
    </div>
    <div class="table-wrapper">
      <table mat-table [dataSource]="tableDataSource" style="width: 100%">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter-text-field">
              <mat-label>Name</mat-label>
              <input
                matInput
                type="text"
                [(ngModel)]="sensorTypeConfigFilterInput.name"
                (ngModelChange)="filterChangedSubject.next()"
                name="displayName"
              />
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.displayName }}</td>
        </ng-container>

        <ng-container matColumnDef="sensorTypePropertyConfigs">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter-text-field">
              <mat-label>Eigenschaften</mat-label>
              <input
                matInput
                type="text"
                [(ngModel)]="sensorTypeConfigFilterInput.sensorPropertyConfig"
                (ngModelChange)="filterChangedSubject.next()"
                name="sensorPropertyConfig"
              />
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-chip-list>
              <mat-chip
                class="violet-chip"
                disabled
                *ngFor="let propertyConfig of element.sensorPropertyConfigs"
                [style.display]="
                  !propertyConfig.displayName && !propertyConfig.propertyName
                    ? 'none'
                    : ''
                "
              >
                {{
                  propertyConfig.displayName
                    ? propertyConfig.displayName
                    : propertyConfig.propertyName
                }}
              </mat-chip>
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container matColumnDef="niotaDeviceTypeId">
          <th mat-header-cell *matHeaderCellDef style="text-align: right">
            <mat-form-field class="filter-text-field">
              <mat-label>Gerätetyp-ID (niota)</mat-label>
              <input
                matInput
                type="number"
                [(ngModel)]="sensorTypeConfigFilterInput.niotaDeviceTypeId"
                (ngModelChange)="filterChangedSubject.next()"
                name="niotaDeviceTypeId"
              />
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-chip-list class="float-right" *ngIf="element.niotaDeviceTypeId !== null && element.niotaDeviceTypeId !== undefined">
              <mat-chip class="blue-chip" disabled>{{
                element.niotaDeviceTypeId
              }}</mat-chip>
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                (click)="$event.stopPropagation()"
                [routerLink]="'/public-utility/sensor-types/edit/' + element.id"
              >
                Bearbeiten
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: displayedColumns"
          [routerLink]="'/public-utility/sensor-types/edit/' + element.id"
        ></tr>
      </table>
    </div>
  </mat-card>
</div>
