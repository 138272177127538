import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { PositioningSensorComponent } from 'src/app/organization/positioning/components/positioning-sensor/positioning-sensor.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [PositioningSensorComponent],
  exports: [PositioningSensorComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    FlexModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
  ],
})
export class PositioningSensorModule {}
