import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditSensorGroupNodeModalComponent } from 'src/app/organization/positioning/components/edit-sensor-group-node-modal/edit-sensor-group-node-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [EditSensorGroupNodeModalComponent],
  exports: [EditSensorGroupNodeModalComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    FlexModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    MatSelectModule,
  ],
})
export class EditSensorGroupNodeModalModule {}
