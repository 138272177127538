<div fxLayout="column" fxLayoutAlign="center center" class="full-page">
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    class="confirmation-content"
  >
    <img src="assets/logo_CityLink.svg" class="logo" />
    <p>
      Ihre E-Mail wurde erfolgreich geändert. Bitte loggen Sie sich zukünftig
      mit der neuen E-Mail Adresse ein.
    </p>
    <p>
      Klicken Sie <a routerLink="/login">hier</a> falls die automatische
      Weiterleitung nicht funktioniert.
    </p>
  </div>
</div>
