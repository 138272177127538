<div fxLayout="column" class="app-area">
  <!-- HEADER ROW -->
  <div fxLayout="row">
    <div fxFlex fxFlexAlign="start start">
      <h1>Nutzergruppen</h1>
    </div>
    <div fxFlex style="text-align: right">
      <button mat-icon-button fxFlexAlign="end end" (click)="onAddClicked()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

  <!-- Content -->
  <mat-card
    fxLayout="row wrap"
    fxLayoutAlign="start start"
    class="square-card mat-elevation-z0"
    style="height: 100%"
  >
    <div fxFlex="100" fxLayout="row" dir="rtl">
      <button
        mat-icon-button
        fxFlexAlign="end end"
        (click)="onAddClicked()"
        [disabled]="!enabled"
      >
        <mat-icon>add</mat-icon>
      </button>
      <button
        mat-icon-button
        fxFlexAlign="end end"
        (click)="onClearFilterClicked()"
        [disabled]="!isFilter()"
      >
        <mat-icon>settings_backup_restore</mat-icon>
      </button>
    </div>
    <div class="table-wrapper">
      <table mat-table [dataSource]="matTableDataSource" style="width: 100%">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter-input">
              <mat-label>Name</mat-label>
              <input
                matInput
                type="text"
                [(ngModel)]="userGroupFilter.name"
                (ngModelChange)="filterChangedSubject.next()"
                name="name"
              />
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="userCount">
          <th mat-header-cell *matHeaderCellDef>Nutzer</th>
          <td mat-cell *matCellDef="let element">
            <mat-chip-list class="float-right">
              <mat-chip class="green-chip" disabled
                >{{ element.userIds?.length || 0 }}
              </mat-chip>
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                (click)="openEdit(element); $event.stopPropagation()"
              >
                Bearbeiten
              </button>
              <button
                mat-menu-item
                (click)="deleteUserGroup(element); $event.stopPropagation()"
              >
                Löschen
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let myDataArray; columns: displayedColumns"
          (click)="openEdit(myDataArray)"
        ></tr>
      </table>
    </div>
  </mat-card>
</div>
