import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { PositioningService } from 'src/app/organization/positioning/services/positioning.service';
import { ActivatedRoute } from '@angular/router';
import { Roles } from 'src/app/_core/models/roles';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';
import { User } from 'src/app/users/models/user';
import { UserGroup } from 'src/app/users/models/user-group';
import { SensorGroupNodeService } from 'src/app/_shared/services/sensor-group-node.service';
import { DisposeBag } from '@ronas-it/dispose-bag/dist/src';

@Component({
  selector: 'app-positioning',
  templateUrl: './positioning-overview.component.html',
  styleUrls: ['./positioning-overview.component.scss'],
})
export class PositioningOverviewComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('scrollContainer')
  scrollContainer;
  enabled = false;
  allUsers: User[];
  allUserGroups: UserGroup[];
  disposebag = new DisposeBag();

  constructor(
    public positioningService: PositioningService,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private sensorGroupNodeService: SensorGroupNodeService
  ) {}

  initTreeUpdatedWebsocketSubscription() {
    this.disposebag.add(
      this.sensorGroupNodeService.getTreeObservable().subscribe(
        (value) => {
          this.positioningService.updateTree(value);
        },
        (error) => {
          if (error instanceof CloseEvent && error.code !== 4403) {
            this.initTreeUpdatedWebsocketSubscription();
          }
          console.log(error);
        }
      )
    );
  }

  ngOnInit() {
    this.initTreeUpdatedWebsocketSubscription();

    this.allUsers = this.activatedRoute.snapshot.data.data.users;
    this.allUserGroups = this.activatedRoute.snapshot.data.data.userGroups;
    this.positioningService.treeRoot = this.activatedRoute.snapshot.data.data.treeRoot;
    this.positioningService.selectedSensor = undefined;
    if (this.positioningService.treeRoot) {
      this.positioningService.treeRoot.selected = true;
    }
    this.disposebag.add(
      this.positioningService.selectionChangedSubject.subscribe((_) => {
        this.scrollRightIfNecessary();
      })
    );

    const currentUserSession = this.authenticationService.getCurrentUserSession();
    // @ts-ignore
    this.enabled = [
      Roles.ORGANIZATION_OWNER,
      Roles.ORGANIZATION_EDITOR,
    ].includes(currentUserSession?.role);
  }

  ngAfterViewInit() {
    if (this.positioningService.treeRoot?.displayName === 'root') {
      this.positioningService.handleRootUnnamed(
        this.positioningService.treeRoot
      );
    }
  }

  ngOnDestroy() {
    this.disposebag.unsubscribe();
  }

  scrollRightIfNecessary() {
    setTimeout(() => {
      this.scrollContainer.nativeElement.scrollBy({
        top: 0,
        left: this.scrollContainer.nativeElement.offsetWidth,
        behavior: 'smooth',
      });
    }, 25);
  }

  scrollTo(i: number) {
    const currentOffset = this.scrollContainer.nativeElement.scrollLeft;
    const toScroll = 386 * i - currentOffset;
    console.log({ currentOffset, toScroll });
    this.scrollContainer.nativeElement.scrollBy({
      top: 0,
      left: toScroll,
      behavior: 'smooth',
    });
  }
}
