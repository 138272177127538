<div fxLayout="column" fxLayoutAlign="center center" class="full-page">
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    class="password-reset-content"
  >
    <img src="assets/logo_CityLink.svg" class="logo" />
    <h1 class="login-title">Passwort ändern</h1>

    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <mat-card
        class="square-card change-password-card mat-elevation-z0"
        fxLayout="column"
        fxLayoutAlign="center stretch"
      >
        <div *ngIf="changePasswordError" class="text-danger error-card">
          {{ changePasswordError }}
        </div>
        <div *ngIf="changePasswordSuccess" class="text-success">
          {{ changePasswordSuccess }}
        </div>
        <mat-form-field appearance="outline">
          <mat-label>Neues Passwort</mat-label>
          <input
            matInput
            placeholder="Neues Passwort"
            type="password"
            formControlName="newPassword"
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Neues Passwort wiederholen</mat-label>
          <input
            matInput
            placeholder="Neues Passwort wiederholen"
            type="password"
            formControlName="confirmPassword"
          />
        </mat-form-field>
        <button
          mat-flat-button
          color="primary"
          type="submit"
          class="upper-case"
          [disabled]="loadingInProcess"
          [class.button-spinner]="loadingInProcess"
        >
          Bestätigen
        </button>
      </mat-card>
    </form>
  </div>
</div>
