import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-brightness-icon',
  template: `
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      [style.height]="size + 'px'"
      [style.width]="size + 'px'"
    >
      <rect
        width="28"
        height="28"
        rx="6"
        [attr.fill]="backgroundColor ? backgroundColor : '#FFC90A'"
      />
      <path
        d="M14.0003 18.5834C16.5316 18.5834 18.5837 16.5314 18.5837 14.0001C18.5837 11.4688 16.5316 9.41675 14.0003 9.41675C11.469 9.41675 9.41699 11.4688 9.41699 14.0001C9.41699 16.5314 11.469 18.5834 14.0003 18.5834Z"
        [attr.stroke]="'white'"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14 3.91675V5.75008"
        [attr.stroke]="'white'"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14 22.25V24.0833"
        [attr.stroke]="'white'"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.86816 6.86841L8.16983 8.17007"
        [attr.stroke]="'white'"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.8301 19.8301L21.1317 21.1317"
        [attr.stroke]="'white'"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.91699 14H5.75033"
        [attr.stroke]="'white'"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.25 14H24.0833"
        [attr.stroke]="'white'"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.86816 21.1317L8.16983 19.8301"
        [attr.stroke]="'white'"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.8301 8.17007L21.1317 6.86841"
        [attr.stroke]="'white'"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [],
})
export class BrightnessIconComponent implements OnInit {
  @Input() backgroundColor: string;
  @Input() size = 128;

  constructor() {}

  ngOnInit(): void {}
}
