import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SensorService } from 'src/app/_shared/services/sensor.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SensorGroupNode } from 'src/app/_shared/models/sensor-group-node';
import { SelectUserGroupsAndUsersModalComponent } from 'src/app/organization/positioning/components/select-user-groups-and-users-modal/select-user-groups-and-users-modal.component';
import { SensorTypeConfig } from 'src/app/_shared/models/sensor-type-config';
import { NotificationConfigService } from 'src/app/organization/notification-configuration/services/notification-config.service';
import { NotificationConfig } from 'src/app/organization/notification-configuration/models/notification-config';
import { Sensor } from 'src/app/_shared/models/sensor';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ErrorService } from 'src/app/_shared/services/error.service';
import { SensorPairDto } from 'src/app/_shared/dtos/sensor-pair.dto';
import { SnackBarService } from 'src/app/_shared/services/snack-bar.service';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DisposeBag } from '@ronas-it/dispose-bag/dist/src';
import { Roles } from 'src/app/_core/models/roles';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';
import { SensorTypesService } from 'src/app/public-utility/sensor-types/services/sensor-types.service';
import { HexValidator } from 'src/app/_shared/validators/hex-validator';
import { GoogleMapsService } from 'src/app/_shared/services/google-maps.service';

@Component({
  selector: 'app-edit-sensor-modal',
  templateUrl: './edit-sensor-modal.component.html',
  styleUrls: ['./edit-sensor-modal.component.scss'],
})
export class EditSensorModalComponent
  implements OnInit, AfterViewInit, OnDestroy {
  form: FormGroup;
  isEdit = false;
  enabled = false;
  sensorTypeConfigs: SensorTypeConfig[];
  notificationConfigs: NotificationConfig[];
  filteredNotificationConfigsForSensorType: NotificationConfig[];
  image: SafeUrl;
  disposeBag = new DisposeBag();

  readonly gmOptions: google.maps.MapOptions = {
    streetViewControl: false,
  };
  markerPosition: google.maps.LatLngLiteral;
  markerOptions: google.maps.MarkerOptions;
  geocoder = new google.maps.Geocoder();

  @ViewChild(GoogleMap) googleMap: GoogleMap;
  @ViewChild(MapMarker) mapMarker: MapMarker;
  unpairedSensors: Sensor[] = [];

  get latCtrl(): AbstractControl {
    return this.form.get('lat');
  }

  get lonCtrl(): AbstractControl {
    return this.form.get('lng');
  }

  constructor(
    private sensorService: SensorService,
    @Inject(MAT_DIALOG_DATA)
    public data: { sensorGroupNode: SensorGroupNode; sensor: Sensor },
    public dialogRef: MatDialogRef<SelectUserGroupsAndUsersModalComponent>,
    private notificationConfigService: NotificationConfigService,
    private domSanitizer: DomSanitizer,
    private errorService: ErrorService,
    private snackBarService: SnackBarService,
    private authenticationService: AuthenticationService,
    private sensorTypesService: SensorTypesService
  ) {}

  ngOnDestroy() {
    this.disposeBag.unsubscribe();
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      eui: new FormControl('', [
        Validators.required,
        Validators.minLength(16),
        Validators.maxLength(16),
        HexValidator.hex,
      ]),
      displayName: new FormControl(
        '',
        this.isEdit
          ? [Validators.required, Validators.maxLength(255)]
          : [Validators.maxLength(255)]
      ),
      notificationConfigs: new FormControl([]),
      sensorTypeConfig: new FormControl({ disabled: true }),
      image: new FormControl(),
      street: new FormControl(undefined, [Validators.maxLength(255)]),
      zipCode: new FormControl(undefined, [Validators.maxLength(5)]),
      city: new FormControl(undefined, [Validators.maxLength(255)]),
      lat: new FormControl(),
      lng: new FormControl(),
    });

    if (this.data.sensor) {
      this.initEdit();
    } else {
      this.initCreate();
    }

    this.disposeBag.add(
      this.latCtrl.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((lat) => {
          this.updateLocationFromCurrentFormValue();
        })
    );
    this.disposeBag.add(
      this.lonCtrl.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((lat) => {
          this.updateLocationFromCurrentFormValue();
        })
    );

    this.form.controls.sensorTypeConfig.valueChanges.subscribe((newVal) => {
      this.filterSelectableNotificationTypes(newVal);
    });

    // @ts-ignore

    this.enabled = [
      Roles.ORGANIZATION_OWNER,
      Roles.ORGANIZATION_EDITOR,
    ].includes(this.authenticationService.getCurrentUserSession()?.role);
    if (!this.enabled) {
      // https://github.com/angular/angular/issues/22556
      setTimeout(() => this.form.disable(), 10);
    }

    this.markerOptions = { draggable: true };
  }

  ngAfterViewInit() {
    const sensor = this.data.sensor;
    if (this.googleMap && sensor.coordinate) {
      GoogleMapsService.centerAndZoomMap(
        this.googleMap,
        undefined,
        {
          lat: sensor.coordinate?.lat,
          lng: sensor.coordinate?.lng,
        },
        18
      );
    }
  }

  onFileSelect(event: any) {
    const files: FileList = event.target.files;
    const reader = new FileReader();
    try {
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        if (
          new TextEncoder().encode(reader.result as string).length > 16777215
        ) {
          this.errorService.showError({
            userErrorMessage:
              'Das gewählte Bild überschreitet die maximale Größe von 16MB.',
          });
          return;
        }
        this.image = this.domSanitizer.bypassSecurityTrustUrl(
          reader.result as string
        );
        this.form.patchValue({
          image: reader.result,
        });
      };
    } catch (e) {}
  }

  private initCreate() {
    this.form.disable();

    const promises = [];

    this.sensorService.getUnpairedSensors().then((unpairedSensors) => {
      this.unpairedSensors = unpairedSensors;
    });
    promises.push(
      this.sensorTypesService
        .getSensorTypeConfigs()
        .then((result) => (this.sensorTypeConfigs = result))
    );
    promises.push(
      this.notificationConfigService
        .getNotificationConfigList()
        .then((result) => (this.notificationConfigs = result))
    );
    Promise.all(promises).then((_) => {
      if (this.enabled) {
        this.form.enable();
      }
    });
  }

  private async initEdit() {
    this.isEdit = true;
    const sensor = this.data.sensor;
    this.form.patchValue({
      eui: sensor.metaData.eui,
      displayName: sensor.displayName,
      street: sensor.address?.street,
      city: sensor.address?.city,
      zipCode: sensor.address?.zipCode,
      lat: sensor.coordinate?.lat,
      lng: sensor.coordinate?.lng,
    });

    if (
      sensor.coordinate &&
      sensor.coordinate.lng !== undefined &&
      sensor.coordinate.lng !== null &&
      sensor.coordinate.lat !== undefined &&
      sensor.coordinate.lat !== null
    ) {
      if (this.googleMap) {
        GoogleMapsService.centerAndZoomMap(
          this.googleMap,
          undefined,
          sensor.coordinate,
          18
        );
      }
      this.markerPosition = {
        lat: sensor.coordinate.lat,
        lng: sensor.coordinate.lng,
      };
    }

    this.form.disable();
    const promises = [];
    promises.push(
      this.sensorTypesService.getSensorTypeConfigs().then((result) => {
        this.sensorTypeConfigs = result;
        const sensorTypeConfig = this.sensorTypeConfigs.filter(
          (item) => item.id === sensor.sensorTypeConfig?.id
        )[0];
        this.form.patchValue({
          sensorTypeConfig,
        });
      })
    );
    promises.push(
      this.notificationConfigService
        .getNotificationConfigList()
        .then((result) => {
          this.notificationConfigs = result;
          const notificationConfigs = this.notificationConfigs.filter((item) =>
            this.data.sensor.notificationConfigIds.includes(item.id)
          );
          this.form.patchValue({
            notificationConfigs,
          });
        })
    );

    promises.push(
      this.sensorService.loadImage(sensor.id).then((result) => {
        if (result && result.image) {
          this.image = this.domSanitizer.bypassSecurityTrustUrl(result.image);
          this.form.patchValue({
            image: result.image,
          });
        }
      })
    );

    Promise.all(promises).then((_) => {
      if (this.enabled) {
        this.form.enable();

        if (sensor.sourceOfGeneration !== 'CITYLINK') {
          this.form.controls.city.disable();
          this.form.controls.street.disable();
          this.form.controls.zipCode.disable();
          this.form.controls.lat.disable();
          this.form.controls.lng.disable();
        }
      }
    });
  }

  private filterSelectableNotificationTypes(sensorTypeConfig) {
    if (!sensorTypeConfig) {
      return [];
    }

    this.filteredNotificationConfigsForSensorType = this.notificationConfigs
      ? this.notificationConfigs.filter((notificationConfig) => {
          return (
            notificationConfig.sensorTypeConfig &&
            notificationConfig.sensorTypeConfig.id === sensorTypeConfig.id
          );
        })
      : [];

    this.form.patchValue({
      notificationConfigs: this.form.controls.notificationConfigs.value.filter(
        (item) => this.filteredNotificationConfigsForSensorType.includes(item)
      ),
    });
  }

  save() {
    if (!this.form.valid) {
      return;
    }
    let dto;

    if (!this.isEdit) {
      dto = {
        eui: this.form.value.eui,
        sensorGroupNodeId: this.data.sensorGroupNode.id,
        sortCriteria: this.data.sensorGroupNode?.sensors?.length || 0,
      } as SensorPairDto;
    } else {
      dto = {
        eui: this.form.value.eui,
        displayName: this.form.value.displayName,
        notificationConfigIds: this.form.value.notificationConfigs.map(
          (item) => item.id
        ),
        sensorGroupNodeId: this.data.sensorGroupNode.id,
        image: this.form.value.image,
        address: {
          street: this.form.value.street,
          zipCode: this.form.value.zipCode,
          city: this.form.value.city,
        },
        coordinate: {
          lng: this.form.value.lng,
          lat: this.form.value.lat,
        },
      } as SensorPairDto;
    }

    this.sensorService.pair(dto).then((result) => {
      this.dialogRef.close(result);
    });
  }

  removeImage() {
    this.image = undefined;
    this.form.patchValue({
      image: null,
    });
  }

  onMapDragEnd(event: google.maps.MapMouseEvent) {
    if (this.form.controls.lng.enabled && this.form.controls.lat.enabled) {
      this.updateMarker(event);
    }
  }

  onMapClick($event: google.maps.MapMouseEvent | google.maps.IconMouseEvent) {
    if (this.form.controls.lng.enabled && this.form.controls.lat.enabled) {
      this.updateMarker($event);
    }
  }

  private updateMarker(
    $event: google.maps.MapMouseEvent | google.maps.IconMouseEvent
  ) {
    this.form.patchValue({
      lat: $event.latLng.lat(),
      lng: $event.latLng.lng(),
    });

    this.markerPosition = {
      lat: $event.latLng.lat(),
      lng: $event.latLng.lng(),
    };
  }

  geocodeAddress() {
    const geocodeRequest: google.maps.GeocoderRequest = {
      address:
        this.form.value.street +
        ', ' +
        this.form.value.zipCode +
        ' ' +
        this.form.value.city,
    };
    this.geocoder.geocode(
      geocodeRequest,
      (
        results: google.maps.GeocoderResult[],
        status: google.maps.GeocoderStatus
      ) => {
        if (status !== google.maps.GeocoderStatus.OK) {
          this.snackBarService.showCustomMessage(
            'Die Koordinaten konnten nicht ermittelt werden'
          );
          return;
        }

        this.form.patchValue({
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        });

        this.markerPosition = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        };

        GoogleMapsService.centerAndZoomMap(
          this.googleMap,
          undefined,
          {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          },
          18
        );
        this.mapMarker.marker.setPosition({
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        });
      }
    );
  }

  canGeocode() {
    return (
      this.form.value.street && this.form.value.zipCode && this.form.value.city
    );
  }

  determineLocationFromPositioning() {
    const address = this.data.sensorGroupNode.address;

    if (address) {
      this.form.patchValue({
        street: address.street,
        zipCode: address.zipCode,
        city: address.city,
      });
    }

    const polygon = this.data.sensorGroupNode.geoAreaPolygon;
    if (polygon && polygon.path && polygon.path.length > 0) {
      const bounds = GoogleMapsService.polygonPathToBounds(polygon.path);
      const center = bounds.getCenter();

      this.form.patchValue({
        lat: center.lat(),
        lng: center.lng(),
      });

      this.markerPosition = {
        lat: center.lat(),
        lng: center.lng(),
      };

      GoogleMapsService.centerAndZoomMap(
        this.googleMap,
        undefined,
        { lat: center.lat(), lng: center.lng() },
        18
      );
      this.mapMarker.marker.setPosition({
        lat: center.lat(),
        lng: center.lng(),
      });
    }
  }

  private updateLocationFromCurrentFormValue() {
    if (
      this.form.value.lat !== null &&
      this.form.value.lat !== undefined &&
      this.form.value.lng !== undefined &&
      this.form.value.lng !== null
    ) {
      this.gmOptions.center = {
        lat: this.form.value.lat,
        lng: this.form.value.lng,
      };
      this.gmOptions.zoom = 18;
      this.markerPosition = {
        lat: this.form.value.lat,
        lng: this.form.value.lng,
      };
      if (this.googleMap) {
        GoogleMapsService.centerAndZoomMap(
          this.googleMap,
          undefined,
          {
            lat: this.form.value.lat,
            lng: this.form.value.lng,
          },
          18
        );
      }
      if (this.mapMarker) {
        this.mapMarker.marker.setPosition({
          lat: this.form.value.lat,
          lng: this.form.value.lng,
        });
      }
    }
  }
}
