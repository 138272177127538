import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { SensorService } from 'src/app/_shared/services/sensor.service';
import { CitylinkSensorDetail } from 'src/app/public-utility/sensors/models/citylink-sensor-detail';
import { CitylinkOrganization } from 'src/app/public-utility/organization/models/citylink-organization';
import { SensorTypeConfig } from 'src/app/_shared/models/sensor-type-config';
import { OrganizationService } from 'src/app/_shared/services/organization.service';
import { SensorTypesService } from 'src/app/public-utility/sensor-types/services/sensor-types.service';
import { ApiService } from 'src/app/_core/services/api/api.service';

export interface SensorListEditResolverResult {
  sensors: CitylinkSensorDetail[];
  organizations: CitylinkOrganization[];
  sensorTypeConfigs: SensorTypeConfig[];
}

@Injectable({
  providedIn: 'root',
})
export class SensorListEditResolver
  implements Resolve<SensorListEditResolverResult | void> {
  constructor(
    private sensorsService: SensorService,
    private organizationsService: OrganizationService,
    private sensorTypesService: SensorTypesService,
    private apiService: ApiService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<SensorListEditResolverResult | void> {
    const promises = [];
    promises.push(this.sensorDetailsPromise(route));
    promises.push(this.organizationsService.getList(false));
    promises.push(this.sensorTypesService.getSensorTypeConfigs(false));

    return Promise.all(promises)
      .then((allPromisesResult) => {
        return {
          sensors: allPromisesResult[0],
          organizations: allPromisesResult[1],
          sensorTypeConfigs: allPromisesResult[2],
        } as SensorListEditResolverResult;
      })
      .catch((err) => {
        this.apiService.handleError(err);
        throw err;
      });
  }

  private sensorDetailsPromise(route: ActivatedRouteSnapshot) {
    const sensorIds = route.queryParams.sensors
      .split(',')
      .map((item) => Number(item));
    const promises = [];
    sensorIds.forEach((sensorId) => {
      promises.push(
        this.sensorsService
          .getSensor(sensorId, false)
          .catch((err) => console.error(err))
      );
    });
    return Promise.all(promises);
  }
}
