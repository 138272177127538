import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
  selector: '[appAddPlaceholder]',
})
export class AddPlaceholderDirective implements OnInit {
  @Input() appAddPlaceholder: Subject<string>;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.appAddPlaceholder.subscribe((placeHolderToAdd) => {
      if (this.el.nativeElement === document.activeElement) {
        document.execCommand('insertText', true, placeHolderToAdd);
      }
    });
  }
}
