import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-mqtt',
  template: `
    <svg
      width="61"
      height="20"
      viewBox="0 0 61 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9522 11.2981C22.0455 11.5062 22.1334 11.72 22.2158 11.9393C22.2982 11.7143 22.3861 11.5006 22.485 11.2868C22.5784 11.0787 22.6773 10.8706 22.7762 10.6737L25.5449 5.08245C25.5944 4.98683 25.6438 4.9137 25.6987 4.85745C25.7482 4.80683 25.8086 4.76745 25.8745 4.73933C25.9404 4.7112 26.0119 4.69995 26.0943 4.69995C26.1767 4.69995 26.2701 4.69995 26.3799 4.69995H28.2917V15.7587H26.0833V9.3912C26.0833 9.08183 26.0998 8.74995 26.1272 8.38995L23.2651 14.0768C23.1772 14.2568 23.0564 14.3862 22.908 14.4818C22.7597 14.5718 22.5839 14.6168 22.3916 14.6168H22.051C21.8588 14.6168 21.6885 14.5718 21.5346 14.4818C21.3863 14.3918 21.2655 14.2568 21.1776 14.0768L18.2935 8.38433C18.3154 8.56433 18.3264 8.7387 18.3374 8.91308C18.3484 9.08745 18.3539 9.25058 18.3539 9.39683V15.7587H16.1455V4.69995H18.0573C18.1671 4.69995 18.2605 4.69995 18.3429 4.69995C18.4253 4.69995 18.4967 4.7112 18.5627 4.73933C18.6286 4.76745 18.6835 4.8012 18.7384 4.85745C18.7879 4.90808 18.8428 4.98683 18.8923 5.08245L21.672 10.7018C21.7654 10.8931 21.8643 11.09 21.9522 11.2981Z"
        [attr.fill]="color"
      />
      <path
        d="M40.1409 10.2237C40.1409 10.7018 40.0914 11.1574 39.998 11.5962C39.8992 12.0349 39.7618 12.4456 39.5805 12.8281C39.3992 13.2106 39.1795 13.5706 38.9103 13.8968C38.6466 14.2287 38.3445 14.5156 38.0094 14.7687L40.7452 17.8287H38.6851C38.3884 17.8287 38.1193 17.7893 37.8775 17.7162C37.6358 17.6431 37.4216 17.4968 37.2348 17.2718L35.8944 15.7306C35.6746 15.7756 35.4549 15.8093 35.2297 15.8374C35.0044 15.8599 34.7737 15.8768 34.5375 15.8768C33.697 15.8768 32.9279 15.7306 32.2357 15.4437C31.5435 15.1568 30.9502 14.7574 30.4613 14.2456C29.9669 13.7393 29.5878 13.1374 29.3186 12.4512C29.0494 11.7649 28.9121 11.0168 28.9121 10.2181C28.9121 9.41932 29.0494 8.67682 29.3186 7.98494C29.5878 7.29869 29.9669 6.69682 30.4613 6.19057C30.9557 5.68432 31.5435 5.28494 32.2357 4.99807C32.9279 4.71119 33.6915 4.56494 34.5375 4.56494C35.378 4.56494 36.1416 4.71119 36.8338 5.00369C37.526 5.29619 38.1138 5.69557 38.6027 6.20182C39.0916 6.70807 39.4707 7.30432 39.7398 7.99619C40.009 8.68244 40.1409 9.42494 40.1409 10.2237ZM37.5754 10.2237C37.5754 9.67807 37.504 9.18307 37.3666 8.74432C37.2293 8.30557 37.0315 7.93432 36.7678 7.63057C36.5097 7.32682 36.191 7.09619 35.8175 6.93307C35.4439 6.76994 35.0154 6.69119 34.532 6.69119C34.0486 6.69119 33.6146 6.76994 33.2355 6.93307C32.8565 7.09619 32.5378 7.32682 32.2796 7.63057C32.0214 7.93432 31.8237 8.30557 31.6808 8.74432C31.5435 9.18307 31.4721 9.67244 31.4721 10.2237C31.4721 10.7749 31.5435 11.2699 31.6808 11.7087C31.8182 12.1474 32.0159 12.5187 32.2796 12.8224C32.5378 13.1262 32.8565 13.3568 33.2355 13.5199C33.6146 13.6774 34.0431 13.7618 34.532 13.7618C35.0099 13.7618 35.4384 13.6831 35.8175 13.5199C36.191 13.3624 36.5097 13.1262 36.7678 12.8224C37.026 12.5187 37.2238 12.1474 37.3666 11.7087C37.5095 11.2699 37.5754 10.7749 37.5754 10.2237Z"
        [attr.fill]="color"
      />
      <path
        d="M48.1892 4.69434V6.73059H45.2612V15.7587H42.7562V6.73059H39.8281V4.69434H48.1892Z"
        [attr.fill]="color"
      />
      <path
        d="M57.7806 4.69434V6.73059H54.7427V15.7587H52.2376V6.73059H49.3096V4.69434H57.7806Z"
        [attr.fill]="color"
      />
      <path
        d="M2.55445 10.955C2.54346 10.955 2.53247 10.955 2.52148 10.955V15.32C2.52148 15.635 2.77419 15.8937 3.08182 15.8937H7.47114C7.43268 13.1656 5.24627 10.955 2.55445 10.955Z"
        [attr.fill]="color"
      />
      <path
        d="M2.55445 7.35498C2.54346 7.35498 2.53247 7.35498 2.52148 7.35498V9.21123C6.1417 9.22811 9.08622 12.2094 9.12467 15.8937H11.0034C10.9705 11.18 7.19645 7.35498 2.55445 7.35498Z"
        [attr.fill]="color"
      />
      <path
        d="M13.6678 15.3256V11.3825C12.3933 8.18745 9.82785 5.66183 6.63612 4.48621H3.08182C2.77419 4.48621 2.52148 4.74496 2.52148 5.05996V5.61683C8.0919 5.63371 12.624 10.2293 12.657 15.8993H13.1075C13.4206 15.8937 13.6678 15.6406 13.6678 15.3256Z"
        [attr.fill]="color"
      />
      <path
        d="M12.2014 6.07808C12.7178 6.60683 13.2452 7.27621 13.6682 7.86683V5.05433C13.6682 4.73933 13.421 4.48621 13.1133 4.48621H10.2842C10.9434 4.95308 11.6246 5.48746 12.2014 6.07808Z"
        [attr.fill]="color"
      />
    </svg>
  `,
  styles: [],
})
export class MqttLogoComponent implements OnInit {
  @Input() color: string;

  constructor() {}

  ngOnInit(): void {}
}
