import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserGroupEditComponent } from './user-group-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MultipleUserSelectModule } from 'src/app/_shared/components/form-controls/multiple-user-select/multiple-user-select.module';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [UserGroupEditComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatTableModule,
    MultipleUserSelectModule,
    FormsModule,
    MatCardModule,
  ],
})
export class UserGroupEditModule {}
