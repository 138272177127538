<mat-list-item>
  <div class="container" fxLayout="row" [style.padding-left.px]="paddingLeft">
    <div fxFlex="90%" fxLayoutAlign="start center">
      <span class="truncate-text">{{ item.displayName }}</span>
      <span class="dot warning" *ngIf="hasWarnings()"></span>
      <span class="dot alarm" *ngIf="hasAlarms()"></span>
    </div>
    <div fxFlex="10%">
      <span
        *ngIf="
          (item.children && item.children.length > 0) ||
          (item.sensors && item.sensors.length > 0)
        "
        style="float: right; cursor: pointer"
      >
        <mat-icon
          [@indicatorRotate]="item.selected ? 'expanded' : 'collapsed'"
          (click)="onItemSelected()"
        >
          expand_more
        </mat-icon>
      </span>
    </div>
  </div>
</mat-list-item>
<div *ngIf="item.selected">
  <ng-container>
    <app-tree-item
      *ngFor="let child of item.children"
      [item]="child"
      [depth]="depth + 1"
    >
    </app-tree-item>
  </ng-container>
  <ng-container>
    <app-sensor-item
      *ngFor="let sensor of item.sensors"
      [sensor]="sensor"
      [paddingLeft]="paddingLeft + 8"
    ></app-sensor-item>
  </ng-container>
</div>
