import { NgModule } from '@angular/core';
import { OrganizationSettingsComponentModule } from 'src/app/_shared/components/organization-settings/organization-settings-component.module';
import { ErrorModalModule } from 'src/app/_shared/components/error-modal/error-modal.module';
import { MailTemplateSettingsModule } from 'src/app/_shared/components/mail-template-settings/mail-template-settings.module';
import { SharedSensorModule } from 'src/app/_shared/components/shared-sensor/shared-sensor.module';

@NgModule({
  imports: [
    OrganizationSettingsComponentModule,
    ErrorModalModule,
    MailTemplateSettingsModule,
    SharedSensorModule,
  ],
  exports: [
    OrganizationSettingsComponentModule,
    ErrorModalModule,
    MailTemplateSettingsModule,
    SharedSensorModule,
  ],
})
export class SharedModule {}
