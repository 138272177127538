<div fxLayout="column" class="user-row">
  <div fxFlex="100" fxLayout="row" class="selection-wrapper">
    <div fxLayout="column" fxFlex="grow">
      <div class="user-title">{{ user.firstName }} {{ user.lastName }}</div>
      <div class="subtitle">
        <span>{{ user.username }}</span>
      </div>
    </div>
    <div fxFlex="40px" fxFlexAlign="center" style="text-align: right">
      <button
        mat-icon-button
        class="options-button"
        [matMenuTriggerFor]="menu"
        (click)="$event.stopPropagation()"
      >
        <mat-icon class="options">more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="removeUser()">
          <mat-icon>delete_outline</mat-icon>
          Entfernen
        </button>
      </mat-menu>
    </div>
  </div>
</div>
