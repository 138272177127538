import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiError } from 'src/app/_shared/exception/api.error';
import { UserService } from 'src/app/users/services/user.service';
import { Roles } from 'src/app/_core/models/roles';
import { MatDialog } from '@angular/material/dialog';
import { RequestNewPasswordComponent } from 'src/app/_core/components/request-new-password/request-new-password.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  @ViewChild('f') loginForm: NgForm;
  loginError: string;
  loginInProcess = false;
  returnUrl: string = undefined;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  ngAfterViewInit(): void {
    if (this.authenticationService.apiError) {
      this.loginError = this.authenticationService.apiError;
      this.authenticationService.apiError = null;
    }
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl;
  }

  onSubmit() {
    this.loginInProcess = true;
    this.authenticationService
      .login(this.loginForm.value.email, this.loginForm.value.password)
      .then((_) => {
        // Saves the current profile in the localStorage as a sideeffect.
        this.userService.getUserProfile();

        if (this.returnUrl) {
          this.router.navigateByUrl(this.returnUrl);
        } else {
          const role = this.authenticationService.getCurrentUserSession().role;
          if (
            Roles.ORGANIZATION_VIEWER === role ||
            Roles.ORGANIZATION_EDITOR === role ||
            Roles.ORGANIZATION_OWNER === role
          ) {
            this.router.navigateByUrl('organization/dashboard');
          } else if (
            Roles.CITYLINK_EDITOR === role ||
            Roles.CITYLINK_OWNER === role ||
            Roles.CITYLINK_VIEWER === role
          ) {
            this.router.navigateByUrl('public-utility/dashboard');
          }
        }
      })
      .catch((error) => {
        if (error instanceof ApiError) {
          if (error.error) {
            this.loginError = error.error;
          } else if (401 === error.statusCode) {
            this.loginError =
              'Die Kombination aus Nutzername und Passwort ist fehlerhaft.';
          } else {
            this.loginError = 'Es ist ein Fehler beim Login aufgetreten.';
          }
        }

        if (error instanceof HttpErrorResponse) {
          if (401 === error.status) {
            if (error.error.message && error.error.message !== 'Unauthorized') {
              this.loginError = error.error.message;
            } else {
              this.loginError =
                'Die Kombination aus Nutzername und Passwort ist fehlerhaft.';
            }
          } else if (0 === error.status) {
            this.loginError = 'Server ist nicht erreichbar.';
          }
        }

        if (!this.loginError) {
          this.loginError = 'Unknown Error';
        }
        this.loginInProcess = false;
      });
  }

  forgotPassword() {
    this.dialog.open(RequestNewPasswordComponent, {
      height: '250px',
      width: '600px',
    });
  }
}
