import { NgModule } from '@angular/core';
import { DashboardCurrentEventComponent } from 'src/app/organization/dashboard/components/current-events/event/dashboard-current-event.component';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsModule } from 'src/app/organization/notification-configuration/components/icons/icons.module';

@NgModule({
  declarations: [DashboardCurrentEventComponent],
  exports: [DashboardCurrentEventComponent],
  imports: [
    CommonModule,
    FlexModule,
    MatDividerModule,
    BrowserAnimationsModule,
    IconsModule,
  ],
})
export class DashboardCurrentEventModule {}
