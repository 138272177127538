import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { SensorGroupNode } from 'src/app/_shared/models/sensor-group-node';
import { SensorEventType } from 'src/app/organization/dashboard/models/sensor-event-type';
import { ActivatedRoute } from '@angular/router';
import { Sensor } from 'src/app/_shared/models/sensor';
import {
  PositioningService,
  SensorGroupNodeView,
} from 'src/app/organization/positioning/services/positioning.service';
import { Polygon } from 'src/app/_shared/models/Polygon';
import { GoogleMap } from '@angular/google-maps';
import { environment } from 'src/environments/environment';
import { GoogleMapsService } from 'src/app/_shared/services/google-maps.service';

@Component({
  selector: 'app-site-map',
  templateUrl: './dashboard-site-map.component.html',
  styleUrls: ['./dashboard-site-map.component.scss'],
})
export class DashboardSiteMapComponent implements OnInit, AfterViewInit {
  treeRoot: SensorGroupNodeView;
  gmOptions: google.maps.MapOptions;
  markers: Array<{
    markerPosition: google.maps.LatLngLiteral;
    markerOptions: google.maps.MarkerOptions;
    sensor: Sensor;
  }> = [];
  polygons: Array<Polygon> = [];

  @ViewChild(GoogleMap, { static: false }) googleMap: GoogleMap;

  constructor(
    private activatedRoute: ActivatedRoute,
    private positioningService: PositioningService,
    private googleMapsService: GoogleMapsService
  ) {
    this.treeRoot = this.activatedRoute.snapshot.data.treeRoot;
  }

  ngOnInit(): void {
    this.gmOptions = {
      center: { lat: Number(environment.lat), lng: Number(environment.lng) },
      zoom: Number(environment.zoom),
      streetViewControl: false,
    };

    const sensorGroupNodes: SensorGroupNode[] = [];
    this.flattenTreeToSensorGroupNodeArray(this.treeRoot, sensorGroupNodes);

    for (const sensorGroupNode of sensorGroupNodes) {
      if (
        sensorGroupNode.geoAreaPolygon &&
        sensorGroupNode.geoAreaPolygon.path
      ) {
        this.polygons.push(sensorGroupNode.geoAreaPolygon);
      }
    }

    const sensors: Sensor[] = [];
    this.flattenTreeToSensorArray(this.treeRoot, sensors);

    for (const sensor of sensors) {
      if (
        sensor.coordinate &&
        sensor.coordinate.lat !== null &&
        sensor.coordinate.lat !== undefined &&
        sensor.coordinate.lng !== null &&
        sensor.coordinate.lng !== undefined
      ) {
        this.markers.push({
          markerPosition: {
            lat: sensor.coordinate.lat,
            lng: sensor.coordinate.lng,
          },
          markerOptions: {
            title: sensor.displayName,
            icon: { url: this.buildMarkerIconUrl(sensor) },
            clickable: true,
          },
          sensor,
        });
      }
    }
  }

  ngAfterViewInit() {
    if (
      this.treeRoot?.geoAreaPolygon &&
      this.treeRoot?.geoAreaPolygon.path &&
      this.treeRoot?.geoAreaPolygon.path.length > 1
    ) {
      const bounds = GoogleMapsService.polygonPathToBounds(
        this.treeRoot?.geoAreaPolygon.path
      );
      GoogleMapsService.centerAndZoomMap(this.googleMap, bounds);
    } else if (
      this.treeRoot?.geoAreaPolygon &&
      this.treeRoot?.geoAreaPolygon.path &&
      this.treeRoot?.geoAreaPolygon.path.length === 1
    ) {
      GoogleMapsService.centerAndZoomMap(
        this.googleMap,
        null,
        {lng: this.treeRoot?.geoAreaPolygon.path[0].lng, lat: this.treeRoot?.geoAreaPolygon.path[0].lat},
        12
      );
    }
  }

  flattenTreeToSensorGroupNodeArray(
    sensorGroupNode: SensorGroupNode,
    sensorGroupNodes: SensorGroupNode[]
  ) {
    sensorGroupNodes.push(sensorGroupNode);
    if (Array.isArray(sensorGroupNode.children)) {
      sensorGroupNode.children.forEach((child) =>
        this.flattenTreeToSensorGroupNodeArray(child, sensorGroupNodes)
      );
    }
  }

  flattenTreeToSensorArray(
    sensorGroupNode: SensorGroupNode,
    sensors: Sensor[]
  ) {
    if (Array.isArray(sensorGroupNode.sensors)) {
      sensors.push(...sensorGroupNode.sensors);
    }
    if (Array.isArray(sensorGroupNode.children)) {
      sensorGroupNode.children.forEach((child) =>
        this.flattenTreeToSensorArray(child, sensors)
      );
    }
  }

  countSensorGroupNodes(sensorGroupNode: SensorGroupNode) {
    if (!sensorGroupNode) {
      return 0;
    }

    let sensorGroupCount = 0;
    for (const node of sensorGroupNode.children) {
      const count = this.countSensorGroupNodes(node);
      sensorGroupCount = sensorGroupCount + count;
    }

    return sensorGroupCount + sensorGroupNode.children.length;
  }

  countSensor(sensorNode: SensorGroupNode) {
    if (!sensorNode) {
      return 0;
    }

    let sensorCount = 0;
    for (const node of sensorNode.children) {
      const count = this.countSensor(node);
      sensorCount = sensorCount + count;
    }

    return sensorCount + sensorNode.sensors.length;
  }

  countWarnings(sensorNode: SensorGroupNode) {
    if (!sensorNode) {
      return 0;
    }

    let alarmCount = 0;
    for (const node of sensorNode.children) {
      const count = this.countWarnings(node);
      alarmCount = alarmCount + count;
    }

    alarmCount =
      alarmCount +
      this.countAlarmFromSensorNode(sensorNode, SensorEventType.warning);

    return alarmCount;
  }

  countAlarm(sensorNode: SensorGroupNode) {
    if (!sensorNode) {
      return 0;
    }

    let alarmCount = 0;
    for (const node of sensorNode.children) {
      const count = this.countAlarm(node);
      alarmCount = alarmCount + count;
    }

    alarmCount =
      alarmCount +
      this.countAlarmFromSensorNode(sensorNode, SensorEventType.error);

    return alarmCount;
  }

  countAlarmFromSensorNode(
    sensorNode: SensorGroupNode,
    alarmType: SensorEventType
  ) {
    let count = 0;
    for (const sensor of sensorNode.sensors) {
      count += this.countForSensor(sensor, alarmType);
    }

    return count;
  }

  private countForSensor(sensor: Sensor, alarmType: SensorEventType): number {
    let alarmCount = 0;
    for (const event of sensor.notifications) {
      if (event.type === alarmType) {
        alarmCount = alarmCount + 1;
      }
    }
    return alarmCount;
  }

  showSensorInTree(sensor: Sensor) {
    this.unselectAll(this.treeRoot);
    const toOpen = this.positioningService.findParentOfSensorRecursive(
      sensor,
      this.treeRoot
    );
    this.open(toOpen);
  }

  open(sensorGroupNodeView: SensorGroupNodeView) {
    sensorGroupNodeView.selected = true;
    const parent = this.positioningService.findParentOfGroupRecursive(
      sensorGroupNodeView,
      this.treeRoot
    );
    if (parent) {
      this.open(parent);
    }
  }

  unselectAll(sensorGroupNodeView: SensorGroupNodeView) {
    sensorGroupNodeView.selected = false;
    sensorGroupNodeView.children.forEach((child) => this.unselectAll(child));
  }

  buildPolygonOptionsForColor(color: string) {
    return GoogleMapsService.buildPolygonOptions(false, false, color);
  }

  private buildMarkerIconUrl(sensor: Sensor): string {
    const alarmCount = this.countForSensor(sensor, SensorEventType.error);
    const warningCount = this.countForSensor(sensor, SensorEventType.warning);

    if (alarmCount > 0) {
      return this.googleMapsService.generateMarkerIcon(
        sensor.sensorState,
        'exclamation_mark'
      );
    } else if (warningCount > 0) {
      return this.googleMapsService.generateMarkerIcon(
        sensor.sensorState,
        'info'
      );
    } else {
      return this.googleMapsService.generateMarkerIcon(
        sensor.sensorState,
        'point'
      );
    }
  }
}
