import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganizationListModule } from 'src/app/public-utility/organization/components/organization-list/organization-list.module';
import { OrganizationMacListModule } from 'src/app/public-utility/organization/components/organization-mac-list/organization-mac-list.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, OrganizationListModule, OrganizationMacListModule],
  exports: [OrganizationListModule, OrganizationMacListModule],
})
export class PublicUtilityOrganizationModule {}
