import { Component, OnDestroy, OnInit } from '@angular/core';
import { Notification } from 'src/app/organization/dashboard/models/notification';
import { NotificationService } from 'src/app/organization/dashboard/services/notification.service';
import { DisposeBag } from '@ronas-it/dispose-bag/dist/src';

@Component({
  selector: 'app-current-events',
  templateUrl: './dashboard-current-events.component.html',
  styleUrls: ['./dashboard-current-events.component.scss'],
})
export class DashboardCurrentEventsComponent implements OnInit, OnDestroy {
  notificationPage = 0;
  notifications: Array<Notification> = [];
  loadingInProcess = false;
  disposebag = new DisposeBag();

  constructor(public notificationService: NotificationService) {}

  ngOnInit(): void {
    this.initNotificationObservable();
  }

  ngOnDestroy() {
    this.disposebag.unsubscribe();
  }

  initNotificationObservable() {
    this.disposebag.add(
      this.notificationService.getNotificationsObservable(25).subscribe(
        (newNotifications) => {
          for (const notification of newNotifications) {
            if (
              !this.notifications
                .map((item) => item.id)
                .includes(notification.id)
            ) {
              this.notifications.push(notification);
            }
          }
          this.notifications.sort(
            (a, b) => b.date.toDate().getTime() - a.date.toDate().getTime()
          );
        },
        (error) => {
          if (error instanceof CloseEvent && error.code !== 4403) {
            this.initNotificationObservable();
          }
          console.log(error);
        }
      )
    );
  }

  loadNextPage() {
    this.notificationPage++;
    this.loadingInProcess = true;
    this.notificationService
      .getNotifications(this.notificationPage)
      .then((result) => {
        for (const notification of result) {
          if (
            !this.notifications.map((item) => item.id).includes(notification.id)
          ) {
            this.notifications.push(notification);
          }
        }
        this.notifications.sort(
          (a, b) => b.date.toDate().getTime() - a.date.toDate().getTime()
        );
        this.loadingInProcess = false;
      });
  }
}
