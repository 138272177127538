import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { NavigationMenuListItemComponent } from 'src/app/navigation/components/menu-list-item/navigation-menu-list-item.component';

@NgModule({
  declarations: [NavigationMenuListItemComponent],
  exports: [NavigationMenuListItemComponent],
  imports: [CommonModule, MatListModule, FlexModule, MatIconModule],
})
export class NavigationMenuListItemModule {}
