import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SensorTypeConfig } from 'src/app/_shared/models/sensor-type-config';
import {
  sensorConfigTechnicalNameAsArray,
  sensorConfigTechnicalNameToLabel,
} from 'src/app/_shared/models/sensor-config-technical-name';
import {
  measurementUnitsAsArray,
  measurementUnitToLabel,
} from 'src/app/_shared/models/measurement-units';
import {
  measurementUnitsSymbolAsArray,
  measurementUnitSymbolToLabel,
} from 'src/app/_shared/models/measurement-unit-symbols';

import {
  MeasurementValueTypes,
  measurementValueTypesAsArray,
  measurementValueTypeToLabel,
} from 'src/app/_shared/models/measurement-value-types';
import { MatTableDataSource } from '@angular/material/table';
import { SensorTypeConfigInput } from 'src/app/public-utility/sensor-types/dto/sensor-type-config-input.dto';
import { SensorTypesService } from 'src/app/public-utility/sensor-types/services/sensor-types.service';
import { DisposeBag } from '@ronas-it/dispose-bag/dist/src';

@Component({
  selector: 'app-sensor-type-config-edit',
  templateUrl: './sensor-type-config-edit.component.html',
  styleUrls: ['./sensor-type-config-edit.component.scss'],
})
export class SensorTypeConfigEditComponent implements OnInit, OnDestroy {
  form: FormGroup;
  dataSource = new MatTableDataSource();
  displayedColumns = [
    'warning',
    'displayName',
    'technicalName',
    'propertyName',
    'propertyType',
    'unit',
    'symbol',
    'optional',
    'onDisplayName',
    'offDisplayName',
    'removeButton',
  ];
  disposeBag = new DisposeBag();

  constructor(
    private route: ActivatedRoute,
    private sensorTypesService: SensorTypesService,
    private router: Router
  ) {}

  ngOnDestroy() {
    this.disposeBag.unsubscribe();
  }

  ngOnInit(): void {
    const sensorType: SensorTypeConfig = this.route.snapshot.data.sensorType;

    const sensorPropertyConfigFormGroups =
      sensorType.sensorPropertyConfigs?.map((sensorPropertyConfig) => {
        return new FormGroup({
          id: new FormControl(sensorPropertyConfig.id),
          warning: new FormControl(false),
          warningMessage: new FormControl(''),
          displayName: new FormControl(sensorPropertyConfig.displayName, [
            Validators.required,
            Validators.maxLength(255),
          ]),
          technicalName: new FormControl(sensorPropertyConfig.technicalName, [
            Validators.required,
          ]),
          propertyName: new FormControl(sensorPropertyConfig.propertyName, [
            Validators.maxLength(255),
          ]),
          propertyType: new FormControl(sensorPropertyConfig.propertyType, [
            Validators.required,
          ]),
          unit: new FormControl(sensorPropertyConfig.unit, [
            Validators.required,
          ]),
          symbol: new FormControl(sensorPropertyConfig.symbol),
          optional: new FormControl(sensorPropertyConfig.optional, [
            Validators.required,
          ]),
          onDisplayName: new FormControl(sensorPropertyConfig.onDisplayName, [
            Validators.maxLength(255),
          ]),
          offDisplayName: new FormControl(sensorPropertyConfig.offDisplayName, [
            Validators.maxLength(255),
          ]),
        });
      }) || [];

    sensorPropertyConfigFormGroups.forEach((item) => this.validateRow(item));
    sensorPropertyConfigFormGroups.forEach((item) => {
      this.disposeBag.add(
        item.valueChanges.subscribe((_) => this.validateRow(item))
      );
    });

    this.form = new FormGroup({
      name: new FormControl(sensorType.displayName, [
        Validators.required,
        Validators.maxLength(255),
      ]),
      niotaDeviceTypeId: new FormControl(sensorType.niotaDeviceTypeId),
      sensorPropertyConfigs: new FormArray(sensorPropertyConfigFormGroups),
    });
    this.form.controls.niotaDeviceTypeId.disable();

    this.dataSource.data = (this.form.get(
      'sensorPropertyConfigs'
    ) as FormArray).controls;
  }

  save() {
    if (this.form.valid) {
      const dto = {
        displayName: this.form.value.name,
        description: this.form.value.description,
        sensorPropertyConfigs: this.form.value.sensorPropertyConfigs,
      } as SensorTypeConfigInput;

      const id = this.route.snapshot.data.sensorType.id;
      this.sensorTypesService.update(dto, id).then((_) => {
        this.router.navigateByUrl('/public-utility/sensor-types');
      });
    }
  }

  validateRow(rowData) {
    if (
      !rowData.value.technicalName ||
      !rowData.value.propertyType ||
      !rowData.value.unit
    ) {
      rowData.patchValue(
        {
          warning: true,
          warningMessage:
            'Name, Eigenschaft und Einheit müssten Angegeben werden!',
        },
        { emitEvent: false, onlySelf: true }
      );
      return;
    } else if (
      (rowData.value.displayName && rowData.value.displayName.length > 255) ||
      (rowData.value.propertyName && rowData.value.propertyName.length > 255) ||
      (rowData.value.onDisplayName &&
        rowData.value.onDisplayName.length > 255) ||
      (rowData.value.offDisplayName &&
        rowData.value.offDisplayName.length > 255)
    ) {
      rowData.patchValue(
        {
          warning: true,
          warningMessage:
            'Name, Eigenschaft, Titel für "true" und Titel für "false" dürfen maximal 255 Zeichen beinhalten',
        },
        { emitEvent: false, onlySelf: true }
      );
      return;
    }

    rowData.patchValue(
      {
        warning: false,
        warningMessage: undefined,
      },
      { emitEvent: false, onlySelf: true }
    );
  }

  technicalNameToLabel(technicalName: string) {
    return sensorConfigTechnicalNameToLabel(technicalName);
  }

  unitToLabel(unit: string) {
    return measurementUnitToLabel(unit);
  }

  unitOptions() {
    return measurementUnitsAsArray();
  }

  unitSymbolToLabel(unit: string) {
    return measurementUnitSymbolToLabel(unit);
  }

  unitSymbolOptions() {
    return measurementUnitsSymbolAsArray();
  }

  propertyTypeToLabel(propertyType: string) {
    return measurementValueTypeToLabel(propertyType);
  }

  propertyTypeOptions() {
    return measurementValueTypesAsArray();
  }

  technicalNameOptions() {
    return sensorConfigTechnicalNameAsArray();
  }

  addPropertyConfigRow() {
    const sensorPropertyConfigControls = this.form.controls
      .sensorPropertyConfigs as FormArray;

    const newFormGroup = new FormGroup({
      id: new FormControl(),
      warning: new FormControl(true),
      displayName: new FormControl(undefined, [Validators.maxLength(255)]),
      technicalName: new FormControl(null, [
        Validators.required,
        Validators.maxLength(255),
      ]),
      propertyName: new FormControl(),
      propertyType: new FormControl(null, [Validators.required]),
      unit: new FormControl(null, [Validators.required]),
      symbol: new FormControl(),
      onDisplayName: new FormControl(undefined, [Validators.maxLength(255)]),
      offDisplayName: new FormControl(undefined, [Validators.maxLength(255)]),
    }) as FormGroup;

    sensorPropertyConfigControls.push(newFormGroup);
    this.dataSource.data = (this.form.get(
      'sensorPropertyConfigs'
    ) as FormArray).controls;
  }

  removeSensorPropertyConfig(index: number) {
    const sensorPropertyConfigControls = this.form.controls
      .sensorPropertyConfigs as FormArray;
    sensorPropertyConfigControls.removeAt(index);
    this.dataSource.data = (this.form.get(
      'sensorPropertyConfigs'
    ) as FormArray).controls;
  }

  isBooleanRow(element: FormGroup) {
    return element.value.propertyType === MeasurementValueTypes.BOOLEAN;
  }

  anyBooleanRow(): boolean {
    const sensorPropertyConfigControls = this.form.controls
      .sensorPropertyConfigs as FormArray;
    return sensorPropertyConfigControls.value.reduce(
      (a, b) => b.propertyType === MeasurementValueTypes.BOOLEAN || a,
      false
    );
  }
}
