import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { FlexModule } from '@angular/flex-layout';
import { UsernameConfirmationHintComponent } from 'src/app/users/components/username-confirmation-hint/username-confirmation-hint.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [UsernameConfirmationHintComponent],
  exports: [UsernameConfirmationHintComponent],
  imports: [CommonModule, FlexModule, MatCardModule, MatIconModule],
})
export class UsernameConfirmationHintModule {}
