import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Sensor } from 'src/app/_shared/models/sensor';
import { SensorService } from 'src/app/_shared/services/sensor.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationConfigService } from 'src/app/organization/notification-configuration/services/notification-config.service';
import { NotificationConfig } from 'src/app/organization/notification-configuration/models/notification-config';
import { Router } from '@angular/router';
import { PositioningService } from 'src/app/organization/positioning/services/positioning.service';
import { EditSensorModalComponent } from 'src/app/organization/positioning/components/edit-sensor-modal/edit-sensor-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/_shared/services/snack-bar.service';
import { MeasurementsService } from 'src/app/_shared/services/measurements.service';
import { MeasurementAggregationInputDto } from 'src/app/_shared/dtos/measurements/measurement-aggregation-input.dto';
import * as moment from 'moment';
import { MeasurementIntervalOutputDto } from 'src/app/_shared/dtos/measurements/measurement-interval-output.dto';
import * as chartJs from 'chart.js';
import { Notification } from 'src/app/organization/dashboard/models/notification';
import { NotificationService } from 'src/app/organization/dashboard/services/notification.service';
import { DateTimeModalComponent } from 'src/app/_shared/components/date-time-modal/date-time-modal.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SensorPairingShareService } from 'src/app/organization/positioning/services/sensor-pairing-share.service';
import { ShareSensorModalComponent } from 'src/app/organization/positioning/components/share-sensor-modal/share-sensor-modal.component';
import { SensorPairingShareOutputDto } from 'src/app/organization/positioning/dtos/sensor-pairing-share-output.dto';

@Component({
  selector: 'app-positioning-sensor-detail-card',
  templateUrl: './positioning-sensor-detail-card.component.html',
  styleUrls: ['./positioning-sensor-detail-card.component.scss'],
})
export class PositioningSensorDetailCardComponent implements OnInit, OnChanges {
  @Input() sensor: Sensor;
  image: any;
  measurements: MeasurementIntervalOutputDto[] = [];
  notifications: Notification[] = [];
  measurementAggregationInputDto: MeasurementAggregationInputDto = {
    from: moment().subtract(1, 'days').toDate(),
    to: new Date(),
    aggregationType: 'minutes',
    range: 5,
  };
  loading = true;
  selectedTabIndex = 0;
  sensorPairingShares: SensorPairingShareOutputDto[];

  constructor(
    private sensorService: SensorService,
    private notificationConfigService: NotificationConfigService,
    private domSanitizer: DomSanitizer,
    private positioningService: PositioningService,
    private router: Router,
    private snackBarService: SnackBarService,
    private dialog: MatDialog,
    private measurementsService: MeasurementsService,
    private notificationService: NotificationService,
    private sensorPairingShareService: SensorPairingShareService
  ) {}

  ngOnInit(): void {
    this.initData();
    chartJs.Chart.defaults.global.defaultFontColor = getComputedStyle(
      document.body
    ).getPropertyValue('--light-text-1');
  }

  private initData() {
    this.sensorService.loadImage(this.sensor.id).then((result) => {
      if (result && result.image) {
        this.image = this.domSanitizer.bypassSecurityTrustUrl(result.image);
      }
    });

    const promises = [];
    promises.push(this.updateMeasurements());
    promises.push(this.loadShares());

    Promise.all(promises).finally(() => (this.loading = false));
  }

  private updateMeasurements(): Promise<void> {
    return this.measurementsService
      .loadMeasurements(this.sensor.id, this.measurementAggregationInputDto)
      .then((res) => (this.measurements = res));
  }

  private loadShares(): Promise<void> {
    return this.sensorPairingShareService
      .loadSensorShares(this.sensor.id)
      .then((res) => {
        this.sensorPairingShares = res;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    /**
     * Sensor can be changed with @Input Decorator. If it does, this component must be reset to not display Data of the Sensor which
     * was selected before.
     */
    this.notifications = [];
    this.measurements = [];
    this.image = undefined;
    this.measurementAggregationInputDto = {
      from: moment().subtract(1, 'days').toDate(),
      to: new Date(),
      aggregationType: 'minutes',
      range: 5,
    };
    this.loading = true;
    this.selectedTabIndex = 0;
    this.initData();
  }

  editNotificationConfig(notificationConfig: NotificationConfig) {
    this.positioningService.selectedSensor = undefined;
    this.router.navigateByUrl(
      '/organization/alarm-configuration/edit/' + notificationConfig.id
    );
  }

  editSensor() {
    const parentSensorGroupNode = this.positioningService.findParentOfSensorRecursive(
      this.sensor,
      this.positioningService.treeRoot
    );
    this.dialog
      .open(EditSensorModalComponent, {
        width: '620px',
        data: { sensorGroupNode: parentSensorGroupNode, sensor: this.sensor },
      })
      .afterClosed()
      .subscribe((result: Sensor) => {
        if (!result) {
          return;
        }
        parentSensorGroupNode.sensors[
          parentSensorGroupNode.sensors.indexOf(this.sensor)
        ] = result;
        this.positioningService.selectSensor(result);
        this.positioningService.selectionChangedSubject.next(result);

        this.snackBarService.saveSuccessful();
      });
  }

  changeMeasurementAggregationFrom() {
    this.dialog
      .open(DateTimeModalComponent, {
        data: { date: this.measurementAggregationInputDto.from },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.measurements = [];
          this.measurementAggregationInputDto.from = result;
          this.loading = true;
          this.updateMeasurements().finally(() => (this.loading = false));
        }
      });
  }

  changeMeasurementAggregationTo() {
    this.dialog
      .open(DateTimeModalComponent, {
        data: { date: this.measurementAggregationInputDto.to },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.measurements = [];
          this.measurementAggregationInputDto.to = result;
          this.loading = true;
          this.updateMeasurements().finally(() => (this.loading = false));
        }
      });
  }

  tabChanged(event: MatTabChangeEvent) {
    switch (event.index) {
      case 0:
        this.loading = true;
        this.updateMeasurements().finally(() => (this.loading = false));
        break;
      case 1:
        this.loading = true;
        this.notificationService
          .getNotifications(0, this.sensor.id)
          .then((res) => (this.notifications = res))
          .finally(() => (this.loading = false));
        break;
    }
  }

  openCreateShareModal() {
    this.dialog
      .open(ShareSensorModalComponent, {
        data: { sensor: this.sensor },
      })
      .afterClosed()
      .toPromise()
      .then(() => this.loadShares());
  }

  openSharedSensorView(sensorPairingShare: SensorPairingShareOutputDto) {
    window.open(sensorPairingShare.frontendUrl, '_blank').focus();
  }

  copyFrontendUrlIntoClipboard(
    sensorPairingShare: SensorPairingShareOutputDto
  ) {
    navigator.clipboard
      .writeText(sensorPairingShare.frontendUrl)
      .then((it) => {
        this.snackBarService.showCustomMessage(
          'URL in die Zwischenablage kopiert.'
        );
      })
      .catch((it) => {
        this.snackBarService.showCustomMessage(
          'URL konnte nicht in die Zwischenablage kopiert werden.'
        );
      });
  }

  openEdit(sensorPairingShare: SensorPairingShareOutputDto) {
    this.dialog
      .open(ShareSensorModalComponent, {
        data: { sensorPairingShare, sensor: this.sensor },
      })
      .afterClosed()
      .toPromise()
      .then(() => this.loadShares());
  }

  delete(sensorPairingShare: SensorPairingShareOutputDto) {
    this.sensorPairingShareService
      .delete(sensorPairingShare.id)
      .then((it) => {
        this.snackBarService.deleteSuccessful();
      })
      .then(() => this.loadShares());
  }
}
