<div class="menu-list-item" [class.active]="isActive()">
  <div class="container" fxLayout="row">
    <div fxFlex="85%" fxLayout="column">
      <h2 #organizationNameElement>{{ organizationName }}</h2>
      <span style="rgba(255, 255, 255, 0.6);" #userNameElement>{{
        user?.username
      }}</span>
    </div>
    <div fxFlex="15%" fxLayoutAlign="end end">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>settings</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="/user-settings">Profil</button>
        <button
          mat-menu-item
          routerLink="/organization/settings"
          *ngIf="isOrgaOwner"
        >
          Organisationseinstellungen
        </button>
        <button
          mat-menu-item
          routerLink="/public-utility/settings"
          *ngIf="isCitylinkOwner"
        >
          Citylinkeinstellungen
        </button>
        <button
          mat-menu-item
          routerLink="/organization/mail-template"
          *ngIf="isOrgaOwner"
        >
          E-Mail Vorlagen
        </button>
        <button
          mat-menu-item
          routerLink="/public-utility/mail-template"
          *ngIf="isCitylinkOwner"
        >
          E-Mail Vorlagen
        </button>
        <button
          mat-menu-item
          routerLink="/public-utility/legal-settings"
          *ngIf="isCitylinkOwner"
        >
          Datenschutz, Impressum und AGBs
        </button>
        <button
          mat-menu-item
          routerLink="/organization/info-text"
          *ngIf="isOrgaOwner"
        >
          Hinweistexte (Alarmkonfigurationen)
        </button>
        <button
          mat-menu-item
          routerLink="/public-utility/global-push"
          *ngIf="isCitylinkEditor || isCitylinkOwner"
        >
          Globale Push-Nachricht
        </button>
      </mat-menu>
    </div>
  </div>
</div>
