<form [formGroup]="form" (ngSubmit)="close()">
  <h2 mat-dialog-title>
    Sensorfreigabe {{ isCreate ? "erstellen" : "bearbeiten" }}
  </h2>
  <div mat-dialog-content fxLayout="row wrap" fxLayoutGap="24px grid">
    <div fxFlex="100">
      <mat-form-field appearance="outline">
        <mat-label>Sensor</mat-label>
        <mat-select formControlName="sensorId" disabled>
          <mat-option [value]="data.sensor.id">
            {{ data.sensor.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="100">
      <mat-form-field appearance="outline">
        <mat-label>Ablaufdatum</mat-label>
        <input
          matInput
          [matDatepicker]="expirationDate"
          formControlName="expirationDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="expirationDate"
        ></mat-datepicker-toggle>
        <mat-datepicker #expirationDate></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Abbrechen</button>
    <button mat-button (click)="saveAndClose()">Speichern</button>
  </div>
</form>
