<form [formGroup]="form" (ngSubmit)="save()">
  <div fxLayout="column" class="app-area">
    <!-- HEADER ROW -->
    <div fxLayout="row">
      <div fxFlex fxFlexAlign="start start">
        <h1>Organisationseinstellungen</h1>
      </div>
      <div fxFlex style="text-align: right">
        <button mat-button fxFlexAlign="end end" type="submit">
          <mat-icon>save</mat-icon>
          Speichern
        </button>
      </div>
    </div>

    <!-- Content -->
    <mat-card class="square-card mat-elevation-z0">
      <!-- Sensor Notification -->
      <div
        fxLayout="row wrap"
        fxLayoutAlign="start start"
        style="width: 100%"
        *ngIf="isOrganizationUser()"
      >
        <div fxFlex="100"><h2>Sensor Benachrichtigung</h2></div>
        <div fxFlex="100">
          <h4>Verfügbare Platzhalter:</h4>
        </div>
        <div fxFlex="100">
          <div class="placeholder-button-wrapper">
            <button
              mat-raised-button
              color="primary"
              type="button"
              (mousedown)="$event.preventDefault()"
              (click)="
                sensorNotificationPlaceholderSubject.next('{{sensorName}\}')
              "
            >
              Sensorname
            </button>
          </div>
          <div class="placeholder-button-wrapper">
            <button
              mat-raised-button
              color="primary"
              type="button"
              (mousedown)="$event.preventDefault()"
              (click)="
                sensorNotificationPlaceholderSubject.next(
                  '{{notificationType}\}'
                )
              "
            >
              Nachrichtentyp
            </button>
          </div>
          <div class="placeholder-button-wrapper">
            <button
              mat-raised-button
              color="primary"
              type="button"
              (mousedown)="$event.preventDefault()"
              (click)="sensorNotificationPlaceholderSubject.next('{{title}\}')"
            >
              Titel
            </button>
          </div>
          <div class="placeholder-button-wrapper">
            <button
              mat-raised-button
              color="primary"
              type="button"
              (mousedown)="$event.preventDefault()"
              (click)="
                sensorNotificationPlaceholderSubject.next('{{subtitle}\}')
              "
            >
              Untertitel
            </button>
          </div>
          <div class="placeholder-button-wrapper">
            <button
              mat-raised-button
              color="primary"
              type="button"
              (mousedown)="$event.preventDefault()"
              (click)="sensorNotificationPlaceholderSubject.next('{{body}\}')"
            >
              Text
            </button>
          </div>
          <div class="placeholder-button-wrapper">
            <button
              mat-raised-button
              color="primary"
              type="button"
              (mousedown)="$event.preventDefault()"
              (click)="
                sensorNotificationPlaceholderSubject.next(
                  '{{notificationMute}\}'
                )
              "
            >
              Link: Stummschalten
            </button>
          </div>
        </div>
        <div fxFlex="100">
          <mat-form-field appearance="outline">
            <mat-label>Betreff</mat-label>
            <input
              [appAddPlaceholder]="sensorNotificationPlaceholderSubject"
              matInput
              placeholder="Titel"
              formControlName="sensorNotificationSubject"
            />
            <mat-error
              *ngIf="
                form.controls.sensorNotificationSubject.hasError('maxlength')
              "
            >
              Maximal 255 Zeichen!
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="100">
          <editor
            formControlName="sensorNotification"
            [init]="tinyMceOptions"
            #sensorNotificationEditor
          ></editor>
        </div>
      </div>

      <!-- Sensor Notification -->
      <div
        fxLayout="row wrap"
        fxLayoutAlign="start start"
        style="width: 100%; margin-top: 24px"
      >
        <div fxFlex="100"><h2>Nutzer Einladung</h2></div>
        <div fxFlex="100">
          <h4>Verfügbare Platzhalter:</h4>
        </div>
        <div fxFlex="100">
          <div class="placeholder-button-wrapper">
            <button
              mat-raised-button
              color="primary"
              type="button"
              (mousedown)="$event.preventDefault()"
              (click)="userInvitePlaceholderSubject.next('{{invitationLink}\}')"
            >
              Einladungslink
            </button>
          </div>
          <div class="placeholder-button-wrapper">
            <button
              mat-raised-button
              color="primary"
              type="button"
              (mousedown)="$event.preventDefault()"
              (click)="userInvitePlaceholderSubject.next('{{firstName}\}')"
            >
              Vorname
            </button>
          </div>
          <div class="placeholder-button-wrapper">
            <button
              mat-raised-button
              color="primary"
              type="button"
              (mousedown)="$event.preventDefault()"
              (click)="userInvitePlaceholderSubject.next('{{lastName}\}')"
            >
              Nachname
            </button>
          </div>
        </div>
        <div fxFlex="100">
          <mat-form-field appearance="outline">
            <mat-label>Betreff</mat-label>
            <input
              [appAddPlaceholder]="userInvitePlaceholderSubject"
              matInput
              placeholder="Titel"
              formControlName="userInviteSubject"
            />
            <mat-error
              *ngIf="form.controls.userInviteSubject.hasError('maxlength')"
            >
              Maximal 255 Zeichen!
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="100">
          <editor
            formControlName="userInvite"
            [init]="tinyMceOptions"
            #userInviteEditor
          ></editor>
        </div>
      </div>

      <!-- Password Reset -->
      <div
        fxLayout="row wrap"
        fxLayoutAlign="start start"
        style="width: 100%; margin-top: 24px"
      >
        <div fxFlex="100">
          <h2>Passwort zurücksetzen</h2>
        </div>
        <div fxFlex="100">
          <h4>Verfügbare Platzhalter:</h4>
        </div>
        <div fxFlex="100">
          <div class="placeholder-button-wrapper">
            <button
              mat-raised-button
              color="primary"
              type="button"
              (mousedown)="$event.preventDefault()"
              (click)="
                passwordResetPlaceholderSubject.next('{{passwordReset}\}')
              "
            >
              Link: Passwort zurücksetzen
            </button>
          </div>
          <div class="placeholder-button-wrapper">
            <button
              mat-raised-button
              color="primary"
              type="button"
              (mousedown)="$event.preventDefault()"
              (click)="passwordResetPlaceholderSubject.next('{{firstName}\}')"
            >
              Vorname
            </button>
          </div>
          <div class="placeholder-button-wrapper">
            <button
              mat-raised-button
              color="primary"
              type="button"
              (mousedown)="$event.preventDefault()"
              (click)="passwordResetPlaceholderSubject.next('{{lastName}\}')"
            >
              Nachname
            </button>
          </div>
        </div>
        <div fxFlex="100">
          <mat-form-field appearance="outline">
            <mat-label>Betreff</mat-label>
            <input
              [appAddPlaceholder]="passwordResetPlaceholderSubject"
              matInput
              placeholder="Titel"
              formControlName="passwordResetSubject"
            />
            <mat-error
              *ngIf="form.controls.passwordResetSubject.hasError('maxlength')"
            >
              Maximal 255 Zeichen!
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="100">
          <editor
            formControlName="passwordReset"
            [init]="tinyMceOptions"
            #passwordResetEditor
          ></editor>
        </div>
      </div>
    </mat-card>
  </div>
</form>
