<h2 mat-dialog-title>Fehler</h2>
<mat-dialog-content class="mat-typography">
  <h3>{{ errorMessage.userErrorMessage }}</h3>
  <h4 *ngIf="errorMessage.technicalErrorMessage">Technische Fehlermeldung</h4>
  <span
    *ngIf="errorMessage.technicalErrorMessage"
    style="white-space: pre-line"
    >{{ errorMessage.technicalErrorMessage }}</span
  >
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>OK</button>
</mat-dialog-actions>
