import { Injectable } from '@angular/core';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';
import { environment } from 'src/environments/environment';
import AwaitLock from 'await-lock';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UserSession } from 'src/app/_core/models/user-session';
import { ApiError } from 'src/app/_shared/exception/api.error';
import { ErrorService } from 'src/app/_shared/services/error.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private authenticationService: AuthenticationService;
  url = environment.apiUrl;
  private lock = new AwaitLock();

  constructor(private http: HttpClient, private errorService: ErrorService) {}

  async post<T>(
    endpoint: string,
    body: object,
    reqOpts?: object,
    addAuthorizationToken: boolean = true,
    showError: boolean = true
  ): Promise<T | void> {
    if (addAuthorizationToken) {
      await this.refreshSessionIfNecessary();
      reqOpts = this.mergeReqOpts(reqOpts);
    }

    return this.http
      .post<T>(this.url + endpoint, body, reqOpts)
      .toPromise()
      .then()
      .catch((error: any) => {
        if (showError) {
          this.handleError(error);
        } else {
          throw error;
        }
      });
  }

  async patch<T>(
    endpoint: string,
    body: object,
    reqOpts?: object,
    addAuthorizationToken = true,
    handleErrors = true
  ): Promise<T | void> {
    if (addAuthorizationToken) {
      await this.refreshSessionIfNecessary();
      reqOpts = this.mergeReqOpts(reqOpts);
    }

    return this.http
      .patch<T>(this.url + endpoint, body, reqOpts)
      .toPromise()
      .then()
      .catch((error) => {
        if (handleErrors) {
          this.handleError(error);
        } else {
          throw error;
        }
      });
  }

  async get<T>(
    endpoint: string,
    reqOpts?: any,
    filterOptions?: object,
    handleErrors = true
  ): Promise<T | void> {
    await this.refreshSessionIfNecessary();
    reqOpts = this.mergeReqOpts(reqOpts);
    let urlWithEndpoint = this.url + endpoint;

    if (filterOptions && Object.keys(filterOptions).length > 0) {
      urlWithEndpoint =
        urlWithEndpoint +
        '?' +
        this.convertObjectToUrlQueryParams(filterOptions);
    }

    return this.http
      .get<T>(urlWithEndpoint, reqOpts as object)
      .toPromise()
      .then()
      .catch((error: any) => {
        if (handleErrors) {
          this.handleError(error);
        } else {
          throw error;
        }
      });
  }

  public handleError(error: any | HttpErrorResponse) {
    let errorMessage = 'Something bad happened; please try again later.';
    let apiError: ApiError = null;

    if (error.error instanceof ErrorEvent) {
      errorMessage = `An error occurred:: ${error.error.message}`;
    } else {
      errorMessage = `Backend returned code ${error.status}`;

      const properties = ['message', 'code', 'statusCode', 'error'];

      let isApiError = true;
      const apiErrorDto = error.error;
      properties.forEach((property) => {
        if (apiErrorDto.hasOwnProperty(property)) {
          isApiError = isApiError && true;
        } else {
          isApiError = isApiError && false;
        }
      });

      if (isApiError) {
        apiError = new ApiError(
          apiErrorDto.statusCode,
          apiErrorDto.message,
          apiErrorDto.code,
          apiErrorDto.error
        );
      }

      if (error.error.hasOwnProperty('message')) {
        errorMessage += ` body was: ${JSON.stringify(error.error)}`;
      }
    }
    console.error(errorMessage);

    if (apiError) {
      this.errorService.handleError(apiError);
      throw apiError;
    }
    this.errorService.handleError(error);

    // Return an observable with a user-facing error message.
    throw error;
  }

  private async refreshSessionIfNecessary() {
    await this.lock.acquireAsync();
    await this.authenticationService.refreshSessionIfNecessary();
    this.lock.release();
  }

  private mergeReqOpts(reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {};
    }
    if (!reqOpts.headers) {
      reqOpts.headers = {};
    }

    reqOpts.headers['Content-Type'] = 'application/json';

    if (localStorage.getItem('currentUserSession')) {
      // @ts-ignore
      reqOpts.headers.Authorization =
        'Bearer ' +
        (JSON.parse(localStorage.getItem('currentUserSession')) as UserSession)
          .accessToken;
    }

    return reqOpts;
  }

  setAuthenticationService(authenticationService: AuthenticationService) {
    this.authenticationService = authenticationService;
  }

  async delete<T>(
    endpoint: string,
    reqOpts?: object,
    handleErrors = true
  ): Promise<T | void> {
    await this.refreshSessionIfNecessary();
    reqOpts = this.mergeReqOpts(reqOpts);
    return this.http
      .delete<T>(this.url + endpoint, reqOpts)
      .toPromise()
      .then()
      .catch((error: any) => {
        if (handleErrors) {
          this.handleError(error);
        } else {
          throw error;
        }
      });
  }

  private convertObjectToUrlQueryParams(obj) {
    let str = '';
    for (const key in obj) {
      if (obj[key] !== null && obj[key] !== undefined) {
        if (str !== '') {
          str += '&';
        }
        if (obj[key] instanceof Date) {
          str += key + '=' + obj[key].toISOString();
        } else {
          str += key + '=' + encodeURIComponent(obj[key]);
        }
      }
    }

    return str;
  }
}
