<div fxLayout="column" class="app-area">
  <!-- HEADER ROW -->
  <div fxLayout="row">
    <div fxFlex fxFlexAlign="start start">
      <h1>Dashboard</h1>
    </div>
  </div>

  <div fxLayout="row">
    <div fxFlex="100">
      <app-gateways-map></app-gateways-map>
    </div>
  </div>
  <!-- Content -->
  <div fxLayout="row">
    <div fxFlex="420px" fxLayout="row wrap">
      <app-dashboard-number-card
        [title]="'Sensoren (Gesamt)'"
        [value]="data?.sensors?.toString()"
      ></app-dashboard-number-card>
      <app-dashboard-number-card
        [title]="'Organisationen (Gesamt)'"
        [value]="data?.organizations?.toString()"
      ></app-dashboard-number-card>
      <app-dashboard-number-card
        [title]="'Alarme (Gesamt)'"
        [value]="notificationCount('error')"
      ></app-dashboard-number-card>
      <app-dashboard-number-card
        [title]="'Warnungen (Gesamt)'"
        [value]="notificationCount('warning')"
      ></app-dashboard-number-card>
      <app-dashboard-number-card
        [title]="'SWM Admins'"
        [value]="data?.admins?.toString()"
      ></app-dashboard-number-card>
      <app-dashboard-number-card
        [title]="'Gateways (Gesamt)'"
        [value]="data?.gateways?.toString()"
      ></app-dashboard-number-card>
    </div>
    <div
      style="width: 100%"
      fxFlex="0 1 100%"
      fxFlexAlign="start start"
      fxLayout="column"
    >
      <app-dashboard-organizations-card
        [organizations]="organizations"
      ></app-dashboard-organizations-card>
    </div>
  </div>
</div>
