import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DisposeBag } from '@ronas-it/dispose-bag/dist/src';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';
import { NavItem } from 'src/app/navigation/models/nav-item';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/navigation/services/navigation.service';

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './navigation-menu-list-item.component.html',
  styleUrls: ['./navigation-menu-list-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ]),
  ],
})
export class NavigationMenuListItemComponent implements OnInit, OnDestroy {
  expanded = false;
  visible: boolean;
  private disposeBag = new DisposeBag();
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: NavItem;
  @Input() depth: number;

  constructor(
    private navService: NavigationService,
    public router: Router,
    private authenticationService: AuthenticationService
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit() {
    this.navService.currentUrl.subscribe((url: string) => {
      if (this.item.children && url) {
        this.item.children.forEach((child) => {
          if (url.indexOf(`/${child.route}`) === 0) {
            this.expanded = true;
            this.ariaExpanded = true;
          }
        });
      }
    });

    const currentUserSession = this.authenticationService.getCurrentUserSession();
    if (currentUserSession) {
      // @ts-ignore
      this.visible = this.item.roles.includes(currentUserSession.role);
    }

    this.disposeBag.add(
      this.authenticationService.userSessionChangedSubject
        .asObservable()
        .subscribe((userSession) => {
          if (userSession) {
            // @ts-ignore
            this.visible = this.item.roles.includes(userSession.role);
          }
        })
    );
  }

  ngOnDestroy() {
    this.disposeBag.unsubscribe();
  }

  onItemSelected(item: NavItem) {
    if (!item.children || !item.children.length) {
      this.router.navigate([item.route]);
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }
}
