import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { PositioningGroupCardComponent } from 'src/app/organization/positioning/components/positioning-group-card/positioning-group-card.component';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { PositioningSensorModule } from 'src/app/organization/positioning/components/positioning-sensor/positioning-sensor.module';
import { PositioningGroupModule } from 'src/app/organization/positioning/components/positioning-group/positioning-group.module';
import { EditSensorGroupNodeModalModule } from 'src/app/organization/positioning/components/edit-sensor-group-node-modal/edit-sensor-group-node-modal.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PositioningUserGroupItemModule } from 'src/app/organization/positioning/components/positioning-user-group-item/positioning-user-group-item.module';
import { SelectUserGroupsAndUsersModalModule } from 'src/app/organization/positioning/components/select-user-groups-and-users-modal/select-user-groups-and-users-modal.module';
import { PositioningUserItemModule } from 'src/app/organization/positioning/components/positioning-user-item/positioning-user-item.module';
import { EditSensorModalModule } from 'src/app/organization/positioning/components/edit-sensor-modal/edit-sensor-modal.module';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [PositioningGroupCardComponent],
  exports: [PositioningGroupCardComponent],
  imports: [
    CommonModule,
    FlexModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatTooltipModule,
    PositioningSensorModule,
    PositioningGroupModule,
    EditSensorGroupNodeModalModule,
    SelectUserGroupsAndUsersModalModule,
    PositioningUserGroupItemModule,
    PositioningUserItemModule,
    EditSensorModalModule,
    MatCardModule,
  ],
})
export class PositioningGroupCardModule {}
