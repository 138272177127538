export enum ApiErrorType {
  INTERNAL_SERVER_ERROR,
  JWT_TOKEN_INVALID,
  JWT_TOKEN_EXPIRED,
  JWT_TOKEN_MISSING,
  JWT_REFRESH_TOKEN_INVALID,
  JWT_REFRESH_TOKEN_EXPIRED,
  JWT_REFRESH_TOKEN_MISSING,
  SENSOR_PAIRING_NOT_ALLOWED,
  INVALID_PARAMETER_FORMAT,
  ENTITY_NOT_FOUND,
  ENTITY_ALREADY_DEFINED,
  FORBIDDEN_ACTION,
  REPEATED_INVALID_LOGIN,
  ROLE_NOT_FOUND,
  USER_HAS_NO_ROLE,
  USER_ROLE_INVALID,
  UNKNOWN_MEASUREMENT_TYPE,
  SENSOR_TYPE_CONFIG_NOT_FOUND,
  SENSOR_PROPERTY_CONFIG_NOT_FOUND,
  USER_NOT_FOUND,
  EMAIL_ALREADY_TAKEN,
  ROOT_SENSOR_GROUP_NODE_CANNOT_BE_DELETED,
  SENSOR_GROUP_NODE_NO_PARENT_GIVEN,
  SENSOR_GROUP_NODE_PARENT_NOT_FOUND,
  SENSOR_GROUP_NODE_SELF_AS_SUBLEVEL_NOT_ALLOWED,
  SENSOR_ALREADY_PRESENT_IN_SYSTEM,
  NIOTA_DEVICE_CREATE_ERROR,
  PASSWORD_INCORRECT,
  ICON_COLOR_INVALID,
  WEAK_PASSWORD,
  OPWOCO_SUITE_LEGAL_ERROR,
  NIOTA_DEVICE_SYNCRONISE_ERROR,
}

export class ApiError extends Error {
  statusCode?: number;
  message: string;
  code?: number;
  error?: string;

  constructor(
    statusCode?: number,
    message?: string,
    code?: number,
    error?: string
  ) {
    super(message);
    Object.setPrototypeOf(this, ApiError.prototype);
    this.statusCode = statusCode;
    this.message = message;
    this.code = code;
    this.error = error;
  }
}
