<button
  #button
  mat-icon-button
  type="button"
  [attr.aria-haspopup]="picker ? 'dialog' : null"
  [attr.tabindex]="disabled ? -1 : tabIndex"
  [disabled]="disabled"
  (click)="open($event)"
>
  <mat-icon [style.color]="picker?._selected?.rgba">palette</mat-icon>
</button>
