import { Component, Input, OnInit } from '@angular/core';
import { OrganizationDto } from 'src/app/public-utility/organization/dtos/organization.dto';
import { Router } from '@angular/router';
import { Address } from 'src/app/_shared/models/address';

@Component({
  selector: 'app-dashboard-organizations-card',
  templateUrl: './dashboard-organizations-card.component.html',
  styleUrls: ['./dashboard-organizations-card.component.scss'],
})
export class DashboardOrganizationsCardComponent implements OnInit {
  @Input() organizations: Array<OrganizationDto> = [];

  constructor(private router: Router) {}

  ngOnInit(): void {}

  routeToOrganization(organization: OrganizationDto) {
    this.router.navigateByUrl(
      'public-utility/organizations/edit/' + organization.id
    );
  }

  addressString(address: Address): string | void {
    if (!address) {
      return null;
    }

    let result = '';

    if (address.street && (address.zipCode || address.city)) {
      result += address.street + ', ';
    } else if (address.street) {
      result += address.street;
    }

    if (address.zipCode) {
      result += address.zipCode + ' ';
    }

    if (address.city) {
      result += address.city;
    }

    return result;
  }
}
