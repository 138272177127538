import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-motion-icon',
  template: `
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      [style.height]="size + 'px'"
      [style.width]="size + 'px'"
    >
      <rect
        width="28"
        height="28"
        rx="6"
        [attr.fill]="backgroundColor ? backgroundColor : '#EA5280'"
      />
      <path
        d="M13.5415 9.41648L9.41676 11.2499L9.41676 13.9999M13.5415 9.41648L12.2583 14.9165M13.5415 9.41648C13.9281 9.41648 14.2234 9.46865 14.4582 9.56419M19.5001 13.9999C19.5001 13.9999 17.9611 13.6853 17.2084 13.0832C15.3033 11.5591 15.6153 10.035 14.4582 9.56419M12.2583 14.9165L13.0832 15.551M12.2583 14.9165L10.5473 22.2498M15.8332 21.3332L15.8332 17.6665L13.0832 15.551M13.0832 15.551L14.4582 9.56419"
        [attr.stroke]="'white'"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.375 6.20825H15.375"
        [attr.stroke]="'white'"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [],
})
export class MotionIconComponent implements OnInit {
  @Input() backgroundColor: string;
  @Input() size = 128;

  constructor() {}

  ngOnInit(): void {}
}
