import { Injectable } from '@angular/core';
import { SensorTypeConfig } from 'src/app/_shared/models/sensor-type-config';
import { SensorTypeOutputDto } from 'src/app/_shared/dtos/sensor-type-output.dto';
import { ApiService } from 'src/app/_core/services/api/api.service';
import { SnackBarService } from 'src/app/_shared/services/snack-bar.service';
import { SensorTypeConfigInput } from 'src/app/public-utility/sensor-types/dto/sensor-type-config-input.dto';
import { SensorTypeConfigFilterInputDto } from 'src/app/public-utility/sensor-types/dto/sensor-type-config-filter-input.dto';

@Injectable({
  providedIn: 'root',
})
export class SensorTypesService {
  constructor(
    private apiService: ApiService,
    private snackBarService: SnackBarService
  ) {}

  getSensorTypeConfigs(
    handleErrors = true,
    sensorTypeConfigFilterDto?: SensorTypeConfigFilterInputDto
  ): Promise<SensorTypeConfig[]> {
    return this.apiService
      .get<SensorTypeOutputDto[]>(
        '/sensors/type-configurations',
        undefined,
        sensorTypeConfigFilterDto,
        handleErrors
      )
      .then((result) => {
        if (!result) {
          return [];
        }
        return result.map((item) =>
          SensorTypeConfig.fromSensorTypeOutputDto(item)
        );
      });
  }

  getSensorTypeConfig(id: number) {
    return this.apiService
      .get<SensorTypeOutputDto>('/sensors/type-configurations/' + id)
      .then((result) => {
        if (!result) {
          return;
        }
        return SensorTypeConfig.fromSensorTypeOutputDto(result);
      });
  }

  importSensorTypesFromNiota() {
    return this.apiService
      .post('/sensors/type-configurations/niota-import', {})
      .then((_) => {
        this.snackBarService.showCustomMessage('Import erfolgreich');
      });
  }

  update(body: SensorTypeConfigInput, id: number) {
    return this.apiService
      .patch('/sensors/type-configurations/' + id, body)
      .then((_) => {
        this.snackBarService.saveSuccessful();
      });
  }
}
