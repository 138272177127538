import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { UserGroupService } from 'src/app/users/services/user-group.service';
import { UserGroup } from 'src/app/users/models/user-group';

@Injectable({
  providedIn: 'root',
})
export class UserGroupListResolver implements Resolve<UserGroup[]> {
  constructor(private userGroupService: UserGroupService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<UserGroup[]> {
    return this.userGroupService.getList();
  }
}
