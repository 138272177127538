import { Injectable } from '@angular/core';
import { SnackBarService } from 'src/app/_shared/services/snack-bar.service';
import { ApiService } from 'src/app/_core/services/api/api.service';
import { UserGroupOutputDto } from 'src/app/users/dtos/user-group-output.dto';
import { UserGroup } from 'src/app/users/models/user-group';
import { UserGroupFilterInputDto } from 'src/app/users/dtos/user-group-filter.input.dto';

@Injectable({
  providedIn: 'root',
})
export class UserGroupService {
  constructor(
    private snackBarService: SnackBarService,
    private apiService: ApiService
  ) {}

  getList(
    handleErrors = true,
    userGroupFilter?: UserGroupFilterInputDto
  ): Promise<UserGroup[]> {
    return this.apiService
      .get<UserGroupOutputDto[]>(
        '/users/groups',
        undefined,
        userGroupFilter,
        handleErrors
      )
      .then((apiResult) => {
        if (!apiResult) {
          return [];
        }

        return apiResult.map((item) => UserGroup.fromUserGroupOutputDto(item));
      });
  }

  getDetail(id: number, handleErrors = true): Promise<void | UserGroup> {
    return this.apiService
      .get<UserGroupOutputDto>(
        '/users/groups/' + id,
        undefined,
        undefined,
        handleErrors
      )
      .then((result) => {
        if (result) {
          return UserGroup.fromUserGroupOutputDto(result);
        }
      });
  }

  create(userGroup: UserGroup): Promise<void | UserGroup> {
    return this.apiService
      .post<UserGroupOutputDto>('/users/groups', userGroup.toInputDto())
      .then((result) => {
        this.snackBarService.saveSuccessful();

        if (result) {
          return UserGroup.fromUserGroupOutputDto(result);
        }
      });
  }

  update(id: number, userGroup: UserGroup): Promise<void | UserGroup> {
    return this.apiService
      .patch<UserGroupOutputDto>('/users/groups/' + id, userGroup.toInputDto())
      .then((result) => {
        this.snackBarService.saveSuccessful();

        if (result) {
          return UserGroup.fromUserGroupOutputDto(result);
        }
      });
  }

  delete(id: number): Promise<void | UserGroup> {
    return this.apiService
      .delete<UserGroupOutputDto>('/users/groups/' + id)
      .then((result) => {
        this.snackBarService.deleteSuccessful();

        if (result) {
          return UserGroup.fromUserGroupOutputDto(result);
        }
      });
  }
}
