<h2 mat-dialog-title>Sensoren hinzufügen</h2>
<mat-dialog-content>
  <div
    *ngIf="sensors.length === 0"
    style="margin-top: 30px; margin-bottom: 30px"
  >
    Zu dem angegebenen Sensortyp konnte kein Sensor ermittelt werden.
  </div>
  <mat-form-field appearance="outline" *ngIf="sensors.length !== 0">
    <mat-label>Sensoren</mat-label>
    <mat-select [(ngModel)]="selectedSensor" multiple>
      <mat-option *ngFor="let sensor of this.sensors" [value]="sensor.id">
        {{ sensor.displayName }}
        <small *ngIf="sensor.sensorGroupNode">
          ({{ sensor.sensorGroupNode.displayName }})</small
        >
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Abbrechen</button>
    <button mat-button [matDialogClose]="selectedSensor">Hinzufügen</button>
  </mat-dialog-actions>
</mat-dialog-content>
