import { AddressDto } from 'src/app/_shared/dtos/address.dto';

export class Address {
  street: string;
  city: string;
  zipCode: string;

  static fromAddressDto(dto: AddressDto) {
    const result = new Address();

    result.street = dto.street;
    result.city = dto.city;
    result.zipCode = dto.zipCode;

    return result;
  }

  toAddressDto(): AddressDto {
    return {
      city: this.city,
      street: this.street,
      zipCode: this.zipCode,
    };
  }
}
