import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { SensorService } from 'src/app/_shared/services/sensor.service';
import { CitylinkSensor } from 'src/app/public-utility/sensors/models/citylink-sensor';
import { CitylinkOrganization } from '../../organization/models/citylink-organization';
import { SensorTypeConfig } from 'src/app/_shared/models/sensor-type-config';
import { OrganizationService } from 'src/app/_shared/services/organization.service';
import { SensorTypesService } from '../services/sensor-types.service';
import { ApiService } from 'src/app/_core/services/api/api.service';
import { PaginatedCitylinkSensor } from 'src/app/public-utility/sensors/models/paginated-citylink-sensor';

export interface SensorListResolverResult {
  sensors: PaginatedCitylinkSensor;
  organizations: CitylinkOrganization[];
  sensorTypeConfigs: SensorTypeConfig[];
}
@Injectable({
  providedIn: 'root',
})
export class SensorListResolver
  implements Resolve<SensorListResolverResult | void> {
  constructor(
    private sensorsService: SensorService,
    private organizationsService: OrganizationService,
    private sensorTypesService: SensorTypesService,
    private apiService: ApiService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<SensorListResolverResult | void> {
    const promises = [];
    promises.push(this.sensorsService.getSensors());
    promises.push(this.organizationsService.getList(false));
    promises.push(this.sensorTypesService.getSensorTypeConfigs(false));

    return Promise.all(promises)
      .then((allPromisesResult) => {
        return {
          sensors: allPromisesResult[0],
          organizations: allPromisesResult[1],
          sensorTypeConfigs: allPromisesResult[2],
        } as SensorListResolverResult;
      })
      .catch((err) => {
        this.apiService.handleError(err);
        throw err;
      });
  }
}
