import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { NotificationConfigIconOutputDto } from 'src/app/organization/notification-configuration/dto/notification-config-icon-output.dto';
import { NotificationConfigService } from 'src/app/organization/notification-configuration/services/notification-config.service';
import { SensorTypeConfig } from 'src/app/_shared/models/sensor-type-config';
import { SensorTypesService } from 'src/app/public-utility/sensor-types/services/sensor-types.service';
import { ApiService } from 'src/app/_core/services/api/api.service';
import { InfoTextService } from 'src/app/organization/notification-configuration/services/info-text.service';

interface NotificationConfigurationCreateResolverResult {
  icons: NotificationConfigIconOutputDto[];
  sensorTypeConfigs: SensorTypeConfig[];
}

@Injectable({
  providedIn: 'root',
})
export class NotificationConfigurationCreateResolver
  implements Resolve<void | NotificationConfigurationCreateResolverResult> {
  constructor(
    private notificationConfigService: NotificationConfigService,
    private sensorTypesService: SensorTypesService,
    private infoTextService: InfoTextService,
    private apiService: ApiService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<void | NotificationConfigurationCreateResolverResult> {
    const promises = [];

    promises.push(this.notificationConfigService.getIconList(false));
    promises.push(this.sensorTypesService.getSensorTypeConfigs(false));
    promises.push(this.infoTextService.getInfoTextList());

    return Promise.all(promises)
      .then((allPromisesResult) => {
        return {
          icons: allPromisesResult[0],
          sensorTypeConfigs: allPromisesResult[1],
          infoTexts: allPromisesResult[2],
        } as NotificationConfigurationCreateResolverResult;
      })
      .catch((err) => {
        this.apiService.handleError(err);
        throw err;
      });
  }
}
