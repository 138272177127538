import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  EditMode,
  EditSensorGroupNodeModalComponent,
} from 'src/app/organization/positioning/components/edit-sensor-group-node-modal/edit-sensor-group-node-modal.component';
import {
  PositioningService,
  SensorGroupNodeView,
} from 'src/app/organization/positioning/services/positioning.service';
import { SensorGroupNodeService } from 'src/app/_shared/services/sensor-group-node.service';
import { SelectUserGroupsAndUsersModalComponent } from 'src/app/organization/positioning/components/select-user-groups-and-users-modal/select-user-groups-and-users-modal.component';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';
import { EditSensorModalComponent } from 'src/app/organization/positioning/components/edit-sensor-modal/edit-sensor-modal.component';
import { Sensor } from 'src/app/_shared/models/sensor';
import { SnackBarService } from 'src/app/_shared/services/snack-bar.service';
import { UserGroup } from 'src/app/users/models/user-group';
import { User } from 'src/app/users/models/user';
import { Roles } from 'src/app/_core/models/roles';

@Component({
  selector: 'app-group-card',
  templateUrl: './positioning-group-card.component.html',
  styleUrls: ['./positioning-group-card.component.scss'],
})
export class PositioningGroupCardComponent {
  @ViewChild('tabGroup') tabGroup;
  @Input() sensorGroupNode: SensorGroupNodeView;
  @Input() enabled: boolean;
  @Input() allUserGroups: UserGroup[];
  @Input() allUsers: User[];

  constructor(
    private dialog: MatDialog,
    private positioningService: PositioningService,
    private groupService: SensorGroupNodeService,
    private authenticationService: AuthenticationService,
    private snackBarService: SnackBarService
  ) {}

  openCreateGroupModal() {
    this.dialog
      .open(EditSensorGroupNodeModalComponent, {
        width: '620px',
        data: { parent: this.sensorGroupNode, mode: EditMode.CREATE },
      })
      .afterClosed()
      .toPromise()
      .then((result) => {
        if (!this.sensorGroupNode.children) {
          this.sensorGroupNode.children = [];
        }
        if (result) {
          this.sensorGroupNode.children.push(result);
        }
      });
  }

  onDrop(event: DragEvent) {
    const droppedId = JSON.parse(event.dataTransfer.getData('text'));
    const isLevel = event.dataTransfer.types.includes('is_level');
    const isSensor = event.dataTransfer.types.includes('is_sensor');

    if (isLevel) {
      this.positioningService.groupMovedToEndOfParent(
        droppedId,
        this.sensorGroupNode
      );
    } else if (isSensor) {
      this.positioningService.sensorMovedToEndOfParent(
        droppedId,
        this.sensorGroupNode
      );
    }
  }

  groupTabLabel() {
    const count = this.sensorGroupNode?.children?.length
      ? this.sensorGroupNode.children.length
      : 0;
    return `Untergruppen (${count})`;
  }

  sensorTabLabel() {
    const count = this.sensorGroupNode?.sensors?.length
      ? this.sensorGroupNode.sensors.length
      : 0;
    return `Sensoren (${count})`;
  }

  editRootGroup(group: SensorGroupNodeView) {
    this.groupService.getDetails(group.id).then((result) => {
      this.dialog.open(EditSensorGroupNodeModalComponent, {
        width: '620px',
        data: { mode: EditMode.EDIT, sensorGroupNode: result },
      });
    });
  }

  getRootId() {
    return this.positioningService.treeRoot.id;
  }

  removeUserGroup(userGroupToRemove: UserGroup) {
    this.sensorGroupNode.permissions.userGroupIds = this.sensorGroupNode.permissions.userGroupIds.filter(
      (item) => item !== userGroupToRemove.id
    );
    this.groupService.update(
      this.sensorGroupNode.toSensorGroupNodeInputDto(),
      this.sensorGroupNode.id
    );
  }

  addClicked() {
    if (this.tabGroup.selectedIndex === 0) {
      this.openCreateGroupModal();
    } else if (this.tabGroup.selectedIndex === 1) {
      this.dialog
        .open(EditSensorModalComponent, {
          width: '620px',
          data: { sensorGroupNode: this.sensorGroupNode },
        })
        .afterClosed()
        .subscribe((result: Sensor) => {
          if (!result) {
            return;
          }

          this.sensorGroupNode.sensors.push(result);
          this.snackBarService.saveSuccessful();
        });
    } else if (this.tabGroup.selectedIndex === 2) {
      this.openSelectUserGroupModal();
    }
  }

  private openSelectUserGroupModal() {
    this.dialog.open(SelectUserGroupsAndUsersModalComponent, {
      width: '620px',
      data: { sensorGroupNode: this.sensorGroupNode },
    });
  }

  removeUser(user: User) {
    this.sensorGroupNode.permissions.userIds = this.sensorGroupNode.permissions.userIds.filter(
      (item) => item !== user.id
    );
    this.groupService.update(
      this.sensorGroupNode.toSensorGroupNodeInputDto(),
      this.sensorGroupNode.id
    );
  }

  isNotViewerRole() {
    return (
      Roles.ORGANIZATION_VIEWER !==
      this.authenticationService.getCurrentUserSession().role
    );
  }

  userGroupsOfSensorGroupNode() {
    return this.allUserGroups.filter((userGroup) =>
      this.sensorGroupNode.permissions.userGroupIds.includes(userGroup.id)
    );
  }

  usersOfSensorGroupNode() {
    return this.allUsers.filter((user) =>
      this.sensorGroupNode.permissions.userIds.includes(user.id)
    );
  }
}
