import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Roles } from 'src/app/_core/models/roles';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SensorStateIndicatorConfigurationOutputDto } from 'src/app/organization/sensor-state-indicator-configuration/dto/sensor-state-indicator-configuration-output.dto';
import { SensorStateIndicatorConfigurationService } from 'src/app/organization/sensor-state-indicator-configuration/services/sensor-state-indicator-configuration.service';
import { SensorState } from 'src/app/_shared/models/sensor-state';
import { GoogleMapsService } from 'src/app/_shared/services/google-maps.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Operator, OperatorBase } from 'src/app/_core/models/operator';
import { SensorStateIndicatorConfigFilterInputDto } from 'src/app/organization/sensor-state-indicator-configuration/dto/sensor-state-indicator-config-filter-input.dto';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SensorStateIndicatorConfigurationListResolverResult } from 'src/app/organization/sensor-state-indicator-configuration/resolver/sensor-state-indicator-configuration-list.resolver';
import { SensorTypeConfig } from 'src/app/_shared/models/sensor-type-config';
import { SensorPropertyConfig } from 'src/app/_shared/models/sensor-property-config';

@Component({
  selector: 'app-sensor-state-indicator-configuration-list',
  templateUrl: './sensor-state-indicator-configuration-list.component.html',
  styleUrls: ['./sensor-state-indicator-configuration-list.component.scss'],
})
export class SensorStateIndicatorConfigurationListComponent implements OnInit {
  enabled = false;
  matTableDataSource: MatTableDataSource<SensorStateIndicatorConfigurationOutputDto> = new MatTableDataSource<SensorStateIndicatorConfigurationOutputDto>();
  displayedColumns = [
    'title',
    'sensorTypeConfig',
    'sensorPropertyConfig',
    'green',
    'red',
    'sensorCount',
    'options',
  ];
  sensorStateIndicatorConfigFilter: {
    title?: string;
    sensorTypeConfig?: SensorTypeConfig;
    sensorPropertyConfig?: SensorPropertyConfig;
  } = {};
  filterChangedSubject: Subject<void> = new Subject<void>();
  sensorTypeConfigFilterOptions: SensorTypeConfig[] = [];

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private sensorStateIndicatorConfigurationService: SensorStateIndicatorConfigurationService,
    private googleMapsService: GoogleMapsService,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    const currentUserSession = this.authenticationService.getCurrentUserSession();
    // @ts-ignore
    this.enabled = [
      Roles.ORGANIZATION_OWNER,
      Roles.ORGANIZATION_EDITOR,
    ].includes(currentUserSession?.role);
    const data: SensorStateIndicatorConfigurationListResolverResult = this.route
      .snapshot.data.data;
    this.matTableDataSource.data = data.sensorStateIndicatorConfigs;
    this.sensorTypeConfigFilterOptions = data.sensorTypeConfigs;
    console.log(this.sensorTypeConfigFilterOptions);

    this.filterChangedSubject.pipe(debounceTime(300)).subscribe(() => {
      const dto: SensorStateIndicatorConfigFilterInputDto = {
        title: this.sensorStateIndicatorConfigFilter.title,
        sensorTypeConfigId: this.sensorStateIndicatorConfigFilter
          .sensorTypeConfig?.id,
        sensorPropertyConfigId: this.sensorStateIndicatorConfigFilter
          .sensorPropertyConfig?.id,
      };

      this.sensorStateIndicatorConfigurationService
        .getList(dto)
        .then((results) => {
          this.matTableDataSource.data = results;
        });
    });
  }

  onCreateButtonClicked() {
    this.router.navigateByUrl(
      'organization/sensor-state-indicator-configuration/create'
    );
  }

  openEditSensorStateIndicatorConfig(element) {
    this.router.navigateByUrl(
      `organization/sensor-state-indicator-configuration/edit/${element.id}`
    );
  }

  deleteSensorStateIndicatorConfig(element) {
    this.sensorStateIndicatorConfigurationService
      .delete(element.id)
      .then((_) => {
        this.sensorStateIndicatorConfigurationService.getList().then((res) => {
          this.matTableDataSource.data = res;
        });
      });
  }

  redMapIcon() {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(
      this.googleMapsService.generateMarkerIcon(SensorState.RED, 'point')
    );
  }

  yellowMapIcon() {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(
      this.googleMapsService.generateMarkerIcon(SensorState.YELLOW, 'point')
    );
  }

  greenMapIcon() {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(
      this.googleMapsService.generateMarkerIcon(SensorState.GREEN, 'point')
    );
  }

  oppositeOfSelectedOperatorToLabel(element) {
    if (element.operator !== undefined && element.operator !== null) {
      return Operator.translate(Operator.opposite(element.operator));
    }
    return '';
  }

  getOperatorLabel(type: OperatorBase) {
    return Operator.translate(type);
  }

  sensorTypeToLabel(sensorType: SensorTypeConfig) {
    return `${sensorType.displayName} (${sensorType.niotaDeviceTypeId})`;
  }

  sensorPropertyConfigToLabel(sensorPropertyConfig: SensorPropertyConfig) {
    return `${sensorPropertyConfig.displayName}`;
  }

  onClearFilterClicked() {
    this.sensorStateIndicatorConfigFilter = {};
    this.filterChangedSubject.next();
  }

  isFilter() {
    return (
      this.sensorStateIndicatorConfigFilter.sensorPropertyConfig ||
      this.sensorStateIndicatorConfigFilter.sensorTypeConfig ||
      this.sensorStateIndicatorConfigFilter.title
    );
  }
}
