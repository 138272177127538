import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Sensor } from 'src/app/_shared/models/sensor';
import { SensorPairingShareService } from 'src/app/organization/positioning/services/sensor-pairing-share.service';
import { SnackBarService } from 'src/app/_shared/services/snack-bar.service';
import { SensorPairingShareOutputDto } from 'src/app/organization/positioning/dtos/sensor-pairing-share-output.dto';

@Component({
  selector: 'app-share-sensor-modal',
  templateUrl: './share-sensor-modal.component.html',
  styleUrls: ['./share-sensor-modal.component.scss'],
})
export class ShareSensorModalComponent implements OnInit {
  form: FormGroup;
  isCreate = true;

  constructor(
    private dialogRef: MatDialogRef<ShareSensorModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      sensor: Sensor;
      sensorPairingShare: SensorPairingShareOutputDto;
    },
    private sensorPairingShareService: SensorPairingShareService,
    private snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    if (this.data.sensorPairingShare) {
      this.form = new FormGroup({
        sensorId: new FormControl(this.data.sensor.id, [Validators.required]),
        expirationDate: new FormControl(
          this.data.sensorPairingShare.expirationDate
        ),
      });
      this.isCreate = false;
    } else {
      this.form = new FormGroup({
        sensorId: new FormControl(this.data.sensor.id, [Validators.required]),
        expirationDate: new FormControl(),
      });
      this.isCreate = true;
    }
  }

  close() {
    if (!this.form.valid) {
      return;
    }
    this.dialogRef.close(this.form.value);
  }

  saveAndClose() {
    if (!this.form.valid) {
      return;
    }
    if (this.isCreate) {
      this.sensorPairingShareService.create(this.form.value).then((res) => {
        this.snackBarService.saveSuccessful();
        this.dialogRef.close();
      });
    } else {
      this.sensorPairingShareService
        .update(this.data.sensorPairingShare.id, this.form.value)
        .then((res) => {
          this.snackBarService.saveSuccessful();
          this.dialogRef.close();
        });
    }
  }
}
