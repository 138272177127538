import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserSession } from 'src/app/_core/models/user-session';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  constructor() {}

  public connect(): Subject<MessageEvent> {
    const url =
      environment.wsUrl +
      '?authtoken=' +
      (JSON.parse(localStorage.getItem('currentUserSession')) as UserSession)
        .accessToken;
    return this.create(url);
  }

  private create(url): Subject<MessageEvent> {
    const ws = new WebSocket(url, 'v1.1');

    const subject = new Subject<MessageEvent>();
    ws.onmessage = (event) => {
      subject.next(event);
    };

    ws.onerror = (err) => {
      subject.error(err);
    };

    ws.onclose = (ev) => {
      subject.error(ev);
      subject.complete();
    };

    return subject;
  }
}
