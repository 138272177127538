import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalPushComponent } from 'src/app/public-utility/global-push/components/global-push.component';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [GlobalPushComponent],
  imports: [
    CommonModule,
    FlexModule,
    MatIconModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
  ],
})
export class GlobalPushModule {}
