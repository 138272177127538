import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/_core/services/api/api.service';
import { Sensor } from 'src/app/_shared/models/sensor';
import { SensorOutputDto } from 'src/app/_shared/dtos/sensor-output.dto';
import { SnackBarService } from 'src/app/_shared/services/snack-bar.service';
import { SensorInputDto } from 'src/app/_shared/dtos/sensor-input.dto';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';
import { Roles } from 'src/app/_core/models/roles';
import { CitylinkSensorOutputDto } from 'src/app/public-utility/sensors/dto/citylink-sensor-output-dto';
import { CitylinkSensor } from 'src/app/public-utility/sensors/models/citylink-sensor';
import { CitylinkSensorDetail } from 'src/app/public-utility/sensors/models/citylink-sensor-detail';
import { CitylinkSensorDetailOutputDto } from 'src/app/public-utility/sensors/dto/citylink-sensor-detail-output-dto';
import { SensorPairDto } from 'src/app/_shared/dtos/sensor-pair.dto';
import { NiotaImportOutputDto } from 'src/app/public-utility/sensors/dto/niota-import-output.dto';
import { ErrorService } from 'src/app/_shared/services/error.service';
import { SensorFilterInputDto } from 'src/app/public-utility/sensors/dto/sensor-filter-input-dto';
import { PaginatedCitylinkSensor } from 'src/app/public-utility/sensors/models/paginated-citylink-sensor';
import { PaginatedCitylinkSensorOutputDto } from 'src/app/public-utility/sensors/dto/paginated-citylink-sensor-output.dto';

@Injectable({
  providedIn: 'root',
})
export class SensorService {
  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    private snackBarService: SnackBarService,
    private errorService: ErrorService
  ) {}

  getUnpairedSensors(): Promise<Sensor[]> {
    return this.apiService
      .get<SensorOutputDto[]>('/sensors/unpaired')
      .then((result) => {
        if (!result) {
          return [];
        }
        return result.map((item) => Sensor.fromSensorOutputDto(item));
      });
  }

  update(
    dto: SensorInputDto,
    id: number,
    handleError = true,
    showSaveSuccessfullToast = true
  ): Promise<Sensor> {
    return this.apiService
      .patch<SensorOutputDto>(
        '/sensors/' + id,
        dto,
        undefined,
        true,
        handleError
      )
      .then((result) => {
        if (showSaveSuccessfullToast) {
          this.snackBarService.saveSuccessful();
        }
        if (result) {
          return Sensor.fromSensorOutputDto(result);
        }
      });
  }

  getSensor(
    id: number,
    showError = true
  ): Promise<void | CitylinkSensorDetail> {
    return this.apiService
      .get<CitylinkSensorDetailOutputDto>(
        '/sensors/' + id,
        undefined,
        undefined,
        showError
      )
      .then((result) => {
        if (!result) {
          return;
        }

        if (showError && result.niotaData?.error) {
          this.errorService.showError({
            userErrorMessage:
              'Bei der Abfrage der Sensordaten aus dem niota-System ist ein Fehler aufgetreten! (Fehlercode: ' +
              result.niotaData.error.status +
              ')',
            technicalErrorMessage: result.niotaData.error.message,
          });
        }

        return CitylinkSensorDetail.fromCitylinkSensorDetailOutputDto(result);
      });
  }

  getSensors(
    page = 0,
    limit = 100,
    handleErrors = true,
    filter?: SensorFilterInputDto
  ): Promise<Sensor[] | PaginatedCitylinkSensor> {
    const reqHeaders = { headers: {} };
    reqHeaders.headers['x-page-index'] = `${page}`;
    reqHeaders.headers['x-page-limit'] = `${limit}`;
    return this.apiService
      .get<SensorOutputDto[] | CitylinkSensorOutputDto[]>(
        '/sensors',
        reqHeaders,
        filter,
        handleErrors
      )
      .then((result) => {
        if (!result) {
          return [];
        }
        if (
          [
            Roles.CITYLINK_OWNER,
            Roles.CITYLINK_EDITOR,
            Roles.CITYLINK_VIEWER,
          ].includes(this.authenticationService.getCurrentUserSession().role)
        ) {
          const toReturn = new PaginatedCitylinkSensor();
          toReturn.pagination = ((result as unknown) as PaginatedCitylinkSensorOutputDto).pagination;
          toReturn.data = ((result as unknown) as PaginatedCitylinkSensorOutputDto).data.map(
            (sensorDto) => CitylinkSensor.fromCitylinkSensorOutputDto(sensorDto)
          );
          return toReturn;
        }
        return (result as SensorOutputDto[]).map((sensorOutputDto) =>
          Sensor.fromSensorOutputDto(sensorOutputDto)
        );
      });
  }

  create(dto: SensorInputDto, showError = true): Promise<CitylinkSensor> {
    return this.apiService
      .post<CitylinkSensorOutputDto>(
        '/sensors',
        dto,
        undefined,
        true,
        showError
      )
      .then((result) => {
        if (!result) {
          return;
        }
        this.snackBarService.saveSuccessful();
        return CitylinkSensor.fromCitylinkSensorOutputDto(result);
      });
  }

  pair(dto: SensorPairDto): Promise<Sensor> {
    return this.apiService
      .post<SensorOutputDto>('/sensors/pair', dto)
      .then((result) => {
        if (!result) {
          return;
        }
        this.snackBarService.saveSuccessful();
        return Sensor.fromSensorOutputDto(result);
      });
  }

  loadImage(sensorId: number): Promise<{ image: string }> {
    return this.apiService
      .get<{ image: string }>('/sensors/' + sensorId + '/image')
      .then();
  }

  delete(id: number) {
    return this.apiService
      .delete<CitylinkSensorOutputDto>('/sensors/' + id)
      .then((result) => {
        this.snackBarService.deleteSuccessful();
        return result;
      });
  }

  niotaImport(): Promise<NiotaImportOutputDto | void> {
    return this.apiService
      .post<NiotaImportOutputDto>('/sensors/niota-import', {})
      .then((result) => {
        if (!result) {
          return;
        }

        this.snackBarService.showCustomMessage(
          result.imported.length + ' Sensoren importiert.'
        );

        if (result.errors.length > 0) {
          const concatedErrorMessage = result.errors.reduce(
            (prev, current) => prev + current + '\n',
            ''
          );
          this.errorService.showError({
            userErrorMessage: 'Bei dem Import sind Fehler aufgetreten',
            technicalErrorMessage: concatedErrorMessage,
          });
        }
        return result;
      });
  }

  unpair(id: number) {
    return this.apiService
      .delete<NiotaImportOutputDto>('/sensors/unpair/' + id)
      .then((result) => {
        if (!result) {
          return;
        }

        this.snackBarService.deleteSuccessful();

        return result;
      });
  }

  niotaUpdate() {
    return this.apiService
      .post<NiotaImportOutputDto>('/sensors/niota-update', {})
      .then((result) => {
        if (!result) {
          return;
        }

        this.snackBarService.showCustomMessage(
          result.imported.length + ' Sensoren aktualisiert.'
        );

        const errorMessagesConcatenated = result.errors.reduce(
          (prev, current) => prev + current + '\n',
          ''
        );
        this.errorService.showError({
          userErrorMessage: 'Bei der Aktualisierung sind Fehler aufgetreten',
          technicalErrorMessage: errorMessagesConcatenated,
        });

        return result;
      });
  }
}
