import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';
import { Roles } from 'src/app/_core/models/roles';

@Injectable({
  providedIn: 'root',
})
export class AlreadySignedInGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authenticationService.isLoggedIn()) {
      const role = this.authenticationService.getCurrentUserSession().role;
      if (
        Roles.ORGANIZATION_OWNER === role ||
        Roles.ORGANIZATION_EDITOR === role ||
        Roles.ORGANIZATION_VIEWER
      ) {
        this.router.navigateByUrl('organization/dashboard');
      } else if (
        Roles.CITYLINK_OWNER === role ||
        Roles.CITYLINK_EDITOR === role
      ) {
        this.router.navigateByUrl('public-utility/dashboard');
      } else {
        // this.authenticationService.logout();
      }
      return false;
    }

    return true;
  }
}
