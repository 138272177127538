export enum NotificationConfigurationTypeBase {
  ERROR = 'error',
  WARNING = 'warning',
  DEFAULT = 'default',
}

export class NotificationConfigurationType {
  public static entries() {
    return [
      NotificationConfigurationTypeBase.ERROR,
      NotificationConfigurationTypeBase.WARNING,
    ];
  }

  public static translate(value: NotificationConfigurationTypeBase) {
    switch (value) {
      case NotificationConfigurationTypeBase.ERROR:
        return 'Alarm';
      case NotificationConfigurationTypeBase.WARNING:
        return 'Warnung';
    }
  }

  public static severity(type: NotificationConfigurationTypeBase): number {
    switch (type) {
      case NotificationConfigurationTypeBase.DEFAULT:
        return 0;
      case NotificationConfigurationTypeBase.WARNING:
        return 1;
      case NotificationConfigurationTypeBase.ERROR:
        return 2;
    }
  }
}
