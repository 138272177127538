import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PublicUtilityDashboardDataDto } from 'src/app/public-utility/public-utility-dashboard/dto/public-utility-dashboard-data.dto';
import { OrganizationDto } from 'src/app/public-utility/organization/dtos/organization.dto';

@Component({
  selector: 'app-public-utility-dashboard',
  templateUrl: './public-utility-dashboard-overview.component.html',
  styleUrls: ['./public-utility-dashboard-overview.component.scss'],
})
export class PublicUtilityDashboardOverviewComponent implements OnInit {
  data: PublicUtilityDashboardDataDto;
  organizations: Array<OrganizationDto> = [];

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.data = this.activatedRoute.snapshot.data.data.dashboardData;
    this.organizations = this.activatedRoute.snapshot.data.data.organizations;
  }

  notificationCount(type: string) {
    const alarmNotifications = this.data?.notifications?.filter(
      (item) => item.type === type
    );
    if (alarmNotifications && alarmNotifications.length) {
      return alarmNotifications[0].count;
    }
    return 0;
  }
}
