import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { User } from 'src/app/users/models/user';
import { UserGroupService } from 'src/app/users/services/user-group.service';
import { UserGroup } from 'src/app/users/models/user-group';

@Component({
  selector: 'app-user-group-edit',
  templateUrl: './user-group-edit.component.html',
  styleUrls: ['./user-group-edit.component.scss'],
})
export class UserGroupEditComponent implements OnInit {
  form: FormGroup;
  userDataSource: MatTableDataSource<User> = new MatTableDataSource<User>();
  allUsers: Array<User> = [];
  addableUsers: Array<User> = [];
  displayedColumns = ['firstName', 'lastName', 'username', 'delete'];
  selectedUsersToAdd: Array<User>;
  isCreate = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userGroupService: UserGroupService
  ) {}

  ngOnInit(): void {
    this.allUsers = this.activatedRoute.snapshot.data.data.users;
    if (this.activatedRoute.snapshot.data.data.userGroup) {
      this.isCreate = false;
      this.initializeWithUserGroup();
    } else {
      this.initializeWithoutUserGroup();
    }
  }

  private initializeWithUserGroup() {
    const userGroup = this.activatedRoute.snapshot.data.data.userGroup;
    const userGroupUsers = this.allUsers.filter((addableUser) =>
      userGroup.userIds.includes(addableUser.id)
    );
    this.form = new FormGroup({
      id: new FormControl(userGroup.id),
      name: new FormControl(userGroup.name, [
        Validators.required,
        Validators.maxLength(255),
      ]),
      users: new FormControl(userGroupUsers),
      // ngModel does not work when using Reactive Forms :(
      selectedUsersToAdd: new FormControl([]),
    });
    this.userDataSource.data = userGroupUsers;
    this.addableUsers = this.allUsers.filter(
      (addableUser) => !userGroup.userIds.includes(addableUser.id)
    );
  }

  private initializeWithoutUserGroup() {
    this.form = new FormGroup({
      name: new FormControl(undefined, [
        Validators.required,
        Validators.maxLength(255),
      ]),
      users: new FormControl([]),
      // ngModel does not work when using Reactive Forms :(
      selectedUsersToAdd: new FormControl([]),
    });
    this.userDataSource.data = [];
    this.addableUsers = this.allUsers;
  }

  save() {
    const toSave = new UserGroup();
    toSave.name = this.form.value.name;
    toSave.userIds = this.form.value.users.map((item) => item.id);

    if (this.isCreate) {
      this.userGroupService.create(toSave);
    } else {
      this.userGroupService.update(this.form.value.id, toSave);
    }
  }

  addUsers() {
    if (!this.form.get('selectedUsersToAdd').value) {
      return;
    }

    this.form.patchValue({
      users: this.form
        .get('users')
        .value.concat(this.form.get('selectedUsersToAdd').value),
    });

    this.userDataSource.data = this.form.get('users').value;
    this.addableUsers = this.allUsers.filter(
      (addableUser) =>
        !this.form
          .get('users')
          .value.map((user) => user.id)
          .includes(addableUser.id)
    );
    this.form.get('selectedUsersToAdd').patchValue(undefined);
  }

  unselectUser(toUnselect: User) {
    this.form.patchValue({
      users: this.form.value.users.filter((item) => item !== toUnselect),
    });

    this.userDataSource.data = this.form.get('users').value;
    this.addableUsers = this.allUsers.filter(
      (addableUser) =>
        !this.form
          .get('users')
          .value.map((user) => user.id)
          .includes(addableUser.id)
    );
  }
}
