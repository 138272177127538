import { CoordinateDto } from 'src/app/_shared/dtos/coordinate.dto';

export class Coordinate {
  lat: number;
  lng: number;

  static fromCoordinateDto(dto: CoordinateDto): Coordinate {
    const result = new Coordinate();

    Object.assign(result, dto);

    return result;
  }

  toCoordinateDto(): CoordinateDto {
    return { lat: this.lat, lng: this.lng };
  }
}
