<div
  class="container"
  fxLayout="row wrap"
  [style.padding-left.px]="paddingLeft + 8"
>
  <div
    fxFlex="100%"
    fxLayoutAlign="start center"
    class="event-item truncate-text"
  >
    {{ sensor.displayName }}
  </div>
  <div
    fxFlex="100%"
    fxLayoutAlign="start center"
    class="event-count event-item"
  >
    <span style="padding-left: 8px" class="light-text"
      >Ereignisse({{ countEvents(sensor) }})</span
    >
  </div>
  <ng-container
    *ngFor="let event of sensor.notifications | slice: 0:10; let i = index"
  >
    <div
      fxFlex="70%"
      fxLayoutAlign="start center"
      class="event-item"
      [class.text-danger]="isError(event)"
      [class.text-warning]="isWarning(event)"
      [class.text-muted]="isDefault(event)"
    >
      <span style="padding-left: 8px">
        {{ event.title }}
      </span>
    </div>
    <div
      fxFlex="30%"
      fxLayoutAlign="end center"
      class="event-time event-item light-text"
    >
      {{ event.date | date: "HH:mm:ss" }} Uhr
    </div>
  </ng-container>
  <ng-container *ngIf="sensor.notifications.length > 10">
    <div
      fxFlex="100%"
      fxLayoutAlign="center center"
      class="event-item text-muted"
      style="margin-top: 16px; margin-bottom: 16px"
    >
      {{ sensor.notifications.length - 10 }} weitere
    </div>
  </ng-container>
</div>
