<div fxLayout="column" class="app-area">
  <form [formGroup]="form" (ngSubmit)="save()">
    <!-- HEADER ROW -->
    <div fxLayout="row">
      <div fxFlex fxFlexAlign="start start">
        <h1>Sensordetails</h1>
      </div>
      <div fxFlex style="text-align: right">
        <button mat-button fxFlexAlign="end end" type="submit">
          <mat-icon>save</mat-icon>
          Speichern
        </button>
      </div>
    </div>

    <!-- Content -->
    <div fxLayout="row" fxLayoutGap="24px grid">
      <div fxFlex="50">
        <mat-card
          fxLayout="row wrap"
          fxLayoutAlign="start start"
          style="width: 100%"
          fxLayoutGap="12px"
          class="square-card mat-elevation-z0"
        >
          <div fxFlex="100">
            <h4>Allgemeines</h4>
          </div>
          <div fxFlex="100">
            <mat-form-field appearance="outline">
              <mat-label>Name</mat-label>
              <input
                matInput
                placeholder="Name"
                type="text"
                formControlName="title"
              />
              <mat-error *ngIf="form.get('title').hasError('maxlength')">
                Maximal 255 Zeichen!
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="100">
            <mat-form-field appearance="outline">
              <mat-label>Sensortyp</mat-label>
              <mat-select formControlName="sensorTypeConfig">
                <mat-option
                  *ngFor="let sensorTypeConfig of sensorTypeConfigs"
                  [value]="sensorTypeConfig"
                  >{{ sensorTypeConfig.displayName }} ({{
                    sensorTypeConfig.niotaDeviceTypeId
                  }}
                  )
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxFlex="100">
            <h4>Niota</h4>
          </div>

          <div fxFlex="100">
            <mat-checkbox
              color="primary"
              formControlName="applyChangesInIotPlatform"
              (change)="updateValidators()"
            >
              <div>
                <span> Änderungen in niota übernehmen </span>
                <span style="margin-left: 12px">
                  <mat-icon
                    inline
                    style="font-size: 20px; vertical-align: bottom"
                    matTooltip="Die in Citylink hinterlegten Werte werden in das niota-System übertragen."
                  >
                    info_outlined</mat-icon
                  >
                </span>
              </div>
            </mat-checkbox>
          </div>

          <div fxFlex="100">
            <mat-form-field appearance="outline">
              <mat-label>EUI</mat-label>
              <input
                matInput
                placeholder="EUI"
                type="text"
                formControlName="eui"
              />
              <mat-error *ngIf="form.get('eui').invalid"
                >16 Hex-Zeichen</mat-error
              >
            </mat-form-field>
          </div>

          <div fxFlex="100">
            <mat-form-field appearance="outline">
              <mat-label>Application session key</mat-label>
              <input
                matInput
                placeholder="Application session key"
                type="text"
                formControlName="applSessionKey"
              />
              <mat-error *ngIf="form.get('applSessionKey').invalid"
                >32 Hex-Zeichen</mat-error
              >
            </mat-form-field>
          </div>

          <div fxFlex="100">
            <mat-form-field appearance="outline">
              <mat-label>Application Key</mat-label>
              <input
                matInput
                placeholder="Application Key"
                type="text"
                formControlName="applKey"
              />
              <mat-error *ngIf="form.get('applKey').invalid"
                >32 Hex-Zeichen</mat-error
              >
            </mat-form-field>
          </div>

          <div fxFlex="100">
            <mat-form-field appearance="outline">
              <mat-label>Beschreibung</mat-label>
              <input
                matInput
                placeholder="Beschreibung"
                type="text"
                formControlName="description"
              />
            </mat-form-field>
          </div>

          <div fxFlex="100">
            <mat-form-field appearance="outline">
              <mat-label>Device Address</mat-label>
              <input
                matInput
                placeholder="Device Address"
                type="text"
                formControlName="deviceAddress"
              />
              <mat-error *ngIf="form.get('deviceAddress').invalid"
                >8 Hex-Zeichen</mat-error
              >
            </mat-form-field>
          </div>

          <div fxFlex="100">
            <mat-form-field appearance="outline">
              <mat-label>Network session key</mat-label>
              <input
                matInput
                placeholder="Network session key"
                type="text"
                formControlName="netwSessionKey"
              />
              <mat-error *ngIf="form.get('netwSessionKey').invalid"
                >32 Hex-Zeichen</mat-error
              >
            </mat-form-field>
          </div>
        </mat-card>
      </div>
      <div fxFlex="50">
        <mat-card
          fxLayout="row wrap"
          fxLayoutAlign="start start"
          style="width: 100%; height: 100%"
          class="square-card mat-elevation-z0"
        >
          <div fxFlex="calc(100%-40px)">
            <h4 class="square-card-title">Zugewiesene Organisationen</h4>
          </div>
          <div fxFlex="40px" fxLayoutAlign="end">
            <button
              mat-icon-button
              (click)="addOrganizationClicked()"
              type="button"
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <div fxFlex="100" fxLayout="row wrap">
            <ng-container
              *ngFor="
                let organization of selectedOrganizations();
                let i = index
              "
            >
              <div fxFlex="100" fxLayout="row">
                <div fxLayout="column" fxFlex="grow">
                  <div class="sensor-title truncate-text">
                    {{ organization.name }}
                  </div>
                </div>
                <div
                  fxFlex="40px"
                  fxFlexAlign="center"
                  style="text-align: right"
                >
                  <button
                    mat-icon-button
                    class="options-button"
                    (click)="removeOrganization(organization)"
                    type="button"
                  >
                    <mat-icon>delete_outline</mat-icon>
                  </button>
                </div>
              </div>
            </ng-container>
          </div>
        </mat-card>
      </div>
    </div>
  </form>
</div>
