import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SensorGroupNodeService } from 'src/app/_shared/services/sensor-group-node.service';
import { SensorService } from 'src/app/_shared/services/sensor.service';
import {
  PositioningService,
  SensorGroupNodeView,
} from 'src/app/organization/positioning/services/positioning.service';
import { MatDialog } from '@angular/material/dialog';
import {
  EditMode,
  EditSensorGroupNodeModalComponent,
} from 'src/app/organization/positioning/components/edit-sensor-group-node-modal/edit-sensor-group-node-modal.component';
import { SensorGroupNode } from 'src/app/_shared/models/sensor-group-node';

@Component({
  selector: 'app-level',
  templateUrl: './positioning-group.component.html',
  styleUrls: ['./positioning-group.component.scss'],
})
export class PositioningGroupComponent implements OnInit {
  @Input() sensorGroupNode: SensorGroupNodeView;
  @Input() enabled: boolean;

  @ViewChild('groupRow') groupRow: ElementRef;

  /**
   * https://stackoverflow.com/questions/10867506/dragleave-of-parent-element-fires-when-dragging-over-children-elements
   * Use counte as child Elements will fire dragenter and dragleave as well.
   */
  dragging = 0;

  constructor(
    private groupService: SensorGroupNodeService,
    private sensorService: SensorService,
    private positioningService: PositioningService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  onClick() {
    if (this.sensorGroupNode.selected) {
      this.positioningService.unselectGroup(this.sensorGroupNode);
      this.positioningService.selectionChangedSubject.next(
        this.sensorGroupNode
      );
    } else {
      this.positioningService.selectGroup(this.sensorGroupNode);
      this.positioningService.selectionChangedSubject.next(
        this.sensorGroupNode
      );
    }
  }

  onDragEnter(event) {
    this.dragging++;
    /**
     * The Browser does not allow to get data in dragover or dragleave.
     * https://stackoverflow.com/questions/11065803/determine-what-is-being-dragged-from-dragenter-dragover-events
     * Therefore check the flag which was set in onDragStart
     */
    const isSensor = event.dataTransfer.types.includes('is_sensor');
    const isLevel = event.dataTransfer.types.includes('is_level');

    event.preventDefault();
    if (isSensor) {
      this.groupRow.nativeElement.classList.add('group-row-drag-into');
    } else if (isLevel) {
      this.groupRow.nativeElement.classList.add('group-row-drag-over');
    }
  }

  onDragLeave(event) {
    this.dragging--;

    if (this.dragging <= 0) {
      this.groupRow.nativeElement.classList.remove('group-row-drag-into');
      this.groupRow.nativeElement.classList.remove('group-row-drag-over');
      this.groupRow.nativeElement.classList.add('group-row');
    }
  }

  onDragStart(event) {
    requestAnimationFrame(() =>
      this.groupRow.nativeElement.classList.add('hide')
    );
    event.dataTransfer.setData('text', JSON.stringify(this.sensorGroupNode.id));

    /*
     * The Browser does not allow to get data in dragover or dragleave.
     * https://stackoverflow.com/questions/11065803/determine-what-is-being-dragged-from-dragenter-dragover-events
     * Therefore add a flag which can be checked dataTransfer.types.includes("xyz").
     */
    event.dataTransfer.setData('is_level', 'true');
  }

  onDrop(event) {
    this.dragging = 0;
    this.groupRow.nativeElement.classList.remove('group-row-drag-into');
    this.groupRow.nativeElement.classList.remove('group-row-drag-over');
    this.groupRow.nativeElement.classList.add('group-row');

    const droppedId = JSON.parse(event.dataTransfer.getData('text'));
    const isSensor = event.dataTransfer.types.includes('is_sensor');
    const isLevel = event.dataTransfer.types.includes('is_level');

    if (isSensor) {
      this.positioningService.moveSensorToEndOfNewParent(
        droppedId,
        this.sensorGroupNode
      );
    } else if (isLevel) {
      this.positioningService.moveGroup(droppedId, this.sensorGroupNode);
    }
    event.stopPropagation();
  }

  onDragEnd($event: DragEvent) {
    this.dragging = 0;
    this.groupRow.nativeElement.classList.remove('hide');
  }

  removeGroup() {
    this.positioningService.deleteGroup(this.sensorGroupNode);
  }

  getGroupChildrenCount(group: SensorGroupNode): number {
    let total = 0;
    if (group.children) {
      total += group.children.length;
      for (const child of group.children) {
        total += this.getGroupChildrenCount(child);
      }
    }
    return total;
  }

  getSensorCount(group: SensorGroupNode) {
    let total = 0;
    if (group.sensors) {
      total += group.sensors.length;
      for (const child of group.children) {
        total += this.getSensorCount(child);
      }
    }
    return total;
  }

  editGroup(group: SensorGroupNodeView) {
    this.groupService.getDetails(group.id).then((result) => {
      this.dialog.open(EditSensorGroupNodeModalComponent, {
        width: '620px',
        data: {
          parent: this.positioningService.findParentOfGroupRecursive(
            this.sensorGroupNode,
            this.positioningService.treeRoot
          ),
          mode: EditMode.EDIT,
          sensorGroupNode: result,
        },
      });
    });
  }
}
