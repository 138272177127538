import { Injectable } from '@angular/core';
import { LegalInputDto } from 'src/app/public-utility/legal-settings/dto/legal-input.dto';
import { ApiService } from 'src/app/_core/services/api/api.service';
import { SnackBarService } from 'src/app/_shared/services/snack-bar.service';
import { LegalOutputDto } from 'src/app/public-utility/legal-settings/dto/legal-output.dto';

@Injectable({
  providedIn: 'root',
})
export class LegalSettingsService {
  constructor(
    private apiService: ApiService,
    private snackBarService: SnackBarService
  ) {}

  savePrivacy(legalDto: LegalInputDto) {
    this.apiService
      .post<LegalOutputDto>('/legal/privacy/settings', legalDto)
      .then((res) => {
        this.snackBarService.saveSuccessful();
        return res;
      });
  }

  saveImprint(legalDto: LegalInputDto) {
    this.apiService
      .post<LegalOutputDto>('/legal/imprint/settings', legalDto)
      .then((res) => {
        this.snackBarService.saveSuccessful();
        return res;
      });
  }

  saveGtc(legalDto: LegalInputDto) {
    this.apiService
      .post<LegalOutputDto>('/legal/gtc/settings', legalDto)
      .then((res) => {
        this.snackBarService.saveSuccessful();
        return res;
      });
  }

  loadImprint(handleErrors = true): Promise<void | LegalOutputDto> {
    return this.apiService.get<LegalOutputDto>(
      '/legal/imprint/settings',
      undefined,
      undefined,
      handleErrors
    );
  }

  loadPrivacy(handleErrors = true): Promise<void | LegalOutputDto> {
    return this.apiService.get<LegalOutputDto>(
      '/legal/privacy/settings',
      undefined,
      undefined,
      handleErrors
    );
  }

  loadGtc(handleErrors = true): Promise<void | LegalOutputDto> {
    return this.apiService.get<LegalOutputDto>(
      '/legal/gtc/settings',
      undefined,
      undefined,
      handleErrors
    );
  }
}
