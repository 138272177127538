import { Operator } from 'src/app/_core/models/operator';
import { NotificationConfigurationType } from 'src/app/_core/models/notification-configuration-type';
import { NotificationConfigOutputDto } from 'src/app/organization/notification-configuration/dto/notification-config-output.dto';
import { SensorTypeConfig } from 'src/app/_shared/models/sensor-type-config';
import { SensorPropertyConfig } from 'src/app/_shared/models/sensor-property-config';
import { NotificationRepetitionTypeBase } from 'src/app/_core/models/notification-repetition-type-base';

export class NotificationConfig {
  id: number;
  title: string;
  sensorTypeConfig: SensorTypeConfig;
  sensorPropertyConfig: SensorPropertyConfig;
  operator: Operator;
  type: NotificationConfigurationType;
  value: number;
  sensorIds: number[];
  userIds: number[];
  roleIds: number[];
  iconIdentifier: string;
  iconBackgroundColor: string;
  iconForegroundColor: string;
  notificationRepetitionType: NotificationRepetitionTypeBase;

  static fromNotificationConfigOutputDto(
    dto: NotificationConfigOutputDto
  ): NotificationConfig {
    const result = new NotificationConfig();

    Object.assign(result, dto);
    return result;
  }
}
