<form [formGroup]="form" (ngSubmit)="save()">
  <div fxLayout="column" class="app-area">
    <!-- HEADER ROW -->
    <div fxLayout="row">
      <div fxFlex fxFlexAlign="start start">
        <h1>{{ getTitle() }}</h1>
      </div>
      <div fxFlex style="text-align: right">
        <button mat-button fxFlexAlign="end end" type="submit">
          <mat-icon>save</mat-icon>
          Speichern
        </button>
      </div>
    </div>

    <!-- Content -->
    <mat-card
      fxLayout="row"
      class="square-card mat-elevation-z0"
      style="height: 100%"
    >
      <div
        fxLayout="row wrap"
        fxLayoutAlign="start start"
        fxLayoutGap="24px"
        style="width: 100%"
      >
        <div fxFlex="100"><h2>Allgemeines</h2></div>
        <div fxFlex="calc(65%-24px)">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input
              matInput
              placeholder="Name"
              type="text"
              formControlName="name"
            />
            <mat-error *ngIf="form.controls.name.hasError('maxlength')">
              Maximal 255 Zeichen!
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="calc(35%-24px)"></div>
        <div fxFlex="100"><h4>Adresse und Ort</h4></div>
        <ng-container formGroupName="address">
          <div fxFlex="calc(65%-24px)">
            <mat-form-field appearance="outline">
              <mat-label>Straße</mat-label>
              <input
                matInput
                placeholder="Straße"
                type="text"
                formControlName="street"
              />
              <mat-error
                *ngIf="form.get('address.street').hasError('maxlength')"
              >
                Maximal 255 Zeichen!
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="calc(35%-24px)">
            <mat-form-field appearance="outline">
              <mat-label>Postleitzahl</mat-label>
              <input
                matInput
                placeholder="Postleitzahl"
                type="text"
                formControlName="zipCode"
              />
              <mat-error
                *ngIf="form.get('address.zipCode').hasError('maxlength')"
              >
                Maximal 5 Zeichen!
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="calc(65%-24px)">
            <mat-form-field appearance="outline">
              <mat-label>Stadt</mat-label>
              <input
                matInput
                placeholder="Stadt"
                type="text"
                formControlName="city"
              />
              <mat-error *ngIf="form.get('address.city').hasError('maxlength')">
                Maximal 255 Zeichen!
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="calc(35%-24px)"></div>
        </ng-container>
        <div fxFlex="100"><h4>Logo</h4></div>
        <div fxFlex="100">
          <img [src]="logo" *ngIf="form.value.logo" class="logo-preview" />
          <img
            src="assets/logo_CityLink_white.svg"
            *ngIf="!form.value.logo"
            class="logo-preview"
          />
        </div>
        <div fxFlex="calc(50%-24px)">
          <input
            style="display: none"
            type="file"
            (change)="onFileSelect($event)"
            accept="image/*"
            #hiddenfileinput
          />
          <button
            mat-button
            class="upper-case border-button"
            type="button"
            color="primary"
            (click)="hiddenfileinput.click()"
          >
            Datei öffnen
          </button>
        </div>
        <div fxFlex="calc(50%-24px)"></div>
        <ng-container
          formGroupName="owner"
          *ngIf="isCreate || activatedRoute.snapshot.data.organization.owner"
        >
          <div fxFlex="100">
            <h4>Ansprechpartner</h4>
          </div>
          <div fxFlex="calc(50%-24px)">
            <mat-form-field appearance="outline">
              <mat-label>Vorname</mat-label>
              <input
                matInput
                placeholder="Vorname"
                type="text"
                formControlName="firstName"
              />
              <mat-error
                *ngIf="form.get('owner.firstName').hasError('maxlength')"
              >
                Maximal 255 Zeichen!
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="calc(50%-24px)">
            <mat-form-field appearance="outline">
              <mat-label>Nachname</mat-label>
              <input
                matInput
                placeholder="Nachname"
                type="text"
                formControlName="lastName"
              />
              <mat-error
                *ngIf="form.get('owner.lastName').hasError('maxlength')"
              >
                Maximal 255 Zeichen!
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="100">
            <span style="margin-left: 4px" class="light-text"
              >Hinweis: Eine geänderte E-Mail Adresse muss bestätigt werden
              damit sie aktiv wird.</span
            >
            <mat-form-field appearance="outline">
              <mat-label>E-Mail</mat-label>
              <input
                matInput
                placeholder="E-Mail"
                type="email"
                formControlName="username"
              />
              <mat-error
                *ngIf="form.get('owner.username').hasError('maxlength')"
              >
                Maximal 255 Zeichen!
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="calc(50%-24px)">
            <mat-form-field appearance="outline">
              <mat-label>Rolle</mat-label>
              <mat-select formControlName="role">
                <mat-option [value]="organizationOwnerRole">{{
                  getTextForRoleEnum(organizationOwnerRole)
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="50"></div>
        </ng-container>
      </div>
    </mat-card>
  </div>
</form>
