import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { LegalOutputDto } from 'src/app/public-utility/legal-settings/dto/legal-output.dto';
import { LegalSettingsService } from 'src/app/public-utility/legal-settings/services/legal-settings.service';
import { ApiService } from 'src/app/_core/services/api/api.service';

export interface LegalSettingsResolverResult {
  gtc: LegalOutputDto;
  imprint: LegalOutputDto;
  privacy: LegalOutputDto;
}

@Injectable({
  providedIn: 'root', // https://github.com/angular/angular-cli/issues/10170#issuecomment-380673276
})
export class LegalSettingsResolver
  implements Resolve<void | LegalSettingsResolverResult> {
  constructor(
    private legalSettingsService: LegalSettingsService,
    private apiService: ApiService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<void | LegalSettingsResolverResult> {
    const promises = [];
    promises.push(this.legalSettingsService.loadGtc(false));
    promises.push(this.legalSettingsService.loadImprint(false));
    promises.push(this.legalSettingsService.loadPrivacy(false));

    return Promise.all(promises)
      .then((allPromisesResult) => {
        return {
          gtc: allPromisesResult[0],
          imprint: allPromisesResult[1],
          privacy: allPromisesResult[2],
        } as LegalSettingsResolverResult;
      })
      .catch((err) => {
        this.apiService.handleError(err);
        throw err;
      });
  }
}
