import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/users/services/user.service';

@Component({
  selector: 'app-logout-list-item',
  templateUrl: './navigation-logout-list-item.component.html',
  styleUrls: ['./navigation-logout-list-item.component.scss'],
})
export class NavigationLogoutListItemComponent implements OnInit {
  constructor(private userService: UserService) {}

  ngOnInit(): void {}

  logout() {
    this.userService.logout();
  }
}
