import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { DashboardSiteMapSensorItemComponent } from 'src/app/organization/dashboard/components/site-map/sensor-item/dashboard-site-map-sensor-item.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [DashboardSiteMapSensorItemComponent],
  exports: [DashboardSiteMapSensorItemComponent],
  imports: [CommonModule, FlexModule, BrowserAnimationsModule, MatIconModule],
})
export class DashboardSiteMapSensorItemModule {}
