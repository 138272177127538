import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardOverviewComponent } from 'src/app/organization/dashboard/components/dashboard/dashboard-overview.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout';
import { DashboardCurrentEventsModule } from 'src/app/organization/dashboard/components/current-events/dashboard-current-events.module';
import { DashboardSiteMapModule } from 'src/app/organization/dashboard/components/site-map/dashboard-site-map.module';
import { DashboardWarningsCardModule } from 'src/app/organization/dashboard/components/warnings-card/dashboard-warnings-card.module';

@NgModule({
  declarations: [DashboardOverviewComponent],
  exports: [DashboardOverviewComponent],
  imports: [
    CommonModule,
    FlexModule,
    MatButtonModule,
    MatIconModule,
    DashboardWarningsCardModule,
    DashboardCurrentEventsModule,
    DashboardSiteMapModule,
  ],
})
export class DashboardOverviewModule {}
