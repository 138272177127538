export enum SensorSourceOfGeneration {
  NIOTA_MQTT = 'NIOTA_MQTT',
  NIOTA_IMPORT = 'NIOTA_IMPORT',
  TTN_MQTT = 'TTN_MQTT',
  TTN_IMPORT = 'TTN_IMPORT',
  CITYLINK = 'CITYLINK',
}

export function sensorSourceOfGenerationToLabel(
  sourceOfGeneration: SensorSourceOfGeneration
) {
  switch (sourceOfGeneration) {
    case SensorSourceOfGeneration.CITYLINK:
      return 'CityLink';
    case SensorSourceOfGeneration.NIOTA_IMPORT:
      return 'niota';
    case SensorSourceOfGeneration.NIOTA_MQTT:
      return 'MQTT (niota)';
    case SensorSourceOfGeneration.TTN_IMPORT:
      return 'TTN';
    case SensorSourceOfGeneration.TTN_MQTT:
      return 'MQTT (TTN)';
  }
}
