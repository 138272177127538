<div fxLayout="column" fxLayoutAlign="center center" class="full-page">
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    class="confirmation-content"
  >
    <img class="logo" />
    <p>
      Authentifizierung erfolgreich, Sie werden nun automatisch zur
      Passwortvergabe weitergeleitet...
    </p>
    <p>
      Klicken Sie
      <a
        [routerLink]="
          '/users-password-reset?confirmationToken=' + confirmationToken
        "
        >hier</a
      >
      falls die automatische Weiterleitung nicht funktioniert.
    </p>
  </div>
</div>
