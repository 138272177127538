import { Component, Input, OnInit } from '@angular/core';
import { MeasurementIntervalOutputDto } from 'src/app/_shared/dtos/measurements/measurement-interval-output.dto';
import { ChartDataSets } from 'chart.js';
import { ChartOptions, Color } from 'ng2-charts';
import tinycolor from 'tinycolor2';

@Component({
  selector: 'app-sensor-measurements-chart',
  templateUrl: './sensor-measurements-chart.component.html',
  styleUrls: ['./sensor-measurements-chart.component.scss'],
})
export class SensorMeasurementsChartComponent implements OnInit {
  constructor() {}

  @Input() measurementIntervalData: MeasurementIntervalOutputDto;

  public lineChartData: ChartDataSets[] = [];
  public lineChartOptions: ChartOptions<any> = {
    legend: {
      display: false,
    },
    aspectRatio: 3,
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          distribution: 'series',
          ticks: {
            maxTicksLimit: 6,
            maxRotation: 0,
            minRotation: 0,
            callback: (label, index, labels) => {
              return label.split(' ');
            },
          },
          time: {
            unit: 'minute',
            displayFormats: {
              minute: 'DD.MM HH:mm',
            },
            tooltipFormat: 'DD.MM.YYYY HH:mm:ss',
          },
          gridLines: {
            zeroLineColor: getComputedStyle(document.body).getPropertyValue(
              '--selected-background'
            ),
            color: getComputedStyle(document.body).getPropertyValue(
              '--selected-background'
            ),
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
            zeroLineColor: getComputedStyle(document.body).getPropertyValue(
              '--selected-background'
            ),
            color: getComputedStyle(document.body).getPropertyValue(
              '--selected-background'
            ),
          },
          position: 'right',
          ticks: {
            userCallback: (item) => {
              return `${item} ${
                this.measurementIntervalData.latestMeasurement?.value?.unit
                  ?.symbol || ''
              }`;
            },
          },
        },
      ],
    },
    responsive: true,
    tooltips: {
      callbacks: {
        label: (item) =>
          `${item.yLabel} ${
            this.measurementIntervalData.latestMeasurement?.value?.unit
              ?.symbol || ''
          }`,
      },
    },
  };
  public lineChartColors: Color[] = [
    {
      borderColor: getComputedStyle(document.body).getPropertyValue(
        '--primary-color'
      ),
      backgroundColor: SensorMeasurementsChartComponent.setAlphaToHexColor(
        getComputedStyle(document.body).getPropertyValue('--primary-color'),
        0.3
      ),
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];

  static setAlphaToHexColor(hexColor: string, alpha: number): string {
    return tinycolor(hexColor).setAlpha(alpha).toRgbString();
  }

  ngOnInit(): void {

    // set labels for the y axis for booleanMeasurements
    const latestMeasurementValue = this.measurementIntervalData.latestMeasurement.value;
    if ('trueDisplayName' in latestMeasurementValue && 'falseDisplayName' in latestMeasurementValue &&
      latestMeasurementValue.falseDisplayName !== '' && latestMeasurementValue.trueDisplayName !== '') {
      this.lineChartOptions.scales.yAxes[0].ticks = {
        userCallback: (item) => {
          if (item === 0) {
            return latestMeasurementValue.falseDisplayName;
          }
          if (item === 1) {
            return latestMeasurementValue.trueDisplayName;
          }
        },
      };
    }

    this.lineChartData.push({
      // @ts-ignore
      data: this.measurementIntervalData.measurements.map((measurement) => {
        return {
          t: new Date(measurement.dateTime),
          y: measurement.value.value,
        };
      }),
      label: this.measurementIntervalData.displayName,
      lineTension: 0,
      borderWidth: 0.5,
    });
  }
}
