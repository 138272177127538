import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SensorTypeConfigListModule } from 'src/app/public-utility/sensor-types/components/sensor-type-config-list/sensor-type-config-list.module';
import { SensorTypeConfigEditModule } from 'src/app/public-utility/sensor-types/components/sensor-type-config-edit/sensor-type-config-edit.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SensorTypeConfigListModule,
    SensorTypeConfigEditModule,
  ],
  exports: [SensorTypeConfigListModule, SensorTypeConfigEditModule],
})
export class SensorTypesModule {}
