import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { NotificationConfigIconOutputDto } from 'src/app/organization/notification-configuration/dto/notification-config-icon-output.dto';
import { NotificationConfigService } from 'src/app/organization/notification-configuration/services/notification-config.service';
import { SensorTypeConfig } from 'src/app/_shared/models/sensor-type-config';
import { SensorTypesService } from 'src/app/public-utility/sensor-types/services/sensor-types.service';
import { ApiService } from 'src/app/_core/services/api/api.service';
import { NotificationConfig } from 'src/app/organization/notification-configuration/models/notification-config';

export interface NotificationConfigurationListResolverResult {
  notificationConfigs: NotificationConfig[];
  sensorTypeConfigs: SensorTypeConfig[];
}

@Injectable({
  providedIn: 'root',
})
export class NotificationConfigurationListResolver
  implements Resolve<void | NotificationConfigurationListResolverResult> {
  constructor(
    private notificationConfigService: NotificationConfigService,
    private sensorTypesService: SensorTypesService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<void | NotificationConfigurationListResolverResult> {
    const promises: Promise<any>[] = [];
    promises.push(this.notificationConfigService.getNotificationConfigList());
    promises.push(this.sensorTypesService.getSensorTypeConfigs());

    return Promise.all(promises).then((res) => {
      return {
        notificationConfigs: res[0],
        sensorTypeConfigs: res[1],
      };
    });
  }
}
