import { Component, OnInit } from '@angular/core';
import * as chartJs from 'chart.js';
import { NotificationService } from 'src/app/organization/dashboard/services/notification.service';
import * as moment from 'moment';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { NotificationAggregationOutput } from 'src/app/organization/dashboard/dtos/notification-aggregation-output.dto';
import { NotificationConfigurationType } from 'src/app/_core/models/notification-configuration-type';

@Component({
  selector: 'app-warnings-card',
  templateUrl: './dashboard-warnings-card.component.html',
  styleUrls: ['./dashboard-warnings-card.component.scss'],
})
export class DashboardWarningsCardComponent implements OnInit {
  public barChartOptions: chartJs.ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          offset: true,
          gridLines: {
            offsetGridLines: false,
            color: getComputedStyle(document.body).getPropertyValue(
              '--selected-background'
            ),
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
            color: getComputedStyle(document.body).getPropertyValue(
              '--selected-background'
            ),
          },
        },
      ],
    },
  };
  public barChartLabels = [];
  public barChartType = 'bar';
  public barChartData: chartJs.ChartDataSets[] = [{ data: [] }];

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.last24Hours();
    chartJs.Chart.defaults.global.defaultFontColor = getComputedStyle(
      document.body
    ).getPropertyValue('--light-text-1');
  }

  public last24Hours() {
    this.clearChartData();
    const to = moment();
    const from = moment().subtract(24, 'hours');
    this.notificationService
      .getNotificationAggregation(from.toDate(), to.toDate(), 3, 'hours')
      .then((result) => {
        if (!result) {
          return;
        }

        const labels: Array<string> = [];
        for (const notificationAggregationItem of result[0]?.data
          ? result[0]?.data
          : []) {
          const intervalFrom = moment(
            new Date(notificationAggregationItem.from)
          );
          const intervalTo = moment(new Date(notificationAggregationItem.to));
          labels.push(
            intervalFrom.format('HH:mm') + ' - ' + intervalTo.format('HH:mm')
          );
        }
        this.barChartLabels = labels;
        this.barChartData = this.extractChartDataFromNotificationAggregationOutput(
          result
        );
      });
  }

  public lastWeek() {
    this.clearChartData();
    const to = moment().set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999,
    });
    const from = moment(to).subtract(7, 'days');
    this.notificationService
      .getNotificationAggregation(from.toDate(), to.toDate(), 1, 'days')
      .then((result) => {
        if (!result) {
          return;
        }

        const labels: Array<string> = [];
        for (const notificationAggregationItem of result[0].data) {
          const intervalTo = moment(new Date(notificationAggregationItem.to));
          labels.push(intervalTo.format('DD.MM'));
        }
        this.barChartLabels = labels;
        this.barChartData = this.extractChartDataFromNotificationAggregationOutput(
          result
        );
      });
  }

  private lastMonth() {
    this.clearChartData();
    const to = moment()
      .weekday(7)
      .set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
    const from = moment(to).subtract(4, 'weeks');
    this.notificationService
      .getNotificationAggregation(from.toDate(), to.toDate(), 1, 'weeks')
      .then((result) => {
        if (!result) {
          return;
        }

        const labels: Array<string> = [];
        for (const notificationAggregationItem of result[0].data) {
          const intervalTo = moment(new Date(notificationAggregationItem.to));
          labels.push('KW ' + intervalTo.isoWeek());
        }
        this.barChartLabels = labels;
        this.barChartData = this.extractChartDataFromNotificationAggregationOutput(
          result
        );
      });
  }

  /**
   *  This function retrieves the data of the last twelve months.
   *  Unfortunately 12 requests have to be sent, because months have no fixed range of minutes.
   */
  private lastYear() {
    this.clearChartData();
    const to = moment()
      .endOf('month')
      .set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
    const from = moment(to)
      .subtract(12, 'months')
      .startOf('month')
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    this.notificationService
      .getNotificationAggregation(from.toDate(), to.toDate(), 1, 'months')
      .then((result) => {
        if (!result) {
          return;
        }

        const labels: Array<string> = [];
        for (const notificationAggregationItem of result[0].data) {
          const intervalFrom = moment(
            new Date(notificationAggregationItem.from)
          );
          labels.push(intervalFrom.format('MM.yyyy'));
        }
        this.barChartLabels = labels;
        this.barChartData = this.extractChartDataFromNotificationAggregationOutput(
          result
        );
      });
  }

  private extractChartDataFromNotificationAggregationOutput(
    result: Array<NotificationAggregationOutput>
  ) {
    const chartData = [];
    for (const notificationAggregation of result) {
      const data = notificationAggregation.data.map((item) => item.count);
      if (notificationAggregation.type === 'warning') {
        chartData.push({
          data,
          label: 'Warnungen',
          backgroundColor: '#FD9725',
          fill: false,
          notificationType: notificationAggregation.type,
        });
      } else if (notificationAggregation.type === 'error') {
        chartData.push({
          data,
          label: 'Alarme',
          backgroundColor: '#C51162',
          fill: false,
          notificationType: notificationAggregation.type,
        });
      }
    }

    chartData.sort(
      (a, b) =>
        NotificationConfigurationType.severity(a.notificationType) -
        NotificationConfigurationType.severity(b.notificationType)
    );
    return chartData;
  }

  statisticTabChanged(event: MatTabChangeEvent) {
    switch (event.tab.textLabel) {
      case '24 std':
        this.last24Hours();
        break;
      case 'Woche':
        this.lastWeek();
        break;
      case 'Monat':
        this.lastMonth();
        break;
      case 'Jahr':
        this.lastYear();
        break;
    }
  }

  private clearChartData() {
    this.barChartLabels = [];
    this.barChartData = [{ data: [] }];
  }
}
