import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserGroupListComponent } from './user-group-list.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';

@NgModule({
  declarations: [UserGroupListComponent],
  imports: [
    CommonModule,
    MatIconModule,
    FlexModule,
    MatButtonModule,
    MatTableModule,
    MatMenuModule,
    MatCardModule,
    MatInputModule,
    FormsModule,
    MatChipsModule,
  ],
})
export class UserGroupListModule {}
