<div fxLayout="column" class="app-area">
  <!-- HEADER ROW -->
  <div fxLayout="row">
    <div fxFlex fxFlexAlign="start start">
      <h1>Sensorfehler</h1>
    </div>
  </div>

  <!-- Content -->
  <mat-card
    fxLayout="row wrap"
    class="square-card mat-elevation-z0"
    fxLayoutGap="24px"
    fxLayoutAlign="start start"
    style="height: 100%"
  >
    <div fxFlex="100">
      <h4>Sensor</h4>
    </div>
    <div fxFlex="calc(50%-24px)">
      <mat-form-field appearance="outline">
        <mat-label>Sensorname</mat-label>
        <input
          matInput
          placeholder="Sensorname"
          type="text"
          [ngModel]="measurementParseErrorGroupDetail.sensor.displayName"
          readonly
        />
      </mat-form-field>
    </div>
    <div fxFlex="calc(50%-24px)">
      <mat-form-field appearance="outline">
        <mat-label>EUI</mat-label>
        <input
          matInput
          placeholder="EUI"
          type="text"
          [ngModel]="measurementParseErrorGroupDetail.sensor.metaData.eui"
          readonly
        />
      </mat-form-field>
    </div>

    <div fxFlex="100">
      <h4>Sensortyp</h4>
    </div>
    <div fxFlex="calc(50%-24px)">
      <mat-form-field appearance="outline">
        <mat-label>Sensortyp</mat-label>
        <input
          matInput
          placeholder="Sensortype"
          type="text"
          [ngModel]="
            measurementParseErrorGroupDetail.sensorTypeConfig.displayName
          "
          readonly
        />
      </mat-form-field>
    </div>
    <div fxFlex="calc(50%-24px)">
      <button
        mat-raised-button
        color="primary"
        style="margin-top: 12px"
        [routerLink]="
          '/public-utility/sensor-types/edit/' +
          measurementParseErrorGroupDetail.sensorTypeConfig.id
        "
      >
        Sensortypeeinstellungen öffnen
      </button>
    </div>

    <div fxFlex="100">
      <h4>Sensoreigenschaft</h4>
    </div>
    <div fxFlex="calc(33%-24px)">
      <mat-form-field appearance="outline">
        <mat-label>Eigenschaft</mat-label>
        <input
          matInput
          placeholder="Eigenschaft"
          type="text"
          [ngModel]="
            measurementParseErrorGroupDetail.sensorPropertyConfig.displayName
          "
          readonly
        />
      </mat-form-field>
    </div>
    <div fxFlex="calc(33%-24px)">
      <mat-form-field appearance="outline">
        <mat-label>Eigenschaftsname (Property)</mat-label>
        <input
          matInput
          placeholder="Eigenschaftsname (Property)"
          type="text"
          [ngModel]="
            measurementParseErrorGroupDetail.sensorPropertyConfig.propertyName
          "
          readonly
        />
      </mat-form-field>
    </div>
    <div fxFlex="calc(33%-24px)">
      <mat-form-field appearance="outline">
        <mat-label>Eigenschaftstyp</mat-label>
        <input
          matInput
          placeholder="Eigenschaftstyp"
          type="text"
          [ngModel]="
            measurementParseErrorGroupDetail.sensorPropertyConfig.propertyType
          "
          readonly
        />
      </mat-form-field>
    </div>
    <ng-container
      *ngIf="measurementParseErrorGroupDetail.measurementParseErrors.length"
    >
      <div fxFlex="100"><h4>Aufgetretene Fehler</h4></div>
      <div fxFlex="100">
        <div class="table-wrapper">
          <table
            mat-table
            [dataSource]="
              measurementParseErrorGroupDetail.measurementParseErrors
            "
          >
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>Datum und Uhrzeit</th>
              <td mat-cell *matCellDef="let element">
                {{ element.date | date: "dd.MM.yyyy HH:mm:ss" }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
              mat-row
              *matRowDef="let element; columns: displayedColumns"
              class="clickable"
              [class.selected]="element === selectedMeasurementParseError"
              (click)="selectedMeasurementParseError = element"
            ></tr>
          </table>
        </div>
      </div>

      <div fxFlex="100">
        <h4 class="mt-24">Übertragene Datenstruktur</h4>
      </div>
      <div
        fxFlex="100"
        *ngIf="
          selectedMeasurementParseError &&
          selectedMeasurementParseError.sensorData
        "
      >
        <mat-form-field appearance="outline">
          <mat-label>Übertragene Datenstruktur</mat-label>
          <textarea
            matInput
            placeholder="Übertragene Datenstruktur"
            [ngModel]="objectAsString(selectedMeasurementParseError.sensorData)"
            rows="30"
            disabled
          ></textarea>
        </mat-form-field>
      </div>
    </ng-container>
  </mat-card>
</div>
