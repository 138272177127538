import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationConfigurationListModule } from 'src/app/organization/notification-configuration/components/notification-configuration-list/notification-configuration-list.module';
import { NotificationConfigurationEditModule } from 'src/app/organization/notification-configuration/components/notification-configuration-edit/notification-configuration-edit.module';
import { InfoTextListModule } from 'src/app/organization/notification-configuration/components/info-text-list/info-text-list.module';

@NgModule({
  imports: [
    CommonModule,
    NotificationConfigurationListModule,
    NotificationConfigurationEditModule,
    InfoTextListModule,
  ],
  exports: [
    NotificationConfigurationListModule,
    NotificationConfigurationEditModule,
    InfoTextListModule,
  ],
})
export class NotificationConfigurationModule {}
