import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiError } from 'src/app/_shared/exception/api.error';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/users/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';

@Component({
  selector: 'app-request-new-password',
  templateUrl: './request-new-password.component.html',
  styleUrls: ['./request-new-password.component.scss'],
})
export class RequestNewPasswordComponent implements OnInit {
  @ViewChild('f') requestPasswordForm: NgForm;
  requestPasswordError: string;
  requestPasswordSuccess: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.authenticationService.logout(false);
  }

  onSubmit() {
    this.userService
      .requestNewPassword(this.requestPasswordForm.value.username)
      .then((result) => {
        if (!result) {
          return;
        }
        if (result.status === 201) {
          this.requestPasswordError = null;
          this.requestPasswordSuccess = 'Email erfolgreich versandt.';
          setTimeout(() => {
            this.closeDialog();
          }, 3000);
        }
      })
      .catch((error) => {
        if (error instanceof ApiError) {
          if (error.error) {
            this.requestPasswordError = error.error;
          }
          if (404 === error.statusCode) {
            this.requestPasswordError = 'Dieser Benutzer existiert nicht.';
          }
        }

        if (error instanceof HttpErrorResponse) {
          if (401 === error.status) {
            this.requestPasswordError = error.message;
          } else if (0 === error.status) {
            this.requestPasswordError = 'Server ist nicht erreichbar.';
          } else if (404 === error.status) {
            this.requestPasswordError = 'Dieser Benutzer existiert nicht.';
          }
        }

        if (!this.requestPasswordError) {
          this.requestPasswordError = 'Unbekannter Fehler';
        }
      });
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
