import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { PositioningOverviewComponent } from 'src/app/organization/positioning/components/positioning/positioning-overview.component';
import { PositioningGroupCardModule } from 'src/app/organization/positioning/components/positioning-group-card/positioning-group-card.module';
import { PositioningSensorDetailCardModule } from 'src/app/organization/positioning/components/positioning-sensor-detail-card/positioning-sensor-detail-card.module';

@NgModule({
  declarations: [PositioningOverviewComponent],
  exports: [PositioningOverviewComponent],
  imports: [
    CommonModule,
    FlexModule,
    MatButtonModule,
    PositioningGroupCardModule,
    MatIconModule,
    PositioningSensorDetailCardModule,
  ],
})
export class PositioningOverviewModule {}
