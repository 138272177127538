import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/_core/services/api/api.service';
import { SnackBarService } from 'src/app/_shared/services/snack-bar.service';
import { LegalInputDto } from 'src/app/public-utility/legal-settings/dto/legal-input.dto';
import { LegalOutputDto } from 'src/app/public-utility/legal-settings/dto/legal-output.dto';
import { PushNotificationInputDto } from 'src/app/public-utility/global-push/dto/push-notification-input.dto';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  constructor(
    private apiService: ApiService,
    private snackBarService: SnackBarService
  ) {}

  sendNotification(
    pushNotificationDto: PushNotificationInputDto
  ): Promise<void> {
    return this.apiService
      .post<void>('/pushes/global-push', pushNotificationDto)
      .then((res) => {
        this.snackBarService.showCustomMessage(
          'Push-Benachrichtigung versendet.'
        );
        return res;
      });
  }
}
