import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-date-time-modal',
  templateUrl: './date-time-modal.component.html',
  styleUrls: ['./date-time-modal.component.scss'],
})
export class DateTimeModalComponent implements OnInit {
  form: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<DateTimeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { date: Date },
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      date: new FormControl(this.data.date, [Validators.required]),
      time: new FormControl(this.datePipe.transform(this.data.date, 'HH:mm'), [
        Validators.required,
      ]),
    });
  }

  close() {
    if (!this.form.valid) {
      return;
    }

    const dateTime: Date = this.form.value.date;
    dateTime.setHours(this.form.value.time.split(':')[0]);
    dateTime.setMinutes(this.form.value.time.split(':')[1]);
    this.dialogRef.close(dateTime);
  }
}
