<mat-form-field appearance="outline" style="width: 100%">
  <mat-label>Alarmkonfigurationen</mat-label>
  <mat-select
    [(ngModel)]="value"
    #selectModel="ngModel"
    placeholder="Alarmkonfigurationen"
    multiple
    [disabled]="formControl?.disabled"
  >
    <mat-option>
      <ngx-mat-select-search
        [formControl]="notificationConfigFilterCtrl"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-option
      *ngFor="let notificationConfig of filteredNotificationConfigs | async"
      [value]="notificationConfig"
    >
      {{ notificationConfig.title }}
    </mat-option>
  </mat-select>
  <button
    mat-button
    *ngIf="isEmptySelection()"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="value = []; $event.stopPropagation()"
    style="
      transform: translateY(-17%);
      color: rgba(0, 0, 0, 0.54);
      width: 30px;
      height: 30px;
    "
  >
    <mat-icon inline color="">close</mat-icon>
  </button>
</mat-form-field>
