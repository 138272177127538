import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-parking-icon',
  template: `
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      [style.height]="size + 'px'"
      [style.width]="size + 'px'"
    >
      <rect
        width="28"
        height="28"
        rx="6"
        [attr.fill]="backgroundColor ? backgroundColor : '#1287B9'"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.9996 4.427C12.1062 4.427 10.2554 4.98844 8.6812 6.04031C7.10696 7.09218 5.87999 8.58725 5.15545 10.3365C4.43091 12.0857 4.24133 14.0104 4.6107 15.8674C4.98007 17.7243 5.89179 19.43 7.23057 20.7688C8.56935 22.1076 10.2751 23.0193 12.132 23.3887C13.989 23.758 15.9137 23.5685 17.6629 22.8439C19.4121 22.1194 20.9072 20.8924 21.9591 19.3182C23.0109 17.7439 23.5724 15.8931 23.5724 13.9998C23.5724 12.7427 23.3248 11.4979 22.8437 10.3365C22.3626 9.17503 21.6575 8.11973 20.7686 7.23081C19.8797 6.3419 18.8244 5.63677 17.6629 5.15569C16.5015 4.67461 15.2567 4.427 13.9996 4.427ZM9.51456 7.28752C10.8421 6.40046 12.4029 5.927 13.9996 5.927C15.0597 5.927 16.1095 6.13581 17.0889 6.54151C18.0683 6.9472 18.9583 7.54184 19.7079 8.29147C20.4575 9.0411 21.0522 9.93104 21.4579 10.9105C21.8636 11.8899 22.0724 12.9397 22.0724 13.9998C22.0724 15.5965 21.5989 17.1573 20.7119 18.4848C19.8248 19.8124 18.564 20.8471 17.0889 21.4581C15.6138 22.0691 13.9906 22.229 12.4246 21.9175C10.8587 21.606 9.42024 20.8372 8.29123 19.7081C7.16223 18.5791 6.39337 17.1407 6.08188 15.5747C5.77039 14.0088 5.93026 12.3856 6.54127 10.9105C7.15228 9.43537 8.18699 8.17457 9.51456 7.28752ZM14.4598 15.6666H13.1455L13.1455 18.1249C13.1455 18.5391 12.8097 18.8749 12.3955 18.8749C11.9813 18.8749 11.6455 18.5391 11.6455 18.1249L11.6455 14.9166L11.6455 10.3367C11.6455 10.1378 11.7245 9.94707 11.8652 9.80641C12.0058 9.66576 12.1966 9.58674 12.3955 9.58674H14.4598C16.1387 9.58674 17.4997 10.9478 17.4997 12.6267C17.4997 14.3056 16.1387 15.6666 14.4598 15.6666ZM13.1455 14.1666L13.1455 11.0867H14.4598C15.3102 11.0867 15.9997 11.7762 15.9997 12.6267C15.9997 13.4771 15.3102 14.1666 14.4598 14.1666H13.1455Z"
        [attr.fill]="'white'"
      />
    </svg>
  `,
  styles: [],
})
export class ParkingIconComponent implements OnInit {
  @Input() backgroundColor: string;
  @Input() size = 128;

  constructor() {}

  ngOnInit(): void {}
}
