import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Organization } from 'src/app/public-utility/organization/models/organization';

@Component({
  selector: 'app-organization-select-modal',
  templateUrl: './organization-select-modal.component.html',
  styleUrls: ['./organization-select-modal.component.scss'],
})
export class OrganizationSelectModalComponent implements OnInit {
  organizations: Organization[];
  selectedOrganizations: Organization;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { organizations: Organization[] }
  ) {}

  ngOnInit(): void {
    this.organizations = this.data.organizations;
  }
}
