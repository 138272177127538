import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { UserGroupService } from 'src/app/users/services/user-group.service';
import { UserGroup } from 'src/app/users/models/user-group';
import { User } from 'src/app/users/models/user';
import { UserService } from 'src/app/users/services/user.service';
import { ApiService } from 'src/app/_core/services/api/api.service';

interface UserGroupEditResolverResult {
  userGroup: UserGroup;
  users: User[];
}

@Injectable({
  providedIn: 'root',
})
export class UserGroupEditResolver
  implements Resolve<void | UserGroupEditResolverResult> {
  constructor(
    private userGroupService: UserGroupService,
    private userService: UserService,
    private apiService: ApiService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<void | UserGroupEditResolverResult> {
    const promises = [];

    promises.push(this.userGroupService.getDetail(route.params.id, false));
    promises.push(this.userService.getUserList(false));
    return Promise.all(promises)
      .then((allPromisesResult) => {
        return {
          userGroup: allPromisesResult[0],
          users: allPromisesResult[1],
        } as UserGroupEditResolverResult;
      })
      .catch((err) => {
        this.apiService.handleError(err);
        throw err;
      });
  }
}
