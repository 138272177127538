import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { DashboardWarningsCardComponent } from 'src/app/organization/dashboard/components/warnings-card/dashboard-warnings-card.component';
import { ChartsModule } from 'ng2-charts';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [DashboardWarningsCardComponent],
  exports: [DashboardWarningsCardComponent],
  imports: [CommonModule, MatTabsModule, ChartsModule, MatCardModule],
})
export class DashboardWarningsCardModule {}
