import { Injectable, OnDestroy } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Subject } from 'rxjs';
import { UserSession } from 'src/app/_core/models/user-session';
import { ApiService } from 'src/app/_core/services/api/api.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LoginDto } from 'src/app/_core/dtos/login.dto';
import { DisposeBag } from '@ronas-it/dispose-bag/dist/src';
import { Roles } from 'src/app/_core/models/roles';
import { DecodedUserSession } from 'src/app/_core/models/decoded-user-session';
import { OrganizationService } from 'src/app/_shared/services/organization.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService implements OnDestroy {
  apiError?: string;
  private jwtHelperService = new JwtHelperService();
  public organizationDataChangedSubject = new Subject<{
    logo: string;
    organizationName: string;
  }>();
  public userSessionChangedSubject = new Subject<UserSession>();
  private disposeBag = new DisposeBag();

  constructor(
    private router: Router,
    private apiService: ApiService,
    private organizationService: OrganizationService,
    private http: HttpClient
  ) {
    apiService.setAuthenticationService(this);
  }

  ngOnDestroy() {
    this.disposeBag.unsubscribe();
  }

  authenticate(
    username: string,
    password: string
  ): Promise<UserSession | void> {
    const dto: LoginDto = { username, password };
    return this.apiService.post<UserSession>(
      '/users/login',
      dto,
      {},
      false,
      false
    );
  }

  login(username: string, password: string): Promise<boolean | void> {
    return this.authenticate(username, password).then((value) => {
      if (!value) {
        return false;
      }

      localStorage.setItem('currentUserSession', JSON.stringify(value));

      const decoded = this.jwtHelperService.decodeToken(value.accessToken);
      this.userSessionChangedSubject.next(decoded);

      this.organizationService.getOwnOrganization().then((organization) => {
        if (!organization) {
          this.organizationDataChangedSubject.next(null);
          return;
        }
        if (
          organization.logo !== null &&
          organization.logo !== undefined &&
          organization.logo !== ''
        ) {
          localStorage.setItem('organizationLogo', organization.logo);
        }

        this.organizationDataChangedSubject.next({
          logo: organization.logo,
          organizationName: organization.name,
        });
      });
      return true;
    });
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem('currentUserSession');
  }

  logout(navigateToRootRoute = true) {
    localStorage.removeItem('currentUserSession');
    if (navigateToRootRoute) {
      this.router.navigateByUrl('/');
    }
    this.userSessionChangedSubject.next(undefined);
  }

  getCurrentUserSession(): DecodedUserSession {
    const result = JSON.parse(
      localStorage.getItem('currentUserSession')
    ) as UserSession;

    if (!result) {
      return null;
    }

    const decoded = this.jwtHelperService.decodeToken(result.accessToken);

    // The intention is to Logout User if the Role is not known. At the time of implementation this is the case because the roles have been renamed.
    // In general, however, such a check is useful, as with an unknown role it is not clear how the side menu should be structured and which views can be opened.
    if (
      ![
        Roles.ORGANIZATION_OWNER,
        Roles.ORGANIZATION_VIEWER,
        Roles.ORGANIZATION_EDITOR,
        Roles.CITYLINK_EDITOR,
        Roles.CITYLINK_OWNER,
        Roles.CITYLINK_VIEWER,
      ].includes(decoded.role as Roles)
    ) {
      this.logout();
    }

    return decoded;
  }

  async refreshSessionIfNecessary() {
    if (!this.isLoggedIn()) {
      return;
    }

    const currentUserSession = JSON.parse(
      localStorage.getItem('currentUserSession')
    );

    if (
      this.jwtHelperService.isTokenExpired(currentUserSession.accessToken, 3)
    ) {
      const reqOpts = {
        headers: {
          Authorization: 'Bearer ' + currentUserSession.accessToken,
        },
      };

      // Request NOT via apiService, because that would cause a stack overflow because of recursion.
      await this.http
        .post(
          environment.apiUrl + '/users/refresh-token',
          { refresh_token: currentUserSession.refreshToken },
          reqOpts
        )
        .toPromise()
        .then((result: { data: { token: string; refresh_token: string } }) => {
          currentUserSession.accessToken = result.data.token;
          currentUserSession.refreshToken = result.data.refresh_token;
          localStorage.setItem(
            'currentUserSession',
            JSON.stringify(currentUserSession)
          );
        })
        .catch((_) => {
          // this.logout();
        });
    }
  }
}
