import { Component, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/users/services/user.service';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';

@Component({
  selector: 'app-user-confirmation',
  templateUrl: './user-confirmation.component.html',
  styleUrls: ['./user-confirmation.component.scss'],
})
export class UserConfirmationComponent implements OnInit {
  confirmationToken: string;

  @Output()
  apiError: string;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    if (this.authenticationService.isLoggedIn()) {
      this.authenticationService.logout(false);
    }

    this.activatedRoute.queryParams.subscribe((params) => {
      this.confirmationToken = params.confirmationToken;
      this.userService
        .validateConfirmationToken(this.confirmationToken)
        .then((_) => {
          setTimeout(() => {
            this.router.navigateByUrl(
              '/users-password-reset' +
                '?confirmationToken=' +
                this.confirmationToken
            );
          }, 5000);
        })
        .catch((error) => {
          if (404 === error.statusCode || 404 === error.status) {
            this.apiError =
              'Dieser Link ist ungültig. Sie wurden zum Login weitergeleitet.';
          }

          if (401 === error.statusCode || 401 === error.status) {
            this.apiError = error.message;
          } else if (0 === error.statusCode || 0 === error.status) {
            this.apiError =
              'Link kann nicht verifiziert werden, da der Server nicht erreichbar ist.';
          }

          if (!this.apiError) {
            this.apiError = 'Unknown Error';
          }
          this.authenticationService.apiError = this.apiError;
          this.router.navigateByUrl('/login');
        });
    });
  }
}
