import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../models/user';

@Component({
  selector: 'app-username-confirmation-hint',
  templateUrl: './username-confirmation-hint.component.html',
  styleUrls: ['./username-confirmation-hint.component.scss'],
})
export class UsernameConfirmationHintComponent implements OnInit {
  @Input() user: User;

  constructor() {}

  ngOnInit(): void {}
}
