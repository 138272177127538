<mat-card class="square-card mat-elevation-z0">
  <h4 class="square-card-title">Geländeübersicht</h4>
  <div style="padding-left: 8px; padding-right: 8px">
    <div fxLayout="row">
      <div fxFlex="60" style="margin-right: 8px; height: 400px">
        <google-map height="100%" width="100%" [options]="gmOptions">
          <map-marker
            *ngFor="let marker of markers"
            [position]="marker.markerPosition"
            [options]="marker.markerOptions"
            (mapClick)="showSensorInTree(marker.sensor)"
          ></map-marker>
          <map-polygon
            *ngFor="let polygon of polygons"
            [paths]="polygon.path"
            [options]="buildPolygonOptionsForColor(polygon.color)"
          ></map-polygon>
        </google-map>
      </div>
      <div fxFlex="40" style="margin-left: 8px" fxLayout="column">
        <h3 style="margin-bottom: 4px" class="truncate-text">
          {{ this.treeRoot?.displayName }}
        </h3>
        <div class="site-detail-row">
          {{ countSensorGroupNodes(treeRoot) }} Ebenen,
          {{ countSensor(treeRoot) }}Sensoren
        </div>
        <div class="site-detail-row">
          <span class="text-warning"
            >{{ countWarnings(treeRoot) }} Warnungen</span
          >,
          <span class="text-danger">{{ countAlarm(treeRoot) }} Alarm</span>
        </div>
        <mat-card
          class="square-card mat-elevation-z0"
          style="height: 307px; overflow: auto; margin: 8px 0"
        >
          <app-tree-item
            *ngFor="let item of treeRoot?.children"
            [item]="item"
            [depth]="0"
          ></app-tree-item>
          <app-sensor-item
            *ngFor="let sensor of treeRoot?.sensors"
            [sensor]="sensor"
            [paddingLeft]=""
          ></app-sensor-item>
        </mat-card>
      </div>
    </div>
  </div>
</mat-card>
