import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeasurementParseErrorGroupsDetailComponent } from 'src/app/public-utility/measurement-parse-errors/components/measurement-parse-error-detail/measurement-parse-error-groups-detail.component';
import { FlexModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [MeasurementParseErrorGroupsDetailComponent],
  imports: [
    CommonModule,
    FlexModule,
    MatCardModule,
    MatInputModule,
    FormsModule,
    MatTableModule,
    MatButtonModule,
    RouterModule,
  ],
})
export class MeasurementParseErrorDetailModule {}
