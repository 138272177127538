import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { SensorService } from 'src/app/_shared/services/sensor.service';
import { Sensor } from 'src/app/_shared/models/sensor';
import {
  SensorSourceOfGeneration,
  sensorSourceOfGenerationToLabel,
} from 'src/app/_shared/enum/sensor-source-of-generation';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SensorTypeConfig } from 'src/app/_shared/models/sensor-type-config';
import { Organization } from 'src/app/public-utility/organization/models/organization';
import { FormGroup } from '@angular/forms';
import { SensorFilterInputDto } from 'src/app/public-utility/sensors/dto/sensor-filter-input-dto';
import { PageEvent } from '@angular/material/paginator';
import { PaginatedCitylinkSensor } from 'src/app/public-utility/sensors/models/paginated-citylink-sensor';
import { SensorListResolverResult } from 'src/app/public-utility/sensor-types/resolvers/sensor-list-resolver';
import { CitylinkOrganization } from 'src/app/public-utility/organization/models/citylink-organization';

class SensorTableElement extends Sensor {
  selected: boolean;
}

@Component({
  selector: 'app-sensors-list',
  templateUrl: './sensors-list.component.html',
  styleUrls: ['./sensors-list.component.scss'],
})
export class SensorsListComponent implements OnInit {
  enabled = true;
  matTableDataSource = new MatTableDataSource<SensorTableElement>();
  displayedColumns = [
    'checkbox',
    'title',
    'eui',
    'sensorTypeConfig',
    'sourceOfGeneration',
    'organization',
    'connectionStatus',
    'applyChangesInIotPlatform',
    'latestMeasurementDate',
    'options',
  ];
  allSelected = false;
  indeterminate = false;
  selectedCount = 0;
  niotaImportInProgress = false;
  sensorFilterInput = {
    title: undefined,
    eui: undefined,
    sensorTypeConfig: undefined,
    organizations: [],
  };
  filterChangedSubject = new Subject<void>();
  sensorTypeConfigs: SensorTypeConfig[] = [];
  organizations: CitylinkOrganization[] = [];
  form: FormGroup;

  totalLength = 100;
  pageSize: 100;

  constructor(
    private route: ActivatedRoute,
    private sensorService: SensorService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const dto: SensorListResolverResult = this.route.snapshot.data.data;

    this.sensorTypeConfigs = dto.sensorTypeConfigs;
    this.sensorTypeConfigs.sort((a, b) => {
      return a.displayName.localeCompare(b.displayName);
    });

    this.organizations = dto.organizations;
    this.organizations.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    this.matTableDataSource.data = (dto.sensors
      .data as unknown) as SensorTableElement[];
    this.totalLength = dto.sensors.pagination.itemCount;

    this.filterChangedSubject.pipe(debounceTime(300)).subscribe(() => {
      const newDto: SensorFilterInputDto = {
        title: this.sensorFilterInput.title,
        eui: this.sensorFilterInput.eui,
        sensorTypeConfig: this.sensorFilterInput.sensorTypeConfig?.id,
        organizationIds:
          this.sensorFilterInput.organizations.length === 0
            ? undefined
            : this.sensorFilterInput.organizations.map((it) => it.id) || [],
      };

      this.sensorService
        .getSensors(0, 100, true, newDto)
        .then((results: PaginatedCitylinkSensor) => {
          //@ts-ignore
          this.matTableDataSource.data = (results.data as unknown) as SensorTableElement[];
          this.totalLength = results.pagination.itemCount;
        });
    });
  }

  isFilter(): boolean {
    return !!(
      this.sensorFilterInput.eui ||
      this.sensorFilterInput.title ||
      (this.sensorFilterInput.organizations &&
        this.sensorFilterInput.organizations.length) ||
      this.sensorFilterInput.sensorTypeConfig
    );
  }

  onClearFilterClicked() {
    this.sensorFilterInput.eui = null;
    this.sensorFilterInput.title = '';
    this.sensorFilterInput.organizations = [];
    this.sensorFilterInput.sensorTypeConfig = null;

    this.sensorService
      .getSensors(0, 100, true, this.sensorFilterInput)
      .then((results: PaginatedCitylinkSensor) => {
        //@ts-ignore
        this.matTableDataSource.data = results.data;
        this.totalLength = results.pagination.itemCount;
      });
  }

  onAddClicked() {
    this.router.navigateByUrl('/public-utility/sensors/create');
  }

  updateSensor(element) {
    this.router.navigateByUrl('/public-utility/sensors/edit/' + element.id);
  }

  deleteSensor(element: Sensor) {
    this.sensorService.delete(element.id).then((deleted) => {
      if (!deleted) {
        return;
      }
      this.matTableDataSource.data = this.matTableDataSource.data.filter(
        (item) => item.id !== deleted.id
      );
    });
  }

  onCSVImportClicked() {
    this.router.navigateByUrl('/public-utility/sensors/csv-import');
  }

  onNiotaUpdateClicked() {
    this.niotaImportInProgress = true;
    this.sensorService
      .niotaUpdate()
      .then((_) => {
        this.sensorService
          .getSensors()
          .then((sensors: PaginatedCitylinkSensor) => {
            this.matTableDataSource.data = (sensors.data as unknown) as SensorTableElement[];
            this.totalLength = sensors.pagination.itemCount;
          });
      })
      .finally(() => {
        this.niotaImportInProgress = false;
      });
  }

  onNiotaImportClicked() {
    this.niotaImportInProgress = true;
    this.sensorService
      .niotaImport()
      .then((_) => {
        this.sensorService
          .getSensors()
          .then((sensors: PaginatedCitylinkSensor) => {
            this.matTableDataSource.data = (sensors.data as unknown) as SensorTableElement[];
            this.totalLength = sensors.pagination.itemCount;
          });
      })
      .finally(() => {
        this.niotaImportInProgress = false;
      });
  }

  indeterminateCheckBoxClicked() {
    if (this.allSelected) {
      this.matTableDataSource.data.forEach((item) => (item.selected = true));
      this.selectedCount = this.matTableDataSource.data.length;
    } else {
      this.matTableDataSource.data.forEach((item) => (item.selected = false));
      this.selectedCount = 0;
    }
  }

  selectionChanged() {
    this.selectedCount = this.matTableDataSource.data
      .map((item) => item.selected)
      .filter((v) => v).length;
    if (this.selectedCount === this.matTableDataSource.data.length) {
      this.allSelected = true;
      this.indeterminate = false;
    } else if (this.selectedCount > 0) {
      this.allSelected = false;
      this.indeterminate = true;
    } else {
      this.allSelected = false;
      this.indeterminate = false;
    }
  }

  onListEditClicked() {
    const selectedSensorIds = this.matTableDataSource.data
      .filter((item) => item.selected)
      .map((item) => item.id);
    this.router.navigateByUrl(
      '/public-utility/sensors/list-edit?sensors=' + selectedSensorIds
    );
  }

  onQrCodeClicked() {
    const selectedSensorEuis = this.matTableDataSource.data
      .filter((item) => item.selected)
      .map((item) => item.metaData.eui);
    this.router.navigateByUrl(
      '/public-utility/sensors/qr-codes?euis=' + selectedSensorEuis
    );
  }

  sourceOfGenerationToLabel(sourceOfGeneration: SensorSourceOfGeneration) {
    return sensorSourceOfGenerationToLabel(sourceOfGeneration);
  }

  isWithinLast24Hours(latestMeasurementDate: Date) {
    const hours24 = 1000 * 60 * 60 * 24;
    const hourago24 = Date.now() - hours24;

    return latestMeasurementDate.getTime() > hourago24;
  }

  isNotWithinLast24Hours(latestMeasurementDate: Date) {
    const hours24 = 1000 * 60 * 60 * 24;
    const hourago24 = Date.now() - hours24;

    return latestMeasurementDate.getTime() <= hourago24;
  }

  sensorTypeToLabel(sensorType: SensorTypeConfig) {
    return `${sensorType.displayName} (${sensorType.niotaDeviceTypeId})`;
  }

  organizationToLabel(organization: Organization) {
    return `${organization.name}`;
  }

  pageChangeEvent($event: PageEvent) {
    this.sensorService
      .getSensors($event.pageIndex, 100, true, this.sensorFilterInput)
      .then((res: PaginatedCitylinkSensor) => {
        if (res) {
          this.matTableDataSource.data = (res.data as unknown) as SensorTableElement[];
          this.totalLength = res.pagination.itemCount;
        }
      });
  }
}
