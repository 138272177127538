<h2 mat-dialog-title>Löschen</h2>
<mat-dialog-content class="mat-typography">
  Sind Sie sicher, dass Sie das Objekt löschen möchten?
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close class="upper-case">Abbrechen</button>
  <button
    mat-button
    color="warn"
    [mat-dialog-close]="'delete'"
    class="upper-case"
    style="margin-left: auto"
  >
    Löschen
  </button>
</mat-dialog-actions>
