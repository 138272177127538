import { SensorGroupNodePermissionsInputDto } from 'src/app/_shared/dtos/sensor-group-node-permissions-input.dto';
import { AddressDto } from 'src/app/_shared/dtos/address.dto';
import { PolygonDto } from 'src/app/_shared/dtos/polygon.dto';

export class SensorGroupNodeInputDto {
  displayName: string;
  sortCriteria?: number;
  parent?: number;
  address?: AddressDto;
  geoAreaPolygon?: PolygonDto;
  permissions?: SensorGroupNodePermissionsInputDto;
  image?: string;
}
