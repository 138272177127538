import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/_core/services/api/api.service';
import { MeasurementParseErrorPaginatedOutputDto } from 'src/app/public-utility/measurement-parse-errors/dto/measurement-parse-error-paginated.output';
import { MeasurementParseErrorGroupDetailOutputDto } from 'src/app/public-utility/measurement-parse-errors/dto/measurement-parse-error-detail.output';
import { MeasurementParseErrorFilterInputDto } from 'src/app/public-utility/measurement-parse-errors/dto/measurement-parse-error-filter-input.dto';

@Injectable({
  providedIn: 'root',
})
export class MeasurementParseErrorService {
  constructor(private apiService: ApiService) {}

  getList(
    page = 0,
    limit = 100,
    filterOptions?: MeasurementParseErrorFilterInputDto
  ): Promise<void | MeasurementParseErrorPaginatedOutputDto> {
    const reqHeaders = { headers: {} };
    reqHeaders.headers['x-page-index'] = `${page}`;
    reqHeaders.headers['x-page-limit'] = `${limit}`;

    return this.apiService.get<MeasurementParseErrorPaginatedOutputDto>(
      '/sensors/measurements/errors',
      reqHeaders,
      filterOptions
    );
  }

  getById(
    id: number
  ): Promise<void | MeasurementParseErrorGroupDetailOutputDto> {
    return this.apiService.get<MeasurementParseErrorGroupDetailOutputDto>(
      `/sensors/measurements/errors/${id}`
    );
  }
}
