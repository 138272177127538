export enum NotificationRepetitionTypeBase {
  HOURLY = 'hourly',
  THRESHOLD_EXCEEDED = 'threshold_exceeded',
}

export class NotificationRepetitionType {
  public static entries() {
    return [
      NotificationRepetitionTypeBase.HOURLY,
      NotificationRepetitionTypeBase.THRESHOLD_EXCEEDED,
    ];
  }

  public static translate(repetitionType: NotificationRepetitionTypeBase) {
    switch (repetitionType) {
      case NotificationRepetitionTypeBase.HOURLY:
        return 'stündlich';
      case NotificationRepetitionTypeBase.THRESHOLD_EXCEEDED:
        return 'bei Schwellenwertüberschreitung';
    }
  }
}
