import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Sensor } from 'src/app/_shared/models/sensor';
import { SensorEventType } from 'src/app/organization/dashboard/models/sensor-event-type';
import { MatDialog } from '@angular/material/dialog';
import { EditSensorModalComponent } from 'src/app/organization/positioning/components/edit-sensor-modal/edit-sensor-modal.component';
import { SnackBarService } from 'src/app/_shared/services/snack-bar.service';
import { PositioningService } from 'src/app/organization/positioning/services/positioning.service';
import { SensorService } from 'src/app/_shared/services/sensor.service';

@Component({
  selector: 'app-positioning-sensor',
  templateUrl: './positioning-sensor.component.html',
  styleUrls: ['./positioning-sensor.component.scss'],
})
export class PositioningSensorComponent implements OnInit {
  @Input() sensor: Sensor;
  @Input() enabled: boolean;

  @ViewChild('sensorRow') sensorRow;

  dragging = 0;

  constructor(
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
    private sensorService: SensorService,
    public positioningService: PositioningService
  ) {}

  ngOnInit(): void {}

  onClick() {
    this.positioningService.selectSensor(this.sensor);
    this.positioningService.selectionChangedSubject.next(this.sensor);
  }

  hasAlarms() {
    if (this.sensor.notificationInfos) {
      return (
        this.sensor.notificationInfos.filter(
          (item) => SensorEventType.error === item.type
        )[0]?.count > 0
      );
    }
  }

  hasWarnings() {
    if (this.sensor.notificationInfos) {
      return (
        this.sensor.notificationInfos.filter(
          (item) => SensorEventType.warning === item.type
        )[0]?.count > 0
      );
    }
  }

  alarmCount() {
    if (this.sensor.notificationInfos) {
      return this.sensor.notificationInfos.filter(
        (item) => SensorEventType.error === item.type
      )[0]?.count;
    }
  }

  warningsCount() {
    if (this.sensor.notificationInfos) {
      return this.sensor.notificationInfos.filter(
        (item) => SensorEventType.warning === item.type
      )[0]?.count;
    }
  }

  onDragEnter(event) {
    this.dragging++;
    /**
     * The Browser does not allow to get data in dragover or dragleave.
     * https://stackoverflow.com/questions/11065803/determine-what-is-being-dragged-from-dragenter-dragover-events
     * Therefore check the flag which was set in onDragStart
     */
    const isSensor = event.dataTransfer.types.includes('is_sensor');
    event.preventDefault();
    if (isSensor) {
      this.sensorRow.nativeElement.classList.add('group-row-drag-over');
    }
  }

  onDragLeave(event) {
    this.dragging--;

    if (this.dragging <= 0) {
      this.sensorRow.nativeElement.classList.remove('group-row-drag-into');
      this.sensorRow.nativeElement.classList.remove('group-row-drag-over');
      this.sensorRow.nativeElement.classList.add('group-row');
    }
  }

  onDragStart(event) {
    requestAnimationFrame(() =>
      this.sensorRow.nativeElement.classList.add('hide')
    );
    event.dataTransfer.setData('text', this.sensor.id);

    /*
     * The Browser does not allow to get data in dragover or dragleave.
     * https://stackoverflow.com/questions/11065803/determine-what-is-being-dragged-from-dragenter-dragover-events
     * Therefore add a flag which can be checked dataTransfer.types.includes("xyz").
     */
    event.dataTransfer.setData('is_sensor', 'true');
  }

  onDrop(event) {
    event.stopPropagation();
    this.dragging = 0;
    this.sensorRow.nativeElement.classList.remove('group-row-drag-into');
    this.sensorRow.nativeElement.classList.remove('group-row-drag-over');
    this.sensorRow.nativeElement.classList.add('sensor-row');

    const dropped = JSON.parse(event.dataTransfer.getData('text'));
    this.positioningService.moveSensor(dropped, this.sensor);
  }

  onDragEnd($event: DragEvent) {
    this.dragging = 0;
    this.sensorRow.nativeElement.classList.remove('hide');
  }

  editSensor() {
    this.dialog
      .open(EditSensorModalComponent, {
        width: '620px',
        data: {
          sensorGroupNode: this.positioningService.findParentOfSensorRecursive(
            this.sensor,
            this.positioningService.treeRoot
          ),
          sensor: this.sensor,
        },
      })
      .afterClosed()
      .subscribe((result: Sensor) => {
        if (!result) {
          return;
        }
        const parentSensorGroupNode = this.positioningService.findParentOfSensorRecursive(
          this.sensor,
          this.positioningService.treeRoot
        );
        parentSensorGroupNode.sensors[
          parentSensorGroupNode.sensors.indexOf(this.sensor)
        ] = result;
        this.sensor = result;

        this.snackBarService.saveSuccessful();
      });
  }

  removeSensor() {
    this.sensorService.unpair(this.sensor.id).then((result) => {
      const parent = this.positioningService.findParentOfSensorRecursive(
        this.sensor,
        this.positioningService.treeRoot
      );
      parent.sensors.splice(parent.sensors.indexOf(this.sensor), 1);
    });
  }
}
