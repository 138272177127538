import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationConfigurationListComponent } from 'src/app/organization/notification-configuration/components/notification-configuration-list/notification-configuration-list.component';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { IconsModule } from 'src/app/organization/notification-configuration/components/icons/icons.module';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { FilterableSelectModule } from 'src/app/_shared/components/filterable-select/filterable-select.module';

@NgModule({
  declarations: [NotificationConfigurationListComponent],
  exports: [NotificationConfigurationListComponent],
  imports: [
    CommonModule,
    MatTableModule,
    FlexModule,
    MatIconModule,
    MatButtonModule,
    MatChipsModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatSortModule,
    MatMenuModule,
    RouterModule,
    IconsModule,
    MatTooltipModule,
    MatCardModule,
    MatSelectModule,
    FilterableSelectModule,
  ],
})
export class NotificationConfigurationListModule {}
