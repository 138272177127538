<div fxLayout="column" class="app-area">
  <!-- HEADER ROW -->
  <div fxLayout="row">
    <div fxFlex fxFlexAlign="start start">
      <h1>{{ sensorName }}</h1>
    </div>
  </div>

  <!-- Content -->
  <div fxLayout="row wrap" style="margin-top: 12px; margin-bottom: 12px">
    <div fxFlex="100" fxLayoutAlign="center center">
      <button
        mat-button
        fxFlex="160px"
        (click)="changeMeasurementAggregationFrom()"
      >
        {{ measurementAggregationInputDto.from | date: "dd.MM.yyyy HH:mm" }}
      </button>
      <div fxFlex="20px" fxLayoutAlign="center center">
        <div>-</div>
      </div>
      <button
        mat-button
        fxFlex="160px"
        (click)="changeMeasurementAggregationTo()"
      >
        {{ measurementAggregationInputDto.to | date: "dd.MM.yyyy HH:mm" }}
      </button>
    </div>
    <div
      *ngFor="let measurement of measurements"
      fxFlex.gt-xs="100"
      fxFlex.gt-sm="100"
      fxFlex.gt-md="50"
      fxFlex.gt-lg="50"
    >
      <mat-card
        class="square-card"
        fxLayout="column"
        fxLayoutAlign="center stretch"
      >
        <app-sensor-measurements-chart
          [measurementIntervalData]="measurement"
        ></app-sensor-measurements-chart>
      </mat-card>
    </div>
    <div
      *ngIf="!measurements?.length && !loading"
      fxFlex="100"
      style="text-align: center; margin-top: 12px"
    >
      <span class="light-text">Keine Daten vorhanden</span>
    </div>
    <div
      *ngIf="loading"
      fxFlex="100"
      style="text-align: center; margin-top: 12px"
      fxLayout="row"
      fxLayoutAlign="space-around center"
    >
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
