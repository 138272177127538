import { SensorGroupNodePermissionsOutputDto } from 'src/app/_shared/dtos/sensor-group-node-permissions-output.dto';
import { SensorGroupNodePermissionsInputDto } from 'src/app/_shared/dtos/sensor-group-node-permissions-input.dto';

export class SensorGroupNodePermissions {
  userGroupIds: number[] = [];
  userIds: number[] = [];

  static fromSensorGroupNodePermissionsOutputDto(
    dto: SensorGroupNodePermissionsOutputDto
  ) {
    const result = new SensorGroupNodePermissions();

    if (dto) {
      result.userGroupIds = dto.userGroupIds ? dto.userGroupIds : [];
      result.userIds = dto.userIds ? dto.userIds : [];
    }

    return result;
  }

  toSensorGroupNodePermissionsInputDto(): SensorGroupNodePermissionsInputDto {
    const result = new SensorGroupNodePermissionsInputDto();
    result.userGroupIds = this.userGroupIds ? this.userGroupIds : [];
    result.userIds = this.userIds ? this.userIds : [];
    return result;
  }
}
