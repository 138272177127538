import { FormControl } from '@angular/forms';

export interface ValidationResult {
  [key: string]: boolean;
}

export class HexValidator {
  public static hex(control: FormControl): ValidationResult {
    const expression = /^([0-9a-fA-F]+)$/i;
    if (!control.value) {
      // the [required] validator will check presence, not us
      return null;
    }

    const value = String(control.value).trim();
    if (expression.test(value)) {
      return null;
    }

    return { hex: true };
  }
}
