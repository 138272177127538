import { SensorEvent } from 'src/app/_shared/models/sensor-event';
import { Sensor } from 'src/app/_shared/models/sensor';
import { SensorGroupNodeOutputDto } from 'src/app/_shared/dtos/sensor-group-node-output.dto';
import { SensorGroupNodeInputDto } from 'src/app/_shared/dtos/sensor-group-node-input.dto';
import { SensorGroupNodePermissions } from 'src/app/_shared/models/sensor-group-node-permissions';
import { Address } from 'src/app/_shared/models/address';
import { Polygon } from 'src/app/_shared/models/Polygon';

export class SensorGroupNode {
  id: number;
  displayName: string;
  sortCriteria: number;
  parent: SensorGroupNode;
  address: Address;
  geoAreaPolygon: Polygon;
  children: Array<SensorGroupNode>;
  sensors: Array<Sensor>;
  events: Array<SensorEvent>;
  permissions: SensorGroupNodePermissions;

  static fromSensorGroupNodeOutputDto(
    dto: SensorGroupNodeOutputDto
  ): SensorGroupNode {
    const result = new SensorGroupNode();

    result.id = dto.id;
    result.displayName = dto.displayName;
    result.sortCriteria = dto.sortCriteria;
    result.children = dto.children
      ? dto.children.map((item) =>
          SensorGroupNode.fromSensorGroupNodeOutputDto(item)
        )
      : [];
    result.sensors = dto.sensors
      ? dto.sensors
          .sort((a, b) => a.sortCriteria - b.sortCriteria)
          .map((item) => Sensor.fromSensorOutputDto(item))
      : [];
    result.address = dto.address
      ? Address.fromAddressDto(dto.address)
      : undefined;
    result.geoAreaPolygon = dto.geoAreaPolygon
      ? Polygon.fromPolygonDto(dto.geoAreaPolygon)
      : undefined;
    result.permissions = dto.permissions
      ? SensorGroupNodePermissions.fromSensorGroupNodePermissionsOutputDto(
          dto.permissions
        )
      : SensorGroupNodePermissions.fromSensorGroupNodePermissionsOutputDto({
          userIds: [],
          userGroupIds: [],
        });
    result.events = [];

    result.children.sort((a, b) => a.sortCriteria - b.sortCriteria);

    return result;
  }

  toSensorGroupNodeInputDto(): SensorGroupNodeInputDto {
    const result = new SensorGroupNodeInputDto();

    result.displayName = this.displayName;
    result.parent = this.parent ? this.parent.id : undefined;
    result.address = this.address.toAddressDto();
    result.sortCriteria = this.sortCriteria;
    result.permissions = this.permissions?.toSensorGroupNodePermissionsInputDto();

    return result;
  }
}
