import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardNumberCardComponent } from 'src/app/public-utility/public-utility-dashboard/components/dashboard-number-card/dashboard-number-card.component';
import { FlexModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [DashboardNumberCardComponent],
  exports: [DashboardNumberCardComponent],
  imports: [CommonModule, FlexModule, MatCardModule],
})
export class DashboardNumberCardModule {}
