import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { User } from 'src/app/users/models/user';
import { UserService } from 'src/app/users/services/user.service';

interface UserGroupCreateResolverResult {
  users: User[];
}

@Injectable({
  providedIn: 'root',
})
export class UserGroupCreateResolver
  implements Resolve<void | UserGroupCreateResolverResult> {
  constructor(private userService: UserService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<void | UserGroupCreateResolverResult> {
    return this.userService.getUserList().then((res) => {
      return { users: res };
    });
  }
}
