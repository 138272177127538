import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fire-alarm-icon',
  template: `
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      [style.height]="size + 'px'"
      [style.width]="size + 'px'"
    >
      <rect
        width="28"
        height="28"
        rx="6"
        [attr.fill]="backgroundColor ? backgroundColor : '#FB5F57'"
      />
      <path
        d="M11.3085 19.8652C10.2771 18.8103 9.4165 17.3253 9.4165 15.8334C9.4165 14.5557 10.323 13.3857 11.1173 12.2925C11.1173 12.2925 10.8653 14.0377 12.1665 14.9167M19.7327 21.7331C18.2009 23.2505 16.1671 24.0834 13.9993 24.0834C11.8316 24.0834 9.79772 23.2505 8.26595 21.7331C6.73991 20.2183 5.89844 18.2102 5.89844 16.0738C5.89844 14.833 6.17798 13.6435 6.72849 12.5368C7.26476 11.4557 7.97347 10.6664 8.8917 9.89526C9.51023 9.42231 10.2128 8.72755 10.7048 8.12408C11.4179 7.2541 11.977 6.28426 12.3649 5.24312C12.5241 4.81366 12.6397 4.3693 12.71 3.91675C13.3975 4.54998 13.9737 5.31444 14.4557 6.2101C15.4084 7.99572 15.8477 9.58453 15.7621 10.9366C15.7417 11.255 15.7937 11.574 15.9142 11.8693C16.0348 12.1648 16.2208 12.4291 16.4581 12.6423C16.6638 12.8281 16.9044 12.9713 17.166 13.0633C17.4274 13.1554 17.7046 13.1946 17.9814 13.1786C18.5433 13.15 19.0595 12.9019 19.4361 12.4826C19.8154 12.0576 20.1434 11.624 20.4173 11.1818C20.8167 11.6925 21.1504 12.2487 21.4157 12.8477C21.8692 13.8689 22.1002 14.9556 22.1002 16.0738C22.1002 18.2102 21.2587 20.2213 19.7327 21.7331Z"
        [attr.stroke]="'white'"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [],
})
export class FireAlarmIconComponent implements OnInit {
  @Input() backgroundColor: string;
  @Input() size = 128;

  constructor() {}

  ngOnInit(): void {}
}
