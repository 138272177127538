import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationConfig } from 'src/app/organization/notification-configuration/models/notification-config';
import { NotificationConfigService } from 'src/app/organization/notification-configuration/services/notification-config.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Operator, OperatorBase } from 'src/app/_core/models/operator';
import {
  NotificationConfigurationType,
  NotificationConfigurationTypeBase,
} from 'src/app/_core/models/notification-configuration-type';
import { Sensor } from 'src/app/_shared/models/sensor';
import { SensorService } from 'src/app/_shared/services/sensor.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationConfigIconOutputDto } from 'src/app/organization/notification-configuration/dto/notification-config-icon-output.dto';
import { MatDialog } from '@angular/material/dialog';
import { SensorSelectModalComponent } from 'src/app/organization/notification-configuration/components/sensor-select-modal/sensor-select-modal.component';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { Roles } from 'src/app/_core/models/roles';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';
import { SensorTypeConfig } from 'src/app/_shared/models/sensor-type-config';
import { MeasurementValueTypes } from 'src/app/_shared/models/measurement-value-types';
import { NotificationConfigInputDto } from 'src/app/organization/notification-configuration/dto/notification-config-input.dto';
import {
  NotificationRepetitionType,
  NotificationRepetitionTypeBase,
} from 'src/app/_core/models/notification-repetition-type-base';
import { InfoTextOutputDto } from 'src/app/organization/notification-configuration/dto/info-text-output.dto';

@Component({
  selector: 'app-alarm-configuration-edit',
  templateUrl: './notification-configuration-edit.component.html',
  styleUrls: ['./notification-configuration-edit.component.scss'],
})
export class NotificationConfigurationEditComponent
  implements OnInit, OnDestroy {
  enabled = false;
  edit = false;
  form: FormGroup;
  buttonSaveLoading = false;
  notificationConfig: NotificationConfig;
  operator = Operator;
  alarmConfigType = NotificationConfigurationType;
  sensors: Sensor[];
  icons: NotificationConfigIconOutputDto[] = [];
  disposeBag: Array<Subscription> = [];
  sensorTypeConfigs: SensorTypeConfig[];
  notificationConfigurationTypeBase = NotificationConfigurationTypeBase;
  notificationRepetitionTypes = NotificationRepetitionType.entries();
  infoTexts: InfoTextOutputDto[] = [];

  colorOptions = [
    '#DC52F2',
    '#B27CF8',
    '#7F8DF6',
    '#1AABE9',
    '#3CB4CE',
    '#1BD3DF',
    '#33D4A4',
    '#5BD960',
    '#A8DE0D',
    '#FFC90A',
    '#FFA337',
    '#FB5F57',
    '#EA5280',
    '#AAAAAA',
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationConfigService: NotificationConfigService,
    private sensorService: SensorService,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService
  ) {}

  ngOnDestroy() {
    this.disposeBag.forEach((item) => item.unsubscribe());
  }

  ngOnInit() {
    this.notificationConfig = this.route.snapshot.data.data.notificationConfig;
    this.sensorTypeConfigs = this.route.snapshot.data.data.sensorTypeConfigs;
    this.sensorTypeConfigs.sort((a, b) => {
      return a.displayName.localeCompare(b.displayName);
    });
    this.infoTexts = this.route.snapshot.data.data.infoTexts;

    this.loadSensors();
    this.icons = this.route.snapshot.data.data.icons;
    this.initializeForm();
    this.initializeOperatorValidatorSwitcher();
  }

  private initializeForm() {
    this.form = new FormGroup({
      title: new FormControl(undefined, [
        Validators.required,
        Validators.maxLength(255),
      ]),
      sensorTypeConfig: new FormControl(undefined, [Validators.required]),
      sensorPropertyConfig: new FormControl(undefined, [Validators.required]),
      operator: new FormControl(OperatorBase.EQUAL, [Validators.required]),
      type: new FormControl(null, [Validators.required]),
      value: new FormControl(0, []),
      rolesIds: new FormControl([]),
      sensorIds: new FormControl([]),
      userIds: new FormControl([]),
      iconIdentifier: new FormControl('', [Validators.required]),
      iconBackgroundColor: new FormControl(undefined, [Validators.required]),
      notificationRepetitionType: new FormControl(undefined, [
        Validators.required,
      ]),
    });
    if (this.notificationConfig) {
      this.edit = true;
      const sensorTypeConfig = this.sensorTypeConfigs?.filter(
        (item) => item.id === this.notificationConfig.sensorTypeConfig?.id
      )[0];
      const sensorPropertyConfig = sensorTypeConfig?.sensorPropertyConfigs?.filter(
        (item) => item.id === this.notificationConfig.sensorPropertyConfig?.id
      )[0];

      this.form.patchValue({
        title: this.notificationConfig.title,
        sensorTypeConfig,
        sensorPropertyConfig,
        operator: this.notificationConfig.operator,
        type: this.notificationConfig.type,
        value: this.notificationConfig.value,
        rolesIds: this.notificationConfig.roleIds,
        sensorIds: this.notificationConfig.sensorIds || [],
        userIds: this.notificationConfig.userIds,
        iconIdentifier: this.notificationConfig.iconIdentifier,
        iconBackgroundColor: this.notificationConfig.iconBackgroundColor,
        notificationRepetitionType: this.notificationConfig
          .notificationRepetitionType,
      });
    } else {
      this.edit = false;
    }

    const currentUserSession = this.authenticationService.getCurrentUserSession();
    // @ts-ignore
    this.enabled = [
      Roles.ORGANIZATION_OWNER,
      Roles.ORGANIZATION_EDITOR,
    ].includes(currentUserSession?.role);
    if (!this.enabled) {
      this.form.disable();
    }
  }

  /**
   * If the value for operator is "diff", the value for "value" should be optional.
   */
  private initializeOperatorValidatorSwitcher() {
    this.disposeBag.push(
      this.form.controls.operator.valueChanges.subscribe((event) => {
        if (OperatorBase.DIFF === event) {
          this.form.controls.value.clearValidators();
          this.form.controls.value.updateValueAndValidity();
        } else {
          this.form.controls.value.setValidators([Validators.required]);
          this.form.controls.value.updateValueAndValidity();
        }
      })
    );
  }

  private loadSensors() {
    this.sensorService.getSensors().then((res) => {
      this.sensors = res as Sensor[];
    });
  }

  saveNotificationConfig() {
    if (!this.form.valid) {
      return;
    }

    this.buttonSaveLoading = true;

    const dto = {
      id: this.notificationConfig?.id,
      title: this.form.value.title,
      operator: this.form.value.operator,
      iconIdentifier: this.form.value.iconIdentifier,
      iconBackgroundColor: this.form.value.iconBackgroundColor,
      value: this.form.value.value,
      type: this.form.value.type,
      userIds: this.form.value.userIds,
      roleIds: this.form.value.roleIds,
      sensorIds: this.form.value.sensorIds,
      sensorTypeConfigId: this.form.value.sensorTypeConfig.id,
      sensorPropertyConfigId: this.form.value.sensorPropertyConfig.id,
      notificationRepetitionType: this.form.value.notificationRepetitionType,
    } as NotificationConfigInputDto;

    if (this.edit) {
      this.notificationConfigService
        .updateNotificationConfig(dto)
        .then(async (_) => {
          await new Promise((resolve) => setTimeout(resolve, 1000)).then(
            (__) => {
              this.router.navigateByUrl('organization/alarm-configuration');
            }
          );
        })
        .finally(() => (this.buttonSaveLoading = false));
    } else {
      this.notificationConfigService
        .createNotificationConfig(dto)
        .then(async (_) => {
          await new Promise((resolve) => setTimeout(resolve, 1000)).then(
            (__) => {
              this.router.navigateByUrl('organization/alarm-configuration');
            }
          );
        })
        .finally(() => (this.buttonSaveLoading = false));
    }
  }

  addSensorClicked() {
    this.dialog
      .open(SensorSelectModalComponent, {
        width: '600px',
        data: {
          sensors: this.sensors.filter((item) => {
            const alreadySelected = this.form.value.sensorIds.includes(item.id);
            const correctSensorType =
              item.sensorTypeConfig &&
              this.form.value.sensorTypeConfig &&
              item.sensorTypeConfig.id === this.form.value.sensorTypeConfig.id;
            return !alreadySelected && correctSensorType;
          }),
        },
      })
      .afterClosed()
      .toPromise()
      .then((res) => {
        const selectedSensors = this.form.value.sensorIds
          ? this.form.value.sensorIds
          : [];
        if (Array.isArray(res)) {
          res.forEach((item) => selectedSensors.push(item));
        } else if (res !== null && res !== undefined) {
          selectedSensors.push(res);
        }
        this.form.patchValue({
          sensorIds: selectedSensors,
        });
      });
  }

  selectedSensors() {
    if (Array.isArray(this.sensors)) {
      return this.sensors.filter((item) => {
        return this.form.value.sensorIds?.includes(item.id);
      });
    }
    return [];
  }

  removeSensor(sensor: Sensor) {
    const selectedSensors = this.form.value.sensorIds
      ? this.form.value.sensorIds
      : [];
    selectedSensors.splice(selectedSensors.indexOf(sensor.id), 1);
    this.form.patchValue({
      sensorIds: selectedSensors,
    });
  }

  translateNotificationType(type: NotificationConfigurationTypeBase) {
    return NotificationConfigurationType.translate(type);
  }

  getOperatorLabel(operator: OperatorBase) {
    return Operator.translate(operator);
  }

  filterOperators() {
    if (
      !this.form.controls.sensorPropertyConfig.value ||
      !this.form.controls.sensorTypeConfig.value
    ) {
      return [];
    }

    const sensorPropertyConfig = this.form.controls.sensorPropertyConfig.value;
    switch (sensorPropertyConfig.propertyType) {
      case MeasurementValueTypes.BOOLEAN:
        return [OperatorBase.DIFF, OperatorBase.EQUAL, OperatorBase.NOT_EQUAL];
      case MeasurementValueTypes.NOMINAL:
        return [OperatorBase.DIFF, OperatorBase.EQUAL, OperatorBase.NOT_EQUAL];
      case MeasurementValueTypes.NUMERIC:
        return Operator.entries();
    }

    if (sensorPropertyConfig.propertyType === MeasurementValueTypes.BOOLEAN) {
      return [OperatorBase.DIFF, OperatorBase.EQUAL, OperatorBase.NOT_EQUAL];
    }
  }

  showValueTextField() {
    if (
      !this.form.controls.sensorPropertyConfig.value ||
      !this.form.controls.sensorTypeConfig.value
    ) {
      return true;
    }

    if (
      this.form.controls.sensorPropertyConfig.value.propertyType ===
        MeasurementValueTypes.NUMERIC ||
      this.form.controls.sensorPropertyConfig.value.propertyType ===
        MeasurementValueTypes.NOMINAL
    ) {
      return true;
    }

    return false;
  }

  determinePossibleValuesForOperator(): boolean[] {
    if (
      this.form.controls.sensorPropertyConfig.value?.propertyType ===
      MeasurementValueTypes.BOOLEAN
    ) {
      return [true, false];
    }

    return [];
  }

  determineValueTextfieldType() {
    if (
      !this.form.controls.sensorPropertyConfig.value ||
      this.form.controls.sensorPropertyConfig.value.propertyType ===
        MeasurementValueTypes.NUMERIC
    ) {
      return 'number';
    }

    return 'text';
  }

  hasSensorCorrectSensorType(sensor: Sensor) {
    const sensorTypeConfig = this.form.value.sensorTypeConfig;

    if (!sensorTypeConfig || !sensor.sensorTypeConfig) {
      return false;
    }

    return sensor.sensorTypeConfig.id !== sensorTypeConfig.id;
  }

  getRepetitionTypeLabel(repetitionType: NotificationRepetitionTypeBase) {
    return NotificationRepetitionType.translate(repetitionType);
  }

  sensorTypeToLabel(sensorType: SensorTypeConfig) {
    return `${sensorType.displayName} (${sensorType.niotaDeviceTypeId})`;
  }

  getHint() {
    const sensorPropertyConfig = this.form.value.sensorPropertyConfig;
    if (!sensorPropertyConfig) {
      return '';
    }

    return this.infoTexts.filter(
      (it) => it.technicalName === sensorPropertyConfig.technicalName
    )[0]?.description;
  }
}
