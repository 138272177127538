<mat-card fxFlex="370px" class="square-card mat-elevation-z0">
  <div fxLayout="row">
    <div fxFlex>
      <h4
        class="square-card-title truncate-text"
        matTooltipPosition="above"
        matTooltip="{{ sensorGroupNode.displayName }}"
      >
        {{ sensorGroupNode.displayName }}
      </h4>
    </div>
    <div fxFlex fxLayoutAlign="end">
      <button
        mat-icon-button
        class="btn-edit"
        *ngIf="getRootId() === sensorGroupNode.id"
        (click)="editRootGroup(sensorGroupNode)"
        [disabled]="!enabled"
      >
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button (click)="addClicked()" [disabled]="!enabled">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
  <mat-tab-group
    [disableRipple]="true"
    #tabGroup
    [(selectedIndex)]="sensorGroupNode.activeTab"
  >
    <mat-tab [label]="groupTabLabel()">
      <div
        style="width: 100%; height: 100%"
        (dragover)="$event.preventDefault()"
        (drop)="onDrop($event)"
      >
        <ng-container
          *ngFor="let child of sensorGroupNode.children; let i = index"
        >
          <app-level [sensorGroupNode]="child" [enabled]="enabled"></app-level>
        </ng-container>
      </div>
    </mat-tab>
    <mat-tab [label]="sensorTabLabel()">
      <div
        style="width: 100%; height: 100%"
        (drop)="onDrop($event)"
        (dragover)="$event.preventDefault()"
      >
        <ng-container
          *ngFor="let sensor of sensorGroupNode.sensors; let i = index"
        >
          <app-positioning-sensor
            [sensor]="sensor"
            [enabled]="enabled"
          ></app-positioning-sensor>
        </ng-container>
      </div>
    </mat-tab>
    <mat-tab label="Berechtigungen" *ngIf="isNotViewerRole()">
      <h3
        class="tab-content-title"
        *ngIf="userGroupsOfSensorGroupNode().length > 0"
      >
        Nutzergruppen
      </h3>
      <ng-container
        *ngFor="let userGroup of userGroupsOfSensorGroupNode(); let i = index"
      >
        <app-positioning-user-group-item
          [userGroup]="userGroup"
          (removeUserGroupEvent)="removeUserGroup($event)"
        ></app-positioning-user-group-item>
      </ng-container>
      <h3 class="tab-content-title" *ngIf="usersOfSensorGroupNode().length > 0">
        Nutzer
      </h3>
      <ng-container *ngFor="let user of usersOfSensorGroupNode()">
        <app-positioning-user-item
          [user]="user"
          (removeUserEvent)="removeUser($event)"
        ></app-positioning-user-item>
      </ng-container>
      <div class="tab-content-hint">
        Hinweis: Die Berechtigungen werden aktuell nur für Nutzer mit der Rolle
        "Betrachter" angewendet.
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-card>
