import {
  AfterViewInit,
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  NG_VALUE_ACCESSOR,
  NgModel,
} from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { UserGroup } from 'src/app/users/models/user-group';

@Component({
  selector: 'app-multiple-user-group-select',
  templateUrl: './multiple-user-group-select.component.html',
  styleUrls: ['./multiple-user-group-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleUserGroupSelectComponent),
      multi: true,
    },
  ],
})
export class MultipleUserGroupSelectComponent implements OnInit, AfterViewInit {
  private val: any;
  /**
   * The FormControl must be passed, so that the validators can be passed to the input model of this custom ControlValueAccessor.
   */
  @Input() formControl: AbstractControl;
  @Input() userGroups: Array<UserGroup> = [];
  public userGroupFilterCtrl: FormControl = new FormControl();
  public filteredUserGroups: ReplaySubject<UserGroup[]> = new ReplaySubject<
    UserGroup[]
  >(1);
  @ViewChild('selectModel') selectModel: NgModel;

  ngOnInit() {
    // load the initial user list
    if (this.userGroups) {
      this.filteredUserGroups.next(this.userGroups.slice());
    }

    // listen for search field value changes
    this.userGroupFilterCtrl.valueChanges.subscribe(() =>
      this.filterUserGroups()
    );
  }

  ngAfterViewInit(): void {
    if (this.formControl) {
      this.selectModel.control.setValidators(this.formControl.validator);
      this.selectModel.control.setAsyncValidators(
        this.formControl.asyncValidator
      );
    }
  }

  protected filterUserGroups() {
    if (!this.userGroups) {
      return;
    }
    // get the search keyword
    let search = this.userGroupFilterCtrl.value;
    if (!search) {
      this.filteredUserGroups.next(this.userGroups.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredUserGroups.next(
      this.userGroups.filter((userGroup) =>
        this.userGroupToString(userGroup).toLowerCase().includes(search)
      )
    );
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  constructor() {}

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  userGroupToString(userGroup: UserGroup) {
    return userGroup.name;
  }

  isEmptySelection(): boolean {
    return Array.isArray(this.value) && this.value.length > 0;
  }
}
