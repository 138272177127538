import { Component, Inject, OnInit } from '@angular/core';
import { UserGroupService } from 'src/app/users/services/user-group.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { SensorGroupNode } from 'src/app/_shared/models/sensor-group-node';
import { SensorGroupNodeService } from 'src/app/_shared/services/sensor-group-node.service';
import { User } from 'src/app/users/models/user';
import { UserService } from 'src/app/users/services/user.service';
import { UserGroup } from 'src/app/users/models/user-group';

@Component({
  selector: 'app-select-user-groups-and-users-modal',
  templateUrl: './select-user-groups-and-users-modal.component.html',
  styleUrls: ['./select-user-groups-and-users-modal.component.scss'],
})
export class SelectUserGroupsAndUsersModalComponent implements OnInit {
  form: any;
  selectableUserGroups: UserGroup[] = [];
  sensorGroupNode: SensorGroupNode;
  isLoading = true;
  selectableUsers: User[] = [];

  constructor(
    private userGroupService: UserGroupService,
    private sensorGroupNodeService: SensorGroupNodeService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<SelectUserGroupsAndUsersModalComponent>
  ) {}

  ngOnInit(): void {
    this.sensorGroupNode = this.data.sensorGroupNode;
    this.form = new FormGroup({
      userGroups: new FormControl([]),
      users: new FormControl([]),
    });

    const promises: Promise<any>[] = [];

    promises.push(
      this.userGroupService.getList().then((userGroups) => {
        this.selectableUserGroups = userGroups.filter(
          (userGroup) =>
            !this.sensorGroupNode.permissions.userGroupIds.includes(
              userGroup.id
            )
        );
      })
    );
    promises.push(
      this.userService.getUserList().then((users) => {
        this.selectableUsers = users.filter(
          (user) => !this.sensorGroupNode.permissions.userIds.includes(user.id)
        );
      })
    );

    Promise.all(promises).then((_) => {
      this.isLoading = false;
    });
  }

  save() {
    this.sensorGroupNode.permissions.userGroupIds = this.sensorGroupNode.permissions.userGroupIds.concat(
      this.form.value.userGroups.map((item) => item.id)
    );
    this.sensorGroupNode.permissions.userIds = this.sensorGroupNode.permissions.userIds.concat(
      this.form.value.users.map((item) => item.id)
    );
    this.sensorGroupNodeService
      .update(
        this.sensorGroupNode.toSensorGroupNodeInputDto(),
        this.sensorGroupNode.id
      )
      .then((_) => {
        this.dialogRef.close(this.form.value);
      });
  }
}
