import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Organization } from 'src/app/public-utility/organization/models/organization';
import { OrganizationService } from 'src/app/_shared/services/organization.service';

@Injectable({
  providedIn: 'root', // https://github.com/angular/angular-cli/issues/10170#issuecomment-380673276
})
export class OwnOrganizationResolver implements Resolve<void | Organization> {
  constructor(private organizationService: OrganizationService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<void | Organization> {
    return this.organizationService.getOwnOrganization();
  }
}
