import { Component, OnInit, ViewChild } from '@angular/core';
import { SensorTypeConfig } from 'src/app/_shared/models/sensor-type-config';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { SensorService } from 'src/app/_shared/services/sensor.service';
import { SensorInputDto } from 'src/app/_shared/dtos/sensor-input.dto';

import * as papa from 'papaparse';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ErrorService } from 'src/app/_shared/services/error.service';
import { HexValidator } from 'src/app/_shared/validators/hex-validator';

@Component({
  selector: 'app-sensor-csv-import',
  templateUrl: './sensor-csv-import.component.html',
  styleUrls: ['./sensor-csv-import.component.scss'],
})
export class SensorCsvImportComponent implements OnInit {
  sensorTypeConfigs: SensorTypeConfig[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns = [
    'warning',
    'title',
    'eui',
    'sensorTypeConfig',
    'applSessionKey',
    'applKey',
    'deviceAddress',
    'netwSessionKey',
    'description',
    'applyChangesInIotPlatform',
    'removeIcon',
  ];
  @ViewChild(MatTable, { static: false }) table;
  form: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private sensorService: SensorService,
    private errorService: ErrorService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.sensorTypeConfigs = this.activatedRoute.snapshot.data.sensorTypeConfigs;

    this.form = this.formBuilder.group({
      sensors: this.formBuilder.array([]),
    });
  }

  onFileSelect(event: any) {
    const files: FileList = event.target.files;
    const reader = new FileReader();

    reader.readAsText(files[0]);
    reader.onload = (fileReaderEvent) => {
      const result = papa.parse(fileReaderEvent.target.result, {
        header: true,
      });

      const rowData = result.data.map((item) => {
        return new FormGroup({
          warning: new FormControl(false),
          customWarningMessage: new FormControl(undefined),
          title: new FormControl(item.title),
          eui: new FormControl(item.eui),
          sensorTypeConfig: new FormControl(
            this.sensorTypeConfigs.filter(
              (sensorTypeConfig) =>
                sensorTypeConfig.niotaDeviceTypeId ===
                sensorTypeConfig.niotaDeviceTypeId
            )[0]
          ),
          applSessionKey: new FormControl(item.applSessionKey),
          applKey: new FormControl(item.applKey),
          deviceAddress: new FormControl(item.deviceAddress),
          netwSessionKey: new FormControl(item.netwSessionKey),
          description: new FormControl(item.description),
          applyChangesInIotPlatform: new FormControl(
            Boolean(item.applyChangesInIotPlatform)
          ),
        });
      });

      const sensorFormArray = this.form.controls.sensors as FormArray;
      rowData.forEach((item) => {
        sensorFormArray.push(item);
      });

      rowData.forEach((item) => this.validateRow(item));
      this.dataSource.data = (this.form.get('sensors') as FormArray).controls;
    };
  }

  validateRow(rowData: FormGroup) {
    if (
      !rowData.value.title ||
      !rowData.value.eui ||
      !rowData.value.sensorTypeConfig
    ) {
      rowData.patchValue({
        warning: true,
        customWarningMessage:
          'Titel, EUI und ein Sensortyp muss angegeben werden!',
      });
      return;
    }

    if (rowData.value.applyChangesInIotPlatform) {
      if (
        !rowData.value.applSessionKey ||
        (rowData.value.applSessionKey.length !== 32 &&
          HexValidator.hex(rowData.get('applSessionKey') as FormControl)) ||
        !rowData.value.applKey ||
        (rowData.value.applKey.length !== 32 &&
          HexValidator.hex(rowData.get('applKey') as FormControl)) ||
        !rowData.value.deviceAddress ||
        (rowData.value.deviceAddress.length !== 8 &&
          HexValidator.hex(rowData.get('deviceAddress') as FormControl)) ||
        !rowData.value.netwSessionKey ||
        (rowData.value.netwSessionKey.length !== 32 &&
          HexValidator.hex(rowData.get('netwSessionKey') as FormControl))
      ) {
        rowData.patchValue({
          warning: true,
          customWarningMessage:
            'applSessionKey (32 Hex-Zeichen), applKey (32 Hex-Zeichen), deviceAddress (8 Hex-Zeichen) und netwSessionKey (32 Hex-Zeichen) müssen angegeben werden!',
        });
        return;
      }
    }
    rowData.patchValue({
      warning: false,
    });
  }

  async doImport() {
    const imported = [];

    for (const rowData of this.dataSource.data) {
      if (rowData.value.warning) {
        continue;
      }

      const dto: SensorInputDto = {
        title: rowData.value.title,
        eui: rowData.value.eui,
        applKey: rowData.value.applKey,
        applSessionKey: rowData.value.applSessionKey,
        deviceAddress: rowData.value.deviceAddress,
        netwSessionKey: rowData.value.netwSessionKey,
        sensorTypeConfig: rowData.value.sensorTypeConfig?.id,
        applyChangesInIotPlatform: rowData.value.applyChangesInIotPlatform,
        description: rowData.value.description,
      };
      await this.sensorService
        .create(dto, false)
        .then((_) => {
          imported.push(rowData.value);
        })
        .catch((err) => {
          if (err.error) {
            const errorMessage = this.errorService.determineCitylinkErrorMessage(
              err.error
            );
            rowData.patchValue({
              warning: true,
              customWarningMessage:
                errorMessage.userErrorMessage +
                (errorMessage.technicalErrorMessage
                  ? '\n Techn. Fehlermeldung:' +
                    errorMessage.technicalErrorMessage
                  : ''),
            });
          } else {
            rowData.patchValue({
              warning: true,
            });
          }
        });
    }

    for (const importedSensor of imported) {
      const index = this.form.value.sensors
        .map((item) => item.eui)
        .indexOf(importedSensor.eui);
      const sensorsFormArray = this.form.controls.sensors as FormArray;
      if (index >= 0) {
        sensorsFormArray.removeAt(index);
      }
    }

    this.dataSource.data = (this.form.get('sensors') as FormArray).controls;
    this.table.renderRows();
  }

  isImportable() {
    return (
      Array.isArray(this.dataSource.data) &&
      this.dataSource.data.length > 0 &&
      this.dataSource.data.reduce(
        (currentValue, item) => !item.value.warning || currentValue,
        true
      )
    );
  }

  remove(index) {
    const sensors = this.form.controls.sensors as FormArray;
    sensors.removeAt(index);
    this.dataSource.data = (this.form.get('sensors') as FormArray).controls;
    this.table.renderRows();
  }
}
