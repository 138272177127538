import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CoreModule } from 'src/app/_core/core.module';
import { FilterableSelectComponent } from 'src/app/_shared/components/filterable-select/filterable-select.component';

@NgModule({
  declarations: [FilterableSelectComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    CoreModule,
  ],
  exports: [FilterableSelectComponent],
})
export class FilterableSelectModule {}
