import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemperatureIconComponent } from 'src/app/organization/notification-configuration/components/icons/temperature-icon.component';
import { HumidityIconComponent } from 'src/app/organization/notification-configuration/components/icons/humidity-icon.component';
import { FallbackIconComponent } from 'src/app/organization/notification-configuration/components/icons/fallback-icon.component';
import { OpenedIconComponent } from 'src/app/organization/notification-configuration/components/icons/opened-icon.component';
import { ParkingIconComponent } from 'src/app/organization/notification-configuration/components/icons/parking-icon.component';
import { MotionIconComponent } from 'src/app/organization/notification-configuration/components/icons/motion-icon.component';
import { FireAlarmIconComponent } from 'src/app/organization/notification-configuration/components/icons/fire-alarm-icon.component';
import { WaterLeakageIconComponent } from 'src/app/organization/notification-configuration/components/icons/water-leakage-icon.component';
import { BrightnessIconComponent } from 'src/app/organization/notification-configuration/components/icons/brightness-icon.component';
import { Co2IconComponent } from 'src/app/organization/notification-configuration/components/icons/co2-icon.component';

@NgModule({
  declarations: [
    TemperatureIconComponent,
    HumidityIconComponent,
    FallbackIconComponent,
    OpenedIconComponent,
    ParkingIconComponent,
    MotionIconComponent,
    FireAlarmIconComponent,
    WaterLeakageIconComponent,
    BrightnessIconComponent,
    Co2IconComponent,
  ],
  imports: [CommonModule],
  exports: [
    TemperatureIconComponent,
    HumidityIconComponent,
    FallbackIconComponent,
    OpenedIconComponent,
    ParkingIconComponent,
    MotionIconComponent,
    FireAlarmIconComponent,
    WaterLeakageIconComponent,
    BrightnessIconComponent,
    Co2IconComponent,
  ],
})
export class IconsModule {}
