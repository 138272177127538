<div fxLayout="column" class="app-area">
  <!-- HEADER ROW -->
  <div fxLayout="row">
    <div fxFlex fxFlexAlign="start start">
      <h1>Ampelkonfigurationen</h1>
    </div>
  </div>

  <!-- Content -->
  <mat-card
    fxLayout="row wrap"
    fxLayoutAlign="start start"
    class="square-card mat-elevation-z0"
    style="height: 100%"
  >
    <div fxFlex style="text-align: right" dir="rtl">
      <button
        mat-icon-button
        fxFlexAlign="end end"
        (click)="onCreateButtonClicked()"
        [disabled]="!enabled"
      >
        <mat-icon>add</mat-icon>
      </button>
      <button
        mat-icon-button
        fxFlexAlign="end end"
        (click)="onClearFilterClicked()"
        [disabled]="!isFilter()"
      >
        <mat-icon>settings_backup_restore</mat-icon>
      </button>
    </div>
    <div fxFlex="100" class="table-wrapper">
      <table mat-table [dataSource]="matTableDataSource" style="width: 100%">
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="filter-input">
              <mat-label>Name</mat-label>
              <input
                matInput
                type="text"
                [(ngModel)]="sensorStateIndicatorConfigFilter.title"
                (ngModelChange)="filterChangedSubject.next()"
                name="name"
              />
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="row-title">{{ element.title }}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="sensorTypeConfig">
          <th mat-header-cell *matHeaderCellDef>
            <app-filterable-select
              class="filter-select"
              [(ngModel)]="sensorStateIndicatorConfigFilter.sensorTypeConfig"
              label="Sensortyp"
              [objects]="sensorTypeConfigFilterOptions"
              [objToLabel]="sensorPropertyConfigToLabel"
              (ngModelChange)="
                sensorStateIndicatorConfigFilter.sensorPropertyConfig = undefined;
                filterChangedSubject.next()
              "
            ></app-filterable-select>
          </th>
          <td mat-cell *matCellDef="let element">
            <div>{{ element.sensorTypeConfig?.displayName }}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="sensorPropertyConfig">
          <th mat-header-cell *matHeaderCellDef>
            <app-filterable-select
              class="filter-select"
              [disabled]="!sensorStateIndicatorConfigFilter.sensorTypeConfig"
              [(ngModel)]="
                sensorStateIndicatorConfigFilter.sensorPropertyConfig
              "
              label="Sensoreigenschaft"
              [objects]="
                sensorStateIndicatorConfigFilter.sensorTypeConfig
                  ?.sensorPropertyConfigs || []
              "
              [objToLabel]="sensorPropertyConfigToLabel"
              (ngModelChange)="filterChangedSubject.next()"
            ></app-filterable-select>
          </th>
          <td mat-cell *matCellDef="let element">
            <div>
              {{ element.sensorPropertyConfig?.displayName }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="sensorCount">
          <th mat-header-cell *matHeaderCellDef>Sensoren</th>
          <td mat-cell *matCellDef="let element">
            <mat-chip-list>
              <mat-chip disabled class="blue-chip">
                {{ element.sensorIds?.length || 0 }}
              </mat-chip>
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container matColumnDef="green">
          <th mat-header-cell *matHeaderCellDef>
            <img [src]="greenMapIcon()" style="padding-bottom: 1em" />
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-chip-list>
              <mat-chip disabled class="green-chip">
                falls Sensorwert
                {{ oppositeOfSelectedOperatorToLabel(element) }}
                {{ element.green }}
              </mat-chip>
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container matColumnDef="red">
          <th mat-header-cell *matHeaderCellDef>
            <img [src]="redMapIcon()" style="padding-bottom: 1em" />
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-chip-list>
              <mat-chip disabled class="red-chip">
                falls Sensorwert
                {{ getOperatorLabel(element.operator) }}
                {{ element.red }}
              </mat-chip>
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef class="icon-column"></th>
          <td mat-cell *matCellDef="let element" class="icon-column">
            <button
              mat-icon-button
              style="float: right"
              [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                (click)="openEditSensorStateIndicatorConfig(element)"
              >
                Bearbeiten
              </button>
              <button
                mat-menu-item
                (click)="deleteSensorStateIndicatorConfig(element)"
                [disabled]="!enabled"
              >
                Löschen
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="clickable"
          (click)="openEditSensorStateIndicatorConfig(row)"
        ></tr>
      </table>
    </div>
  </mat-card>
</div>
