import { Component, OnInit } from '@angular/core';
import {
  MeasurementParseErrorGroupDetailOutputDto,
  MeasurementParseErrorOutputDto,
} from 'src/app/public-utility/measurement-parse-errors/dto/measurement-parse-error-detail.output';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-measurement-parse-error-detail',
  templateUrl: './measurement-parse-error-groups-detail.component.html',
  styleUrls: ['./measurement-parse-error-groups-detail.component.scss'],
})
export class MeasurementParseErrorGroupsDetailComponent implements OnInit {
  measurementParseErrorGroupDetail: MeasurementParseErrorGroupDetailOutputDto;
  displayedColumns = ['date'];
  selectedMeasurementParseError: MeasurementParseErrorOutputDto;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.measurementParseErrorGroupDetail = this.activatedRoute.snapshot.data.data;
    this.selectedMeasurementParseError = this.measurementParseErrorGroupDetail.measurementParseErrors[0];
  }

  objectAsString(sensorData: any) {
    return JSON.stringify(sensorData, null, 2);
  }
}
