<form [formGroup]="form" (ngSubmit)="save()">
  <h2 mat-dialog-title>Sensor {{ isEdit ? "berbeiten" : "hinzufügen" }}</h2>
  <mat-dialog-content>
    <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="24px">
      <div fxFlex="100" *ngIf="isEdit">
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input
            matInput
            placeholder="Name"
            type="text"
            formControlName="displayName"
          />
        </mat-form-field>
      </div>
      <div [fxFlex]="isEdit ? 'calc(50% - 24px)' : '100'">
        <mat-form-field appearance="outline">
          <mat-label>EUI</mat-label>
          <input
            matInput
            placeholder="EUI"
            type="text"
            formControlName="eui"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              *ngFor="let option of unpairedSensors"
              [value]="option.metaData.eui"
            >
              {{ option.metaData.eui }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="form.controls.eui.hasError('maxlength')"
            >Maximal 16 Zeichen!</mat-error
          >
          <mat-error *ngIf="form.controls.eui.hasError('minlength')"
            >Mindestens 16 Zeichen!</mat-error
          >
        </mat-form-field>
      </div>
      <ng-container *ngIf="isEdit">
        <div fxFlex="calc(50% - 24px)">
          <mat-form-field
            appearance="outline"
            *ngIf="isEdit && this.form.controls.sensorTypeConfig.value"
          >
            <mat-label>Sensortyp</mat-label>
            <input
              matInput
              placeholder="Sensortyp"
              type="text"
              formControlName="sensorTypeConfig"
              [value]="
                this.form.controls.sensorTypeConfig.value.displayName +
                ' (' +
                this.form.controls.sensorTypeConfig.value.niotaDeviceTypeId +
                ')'
              "
              readonly
            />
          </mat-form-field>
        </div>
        <div fxFlex="100">
          <app-multiple-notification-config-select
            [notificationConfigs]="filteredNotificationConfigsForSensorType"
            formControlName="notificationConfigs"
            [formControl]="form.controls.notificationConfigs"
          ></app-multiple-notification-config-select>
        </div>

        <div fxFlex="100">
          <h3>Adresse und Koordinaten</h3>
        </div>
        <div fxFlex="100">
          <mat-form-field appearance="outline">
            <mat-label>Straße und Hausnummer</mat-label>
            <input
              matInput
              placeholder="Straße und Hausnummer"
              type="text"
              formControlName="street"
            />
            <mat-error *ngIf="form.controls.street.hasError('maxlength')"
              >Maximal 255 Zeichen!</mat-error
            >
          </mat-form-field>
        </div>
        <div fxFlex="calc(30% - 24px)">
          <mat-form-field appearance="outline">
            <mat-label>PLZ</mat-label>
            <input
              matInput
              placeholder="PLZ"
              type="text"
              formControlName="zipCode"
            />
            <mat-error *ngIf="form.controls.zipCode.hasError('maxlength')"
              >Maximal 5 Zeichen!</mat-error
            >
          </mat-form-field>
        </div>
        <div fxFlex="calc(70% - 24px)">
          <mat-form-field appearance="outline">
            <mat-label>Stadt</mat-label>
            <input
              matInput
              placeholder="Stadt"
              type="text"
              formControlName="city"
            />
            <mat-error *ngIf="form.controls.city.hasError('maxlength')"
              >Maximal 255 Zeichen!</mat-error
            >
          </mat-form-field>
        </div>
        <div fxFlex="calc(30% - 24px)"></div>
        <div fxFlex="100" fxLayoutAlign="center center">
          <button
            mat-raised-button
            class="upper-case"
            type="button"
            color="primary"
            [disabled]="
              !data.sensorGroupNode.address ||
              !enabled ||
              form.controls.lng.disabled ||
              form.controls.lat.disabled
            "
            (click)="determineLocationFromPositioning()"
          >
            Von der Positionierung übernehmen
          </button>
          <span style="margin: 12px"></span>
          <button
            mat-raised-button
            class="upper-case"
            type="button"
            color="primary"
            [disabled]="
              !canGeocode() ||
              !enabled ||
              form.controls.lng.disabled ||
              form.controls.lat.disabled
            "
            (click)="geocodeAddress()"
          >
            Koordinaten ermitteln
          </button>
        </div>
        <div fxFlex="100">
          Hinweis: Eine Veränderung der Position auf der Karte beeinflusst nur
          die Koordinaten. Die eingetragene Adresse bleibt bestehen.
        </div>
        <div fxFlex="100">
          <google-map
            height="350px"
            width="100%"
            [options]="gmOptions"
            (mapClick)="onMapClick($event)"
          >
            <map-marker
              [style.display]="!markerPosition ? 'none' : ''"
              [position]="markerPosition"
              [options]="markerOptions"
              (mapDragend)="onMapDragEnd($event)"
            ></map-marker>
          </google-map>
        </div>
        <div fxFlex="calc(50% - 24px)">
          <mat-form-field appearance="outline" style="margin-top: 24px">
            <mat-label>Längengrad</mat-label>
            <input
              matInput
              placeholder="Längengrad"
              type="number"
              formControlName="lng"
            />
          </mat-form-field>
        </div>
        <div fxFlex="calc(50% - 24px)">
          <mat-form-field appearance="outline" style="margin-top: 24px">
            <mat-label>Breitengrad</mat-label>
            <input
              matInput
              placeholder="Breitengrad"
              type="number"
              formControlName="lat"
            />
          </mat-form-field>
        </div>

        <div fxFlex="100">
          <h3>Bild des Sensors</h3>
        </div>
        <div fxFlex="100" fxLayoutAlign="center start">
          <img [src]="image" class="image-preview" *ngIf="form.value.image" />
        </div>
        <div fxFlex="100" fxLayoutAlign="center start">
          <input
            style="display: none"
            type="file"
            (change)="onFileSelect($event)"
            accept="image/*"
            #hiddenfileinput
          />
          <button
            mat-button
            class="upper-case border-button"
            type="button"
            color="primary"
            [disabled]="!enabled"
            (click)="hiddenfileinput.click()"
          >
            Bild auswählen
          </button>
          <button
            mat-button
            color="warn"
            type="button"
            class="upper-case border-button"
            (click)="removeImage()"
            [disabled]="!enabled"
            *ngIf="form.value.image"
          >
            Bild entfernen
          </button>
        </div>
      </ng-container>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Abbrechen</button>
    <button mat-button type="submit" [disabled]="!enabled">Speichern</button>
  </mat-dialog-actions>
</form>
