import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SensorStateIndicatorConfigurationEditModule } from 'src/app/organization/sensor-state-indicator-configuration/components/sensor-state-indicator-configuration-edit/sensor-state-indicator-configuration-edit.module';
import { SensorStateIndicatorConfigurationListModule } from 'src/app/organization/sensor-state-indicator-configuration/components/sensor-state-indicator-configuration-list/sensor-state-indicator-configuration-list.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SensorStateIndicatorConfigurationEditModule,
    SensorStateIndicatorConfigurationListModule,
  ],
  exports: [
    SensorStateIndicatorConfigurationEditModule,
    SensorStateIndicatorConfigurationListModule,
  ],
})
export class SensorStateIndicatorConfigurationModule {}
