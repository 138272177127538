<div fxLayout="column" class="app-area">
  <!-- HEADER ROW -->
  <div fxLayout="row">
    <div fxFlex fxFlexAlign="start start">
      <h1>Dashboard</h1>
    </div>
    <!--    <div fxFlex style="text-align: right">-->
    <!--      <button mat-icon-button fxFlexAlign="end end" class="options-button">-->
    <!--        <mat-icon>more_vert</mat-icon>-->
    <!--      </button>-->
    <!--    </div>-->
  </div>

  <!-- Content -->
  <div fxLayout="row">
    <!-- Warnings and recent Events -->
    <div
      fxFlex.gt-xs="50"
      fxFlex.gt-sm="40"
      fxFlex.gt-md="30"
      fxFlex.gt-lg="30"
      fxLayout="column"
    >
      <app-warnings-card></app-warnings-card>
      <app-current-events></app-current-events>
    </div>

    <!-- Map -->
    <div
      fxFlex.gt-xs="50"
      fxFlex.gt-sm="60"
      fxFlex.gt-md="70"
      fxFlex.gt-lg="70"
    >
      <app-site-map></app-site-map>
    </div>
  </div>
</div>
