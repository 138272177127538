import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SensorGroupNode } from 'src/app/_shared/models/sensor-group-node';
import { Subject } from 'rxjs';
import { SensorGroupNodeService } from 'src/app/_shared/services/sensor-group-node.service';
import { Sensor } from 'src/app/_shared/models/sensor';
import { SensorService } from 'src/app/_shared/services/sensor.service';
import { MatDialog } from '@angular/material/dialog';
import {
  EditMode,
  EditSensorGroupNodeModalComponent,
} from 'src/app/organization/positioning/components/edit-sensor-group-node-modal/edit-sensor-group-node-modal.component';
import { ErrorService } from 'src/app/_shared/services/error.service';
import { SensorPairDto } from 'src/app/_shared/dtos/sensor-pair.dto';

export class SensorGroupNodeView extends SensorGroupNode {
  selected?: boolean;
  activeTab?: number;
  children: Array<SensorGroupNodeView>;
}

/**
 * This Service is used to handle the Logic of the positioning component and all its child components
 * 1. Selected Groups
 * 2. Deletion of Groups
 * 3. Drag/Drop of Groups/Sensors
 */
@Injectable({
  providedIn: 'root',
})
export class PositioningService {
  treeRoot: SensorGroupNodeView;
  selectedSensor: Sensor;
  selectionChangedSubject = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private groupService: SensorGroupNodeService,
    private sensorService: SensorService,
    private dialog: MatDialog,
    private error: ErrorService
  ) {}

  selectedGroups(group: SensorGroupNodeView): SensorGroupNodeView[] {
    const result = [];

    if (group) {
      if (group.selected) {
        result.push(group);
      }

      if (group.children) {
        for (const child of group.children) {
          result.push(...this.selectedGroups(child));
        }
      }
    }

    return result;
  }

  flattenTreeToSensorArray(group: SensorGroupNodeView): Sensor[] {
    const result = [];

    if (group) {
      result.push(...group.sensors);

      if (group.children) {
        for (const child of group.children) {
          result.push(...this.flattenTreeToSensorArray(child));
        }
      }
    }

    return result;
  }

  unselectGroup(groupToUnselect: SensorGroupNodeView) {
    const parentOfSelectedGroup = this.findParentOfGroupRecursive(
      groupToUnselect,
      this.treeRoot
    );
    if (parentOfSelectedGroup) {
      parentOfSelectedGroup.children.forEach((item) =>
        this.unselectAllChildrenRecursive(item)
      );
    }

    if (
      this.selectedSensor &&
      groupToUnselect.sensors.includes(this.selectedSensor)
    ) {
      this.selectedSensor = undefined;
    }
  }

  selectGroup(groupToSelect: SensorGroupNodeView) {
    groupToSelect.selected = true;

    /**
     * Unselect all SensorGroupNodes which are not in the hierachy of the new selected Sensor.
     */
    let currentGroup = groupToSelect;
    const groupHierarchyUpwards = [currentGroup];
    while (this.findParentOfGroupRecursive(currentGroup, this.treeRoot)) {
      currentGroup = this.findParentOfGroupRecursive(
        currentGroup,
        this.treeRoot
      );
      groupHierarchyUpwards.push(currentGroup);
    }
    const selectedGroups = this.selectedGroups(this.treeRoot);
    for (const selectedGroup of selectedGroups) {
      selectedGroup.selected = groupHierarchyUpwards.includes(selectedGroup);
    }

    /**
     * Unselect Sensor if the selected Sensor is not a child of the new selected SensorGroupNode.
     */
    if (!groupToSelect.sensors.includes(this.selectedSensor)) {
      this.selectedSensor = undefined;
    }
  }

  selectSensor(sensor: Sensor) {
    this.selectedSensor = sensor;

    /**
     * A Sensor was selected. This Sensor can be anywhere in the tree of selected SensorGroupNodes.
     * We must find the parent and unselect all Child SensorGroupNodes of the Parent.
     */
    const selectedGroups = this.selectedGroups(this.treeRoot);
    const parent = this.findParentOfSensorRecursive(sensor, this.treeRoot);
    const toUnselect = selectedGroups.splice(
      selectedGroups.indexOf(parent) + 1
    );
    for (const group of toUnselect) {
      this.unselectGroup(group);
    }
  }

  /**
   * @param childWhoseParentIsWanted
   * @param parentCandidate
   */
  public findParentOfGroupRecursive(
    childWhoseParentIsWanted: SensorGroupNode,
    parentCandidate: SensorGroupNode
  ): SensorGroupNode {
    if (
      parentCandidate.children &&
      parentCandidate.children.includes(childWhoseParentIsWanted)
    ) {
      return parentCandidate;
    } else {
      for (const newParentCandidate of parentCandidate.children) {
        const result = this.findParentOfGroupRecursive(
          childWhoseParentIsWanted,
          newParentCandidate
        );
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  private findSensorGroupNodeByIdRecursive(
    sensorGroupNodeId: number,
    parentCandidate: SensorGroupNode
  ) {
    if (parentCandidate.children) {
      if (
        parentCandidate.children
          .map((item) => item.id)
          .includes(sensorGroupNodeId)
      ) {
        return parentCandidate.children.filter(
          (item) => item.id === sensorGroupNodeId
        )[0];
      } else {
        for (const newParentCandidate of parentCandidate.children) {
          const result = this.findSensorGroupNodeByIdRecursive(
            sensorGroupNodeId,
            newParentCandidate
          );
          if (result) {
            return result;
          }
        }
      }
    }
    return null;
  }

  findParentOfSensorRecursive(
    childWhoseParentIsWanted: Sensor,
    parentCandidate: SensorGroupNode
  ): SensorGroupNode {
    if (
      parentCandidate.sensors &&
      parentCandidate.sensors.includes(childWhoseParentIsWanted)
    ) {
      return parentCandidate;
    } else {
      for (const newParentCandidate of parentCandidate.children) {
        const result = this.findParentOfSensorRecursive(
          childWhoseParentIsWanted,
          newParentCandidate
        );
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  findSensorRecursive(sensorId: number, parentCandidate: SensorGroupNode) {
    const filtered = parentCandidate?.sensors?.filter(
      (sensorIdCandidate) => sensorIdCandidate.id === sensorId
    );
    if (filtered.length > 0) {
      return filtered[0];
    } else {
      for (const newParentCandidate of parentCandidate.children) {
        const result = this.findSensorRecursive(sensorId, newParentCandidate);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  unselectAllChildrenRecursive(group: SensorGroupNodeView) {
    this.selectionChangedSubject.next();
    group.selected = false;
    if (group.children) {
      group.children.forEach((item) => this.unselectAllChildrenRecursive(item));
    }
    if (group.sensors && group.sensors.includes(this.selectedSensor)) {
      this.selectedSensor = undefined;
    }
  }

  removeGroupRecursive(
    parent: SensorGroupNode,
    groupToRemove: SensorGroupNode
  ) {
    if (parent.children) {
      if (parent.children.includes(groupToRemove)) {
        const index = parent.children.indexOf(groupToRemove);
        parent.children.splice(index, 1);
        return;
      } else {
        parent.children.forEach((child) =>
          this.removeGroupRecursive(child, groupToRemove)
        );
      }
    }
  }

  moveSensorToEndOfNewParent(sensorId: number, newParent: SensorGroupNode) {
    const sensor = this.findSensorRecursive(sensorId, this.treeRoot);
    if (sensor) {
      const oldParent = this.findParentOfSensorRecursive(sensor, this.treeRoot);
      oldParent.sensors.splice(oldParent.sensors.indexOf(sensor), 1);
      newParent.sensors.push(sensor);

      const dto: SensorPairDto = {
        eui: sensor.metaData.eui,
        sensorGroupNodeId: newParent.id,
      };

      this.sensorService.pair(dto);
    }
  }

  deleteGroup(group: SensorGroupNodeView) {
    this.unselectAllChildrenRecursive(group);
    this.removeGroupRecursive(this.treeRoot, group);
    this.groupService.remove(group.id);
  }

  /**
   * Determines the new Parent of the dropped Group and updates the sortCriteria of the groups.
   * @param sensorGroupNodeId which was dropped
   * @param siblingGroup the Group the dragged Group was dropped.
   */
  moveGroup(sensorGroupNodeId: number, siblingGroup: SensorGroupNodeView) {
    const droppedGroup = this.findSensorGroupNodeByIdRecursive(
      sensorGroupNodeId,
      this.treeRoot
    );
    const oldParent = this.findParentOfGroupRecursive(
      droppedGroup,
      this.treeRoot
    );
    const newParent = this.findParentOfGroupRecursive(
      siblingGroup,
      this.treeRoot
    );

    if (newParent === droppedGroup) {
      this.error.showError({
        userErrorMessage:
          'Sie können eine Sensor-Gruppe nicht sich selbst als Unterebene hinzufügen.',
      });
      return;
    }

    oldParent.children.splice(oldParent.children.indexOf(droppedGroup), 1);
    newParent.children.splice(
      newParent.children.indexOf(siblingGroup),
      0,
      droppedGroup
    );
    droppedGroup.parent = newParent;

    newParent.children.forEach((item, i) => {
      item.sortCriteria = i;
      this.groupService.update(item.toSensorGroupNodeInputDto(), item.id);
    });
  }

  /**
   * Determines the new Parent of the dropped Sensor and updates the sortCriteria of the sensors.
   * @param sensorId which was dropped
   * @param siblingSensor the Sensor on which the dragged Sensor was dropped.
   */
  moveSensor(sensorId: number, siblingSensor: Sensor) {
    const droppedSensor = this.findSensorRecursive(sensorId, this.treeRoot);
    const oldParent = this.findParentOfSensorRecursive(
      droppedSensor,
      this.treeRoot
    );
    const newParent = this.findParentOfSensorRecursive(
      siblingSensor,
      this.treeRoot
    );

    oldParent.sensors.splice(oldParent.sensors.indexOf(droppedSensor), 1);
    newParent.sensors.splice(
      newParent.sensors.indexOf(siblingSensor),
      0,
      droppedSensor
    );
    droppedSensor.parent = newParent;

    for (const [i, sensor] of newParent.sensors.entries()) {
      const dto: SensorPairDto = {
        eui: sensor.metaData.eui,
        sensorGroupNodeId: newParent.id,
        sortCriteria: i,
      };

      this.sensorService.pair(dto);
    }
  }

  groupMovedToEndOfParent(
    sensorGroupNodeId: number,
    newParent: SensorGroupNodeView
  ) {
    const droppedGroup = this.findSensorGroupNodeByIdRecursive(
      sensorGroupNodeId,
      this.treeRoot
    );
    const oldParent = this.findParentOfGroupRecursive(
      droppedGroup,
      this.treeRoot
    );

    if (newParent === droppedGroup) {
      this.error.showError({
        userErrorMessage:
          'Sie können eine Sensor-Gruppe nicht sich selbst als Unterebene hinzufügen.',
      });
      return;
    }

    oldParent.children.splice(oldParent.children.indexOf(droppedGroup), 1);
    newParent.children.push(droppedGroup);
    droppedGroup.parent = newParent;
    droppedGroup.sortCriteria = newParent.children.length;

    this.groupService.update(
      droppedGroup.toSensorGroupNodeInputDto(),
      droppedGroup.id
    );
  }

  sensorMovedToEndOfParent(sensorId: number, newParent: SensorGroupNodeView) {
    const droppedSensor = this.findSensorRecursive(sensorId, this.treeRoot);
    const oldParent = this.findParentOfSensorRecursive(
      droppedSensor,
      this.treeRoot
    );

    oldParent.sensors.splice(oldParent.sensors.indexOf(droppedSensor), 1);
    newParent.sensors.push(droppedSensor);

    droppedSensor.sensorGroupNode = newParent;

    for (const [i, sensor] of newParent.sensors.entries()) {
      const dto: SensorPairDto = {
        eui: sensor.metaData.eui,
        sensorGroupNodeId: newParent.id,
        sortCriteria: i,
      };

      this.sensorService.pair(dto);
    }
  }

  public handleRootUnnamed(sensorGroupNode: SensorGroupNode) {
    this.dialog.open(EditSensorGroupNodeModalComponent, {
      width: '620px',
      data: { sensorGroupNode, mode: EditMode.RENAME_ROOT },
    });
  }

  updateTree(newTreeRoot: SensorGroupNode) {
    const selectedGroups = this.selectedGroups(this.treeRoot);
    this.treeRoot = newTreeRoot;
    this.treeRoot.selected = true;

    this.selectRecursively(this.treeRoot, selectedGroups);
    this.treeUpdatedCheckSelectedSensor();
  }

  private selectRecursively(
    sensorGroupNode: SensorGroupNodeView,
    selectedGroups
  ) {
    if (selectedGroups.map((item) => item.id).includes(sensorGroupNode.id)) {
      const oldSelectedGroup = selectedGroups.filter(
        (item) => item.id === sensorGroupNode.id
      )[0];
      sensorGroupNode.selected = true;
      sensorGroupNode.activeTab = oldSelectedGroup
        ? oldSelectedGroup.activeTab
        : 0;
    }

    for (const child of sensorGroupNode.children) {
      this.selectRecursively(child, selectedGroups);
    }
  }

  private treeUpdatedCheckSelectedSensor() {
    if (this.selectedSensor) {
      const selectedSensorId = this.selectedSensor.id;
      this.selectedSensor = this.flattenTreeToSensorArray(this.treeRoot).filter(
        (item) => item.id === selectedSensorId
      )[0];
    }
  }
}
