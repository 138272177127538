import { NgModule } from '@angular/core';
import { UserListModule } from 'src/app/users/components/user-list/user-list.module';
import { EditUserModalModule } from 'src/app/users/components/edit-user-modal/edit-user-modal.module';
import { UserGroupListModule } from 'src/app/users/components/user-group-list/user-group-list.module';
import { UserGroupEditModule } from 'src/app/users/components/user-group-edit/user-group-edit.module';
import { UsernameConfirmationHintModule } from 'src/app/users/components/username-confirmation-hint/username-confirmation-hint.module';

@NgModule({
  imports: [
    UserListModule,
    EditUserModalModule,
    UserGroupListModule,
    UserGroupEditModule,
    UsernameConfirmationHintModule,
  ],
  exports: [
    UserListModule,
    EditUserModalModule,
    UserGroupListModule,
    UserGroupEditModule,
    UsernameConfirmationHintModule,
  ],
  declarations: [],
})
export class UsersModule {}
