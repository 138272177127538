import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardOrganizationsCardComponent } from 'src/app/public-utility/public-utility-dashboard/components/dashboard-organizations-card/dashboard-organizations-card.component';
import { FlexModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { RouterTestingModule } from '@angular/router/testing';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [DashboardOrganizationsCardComponent],
  exports: [DashboardOrganizationsCardComponent, RouterTestingModule],
  imports: [CommonModule, FlexModule, MatDividerModule, MatCardModule],
})
export class DashboardOrganizationsCardModule {}
