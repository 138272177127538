import { SensorPropertyConfigOutputDto } from 'src/app/_shared/dtos/sensor-property-config-output.dto';

export class SensorPropertyConfig {
  id: number;
  displayName: string;
  technicalName: string;
  propertyName: string;
  propertyType: string;
  unit: string;
  symbol: string;
  optional: boolean;
  onDisplayName?: string;
  offDisplayName?: string;

  static fromSensorPropertyOutputDto(
    dto: SensorPropertyConfigOutputDto
  ): SensorPropertyConfig {
    const result = new SensorPropertyConfig();

    result.id = dto.id;
    result.displayName = dto.displayName;
    result.symbol = dto.symbol;
    result.technicalName = dto.technicalName;
    result.propertyName = dto.propertyName;
    result.propertyType = dto.propertyType;
    result.unit = dto.unit;
    result.optional = dto.optional;
    result.onDisplayName = dto.onDisplayName;
    result.offDisplayName = dto.offDisplayName;

    return result;
  }
}
