import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LegalSettingsComponent } from 'src/app/public-utility/legal-settings/components/legal-settings/legal-settings.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { EditorModule } from '@tinymce/tinymce-angular';

@NgModule({
  declarations: [LegalSettingsComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatCardModule,
    ReactiveFormsModule,
    FlexModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    EditorModule,
  ],
})
export class LegalSettingsModule {}
