import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationMuteSettingsComponent } from 'src/app/organization/notification-mute/components/notification-mute/notification-mute-settings.component';
import { FlexModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
  declarations: [NotificationMuteSettingsComponent],
  exports: [NotificationMuteSettingsComponent],
  imports: [
    CommonModule,
    FlexModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
  ],
})
export class NotificationMuteSettingsModule {}
