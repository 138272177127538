import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SensorTypeConfigListComponent } from 'src/app/public-utility/sensor-types/components/sensor-type-config-list/sensor-type-config-list.component';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NotificationConfigurationModule } from 'src/app/organization/notification-configuration/notification-configuration.module';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [SensorTypeConfigListComponent],
  exports: [SensorTypeConfigListComponent],
  imports: [
    CommonModule,
    FlexModule,
    MatButtonModule,
    MatIconModule,
    NotificationConfigurationModule,
    MatTableModule,
    MatMenuModule,
    RouterModule,
    MatChipsModule,
    MatCardModule,
    MatInputModule,
    FormsModule,
  ],
})
export class SensorTypeConfigListModule {}
