import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from 'src/app/users/models/user';

@Component({
  selector: 'app-positioning-user-item',
  templateUrl: './positioning-user-item.component.html',
  styleUrls: ['./positioning-user-item.component.scss'],
})
export class PositioningUserItemComponent implements OnInit {
  @Input() user: User;
  @Output() removeUserEvent = new EventEmitter<User>();

  constructor() {}

  ngOnInit(): void {}

  removeUser() {
    this.removeUserEvent.next(this.user);
  }
}
