import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/users/services/user.service';
import { ApiError } from 'src/app/_shared/exception/api.error';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';
import { PasswordValidator } from 'src/app/_shared/validators/password-validator';

@Component({
  selector: 'app-user-password-reset',
  templateUrl: './user-password-reset.component.html',
  styleUrls: ['./user-password-reset.component.scss'],
})
export class UserPasswordResetComponent implements OnInit {
  form: FormGroup;
  changePasswordError: string;
  changePasswordSuccess: string;
  protected confirmationToken: string;
  loadingInProcess = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.authenticationService.logout(false);
    this.activatedRoute.queryParams.subscribe((params) => {
      this.confirmationToken = params.confirmationToken;
    });

    this.form = new FormGroup({
      newPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        PasswordValidator.strong,
      ]),
      confirmPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        PasswordValidator.strong,
      ]),
    });
  }

  onSubmit() {
    if (!(this.form.value.newPassword === this.form.value.confirmPassword)) {
      this.changePasswordError = 'Passwörter sind nicht identisch !';
      return;
    }

    if (
      this.form.controls.newPassword.errors?.required ||
      this.form.controls.confirmPassword.errors?.required
    ) {
      this.changePasswordError = 'Bitte tragen sie ein neues Passwort ein.';
      return;
    }

    if (
      this.form.controls.newPassword.errors?.minlength ||
      this.form.controls.confirmPassword.errors?.minlength
    ) {
      this.changePasswordError =
        'Das Passwort muss mindestens 8 Zeichen lang sein.';
      return;
    }

    if (
      this.form.controls.newPassword.errors?.strong ||
      this.form.controls.confirmPassword.errors?.strong
    ) {
      this.changePasswordError =
        'Mindestens ein Groß- und Kleinbuchstabe sowie eine Zahl.';
      return;
    }

    this.loadingInProcess = true;
    this.userService
      .changePassword(this.confirmationToken, this.form.value.newPassword)
      .then((_) => this.handlePasswordSetSuccess())
      .catch((error) => this.handlePasswordSetError(error));
  }

  private handlePasswordSetError(error) {
    this.loadingInProcess = false;
    if (error instanceof ApiError) {
      if (error.error) {
        this.changePasswordError = error.error;
      }
    }

    if (error instanceof HttpErrorResponse) {
      if (401 === error.status) {
        this.changePasswordError = error.message;
      } else if (0 === error.status) {
        this.changePasswordError = 'Server ist nicht erreichbar.';
      }
    }

    if (!this.changePasswordError) {
      this.changePasswordError = 'Unknown Error';
    }
  }

  private handlePasswordSetSuccess() {
    this.changePasswordError = null;
    this.changePasswordSuccess = 'Passwort erfolgreich geändert.';
    setTimeout(() => {
      this.loadingInProcess = false;
      this.router.navigateByUrl('/login');
    }, 5000);
  }
}
