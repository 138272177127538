<div fxLayout="column" class="app-area">
  <!-- HEADER ROW -->
  <div fxLayout="row">
    <div fxFlex fxFlexAlign="start start">
      <h1>Organisationen</h1>
    </div>
  </div>

  <!-- Content -->
  <div class="square-card" style="height: 100%">
    <h4 class="square-card-title">{{ organization.name }}</h4>

    <mat-chip-list>
      <ng-container
        *ngFor="let sensorMac of organization.sensorMac; let i = index"
      >
        <mat-chip disabled class="blue-chip">
          {{ sensorMac }}
        </mat-chip>
      </ng-container>
    </mat-chip-list>
  </div>
</div>
