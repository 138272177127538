import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/_core/services/api/api.service';
import { SensorGroupNodeOutputDto } from 'src/app/_shared/dtos/sensor-group-node-output.dto';
import { SensorGroupNode } from 'src/app/_shared/models/sensor-group-node';
import { SensorService } from 'src/app/_shared/services/sensor.service';
import { SnackBarService } from 'src/app/_shared/services/snack-bar.service';
import { WebsocketService } from 'src/app/_shared/services/websocket.service';
import { Observable } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { SensorGroupNodeInputDto } from 'src/app/_shared/dtos/sensor-group-node-input.dto';

@Injectable({
  providedIn: 'root',
})
export class SensorGroupNodeService {
  constructor(
    private apiService: ApiService,
    private sensorService: SensorService,
    private snackBarService: SnackBarService,
    private websocketService: WebsocketService
  ) {}

  getTree(handleErrors = true): Promise<SensorGroupNode | void> {
    return this.apiService
      .get<SensorGroupNodeOutputDto>(
        '/sensor-group-nodes',
        undefined,
        undefined,
        handleErrors
      )
      .then((result) => {
        if (!result) {
          return;
        }
        return SensorGroupNode.fromSensorGroupNodeOutputDto(result);
      });
  }

  create(data: SensorGroupNodeInputDto) {
    return this.apiService.post('/sensor-group-nodes', data).then((res) => {
      this.snackBarService.saveSuccessful();
      return res;
    });
  }

  update(data: SensorGroupNodeInputDto, id: number): Promise<SensorGroupNode> {
    return this.apiService
      .patch<SensorGroupNodeOutputDto>('/sensor-group-nodes/' + id, data)
      .then((result) => {
        this.snackBarService.saveSuccessful();
        if (!result) {
          return undefined;
        }
        return SensorGroupNode.fromSensorGroupNodeOutputDto(result);
      });
  }

  remove(sensorGroupNodeId: number): Promise<void> {
    return this.apiService
      .delete('/sensor-group-nodes/' + sensorGroupNodeId)
      .then((_) => {
        this.snackBarService.deleteSuccessful();
      });
  }

  getDetails(id: number): Promise<SensorGroupNode | void> {
    return this.apiService.get<SensorGroupNode>('/sensor-group-nodes/' + id);
  }

  getTreeObservable(): Observable<SensorGroupNode> {
    return this.websocketService.connect().pipe(
      map((value) => {
        return JSON.parse(value.data);
      }),
      filter((value) => {
        return value.event === 'sensorGroupNode';
      }),
      debounceTime(300),
      map((value) => {
        return SensorGroupNode.fromSensorGroupNodeOutputDto(value.payload);
      })
    );
  }

  loadImage(id: number) {
    return this.apiService
      .get<{ image: string }>('/sensor-group-nodes/' + id + '/image')
      .then();
  }
}
