<form [formGroup]="form" (ngSubmit)="save()">
  <h2 mat-dialog-title>Nutzergruppen Hinzufügen</h2>
  <mat-dialog-content>
    <div fxLayout="row wrap" fxLayoutAlign="start start" *ngIf="!isLoading">
      <div fxFlex="100">
        <h3>Nutzergruppen</h3>
      </div>
      <div fxFlex="100">
        <app-multiple-user-group-select
          [userGroups]="selectableUserGroups"
          formControlName="userGroups"
        ></app-multiple-user-group-select>
      </div>
      <div fxFlex="100">
        <h3>Nutzer</h3>
      </div>
      <div fxFlex="100">
        <app-multiple-user-select
          [users]="selectableUsers"
          formControlName="users"
        ></app-multiple-user-select>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Abbrechen</button>
    <button mat-button type="submit">Speichern</button>
  </mat-dialog-actions>
</form>
