import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InfoTextOutputDto } from 'src/app/organization/notification-configuration/dto/info-text-output.dto';
import {
  SensorConfigTechnicalName,
  sensorConfigTechnicalNameAsArray,
  sensorConfigTechnicalNameToLabel,
} from 'src/app/_shared/models/sensor-config-technical-name';
import { InfoTextService } from 'src/app/organization/notification-configuration/services/info-text.service';
import { InfoTextInputDto } from 'src/app/organization/notification-configuration/dto/info-text-input.dto';

@Component({
  selector: 'app-info-text-edit-modal',
  templateUrl: './info-text-edit-modal.component.html',
  styleUrls: ['./info-text-edit-modal.component.scss'],
})
export class InfoTextEditModalComponent implements OnInit {
  form: FormGroup;
  loadingInProcess = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: InfoTextOutputDto,
    private infoTextService: InfoTextService,
    private dialogRef: MatDialogRef<InfoTextEditModalComponent>
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      technicalName: new FormControl(this.data.technicalName),
      description: new FormControl(this.data.description),
    });
  }

  save() {
    const dto: InfoTextInputDto = this.form.value;
    this.loadingInProcess = true;
    this.infoTextService
      .update(this.data.id, dto)
      .then((res) => {
        this.dialogRef.close();
      })
      .finally(() => {
        this.loadingInProcess = false;
      });
  }

  technicalNames() {
    return sensorConfigTechnicalNameAsArray();
  }

  technicalNameToLabel(technicalName: SensorConfigTechnicalName) {
    return sensorConfigTechnicalNameToLabel(technicalName);
  }
}
