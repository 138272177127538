import { OrganizationDto } from 'src/app/public-utility/organization/dtos/organization.dto';
import { User } from 'src/app/users/models/user';
import { OrganizationWithInitialUserInput } from 'src/app/public-utility/organization/dtos/organization-with-initial-user-input';
import { OrganizationInputDto } from 'src/app/public-utility/organization/dtos/organization-input.dto';
import { Address } from 'src/app/_shared/models/address';

export class Organization {
  id: number;
  name: string;
  email: string;
  logo: string;
  address: Address;
  owner: User;
  sensorTypes: SensorType[];
  sensorMac: string[];

  static fromOrganizationOutputDto(dto: OrganizationDto): Organization {
    const result = new Organization();

    result.id = dto.id;
    result.name = dto.name;
    result.email = dto.email;
    result.logo = dto.logo;
    result.address = dto.address
      ? Address.fromAddressDto(dto.address)
      : undefined;
    result.owner = dto.owner ? User.fromUserOutputDto(dto.owner) : undefined;
    result.sensorTypes = dto.sensorTypes;
    result.sensorMac = dto.sensorMac;

    return result;
  }

  toOrganizationInputDto(): OrganizationInputDto {
    return {
      name: this.name,
      address: this.address ? this.address.toAddressDto() : undefined,
    };
  }

  toOrganizationWithInitialUserInputDto(): OrganizationWithInitialUserInput {
    return {
      name: this.name,
      address: this.address ? this.address.toAddressDto() : undefined,
      owner: this.owner ? this.owner.toUserInputDto() : undefined,
    };
  }
}

export class SensorType {
  typeName: string;
  sensorCount = 0;
}
