<ng-container *ngIf="visible">
  <div *ngIf="item.sectionTitle" class="section-title">
    {{ item.displayName }}
  </div>
  <a
    mat-list-item
    (click)="onItemSelected(item)"
    [ngClass]="{
      'active-list-item': item.route
        ? router.isActive(item.route, false)
        : false,
      expanded: expanded
    }"
    class="menu-list-item"
    *ngIf="!item.sectionTitle"
    [style.padding-left]="depth * 24 + 'px'"
  >
    <div class="container" fxLayout="row">
      <div fxFlex="15%" fxLayoutAlign="start center">
        <mat-icon>{{ item.iconName }}</mat-icon>
      </div>
      <div fxFlex="75%" fxLayoutAlign="start center">
        {{ item.displayName }}
      </div>
      <div fxFlex="10%">
        <span
          *ngIf="item.children && item.children.length"
          style="float: right"
        >
          <mat-icon [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'">
            expand_more
          </mat-icon>
        </span>
      </div>
    </div>
  </a>
  <div *ngIf="expanded">
    <app-menu-list-item
      *ngFor="let child of item.children"
      [item]="child"
      [depth]="depth + 1"
    >
    </app-menu-list-item>
  </div>
  <mat-divider *ngIf="item.divider"></mat-divider>
</ng-container>
