import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PositioningOverviewModule } from 'src/app/organization/positioning/components/positioning/positioning-overview.module';
import { ShareSensorModalComponent } from './components/share-sensor-modal/share-sensor-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  imports: [
    CommonModule,
    PositioningOverviewModule,
    ReactiveFormsModule,
    MatDialogModule,
    FlexModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
    MatSelectModule,
  ],
  exports: [PositioningOverviewModule],
  declarations: [ShareSensorModalComponent],
})
export class PositioningModule {}
