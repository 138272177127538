<mat-card
  class="square-card mat-elevation-z0"
  fxFlex="0 1 auto"
  fxLayout="column"
>
  <h4 class="square-card-title">Organisationen</h4>
  <ng-container *ngFor="let organization of organizations; let i = index">
    <div class="clickable" (click)="routeToOrganization(organization)">
      <h5 style="margin-bottom: 0">{{ organization.name }}</h5>
      <span
        class="location"
        *ngIf="addressString(organization.address) !== null"
        >{{ addressString(organization.address) }}</span
      >
    </div>
    <mat-divider *ngIf="i < organizations.length - 1"></mat-divider>
  </ng-container>
</mat-card>
