import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/users/services/user.service';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ChangeUserProfileDto } from 'src/app/users/dtos/change-user-profile.dto';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';
import { CustomErrorStateMatcher } from 'src/app/_shared/form/custom-error-state-matcher';
import { User } from 'src/app/users/models/user';
import { ActivatedRoute } from '@angular/router';
import { SnackBarService } from 'src/app/_shared/services/snack-bar.service';
import { Roles } from 'src/app/_core/models/roles';
import { PasswordValidator } from 'src/app/_shared/validators/password-validator';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit {
  // https://github.com/angular/components/issues/4190#issuecomment-305031716
  @ViewChild('f') viewForm;
  profileForm: FormGroup;
  changePasswordError: string;
  user: User;
  edit: boolean;
  matcher: CustomErrorStateMatcher;
  isChangePassword = false;

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private snackBarService: SnackBarService
  ) {
    this.matcher = new CustomErrorStateMatcher();
  }

  ngOnInit(): void {
    this.user = this.activatedRoute.snapshot.data.profile;
    this.initializeForm(this.user);
  }

  private initializeForm(user: User) {
    this.profileForm = new FormGroup({
      currentPassword: new FormControl(undefined, { updateOn: 'blur' }),
      newPassword: new FormControl(undefined, { updateOn: 'blur' }),
      confirmPassword: new FormControl(undefined, { updateOn: 'blur' }),
      username: new FormControl(user.username, [
        Validators.required,
        Validators.email,
        Validators.maxLength(255),
      ]),
      firstName: new FormControl(user.firstName, [Validators.maxLength(255)]),
      lastName: new FormControl(user.lastName, [Validators.maxLength(255)]),
      sendEmailNotifications: new FormControl(user.sendEmailNotifications, [
        Validators.nullValidator,
      ]),
      sendPushNotifications: new FormControl(user.sendPushNotifications, [
        Validators.nullValidator,
      ]),
    });
  }

  /**
   * Unfortunately the Validator function must be asynchronous.
   * At least one tick must be waited until the new value is present in the Value Object after the blur event.
   */
  passwordConfirmValidator(
    control: AbstractControl
  ): Promise<{ [key: string]: boolean } | null> {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(undefined), 1);
    }).then((_) => {
      const formGroup = control.parent;
      const password = formGroup.value.newPassword;
      const confirmPassword = formGroup.value.confirmPassword;

      if (!password || !confirmPassword) {
        return null;
      }

      /**
       * Set or remove Errors
       */
      if (password === confirmPassword) {
        if (
          control === formGroup.get('confirmPassword') &&
          formGroup.get('newPassword').hasError('notSame')
        ) {
          formGroup.get('newPassword').setErrors({ notSame: null });
          formGroup
            .get('newPassword')
            .updateValueAndValidity({ onlySelf: true });
        } else if (
          control === formGroup.get('newPassword') &&
          formGroup.get('confirmPassword').hasError('notSame')
        ) {
          formGroup.get('confirmPassword').setErrors({ notSame: null });
          formGroup
            .get('confirmPassword')
            .updateValueAndValidity({ onlySelf: true });
        }
        return null;
      } else {
        if (
          control === formGroup.get('confirmPassword') &&
          !formGroup.get('newPassword').hasError('notSame')
        ) {
          formGroup.get('newPassword').setErrors({ notSame: true });
          formGroup
            .get('newPassword')
            .updateValueAndValidity({ onlySelf: true });
        } else if (
          control === formGroup.get('newPassword') &&
          !formGroup.get('confirmPassword').hasError('notSame')
        ) {
          formGroup.get('confirmPassword').setErrors({ notSame: true });
          formGroup
            .get('confirmPassword')
            .updateValueAndValidity({ onlySelf: true });
        }
        return { notSame: true };
      }
    });
  }

  save() {
    if (!this.profileForm.valid) {
      return;
    }

    const userProfile: ChangeUserProfileDto = this.profileForm.value;
    this.userService.editUserProfile(userProfile).then((user: User) => {
      if (!user) {
        return;
      }

      this.user = user;
      this.changePasswordError = null;
      this.onNotChangePassword();
      // https://github.com/angular/components/issues/4190#issuecomment-305031716
      this.viewForm.resetForm();
      this.initializeForm(user);
      this.snackBarService.saveSuccessful();
    });
  }

  onChangePassword() {
    this.isChangePassword = true;

    this.profileForm.controls.currentPassword.setValidators([
      Validators.required,
    ]);
    this.profileForm.controls.newPassword.setValidators([
      Validators.minLength(8),
      Validators.required,
      PasswordValidator.strong,
    ]);
    this.profileForm.controls.newPassword.setAsyncValidators([
      this.passwordConfirmValidator,
    ]);
    this.profileForm.controls.confirmPassword.setValidators([
      Validators.minLength(8),
      Validators.required,
      PasswordValidator.strong,
    ]);
    this.profileForm.controls.confirmPassword.setAsyncValidators([
      this.passwordConfirmValidator,
    ]);
  }

  onNotChangePassword() {
    this.profileForm.controls.currentPassword.clearValidators();
    this.profileForm.controls.newPassword.clearValidators();
    this.profileForm.controls.newPassword.clearAsyncValidators();
    this.profileForm.controls.confirmPassword.clearValidators();
    this.profileForm.controls.confirmPassword.clearAsyncValidators();

    this.isChangePassword = false;
  }

  isOrganizationUser(): boolean {
    const currentUserSession = this.authenticationService.getCurrentUserSession();

    if (!currentUserSession) {
      return false;
    }

    return (
      Roles.ORGANIZATION_EDITOR === currentUserSession.role ||
      Roles.ORGANIZATION_OWNER === currentUserSession.role ||
      Roles.ORGANIZATION_VIEWER === currentUserSession.role
    );
  }
}
