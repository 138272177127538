import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GatewaysMapComponent } from './gateways-map.component';
import {GoogleMapsModule} from "@angular/google-maps";
import {MatCardModule} from "@angular/material/card";
import {FlexModule} from "@angular/flex-layout";



@NgModule({
    declarations: [
        GatewaysMapComponent
    ],
    exports: [
        GatewaysMapComponent
    ],
  imports: [
    CommonModule,
    GoogleMapsModule,
    MatCardModule,
    FlexModule
  ]
})
export class GatewaysMapModule { }
