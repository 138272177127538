<div fxLayout="column" class="app-area">
  <!-- HEADER ROW -->
  <div fxLayout="row">
    <div fxFlex fxFlexAlign="start start">
      <h1>CSV-Import</h1>
    </div>
    <div fxFlex style="text-align: right">
      <input
        style="display: none"
        type="file"
        (change)="onFileSelect($event)"
        accept=".csv"
        #hiddenfileinput
      />
      <button
        mat-button
        class="upper-case border-button"
        type="button"
        (click)="hiddenfileinput.click()"
      >
        <mat-icon>upload</mat-icon>
        Datei hochladen
      </button>
    </div>
  </div>

  <div class="square-card" style="height: 100%">
    <div fxLayout="row wrap">
      <div fxFlex="100" [formGroup]="form" style="overflow: scroll">
        <table mat-table [dataSource]="dataSource" style="width: 100%">
          <ng-container matColumnDef="warning">
            <th mat-header-cell *matHeaderCellDef style="width: 40px"></th>
            <td mat-cell *matCellDef="let element" style="width: 40px">
              <mat-icon
                *ngIf="
                  element.value.warning && !element.value.customWarningMessage
                "
                class="text-warning"
                matTooltip="Die Daten sind unvollständig und können nicht importiert werden!"
                >warning_amber
              </mat-icon>
              <mat-icon
                *ngIf="
                  element.value.warning && element.value.customWarningMessage
                "
                class="text-danger"
                [matTooltip]="element.value.customWarningMessage"
                >warning_amber
              </mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-form-field appearance="standard">
                <input
                  matInput
                  placeholder="Name"
                  type="text"
                  name="title"
                  formControlName="title"
                  (ngModelChange)="validateRow(element)"
                />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="eui">
            <th mat-header-cell *matHeaderCellDef>eui</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-form-field appearance="standard">
                <input
                  matInput
                  placeholder="EUI"
                  type="text"
                  name="eui"
                  formControlName="eui"
                  (ngModelChange)="validateRow(element)"
                />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="sensorTypeConfig">
            <th mat-header-cell *matHeaderCellDef>Sensortyp</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-select
                formControlName="sensorTypeConfig"
                style="min-width: 200px"
                (ngModelChange)="validateRow(element)"
              >
                <mat-option
                  *ngFor="let sensorTypeConfig of sensorTypeConfigs"
                  [value]="sensorTypeConfig"
                  >{{ sensorTypeConfig.displayName }} ({{
                    sensorTypeConfig.niotaDeviceTypeId
                  }})
                </mat-option>
              </mat-select>
            </td>
          </ng-container>

          <ng-container matColumnDef="applSessionKey">
            <th mat-header-cell *matHeaderCellDef>applSessionKey</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-form-field appearance="standard">
                <input
                  matInput
                  placeholder="applSessionKey"
                  type="text"
                  name="applSessionKey"
                  formControlName="applSessionKey"
                  (ngModelChange)="validateRow(element)"
                />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="applKey">
            <th mat-header-cell *matHeaderCellDef>applKey</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-form-field appearance="standard">
                <input
                  matInput
                  placeholder="applKey"
                  type="text"
                  name="applKey"
                  formControlName="applKey"
                  (ngModelChange)="validateRow(element)"
                />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="deviceAddress">
            <th mat-header-cell *matHeaderCellDef>deviceAddress</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-form-field appearance="standard">
                <input
                  matInput
                  placeholder="deviceAddress"
                  type="text"
                  name="deviceAddress"
                  formControlName="deviceAddress"
                  (ngModelChange)="validateRow(element)"
                />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="netwSessionKey">
            <th mat-header-cell *matHeaderCellDef>netwSessionKey</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-form-field appearance="standard">
                <input
                  matInput
                  placeholder="netwSessionKey"
                  type="text"
                  name="netwSessionKey"
                  formControlName="netwSessionKey"
                  (ngModelChange)="validateRow(element)"
                />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>description</th>
            <td mat-cell *matCellDef="let element" [formGroup]="element">
              <mat-form-field appearance="standard">
                <input
                  matInput
                  placeholder="description"
                  type="text"
                  name="description"
                  formControlName="description"
                  (ngModelChange)="validateRow(element)"
                />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="applyChangesInIotPlatform">
            <th mat-header-cell *matHeaderCellDef style="width: 80px">
              Änderungen in niota übernehmen
              <mat-icon
                inline
                style="
                  margin-left: 8px;
                  font-size: 20px;
                  vertical-align: bottom;
                  width: 16px;
                "
                matTooltip="Die in Citylink hinterlegten Werte werden in das niota-System übertragen."
              >
                info_outlined
              </mat-icon>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [formGroup]="element"
              style="width: 80px; text-align: center"
            >
              <mat-checkbox
                class="checkbox-center"
                color="primary"
                formControlName="applyChangesInIotPlatform"
                (ngModelChange)="validateRow(element)"
              ></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="removeIcon">
            <th mat-header-cell *matHeaderCellDef style="width: 80px"></th>
            <td
              mat-cell
              *matCellDef="let element; let i = index"
              style="width: 80px; text-align: center"
            >
              <button
                mat-button
                class="border-button"
                type="button"
                (click)="remove(i)"
              >
                <mat-icon color="warn">delete</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let myDataArray; columns: displayedColumns"
          ></tr>
        </table>
      </div>
      <div fxFlex="100" fxLayoutAlign="center start" style="margin-top: 24px">
        <button
          mat-button
          color="primary"
          class="upper-case border-button"
          type="button"
          [disabled]="!isImportable()"
          (click)="doImport()"
        >
          <mat-icon>publish</mat-icon>
          Sensoren anlegen
        </button>
      </div>
    </div>
  </div>
</div>
