import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeasurementParseErrorListModule } from 'src/app/public-utility/measurement-parse-errors/components/measurement-parse-error-list/measurement-parse-error-list.module';
import { MeasurementParseErrorDetailModule } from 'src/app/public-utility/measurement-parse-errors/components/measurement-parse-error-detail/measurement-parse-error-detail.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MeasurementParseErrorListModule,
    MeasurementParseErrorDetailModule,
  ],
  exports: [MeasurementParseErrorListModule, MeasurementParseErrorDetailModule],
})
export class MeasurementParseErrorsModule {}
