import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { OrganizationDto } from 'src/app/public-utility/organization/dtos/organization.dto';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizationService } from 'src/app/_shared/services/organization.service';
import { DeleteConfirmModalComponent } from 'src/app/_core/components/delete-confirm-modal/delete-confirm-modal.component';
import { Roles } from 'src/app/_core/models/roles';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';
import { SensorTypeNameMapper } from 'src/app/_shared/enum/sensor-type-enum';
import { Organization } from 'src/app/public-utility/organization/models/organization';
import { MatDialog } from '@angular/material/dialog';
import { CitylinkOrganization } from 'src/app/public-utility/organization/models/citylink-organization';
import { OrganizationFilterInputDto } from 'src/app/public-utility/organization/dtos/organization-filter-input.dto';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss'],
})
export class OrganizationListComponent implements OnInit {
  enabled = false;
  organizationDataSource: MatTableDataSource<CitylinkOrganization> = new MatTableDataSource<CitylinkOrganization>();
  displayedColumns = [
    'name',
    'address',
    'owner',
    'sensorCount',
    'userCount',
    'notificationConfigCount',
    'invite',
    'options',
  ];
  organizationFilter: OrganizationFilterInputDto = {};
  filterChangedSubject: Subject<void> = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    protected router: Router,
    private organizationService: OrganizationService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.organizationDataSource.data = this.activatedRoute.snapshot.data.organizations;

    const currentUserSession = this.authenticationService.getCurrentUserSession();
    // @ts-ignore
    this.enabled = [Roles.CITYLINK_EDITOR, Roles.CITYLINK_OWNER].includes(
      currentUserSession?.role
    );

    this.filterChangedSubject.pipe(debounceTime(300)).subscribe(() => {
      this.organizationService
        .getList(true, this.organizationFilter)
        .then((results) => {
          this.organizationDataSource.data = results;
        });
    });
  }

  openOrganizationMacList(element: Organization) {
    this.router.navigateByUrl('public-utility/organizations/' + element.id);
  }

  private refreshOrganizations() {
    this.organizationService
      .getList()
      .then((result) =>
        result
          ? (this.organizationDataSource.data = result)
          : (this.organizationDataSource.data = [])
      );
  }

  delete(organization: OrganizationDto) {
    this.dialog
      .open(DeleteConfirmModalComponent)
      .afterClosed()
      .toPromise()
      .then((result) => {
        if (result === 'delete') {
          this.organizationService.delete(organization.id).then((_) => {
            this.refreshOrganizations();
          });
        }
      });
  }

  ownerString(element: Organization) {
    let result = '';
    if (element.owner) {
      if (element.owner.firstName) {
        result += element.owner.firstName + ' ';
      }
      if (element.owner.lastName) {
        result += element.owner.lastName + ' ';
      }
      if (element.owner.username) {
        result += `(${element.owner.username})`;
      }
    }
    return result;
  }

  addressString(element: Organization) {
    let result = '';
    if (element.address.street) {
      result += element.address.street;
    }
    if (element.address.zipCode || element.address.city) {
      result += ', ';
    }
    if (element.address.zipCode) {
      result += element.address.zipCode + ' ';
    }
    if (element.address.city) {
      result += element.address.city;
    }
    return result;
  }

  resendInvitation(element: Organization) {
    this.organizationService.resendInvitation(element.id);
  }

  getSensorTypeName(typeName?: string) {
    return SensorTypeNameMapper.getSensorTypeNameBySensorType(typeName);
  }

  onClearFilterClicked() {
    this.organizationFilter = {};
    this.filterChangedSubject.next();
  }

  isFilter() {
    return (
      this.organizationFilter.owner ||
      this.organizationFilter.address ||
      (this.organizationFilter.ownerEmailConfirmed !== undefined &&
        this.organizationFilter.ownerEmailConfirmed !== null) ||
      this.organizationFilter.name
    );
  }
}
