import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';
import { UserService } from 'src/app/users/services/user.service';
import { User } from 'src/app/users/models/user';
import { DisposeBag } from '@ronas-it/dispose-bag/dist/src';
import { Roles } from 'src/app/_core/models/roles';
import { Router } from '@angular/router';
import { fillParentContainer } from 'fancy-textfill';

@Component({
  selector: 'app-account-list-item',
  templateUrl: './navigation-account-list-item.component.html',
  styleUrls: ['./navigation-account-list-item.component.scss'],
})
export class NavigationAccountListItemComponent
  implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  user: User;
  organizationName = '';
  private disposeBag = new DisposeBag();
  isOrgaOwner = false;
  isCitylinkOwner = true;
  isCitylinkEditor = false;
  @ViewChild('organizationNameElement') organizationNameElement;
  @ViewChild('userNameElement') userNameElement;

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    const userSession = this.authenticationService.getCurrentUserSession();
    if (userSession) {
      // @ts-ignore
      this.isOrgaOwner = Roles.ORGANIZATION_OWNER === userSession.role;
      // @ts-ignore
      this.isCitylinkOwner = Roles.CITYLINK_OWNER === userSession.role;
      this.isCitylinkEditor = Roles.CITYLINK_EDITOR === userSession.role;
    }

    this.authenticationService.userSessionChangedSubject
      .asObservable()
      .subscribe((newUserSession) => {
        if (newUserSession) {
          // @ts-ignore
          this.isOrgaOwner = Roles.ORGANIZATION_OWNER === newUserSession.role;
          // @ts-ignore
          this.isCitylinkOwner = Roles.CITYLINK_OWNER === newUserSession.role;
          this.isCitylinkEditor = Roles.CITYLINK_EDITOR === userSession.role;
        }
      });

    if (userSession) {
      this.organizationName = userSession.organization.name;
    }

    this.disposeBag.add(
      this.authenticationService.organizationDataChangedSubject
        .asObservable()
        .subscribe((orgaData) => {
          this.organizationName = orgaData.organizationName;
          if (this.organizationNameElement) {
            fillParentContainer(this.organizationNameElement.nativeElement, {
              multiline: false,
            });
          }
        })
    );

    this.userService.getUserProfile().then((result) => {
      if (result) {
        this.user = result;
      }
    });

    this.disposeBag.add(
      this.userService.userProfileChangedSubject
        .asObservable()
        .subscribe((profile) => {
          if (!profile) {
            return;
          }

          this.user = profile;
          if (this.user) {
            fillParentContainer(this.organizationNameElement.nativeElement, {
              multiline: false,
            });
          }
        })
    );
  }

  ngAfterViewInit() {
    fillParentContainer(this.organizationNameElement.nativeElement, {
      multiline: false,
    });
  }

  ngAfterViewChecked() {
    fillParentContainer(this.organizationNameElement.nativeElement, {
      multiline: false,
    });
  }

  ngOnDestroy() {
    this.disposeBag.unsubscribe();
  }

  isActive(): boolean {
    const url = this.router.url;
    if (
      url.includes('user-settings') ||
      url.includes('organization/settings') ||
      url.includes('public-utility/settings') ||
      url.includes('organization/mail-template') ||
      url.includes('public-utility/mail-template')
    ) {
      return true;
    }
    return false;
  }
}
