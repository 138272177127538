import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { PushNotificationService } from 'src/app/public-utility/global-push/services/push-notification.service';

@Component({
  selector: 'app-global-push',
  templateUrl: './global-push.component.html',
  styleUrls: ['./global-push.component.scss'],
})
export class GlobalPushComponent implements OnInit {
  form: FormGroup;
  @ViewChild('formRef') formRef: NgForm;

  constructor(private pushNotificationService: PushNotificationService) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      title: new FormControl(undefined, Validators.required),
      subtitle: new FormControl(),
      body: new FormControl(),
    });
  }

  sendNotification() {
    this.pushNotificationService.sendNotification(this.form.value).then(() => {
      this.form.reset();
      this.formRef.resetForm();
    });
  }
}
