<div fxLayout="column" class="user-group-row">
  <div fxFlex="100" fxLayout="row" class="selection-wrapper">
    <div fxLayout="column" fxFlex="grow">
      <div class="group-title">{{ userGroup.name }}</div>
      <div class="subtitle">
        <span>{{ userGroup.userIds.length }} Nutzer</span>
      </div>
    </div>
    <div fxFlex="40px" fxFlexAlign="center" style="text-align: right">
      <button
        mat-icon-button
        class="options-button"
        [matMenuTriggerFor]="menu"
        (click)="$event.stopPropagation()"
      >
        <mat-icon class="options">more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="editUserGroup()">
          <mat-icon>create</mat-icon>
          Bearbeiten
        </button>
        <button mat-menu-item (click)="removeUserGroup()">
          <mat-icon>delete_outline</mat-icon>
          Entfernen
        </button>
      </mat-menu>
    </div>
  </div>
</div>
