import { Component, OnInit } from '@angular/core';
import { DateTimeModalComponent } from 'src/app/_shared/components/date-time-modal/date-time-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MeasurementIntervalOutputDto } from 'src/app/_shared/dtos/measurements/measurement-interval-output.dto';
import { MeasurementAggregationInputDto } from 'src/app/_shared/dtos/measurements/measurement-aggregation-input.dto';
import * as moment from 'moment';
import { SensorPairingShareService } from 'src/app/organization/positioning/services/sensor-pairing-share.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-shared-sensor',
  templateUrl: './shared-sensor.component.html',
  styleUrls: ['./shared-sensor.component.scss'],
})
export class SharedSensorComponent implements OnInit {
  measurements: MeasurementIntervalOutputDto[] = [];
  measurementAggregationInputDto: MeasurementAggregationInputDto = {
    from: moment().subtract(1, 'days').toDate(),
    to: new Date(),
    aggregationType: 'minutes',
    range: 5,
  };
  loading = true;
  sensorPairingShareIdentifier: string;
  sensorName = '';

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private sensorPairingShareService: SensorPairingShareService
  ) {}

  ngOnInit(): void {
    this.sensorPairingShareIdentifier = this.route.snapshot.paramMap.get(
      'sensorPairingShareIdentifier'
    );
    this.updateMeasurements().finally(() => (this.loading = false));
  }

  changeMeasurementAggregationFrom() {
    this.dialog
      .open(DateTimeModalComponent, {
        data: { date: this.measurementAggregationInputDto.from },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.measurements = [];
          this.measurementAggregationInputDto.from = result;
          this.loading = true;
          this.updateMeasurements().finally(() => (this.loading = false));
        }
      });
  }

  private updateMeasurements(): Promise<void> {
    return this.sensorPairingShareService
      .loadMeasurements(
        this.sensorPairingShareIdentifier,
        this.measurementAggregationInputDto
      )
      .then((res) => {
        if (res) {
          this.measurements = res.measurements;
          this.sensorName = res.sensorName;
        }
      });
  }

  changeMeasurementAggregationTo() {
    this.dialog
      .open(DateTimeModalComponent, {
        data: { date: this.measurementAggregationInputDto.to },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.measurements = [];
          this.measurementAggregationInputDto.to = result;
          this.loading = true;
          this.updateMeasurements().finally(() => (this.loading = false));
        }
      });
  }
}
