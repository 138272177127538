import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationConfigurationModule } from 'src/app/organization/notification-configuration/notification-configuration.module';
import { DashboardModule } from 'src/app/organization/dashboard/dashboard.module';
import { PositioningModule } from 'src/app/organization/positioning/positioning.module';
import { SensorStateIndicatorConfigurationModule } from 'src/app/organization/sensor-state-indicator-configuration/sensor-state-indicator-configuration.module';
import { NotificationMuteSettingsModule } from 'src/app/organization/notification-mute/components/notification-mute/notification-mute-settings.module';
import { NotificationMuteModule } from 'src/app/organization/notification-mute/notification-mute.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NotificationConfigurationModule,
    DashboardModule,
    PositioningModule,
    SensorStateIndicatorConfigurationModule,
    NotificationMuteModule,
  ],
  exports: [
    NotificationConfigurationModule,
    DashboardModule,
    PositioningModule,
    SensorStateIndicatorConfigurationModule,
    NotificationMuteModule,
  ],
})
export class OrganizationModule {}
