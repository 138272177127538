import {
  AfterViewInit,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  NgModel,
} from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { User } from 'src/app/users/models/user';

@Component({
  selector: 'app-multiple-user-select',
  templateUrl: './multiple-user-select.component.html',
  styleUrls: ['./multiple-user-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleUserSelectComponent),
      multi: true,
    },
  ],
})
export class MultipleUserSelectComponent
  implements ControlValueAccessor, OnInit, OnChanges, AfterViewInit {
  private val: any;
  /**
   * The FormControl must be passed, so that the validators can be passed to the input model of this custom ControlValueAccessor.
   */
  @Input() formControl: AbstractControl;
  @Input() users: Array<User> = [];
  public userFilterCtrl: FormControl = new FormControl();
  public filteredUsers: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  @ViewChild('selectModel') selectModel: NgModel;

  ngOnInit() {
    // load the initial user list
    if (this.users) {
      this.filteredUsers.next(this.users.slice());
    }

    // listen for search field value changes
    this.userFilterCtrl.valueChanges.subscribe(() => this.filterUsers());
  }

  ngOnChanges(changes: SimpleChanges) {
    // load the initial user list
    if (this.users) {
      this.filteredUsers.next(this.users.slice());
    }
  }

  ngAfterViewInit(): void {
    if (this.formControl) {
      this.selectModel.control.setValidators(this.formControl.validator);
      this.selectModel.control.setAsyncValidators(
        this.formControl.asyncValidator
      );
    }
  }

  protected filterUsers() {
    if (!this.users) {
      return;
    }
    // get the search keyword
    let search = this.userFilterCtrl.value;
    if (!search) {
      this.filteredUsers.next(this.users.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredUsers.next(
      this.users.filter((user) =>
        this.userToString(user).toLowerCase().includes(search)
      )
    );
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  constructor() {}

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  userToString(user: User) {
    return `${user.firstName} ${user.lastName}`;
  }

  isEmptySelection(): boolean {
    return Array.isArray(this.value) && this.value.length > 0;
  }
}
