import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedSensorComponent } from 'src/app/_shared/components/shared-sensor/shared-sensor.component';
import { MatTabsModule } from '@angular/material/tabs';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { SensorMeasurementsChartModule } from 'src/app/organization/positioning/components/sensor-measurements-chart/sensor-measurements-chart.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [SharedSensorComponent],
  imports: [
    CommonModule,
    MatTabsModule,
    FlexModule,
    MatButtonModule,
    SensorMeasurementsChartModule,
    MatProgressSpinnerModule,
    MatCardModule,
  ],
  exports: [SharedSensorComponent],
})
export class SharedSensorModule {}
