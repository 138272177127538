<mat-card
  fxFlex
  class="square-card mat-elevation-z0"
  fxLayout="column"
  fxLayoutAlign="center center"
>
  <h4 class="square-card-title">Gateways</h4>
  <div style="height: 600px; width: 100%">
    <google-map height="100%" width="100%" [options]="gmOptions">
      <map-marker #marker="mapMarker"
                  *ngFor="let place of places;"
                  [position]="place.markerPosition"
                  [options]="place.markerOptions"
                  (mapClick)="openInfoWindow(marker, place.gateway)"
      >
        <map-info-window>
          <div *ngIf="gatewayInfo">
            <p>EUI: <strong>{{formatEui(gatewayInfo.eui)}}</strong></p>
            <p>Titel: <strong>{{gatewayInfo.title}}</strong></p>
            <p>Beschreibung: <strong>{{gatewayInfo.description}}</strong></p>
            <p>Hersteller: <strong>{{gatewayInfo.manufacturer}}</strong></p>
            <p>Paketanzahl: <strong>{{gatewayInfo.countPackets.toLocaleString()}}</strong></p>
            <p>Koordinaten: <strong>{{gatewayInfo.coordinates.lat}}, {{gatewayInfo.coordinates.lng}}</strong></p>
            <p>Adresse: <strong>{{gatewayInfo.address}}</strong></p>
          </div>
        </map-info-window>
      </map-marker>
    </google-map>
  </div>
</mat-card>





