import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { DashboardCurrentEventsComponent } from 'src/app/organization/dashboard/components/current-events/dashboard-current-events.component';
import { DashboardCurrentEventModule } from 'src/app/organization/dashboard/components/current-events/event/dashboard-current-event.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [DashboardCurrentEventsComponent],
  exports: [DashboardCurrentEventsComponent],
  imports: [
    CommonModule,
    DashboardCurrentEventModule,
    MatTabsModule,
    FlexModule,
    MatButtonModule,
    MatCardModule,
  ],
})
export class DashboardCurrentEventsModule {}
