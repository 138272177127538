import { NgModule } from '@angular/core';
import { LoginModule } from 'src/app/_core/components/login/login.module';
import { RequestNewPasswordModule } from 'src/app/_core/components/request-new-password/request-new-password.module';
import { UserConfirmationModule } from 'src/app/_core/components/user-confirmation/user-confirmation.module';
import { UserPasswordResetModule } from 'src/app/_core/components/user-password-reset/user-password-reset.module';
import { DeleteConfirmModalModule } from 'src/app/_core/components/delete-confirm-modal/delete-confirm-modal.module';
import { UserSettingsModule } from 'src/app/_core/components/user-settings/user-settings.module';

@NgModule({
  imports: [
    LoginModule,
    RequestNewPasswordModule,
    UserConfirmationModule,
    UserPasswordResetModule,
    UserSettingsModule,
    DeleteConfirmModalModule,
  ],
  exports: [DeleteConfirmModalModule],
})
export class CoreModule {}
