import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorModalComponent } from 'src/app/_shared/components/error-modal/error-modal.component';
import { ApiError, ApiErrorType } from 'src/app/_shared/exception/api.error';

export interface ErrorMessage {
  userErrorMessage: string;
  technicalErrorMessage?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private dialog: MatDialog) {}

  determineErrorMessage(err: ApiError): ErrorMessage {
    if (err.code !== undefined && err.code !== null) {
      return this.determineCitylinkErrorMessage(err);
    } else if (err.statusCode !== null && err.statusCode !== undefined) {
      return this.determineHttpErrorMessage(err);
    } else {
      return {
        userErrorMessage:
          'Ein unerwarteter Fehler ist aufgetreten. Bitte wenden sie sich an den Softwareersteller.',
        technicalErrorMessage: err.message,
      };
    }
  }

  handleError(error: ApiError) {
    const errorMessage = this.determineErrorMessage(error);
    this.showError(errorMessage);
  }

  showError(errorMessage: ErrorMessage) {
    this.dialog.open(ErrorModalComponent, {
      data: { errorMessage },
    });
  }

  public determineCitylinkErrorMessage(error: ApiError): ErrorMessage {
    switch (error.code) {
      case ApiErrorType.INTERNAL_SERVER_ERROR:
        return {
          userErrorMessage: 'Ein unbekannter Fehler ist aufgetreten.',
          technicalErrorMessage: error.message,
        };
      case ApiErrorType.JWT_TOKEN_INVALID:
      case ApiErrorType.JWT_TOKEN_EXPIRED:
      case ApiErrorType.JWT_TOKEN_MISSING:
      case ApiErrorType.JWT_REFRESH_TOKEN_INVALID:
      case ApiErrorType.JWT_REFRESH_TOKEN_EXPIRED:
      case ApiErrorType.JWT_REFRESH_TOKEN_MISSING:
        return {
          userErrorMessage:
            'Ihre Anmeldung ist abgelaufen. Bitte melden sie sich neu an.',
        };
      case ApiErrorType.INVALID_PARAMETER_FORMAT:
        return {
          userErrorMessage:
            'Ein Parameter zur Abfrage des Objektes ist fehlerhaft. Wenden sie sich an den Administrator.',
          technicalErrorMessage: error.message,
        };
      case ApiErrorType.ENTITY_NOT_FOUND:
        return { userErrorMessage: 'Das Objekt konnte nicht gefunden werden.' };
      case ApiErrorType.ENTITY_ALREADY_DEFINED:
        return {
          userErrorMessage: 'Das zu speichernde Objekt ist bereits vorhanden.',
        };
      case ApiErrorType.FORBIDDEN_ACTION:
        return {
          userErrorMessage:
            'Sie sind nicht berechtigt diese Aktion durchzuführen oder das Objekt abzufragen.',
        };
      case ApiErrorType.REPEATED_INVALID_LOGIN:
        return {
          userErrorMessage:
            'Sie haben sich wiederholt versucht mit fehlerhaften Daten anzumelden.',
        };
      case ApiErrorType.ROLE_NOT_FOUND:
        return { userErrorMessage: 'Die angegeben Rolle ist unbekannt.' };
      case ApiErrorType.USER_HAS_NO_ROLE:
        return {
          userErrorMessage:
            'Ihnen ist keine Rolle zugeteilt. Wenden sie sich an den Administrator',
        };
      case ApiErrorType.USER_ROLE_INVALID:
        return { userErrorMessage: 'Die angegebene Rolle ist nicht valide.' };
      case ApiErrorType.UNKNOWN_MEASUREMENT_TYPE:
        return {
          userErrorMessage:
            'Die angefragten Werte haben ein unbekanntes Format.',
        };
      case ApiErrorType.SENSOR_TYPE_CONFIG_NOT_FOUND:
        return { userErrorMessage: 'Der angegebene Sensortyp ist unbekannt.' };
      case ApiErrorType.SENSOR_PROPERTY_CONFIG_NOT_FOUND:
        return {
          userErrorMessage: 'Die angegebener Sensoreigenschafts ist unbekannt.',
        };
      case ApiErrorType.USER_NOT_FOUND:
        return { userErrorMessage: 'Der Nutzer ist unbekannt.' };
      case ApiErrorType.EMAIL_ALREADY_TAKEN:
        return {
          userErrorMessage:
            'Die angegebene E-Mail Adresse wird bereits verwendet.',
        };
      case ApiErrorType.ROOT_SENSOR_GROUP_NODE_CANNOT_BE_DELETED:
        return {
          userErrorMessage: 'Die Wurzelebene kann nicht gelöscht werden.',
        };
      case ApiErrorType.SENSOR_GROUP_NODE_NO_PARENT_GIVEN:
        return {
          userErrorMessage:
            'Für die Positionierung wird die Angabe der übergeordneten Ebene benötigt.',
        };
      case ApiErrorType.SENSOR_GROUP_NODE_PARENT_NOT_FOUND:
        return {
          userErrorMessage:
            'Für die Positionierung ist die angegebene übergeordneten Ebene unbekannt.',
        };
      case ApiErrorType.SENSOR_GROUP_NODE_SELF_AS_SUBLEVEL_NOT_ALLOWED:
        return {
          userErrorMessage:
            'Eine Positionierungsebene kann sich nicht selbst hinzugefügt werden.',
        };
      case ApiErrorType.SENSOR_ALREADY_PRESENT_IN_SYSTEM:
        return {
          userErrorMessage:
            'Der zu speichernde Sensor ist im System bereits vorhanden.',
        };
      case ApiErrorType.NIOTA_DEVICE_CREATE_ERROR:
        return {
          userErrorMessage:
            'Bei der Anlage des Sensors in niota ist ein Fehler aufgetreten.',
          technicalErrorMessage: error.message,
        };
      case ApiErrorType.NIOTA_DEVICE_SYNCRONISE_ERROR:
        return {
          userErrorMessage:
            'Bei der Syncronisation des Sensors in niota ist ein Fehler aufgetreten.',
          technicalErrorMessage: error.message,
        };
      case ApiErrorType.PASSWORD_INCORRECT:
        return { userErrorMessage: 'Fehlerhafte Passworteingabe.' };
      case ApiErrorType.ICON_COLOR_INVALID:
        return {
          userErrorMessage: 'Die angegebene Icon-Farbe wird nicht unterstützt.',
        };
      case ApiErrorType.WEAK_PASSWORD:
        return {
          userErrorMessage:
            'Das Passwort entspricht nicht den Sicherheitsbestimmungen.',
        };
      default:
        return {
          userErrorMessage: 'Ein unbekannter Fehler ist aufgetreten.',
          technicalErrorMessage: error.message,
        };
    }
  }

  private determineHttpErrorMessage(err: ApiError): ErrorMessage {
    switch (err.statusCode) {
      case 400:
        return {
          userErrorMessage:
            'Das Back-End System konnte die Anfrage nicht bearbeiten.',
          technicalErrorMessage: err.message,
        };
      case 403:
        return {
          userErrorMessage:
            'Sie sind nicht berechtigt diese Aktion durchzuführen oder das Objekt abzufragen.',
        };
      case 404:
        return { userErrorMessage: 'Das Objekt konnte nicht gefunden werden.' };
      case 500:
      default:
        return {
          userErrorMessage:
            'Ein unerwarteter Fehler ist aufgetreten. Bitte wenden sie sich an den Softwareersteller.',
          technicalErrorMessage: err.message,
        };
    }
  }
}
