import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { SensorGroupNode } from 'src/app/_shared/models/sensor-group-node';
import { SensorGroupNodeService } from 'src/app/_shared/services/sensor-group-node.service';
import { UserGroup } from 'src/app/users/models/user-group';
import { User } from 'src/app/users/models/user';
import { UserService } from 'src/app/users/services/user.service';
import { UserGroupService } from 'src/app/users/services/user-group.service';
import { ApiService } from 'src/app/_core/services/api/api.service';

interface PositioningOverviewResolverResult {
  treeRoot: SensorGroupNode;
  userGroups: UserGroup[];
  users: User[];
}

@Injectable({
  providedIn: 'root', // https://github.com/angular/angular-cli/issues/10170#issuecomment-380673276
})
export class PositioningOverviewResolver
  implements Resolve<void | PositioningOverviewResolverResult> {
  constructor(
    private sensorGroupNodeService: SensorGroupNodeService,
    private userService: UserService,
    private userGroupService: UserGroupService,
    private apiService: ApiService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<void | PositioningOverviewResolverResult> {
    const promises = [];
    promises.push(this.sensorGroupNodeService.getTree(false));
    promises.push(this.userService.getUserList(false));
    promises.push(this.userGroupService.getList(false));

    return Promise.all(promises)
      .then((allPromisesResult) => {
        return {
          treeRoot: allPromisesResult[0],
          userGroups: allPromisesResult[1],
          users: allPromisesResult[2],
        } as PositioningOverviewResolverResult;
      })
      .catch((err) => {
        this.apiService.handleError(err);
        throw err;
      });
  }
}
