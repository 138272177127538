import { Component, OnInit } from '@angular/core';
import { NotificationConfigService } from 'src/app/organization/notification-configuration/services/notification-config.service';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationConfig } from 'src/app/organization/notification-configuration/models/notification-config';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NotificationConfigurationType,
  NotificationConfigurationTypeBase,
} from 'src/app/_core/models/notification-configuration-type';
import { Roles } from 'src/app/_core/models/roles';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';
import { Operator, OperatorBase } from 'src/app/_core/models/operator';
import { Subject } from 'rxjs';
import { SensorTypeConfig } from 'src/app/_shared/models/sensor-type-config';
import { SensorPropertyConfig } from 'src/app/_shared/models/sensor-property-config';
import { debounceTime } from 'rxjs/operators';
import { SensorStateIndicatorConfigFilterInputDto } from 'src/app/organization/sensor-state-indicator-configuration/dto/sensor-state-indicator-config-filter-input.dto';
import { NotificationConfigurationFilterInputDto } from 'src/app/organization/notification-configuration/dto/notification-configuration-filter-input.dto';

@Component({
  selector: 'app-alarm-configuration',
  templateUrl: './notification-configuration-list.component.html',
  styleUrls: ['./notification-configuration-list.component.scss'],
})
export class NotificationConfigurationListComponent implements OnInit {
  displayedColumns = [
    'icon',
    'title',
    'type',
    'sensorTypeConfig',
    'sensorPropertyConfig',
    'rule',
    'sensorCount',
    'configurationIncompleteHint',
    'options',
  ];
  matTableDataSource = new MatTableDataSource<NotificationConfig>();
  enabled = false;
  notificationConfigFilter: {
    title?: string;
    type?: NotificationConfigurationTypeBase;
    operator?: OperatorBase;
    sensorTypeConfig?: SensorTypeConfig;
    sensorPropertyConfig?: SensorPropertyConfig;
  } = {};
  filterChangedSubject: Subject<void> = new Subject<void>();
  sensorTypeConfigs: SensorTypeConfig[];

  constructor(
    protected notificationConfigService: NotificationConfigService,
    protected router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.matTableDataSource.data = this.activatedRoute.snapshot.data.data.notificationConfigs;
    this.sensorTypeConfigs = this.activatedRoute.snapshot.data.data.sensorTypeConfigs;

    const currentUserSession = this.authenticationService.getCurrentUserSession();
    // @ts-ignore
    this.enabled = [
      Roles.ORGANIZATION_OWNER,
      Roles.ORGANIZATION_EDITOR,
    ].includes(currentUserSession?.role);

    this.filterChangedSubject.pipe(debounceTime(300)).subscribe(() => {
      this.reloadNotificationConfigList();
    });
  }

  private reloadNotificationConfigList() {
    const dto: NotificationConfigurationFilterInputDto = {
      title: this.notificationConfigFilter.title,
      sensorTypeConfigId: this.notificationConfigFilter.sensorTypeConfig?.id,
      sensorPropertyConfigId: this.notificationConfigFilter.sensorPropertyConfig
        ?.id,
      type: this.notificationConfigFilter.type,
      operator: this.notificationConfigFilter.operator,
    };

    this.notificationConfigService
      .getNotificationConfigList(dto)
      .then((result) => {
        if (!result) {
          return;
        }
        this.matTableDataSource.data = result;
      });
  }

  deleteNotificationConfig(element: NotificationConfig) {
    this.notificationConfigService
      .deleteNotificationConfig(element.id)
      .then((_) => {
        this.reloadNotificationConfigList();
      });
  }

  openEditNotificationConfig(element: NotificationConfig) {
    this.router.navigateByUrl(
      'organization/alarm-configuration/edit/' + element.id
    );
  }

  onCreateButtonClicked() {
    this.router.navigateByUrl('organization/alarm-configuration/create');
  }

  translateNotificationType(type: NotificationConfigurationTypeBase) {
    return NotificationConfigurationType.translate(type);
  }

  getOperatorLabel(operator: OperatorBase) {
    return Operator.translate(operator);
  }

  isConfigurationIncomplete(element: NotificationConfig) {
    return !element.sensorPropertyConfig || !element.sensorPropertyConfig;
  }

  notificationTypes() {
    return NotificationConfigurationType.entries();
  }

  sensorTypeToLabel(sensorTypeConfig: SensorTypeConfig) {
    return sensorTypeConfig.displayName;
  }

  sensorPropertyConfigToLabel(sensorPropertyConfig: SensorPropertyConfig) {
    return sensorPropertyConfig.displayName;
  }

  operators() {
    return Operator.entries();
  }

  onClearFilterClicked() {
    this.notificationConfigFilter = {
      title: undefined,
      sensorPropertyConfig: undefined,
      sensorTypeConfig: undefined,
      type: undefined,
      operator: undefined,
    };
    this.filterChangedSubject.next();
  }

  isFilter() {
    return (
      this.notificationConfigFilter.type ||
      this.notificationConfigFilter.operator ||
      this.notificationConfigFilter.sensorTypeConfig ||
      this.notificationConfigFilter.sensorPropertyConfig ||
      this.notificationConfigFilter.title
    );
  }
}
