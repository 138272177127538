import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MeasurementParseErrorGroupOutputDto } from 'src/app/public-utility/measurement-parse-errors/dto/measurement-parse-error.output';
import { ActivatedRoute } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { MeasurementParseErrorPaginatedOutputDto } from 'src/app/public-utility/measurement-parse-errors/dto/measurement-parse-error-paginated.output';
import { MeasurementParseErrorService } from 'src/app/public-utility/measurement-parse-errors/services/measurement-parse-error.service';
import { MeasurementParseErrorFilterInputDto } from 'src/app/public-utility/measurement-parse-errors/dto/measurement-parse-error-filter-input.dto';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SensorFilterInputDto } from 'src/app/public-utility/sensors/dto/sensor-filter-input-dto';

@Component({
  selector: 'app-measurement-parse-error-list',
  templateUrl: './measurement-parse-error-groups-list.component.html',
  styleUrls: ['./measurement-parse-error-groups-list.component.scss'],
})
export class MeasurementParseErrorGroupsListComponent implements OnInit {
  tableDataSource: MatTableDataSource<MeasurementParseErrorGroupOutputDto> = new MatTableDataSource<MeasurementParseErrorGroupOutputDto>();
  displayedColumns = [
    'sensorTitle',
    'sensorEUI',
    'sensorProperty',
    'date',
    'errorCount',
  ];
  totalLength = 100;
  pageSize: 100;
  measurementParseErrorFilter: MeasurementParseErrorFilterInputDto = {};
  filterChangedSubject: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private measurementParseErrorService: MeasurementParseErrorService
  ) {}

  ngOnInit(): void {
    const dto: MeasurementParseErrorPaginatedOutputDto = this.route.snapshot
      .data.data;
    this.tableDataSource.data = dto.data;
    this.totalLength = dto.pagination.itemCount;

    this.filterChangedSubject.pipe(debounceTime(300)).subscribe(() => {
      this.measurementParseErrorService
        .getList(0, 100, this.measurementParseErrorFilter)
        .then((result) => {
          // @ts-ignore
          if (result !== undefined && result !== null) {
            this.tableDataSource.data = (result as MeasurementParseErrorPaginatedOutputDto).data;
            this.totalLength = (result as MeasurementParseErrorPaginatedOutputDto).pagination.itemCount;
          }
        });
    });
  }

  toDate(dateString) {
    return new Date(dateString);
  }

  pageChangeEvent(pageEvent: PageEvent) {
    this.measurementParseErrorService
      .getList(pageEvent.pageIndex, 100, this.measurementParseErrorFilter)
      .then((res) => {
        if (res) {
          this.tableDataSource.data = res.data;
          this.totalLength = res.pagination.itemCount;
        }
      });
  }

  isFilter() {
    return (
      this.measurementParseErrorFilter.sensorEUI ||
      this.measurementParseErrorFilter.sensorName ||
      this.measurementParseErrorFilter.sensorPropertyName
    );
  }

  onClearFilterClicked() {
    this.measurementParseErrorFilter = {};
    this.filterChangedSubject.next();
  }
}
