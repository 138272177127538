import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/_core/services/api/api.service';
import { SensorStateIndicatorConfigurationOutputDto } from 'src/app/organization/sensor-state-indicator-configuration/dto/sensor-state-indicator-configuration-output.dto';
import { SensorStateIndicatorConfigurationPostDto } from 'src/app/organization/sensor-state-indicator-configuration/dto/sensor-state-indicator-configuration-post.dto';
import { SensorStateIndicatorConfigurationPatchDto } from 'src/app/organization/sensor-state-indicator-configuration/dto/sensor-state-indicator-configuration-patch.dto';
import { SnackBarService } from 'src/app/_shared/services/snack-bar.service';
import { SensorStateIndicatorConfigFilterInputDto } from 'src/app/organization/sensor-state-indicator-configuration/dto/sensor-state-indicator-config-filter-input.dto';

@Injectable({
  providedIn: 'root',
})
export class SensorStateIndicatorConfigurationService {
  constructor(
    private apiService: ApiService,
    private snackBarService: SnackBarService
  ) {}

  getList(
    sensorStateIndicatorConfigFilterInputDto?: SensorStateIndicatorConfigFilterInputDto
  ): Promise<SensorStateIndicatorConfigurationOutputDto[]> {
    return this.apiService
      .get<SensorStateIndicatorConfigurationOutputDto[]>(
        '/sensor-state-indicator-configuration',
        undefined,
        sensorStateIndicatorConfigFilterInputDto
      )
      .then((result) => {
        if (!result) {
          return [];
        }

        return result;
      });
  }

  getById(
    id: number,
    handleErrors = true
  ): Promise<void | SensorStateIndicatorConfigurationOutputDto> {
    return this.apiService.get<SensorStateIndicatorConfigurationOutputDto>(
      `/sensor-state-indicator-configuration/${id}`,
      undefined,
      undefined,
      handleErrors
    );
  }

  post(
    dto: SensorStateIndicatorConfigurationPostDto
  ): Promise<void | SensorStateIndicatorConfigurationOutputDto> {
    return this.apiService
      .post<SensorStateIndicatorConfigurationOutputDto>(
        '/sensor-state-indicator-configuration',
        dto
      )
      .then((res) => {
        this.snackBarService.saveSuccessful();
        return res;
      });
  }

  patch(
    dto: SensorStateIndicatorConfigurationPatchDto,
    id: number
  ): Promise<void | SensorStateIndicatorConfigurationOutputDto> {
    return this.apiService
      .patch<SensorStateIndicatorConfigurationOutputDto>(
        `/sensor-state-indicator-configuration/${id}`,
        dto
      )
      .then((res) => {
        this.snackBarService.saveSuccessful();
        return res;
      });
  }

  delete(id): Promise<any> {
    return this.apiService
      .delete<any>(`/sensor-state-indicator-configuration/${id}`)
      .then((_) => {
        this.snackBarService.deleteSuccessful();
      });
  }
}
