export enum SensorConfigTechnicalName {
  TEMPERATURE = 'temperature',
  HUMIDITY = 'humidity',
  VOLTAGE = 'voltage',
  MOTION = 'motion',
  LIGHT = 'light',
  MULTI = 'multi',
  WATER = 'water',
  CARBON_DIOXIDE = 'carbon_dioxide',
  ENERGY = 'energy',
}

export function sensorConfigTechnicalNameAsArray(): Array<SensorConfigTechnicalName> {
  return [
    SensorConfigTechnicalName.TEMPERATURE,
    SensorConfigTechnicalName.HUMIDITY,
    SensorConfigTechnicalName.VOLTAGE,
    SensorConfigTechnicalName.MOTION,
    SensorConfigTechnicalName.LIGHT,
    SensorConfigTechnicalName.MULTI,
    SensorConfigTechnicalName.WATER,
    SensorConfigTechnicalName.CARBON_DIOXIDE,
    SensorConfigTechnicalName.ENERGY,
  ];
}

export function sensorConfigTechnicalNameToLabel(
  enumItem: SensorConfigTechnicalName | string
): string {
  switch (enumItem) {
    case SensorConfigTechnicalName.TEMPERATURE:
      return 'Temperatur';
    case SensorConfigTechnicalName.HUMIDITY:
      return 'Feuchtigkeit';
    case SensorConfigTechnicalName.VOLTAGE:
      return 'Spannung (Elektrisch)';
    case SensorConfigTechnicalName.MOTION:
      return 'Bewegung';
    case SensorConfigTechnicalName.LIGHT:
      return 'Licht';
    case SensorConfigTechnicalName.MULTI:
      return 'Multi';
    case SensorConfigTechnicalName.WATER:
      return 'Wasser';
    case SensorConfigTechnicalName.CARBON_DIOXIDE:
      return 'Kohlenstoffdioxid';
    case SensorConfigTechnicalName.ENERGY:
      return 'Energie';
    default:
      return '';
  }
}
