import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicUtilityDashboardOverviewModule } from 'src/app/public-utility/public-utility-dashboard/components/public-utility-dashboard-overview/public-utility-dashboard-overview.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, PublicUtilityDashboardOverviewModule],
  exports: [PublicUtilityDashboardOverviewModule],
})
export class PublicUtilityDashboardModule {}
