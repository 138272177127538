<mat-card class="square-card mat-elevation-z0">
  <h4 class="square-card-title">Aktuelle Ereignisse</h4>
  <mat-tab-group [disableRipple]="true">
    <mat-tab label="Alle">
      <ng-container *ngFor="let event of notifications; let i = index">
        <app-event
          [date]="event.date"
          [title]="event.title"
          [subtitle]="event.subtitle"
          [body]="event.body"
          [type]="event.type"
          [location]="event.location"
          [showDivider]="i < notifications.length - 1"
          [iconUrl]="event.icon"
        >
        </app-event>
      </ng-container>
    </mat-tab>
    <mat-tab label="Alarme">
      <ng-container *ngFor="let event of notifications; let i = index">
        <ng-container *ngIf="event.type == 'error'">
          <app-event
            [date]="event.date"
            [title]="event.title"
            [subtitle]="event.subtitle"
            [body]="event.body"
            [type]="event.type"
            [location]="event.location"
            [showDivider]="i < notifications.length - 1"
            [iconUrl]="event.icon"
          >
          </app-event>
        </ng-container>
      </ng-container>
    </mat-tab>
    <mat-tab label="Warnungen">
      <ng-container *ngFor="let event of notifications; let i = index">
        <ng-container *ngIf="event.type == 'warning'">
          <app-event
            [date]="event.date"
            [title]="event.title"
            [subtitle]="event.subtitle"
            [body]="event.body"
            [type]="event.type"
            [location]="event.location"
            [showDivider]="i < notifications.length - 1"
            [iconUrl]="event.icon"
          >
          </app-event>
        </ng-container>
      </ng-container>
    </mat-tab>
  </mat-tab-group>
  <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: 16px">
    <button
      mat-button
      color="primary"
      class="upper-case"
      (click)="loadNextPage()"
      [disabled]="loadingInProcess"
      [class.button-spinner]="loadingInProcess"
    >
      Mehr Anzeigen
    </button>
  </div>
</mat-card>
