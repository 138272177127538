import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Organization } from 'src/app/public-utility/organization/models/organization';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';
import { OrganizationService } from 'src/app/_shared/services/organization.service';
import tinycolor from 'tinycolor2';
import { Roles, roleToLabelText } from 'src/app/_core/models/roles';
import { UserInputDto } from 'src/app/users/dtos/user-input.dto';
import { OrganizationWithInitialUserInput } from 'src/app/public-utility/organization/dtos/organization-with-initial-user-input';
import { UserService } from 'src/app/users/services/user.service';
import { ErrorService } from 'src/app/_shared/services/error.service';
import { Color } from 'src/app/_shared/angular-material-color-picker';

@Component({
  selector: 'app-organization-settings',
  templateUrl: './organization-settings.component.html',
  styleUrls: ['./organization-settings.component.scss'],
})
export class OrganizationSettingsComponent implements OnInit {
  form: FormGroup;
  logo: SafeUrl;
  isCreate = false;
  organizationOwnerRole = Roles.ORGANIZATION_OWNER;

  constructor(
    private domSanitizer: DomSanitizer,
    public activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private organizationService: OrganizationService,
    private userService: UserService,
    private router: Router,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {
    const organization: Organization = this.activatedRoute.snapshot.data
      .organization;
    if (organization) {
      this.initForEdit(organization);
    } else {
      this.initForCreate();
    }
  }

  getTextForRoleEnum(organizationOwnerRole: Roles) {
    return roleToLabelText(organizationOwnerRole);
  }

  private initForEdit(organization: Organization) {
    const isOwnOrgaEdit = this.isOwnOrgaEdit();

    this.logo = this.domSanitizer.bypassSecurityTrustUrl(organization.logo);
    this.form = new FormGroup({
      id: new FormControl(organization.id),
      name: new FormControl(organization.name, [
        Validators.required,
        Validators.maxLength(255),
      ]),
      logo: new FormControl(organization.logo),
      address: new FormGroup({
        street: new FormControl(organization.address?.street, [
          Validators.maxLength(255),
        ]),
        city: new FormControl(organization.address?.city, [
          Validators.maxLength(255),
        ]),
        zipCode: new FormControl(organization.address?.zipCode, [
          Validators.maxLength(5),
        ]),
      }),
      owner: new FormGroup({
        firstName: new FormControl(
          organization?.owner?.firstName,
          isOwnOrgaEdit ? [] : [Validators.required, Validators.maxLength(255)]
        ),
        lastName: new FormControl(
          organization?.owner?.lastName,
          isOwnOrgaEdit ? [] : [Validators.required, Validators.maxLength(255)]
        ),
        username: new FormControl(
          organization?.owner?.username,
          isOwnOrgaEdit
            ? [Validators.email, Validators.maxLength(255)]
            : [Validators.required, Validators.email, Validators.maxLength(255)]
        ),
        role: new FormControl(
          { value: this.organizationOwnerRole, disabled: true },
          isOwnOrgaEdit ? [] : [Validators.required]
        ),
      }),
    });
    this.isCreate = false;
  }

  private isOwnOrgaEdit() {
    return (
      this.router.url.includes('public-utility/settings') ||
      this.router.url.includes('organization/settings')
    );
  }

  colorHexToColorPickerColor(hex: string): Color {
    const rgb = tinycolor(hex).toRgb();
    return new Color(rgb.r, rgb.g, rgb.b);
  }

  save() {
    if (!this.form.valid) {
      return;
    }

    if (this.isCreate) {
      this.create();
    } else {
      this.update();
    }
  }

  onFileSelect(event: any) {
    const files: FileList = event.target.files;
    const reader = new FileReader();
    try {
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        if (new TextEncoder().encode(reader.result as string).length > 64000) {
          this.errorService.showError({
            userErrorMessage:
              'Das gewählte Bild überschreitet die maximale Größe von 64 Kb.',
          });
          return;
        }
        this.logo = this.domSanitizer.bypassSecurityTrustUrl(
          reader.result as string
        );
        this.form.patchValue({
          logo: reader.result,
        });
      };
    } catch (e) {
      console.log(e);
    }
  }

  private initForCreate() {
    this.form = new FormGroup({
      name: new FormControl(undefined, [
        Validators.required,
        Validators.maxLength(255),
      ]),
      logo: new FormControl(),
      address: new FormGroup({
        street: new FormControl(undefined, [
          Validators.required,
          Validators.maxLength(255),
        ]),
        city: new FormControl(undefined, [
          Validators.required,
          Validators.maxLength(255),
        ]),
        zipCode: new FormControl(undefined, [
          Validators.required,
          Validators.maxLength(5),
        ]),
      }),
      owner: new FormGroup({
        firstName: new FormControl(undefined, [
          Validators.required,
          Validators.maxLength(255),
        ]),
        lastName: new FormControl(undefined, [
          Validators.required,
          Validators.maxLength(255),
        ]),
        username: new FormControl(undefined, [
          Validators.required,
          Validators.email,
          Validators.maxLength(255),
        ]),
        role: new FormControl(
          { value: this.organizationOwnerRole, disabled: true },
          [Validators.required]
        ),
      }),
    });
    this.isCreate = true;
  }

  private async update() {
    const dto = new OrganizationWithInitialUserInput();
    dto.name = this.form.value.name;
    dto.logo = this.form.value.logo;
    dto.address = this.form.value.address;

    if (!this.isOwnOrgaEdit()) {
      dto.owner = {
        firstName: this.form.value.owner.firstName,
        lastName: this.form.value.owner.lastName,
        username: this.form.value.owner.username,
      };
    }

    const profile = await this.userService.getUserProfile();
    if (profile && profile.organizationId === this.form.value.id) {
      this.organizationService
        .update(dto, this.form.value.id)
        .then((result) => {
          if (result) {
            this.authenticationService.organizationDataChangedSubject.next({
              organizationName: result.name,
              logo: result.logo,
            });
          }

          if (this.router.url.includes('public-utility/settings')) {
            this.router.navigateByUrl('/public-utility/dashboard');
          } else {
            this.router.navigateByUrl('/public-utility/organizations');
          }
        });
    } else {
      this.organizationService
        .update(dto, this.form.value.id)
        .then((orga: Organization) => {
          if (this.router.url.includes('settings') && orga) {
            this.authenticationService.organizationDataChangedSubject.next({
              logo: orga.logo,
              organizationName: orga.name,
            });
          }
          this.routeAfterSave();
        });
    }
  }

  private create() {
    const dto: OrganizationWithInitialUserInput = {
      name: this.form.value.name,
      logo: this.form.value.logo,
      address: this.form.value.address,
      owner: {
        firstName: this.form.value.owner.firstName,
        lastName: this.form.value.owner.lastName,
        username: this.form.value.owner.username,
        role: this.form.value.owner.role,
      } as UserInputDto,
    };
    this.organizationService.create(dto).then((_) => {
      this.router.navigateByUrl('/public-utility/organizations');
    });
  }

  private routeAfterSave() {
    if (
      [Roles.CITYLINK_OWNER, Roles.CITYLINK_EDITOR, Roles.CITYLINK_VIEWER]
        //@ts-ignore
        .includes(this.authenticationService.getCurrentUserSession().role)
    ) {
      if (this.router.url.includes('settings')) {
        this.router.navigateByUrl('/public-utility/dashboard');
      } else {
        this.router.navigateByUrl('/public-utility/organizations');
      }
      return;
    }
    if (
      [
        Roles.ORGANIZATION_OWNER,
        Roles.ORGANIZATION_EDITOR,
        Roles.ORGANIZATION_VIEWER,
      ]
        //@ts-ignore
        .includes(this.authenticationService.getCurrentUserSession().role)
    ) {
      this.router.navigateByUrl('/organization/dashboard');
    }
  }

  getTitle() {
    if (this.isCreate) {
      return 'Organisation erstellen';
    }

    if (this.router.url.includes('public-utility/settings')) {
      return 'Citylinkeinstellungen';
    } else {
      return 'Organisationseinstellungen';
    }
  }
}
