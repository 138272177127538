import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { Roles } from 'src/app/_core/models/roles';
import { NavItem } from 'src/app/navigation/models/nav-item';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';
import { UserService } from 'src/app/users/services/user.service';
import { DisposeBag } from '@ronas-it/dispose-bag/dist/src';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  navItems: NavItem[];
  logo: SafeUrl;
  showSideNav = false;
  private disposeBag = new DisposeBag();
  routingInProgress = false;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      this.document.body.classList.add('dark-theme');
    }

    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) => {
        const isDarkTheme = e.matches;
        if (isDarkTheme) {
          this.document.body.classList.add('dark-theme');
        } else {
          this.document.body.classList.remove('dark-theme');
        }
      });

    this.logo = localStorage.getItem('organizationLogo')
      ? this.domSanitizer.bypassSecurityTrustUrl(
          localStorage.getItem('organizationLogo')
        )
      : undefined;
    this.disposeBag.add(
      this.authenticationService.organizationDataChangedSubject
        .asObservable()
        .subscribe((orgaData) => {
          this.logo = orgaData.logo
            ? this.domSanitizer.bypassSecurityTrustUrl(orgaData.logo)
            : undefined;
        })
    );

    if (this.router.url !== '/' && this.authenticationService.isLoggedIn()) {
      this.showSideNav = true;
    }

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        if (
          val.url === '/users-password-reset' ||
          val.url === '/request-new-password'
        ) {
          this.showSideNav = false;
        } else if (this.authenticationService.isLoggedIn()) {
          this.showSideNav = true;
        }
      }
    });

    this.disposeBag.add(
      this.authenticationService.userSessionChangedSubject
        .asObservable()
        .subscribe((newSession) => {
          if (newSession) {
            if (this.router.url === '/login') {
              this.disposeBag.add(
                this.router.events.subscribe((routerEvent) => {
                  if (
                    routerEvent instanceof NavigationEnd ||
                    routerEvent instanceof NavigationCancel ||
                    routerEvent instanceof NavigationError
                  ) {
                    this.showSideNav = true;
                    this.disposeBag.unsubscribe('afterLoginSubject');
                  }
                }),
                'afterLoginSubject'
              );
            } else if (
              this.router.url === '/users-password-reset' ||
              this.router.url === '/request-new-password'
            ) {
              this.showSideNav = false;
            } else {
              this.showSideNav = true;
            }
          } else {
            this.showSideNav = false;
          }
        })
    );

    this.disposeBag.add(
      this.router.events.subscribe((val) => {
        if (val instanceof NavigationStart) {
          this.routingInProgress = true;
        } else if (
          val instanceof NavigationEnd ||
          val instanceof NavigationCancel ||
          val instanceof NavigationError
        ) {
          this.routingInProgress = false;
        }
      })
    );

    this.navItems = [
      {
        displayName: 'Dashboard',
        iconName: 'dashboard',
        route: 'public-utility/dashboard',
        roles: [
          Roles.CITYLINK_OWNER,
          Roles.CITYLINK_EDITOR,
          Roles.CITYLINK_VIEWER,
        ],
      },
      {
        displayName: 'Dashboard',
        iconName: 'dashboard',
        route: 'organization/dashboard',
        roles: [
          Roles.ORGANIZATION_OWNER,
          Roles.ORGANIZATION_EDITOR,
          Roles.ORGANIZATION_VIEWER,
        ],
      },
      {
        displayName: 'Sensortypen',
        iconName: 'settings_input_antenna',
        route: 'public-utility/sensor-types',
        roles: [
          Roles.CITYLINK_OWNER,
          Roles.CITYLINK_EDITOR,
          Roles.CITYLINK_VIEWER,
        ],
      },
      {
        displayName: 'Sensoren',
        iconName: 'sensors',
        route: 'public-utility/sensors',
        roles: [
          Roles.CITYLINK_OWNER,
          Roles.CITYLINK_EDITOR,
          Roles.CITYLINK_VIEWER,
        ],
      },
      {
        displayName: 'Sensorfehler',
        iconName: 'error_outline',
        route: 'public-utility/measurement-parse-error-groups',
        roles: [
          Roles.CITYLINK_OWNER,
          Roles.CITYLINK_EDITOR,
          Roles.CITYLINK_VIEWER,
        ],
      },
      {
        displayName: 'Positionierung',
        iconName: 'layers',
        route: 'organization/positioning',
        roles: [
          Roles.ORGANIZATION_OWNER,
          Roles.ORGANIZATION_EDITOR,
          Roles.ORGANIZATION_VIEWER,
        ],
      },
      {
        displayName: 'Alarmkonfiguration',
        iconName: 'notifications_active',
        route: 'organization/alarm-configuration',
        roles: [
          Roles.ORGANIZATION_OWNER,
          Roles.ORGANIZATION_EDITOR,
          Roles.ORGANIZATION_VIEWER,
        ],
      },
      {
        displayName: 'Ampelkonfiguration',
        iconName: 'traffic',
        route: 'organization/sensor-state-indicator-configuration',
        roles: [
          Roles.ORGANIZATION_OWNER,
          Roles.ORGANIZATION_EDITOR,
          Roles.ORGANIZATION_VIEWER,
        ],
      },
      {
        displayName: 'Nutzerverwaltung',
        iconName: 'people_alt',
        route: 'public-utility/users',
        roles: [
          Roles.CITYLINK_OWNER,
          Roles.CITYLINK_EDITOR,
          Roles.CITYLINK_VIEWER,
        ],
      },
      {
        displayName: 'Nutzerverwaltung',
        iconName: 'people_alt',
        roles: [Roles.ORGANIZATION_OWNER, Roles.ORGANIZATION_EDITOR],
        children: [
          {
            displayName: 'Nutzer',
            route: 'organization/users',
            roles: [Roles.ORGANIZATION_OWNER, Roles.ORGANIZATION_EDITOR],
          },
          {
            displayName: 'Nutzergruppen',
            route: 'organization/user-groups',
            roles: [Roles.ORGANIZATION_OWNER, Roles.ORGANIZATION_EDITOR],
          },
        ],
      },
      {
        displayName: 'Organisationen',
        iconName: 'business',
        route: 'public-utility/organizations',
        roles: [
          Roles.CITYLINK_OWNER,
          Roles.CITYLINK_EDITOR,
          Roles.CITYLINK_VIEWER,
        ],
      },
    ];
  }

  ngOnDestroy() {
    this.disposeBag.unsubscribe();
  }

  logout() {
    this.authenticationService.logout();
    this.userService.removeProfileFromSession();
  }
}
