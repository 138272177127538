import { Injectable } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { SensorState } from 'src/app/_shared/models/sensor-state';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  constructor() {}

  public static polygonPathToBounds(
    path: Array<{ lat: number; lng: number }>
  ): google.maps.LatLngBounds {
    const bounds = new google.maps.LatLngBounds();

    for (const coordinate of path) {
      bounds.extend(coordinate);
    }

    return bounds;
  }

  public static centerAndZoomMap(
    googleMap: GoogleMap,
    bounds?: google.maps.LatLngBounds,
    coordinates?: { lng: number; lat: number },
    zoom?: number
  ) {
    console.log(coordinates);
    if (bounds) {
      googleMap.googleMap.setCenter(bounds.getCenter());
      googleMap.googleMap.fitBounds(bounds);
    } else {
      googleMap.googleMap.setCenter(coordinates);
      googleMap.googleMap.setZoom(zoom);
    }
  }

  public static buildPolygonOptions(
    editable: boolean,
    draggable: boolean,
    color = '#AAAAAA'
  ) {
    return {
      fillColor: color,
      strokeColor: color,
      editable,
      draggable,
    };
  }

  public generateMarkerIcon(
    color: SensorState,
    icon: 'point' | 'info' | 'exclamation_mark'
  ) {
    let hexcolor = '#0081d1';
    switch (color) {
      case SensorState.RED:
        hexcolor = '#c51162';
        break;
      case SensorState.YELLOW:
        hexcolor = '#fd9725';
        break;
      case SensorState.GREEN:
        hexcolor = '#1eb223';
        break;
    }

    let svg;
    switch (icon) {
      case 'exclamation_mark':
        svg = `
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 6H20V23.3333H12V6Z" fill="white"/>
            <path d="M16 2.66667C10.4 2.66667 5.33334 6.96 5.33334 13.6C5.33334 18.0267 8.89334 23.2667 16 29.3333C23.1067 23.2667 26.6667 18.0267 26.6667 13.6C26.6667 6.96 21.6 2.66667 16 2.66667ZM17.3333 20H14.6667V17.3333H17.3333V20ZM17.3333 14.6667H14.6667V8H17.3333V14.6667Z" fill="${hexcolor}"/>
        </svg>
        `;
        return 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svg);
      case 'info':
        svg = `
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 6H20V23.3333H12V6Z" fill="white"/>
          <path d="M16 2.66667C10.4 2.66667 5.33334 6.96 5.33334 13.6C5.33334 18.0267 8.89334 23.2667 16 29.3333C23.1067 23.2667 26.6667 18.0267 26.6667 13.6C26.6667 6.96 21.6 2.66667 16 2.66667ZM17.3333 20H14.6667V13.3333H17.3333V20ZM17.3333 10.6667H14.6667V8H17.3333V10.6667Z" fill="${hexcolor}"/>
        </svg>
        `;
        return 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svg);
      case 'point':
        svg = `
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.6667 6H21.3333V20.6667H10.6667V6Z" fill="white"/>
          <path d="M16 2.66667C10.4 2.66667 5.33333 6.96 5.33333 13.6C5.33333 18.0267 8.89333 23.2667 16 29.3333C23.1067 23.2667 26.6667 18.0267 26.6667 13.6C26.6667 6.96 21.6 2.66667 16 2.66667ZM16 16C14.5333 16 13.3333 14.8 13.3333 13.3333C13.3333 11.8667 14.5333 10.6667 16 10.6667C17.4667 10.6667 18.6667 11.8667 18.6667 13.3333C18.6667 14.8 17.4667 16 16 16Z" fill="${hexcolor}"/>
        </svg>
          `;
        return 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(svg);
    }
  }
}
