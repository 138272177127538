import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SensorMeasurementsChartComponent } from './sensor-measurements-chart.component';
import { ChartsModule } from 'ng2-charts';
import { FlexModule } from '@angular/flex-layout';

@NgModule({
  declarations: [SensorMeasurementsChartComponent],
  imports: [CommonModule, ChartsModule, FlexModule],
  exports: [SensorMeasurementsChartComponent],
})
export class SensorMeasurementsChartModule {}
