<mat-card class="square-card mat-elevation-z0">
  <h4 class="square-card-title">Warnungen</h4>

  <mat-tab-group
    [disableRipple]="true"
    (selectedTabChange)="statisticTabChanged($event)"
  >
    <mat-tab label="24 std">
      <canvas
        baseChart
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [chartType]="barChartType"
      >
      </canvas>
    </mat-tab>
    <mat-tab label="Woche">
      <canvas
        baseChart
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [chartType]="barChartType"
      >
      </canvas>
    </mat-tab>
    <mat-tab label="Monat">
      <canvas
        baseChart
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [chartType]="barChartType"
      >
      </canvas>
    </mat-tab>
    <mat-tab label="Jahr">
      <canvas
        baseChart
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [chartType]="barChartType"
      >
      </canvas>
    </mat-tab>
  </mat-tab-group>
</mat-card>
