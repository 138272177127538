import { SensorTypeConfig } from 'src/app/_shared/models/sensor-type-config';
import { Organization } from 'src/app/public-utility/organization/models/organization';
import { CitylinkSensorOutputDto } from 'src/app/public-utility/sensors/dto/citylink-sensor-output-dto';
import { SensorSourceOfGeneration } from 'src/app/_shared/enum/sensor-source-of-generation';
import { SensorConnectionStatus } from 'src/app/_shared/enum/sensor-connection-status';

export class CitylinkSensor {
  id: number;
  displayName: string;
  sensorTypeConfig: SensorTypeConfig;
  sourceOfGeneration: SensorSourceOfGeneration;
  connectionStatus: SensorConnectionStatus;
  organizations: Organization[];
  applyChangesInIotPlatform: boolean;
  latestMeasurementDate?: Date;
  metaData: {
    eui: string;
    niotaId: number;
  };

  static fromCitylinkSensorOutputDto(
    dto: CitylinkSensorOutputDto
  ): CitylinkSensor {
    const result = new CitylinkSensor();

    result.id = dto.id;
    result.displayName = dto.displayName;
    result.sourceOfGeneration = dto.sourceOfGeneration;
    result.connectionStatus = dto.connectionStatus;
    if (dto.sensorTypeConfig) {
      result.sensorTypeConfig = SensorTypeConfig.fromSensorTypeOutputDto(
        dto.sensorTypeConfig
      );
    }
    if (dto.latestMeasurementDate) {
      result.latestMeasurementDate = new Date(dto.latestMeasurementDate);
    }
    result.organizations =
      dto.organizations?.map((item) =>
        Organization.fromOrganizationOutputDto(item)
      ) || [];

    result.metaData = dto.metaData;
    result.applyChangesInIotPlatform = dto.applyChangesInIotPlatform;

    return result;
  }
}
