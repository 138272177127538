import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/_core/services/api/api.service';
import { Router } from '@angular/router';
import { UserOutputDto } from 'src/app/users/dtos/user-output.dto';
import { ConfirmDto } from 'src/app/users/dtos/confirm.dto';
import { ChangePasswordDto } from 'src/app/users/dtos/change-password.dto';
import { RequestPasswordDto } from 'src/app/users/dtos/request-password.dto';
import { SnackBarService } from 'src/app/_shared/services/snack-bar.service';
import { ChangeUserProfileDto } from 'src/app/users/dtos/change-user-profile.dto';
import { HttpResponse } from '@angular/common/http';
import { User } from 'src/app/users/models/user';
import { UserInputDto } from 'src/app/users/dtos/user-input.dto';
import { ErrorService } from 'src/app/_shared/services/error.service';
import { Subject } from 'rxjs';
import { UserFilterInputDto } from 'src/app/users/dtos/user-filter-input.dto';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  LOCAL_STORAGE_PROFILE_KEY = 'profile';
  userProfileChangedSubject = new Subject<User | void>();

  constructor(
    private router: Router,
    private apiService: ApiService,
    private errorService: ErrorService,
    private snackBarService: SnackBarService
  ) {}

  private getProfile(): Promise<User | void> {
    return this.apiService.get<UserOutputDto>('/users/profile').then((dto) => {
      if (!dto) {
        return;
      }
      return User.fromUserOutputDto(dto);
    });
  }

  getUserList(
    handleErrors = true,
    userFilterDto?: UserFilterInputDto
  ): Promise<User[]> {
    return this.apiService
      .get<UserOutputDto[]>('/users', undefined, userFilterDto, handleErrors)
      .then((result) => {
        if (!result) {
          return [];
        }
        return result.map((dto) => User.fromUserOutputDto(dto));
      });
  }

  deleteUser(id: number): Promise<void> {
    return this.apiService.delete('/users/' + id).then((_) => {
      this.snackBarService.deleteSuccessful();
    });
  }

  validateConfirmationToken(confirmationToken: string): Promise<void> {
    const confirmDto: ConfirmDto = { confirmationToken };
    return this.apiService.post<void>(
      '/users/confirmation-token/validate',
      confirmDto,
      {},
      false,
      false
    );
  }

  confirmUsername(confirmationToken: string): Promise<void> {
    const confirmDto: ConfirmDto = { confirmationToken };
    return this.apiService.post<void>(
      '/users/username-change',
      confirmDto,
      {},
      false,
      false
    );
  }

  changePassword(
    confirmationToken: string,
    newPassword: string
  ): Promise<void> {
    const changePasswordDto: ChangePasswordDto = {
      confirmationToken,
      newPassword,
    };
    return this.apiService.post<void>(
      '/users/password-change',
      changePasswordDto,
      {},
      false
    );
  }

  editUserProfile(dto: ChangeUserProfileDto | UserInputDto): Promise<User> {
    return this.apiService
      .post<HttpResponse<UserOutputDto>>('/users/profile', dto, {
        observe: 'response',
      })
      .then((res) => {
        if (!res) {
          return;
        }

        localStorage.setItem(
          this.LOCAL_STORAGE_PROFILE_KEY,
          JSON.stringify(res.body)
        );
        this.userProfileChangedSubject.next(res.body as User);

        if (dto instanceof ChangeUserProfileDto && dto.newPassword) {
          this.snackBarService.saveSuccessfulAndLogout();
        } else {
          this.snackBarService.saveSuccessful();
        }
        return User.fromUserOutputDto(res.body);
      });
  }

  requestNewPassword(
    username: string
  ): Promise<HttpResponse<UserOutputDto> | void> {
    const dto: RequestPasswordDto = { username };
    return this.apiService
      .post<HttpResponse<UserOutputDto>>(
        '/users/password-reset',
        dto,
        { observe: 'response' },
        false,
        false
      )
      .then((result) => {
        if (!result) {
          return;
        }

        localStorage.setItem(
          this.LOCAL_STORAGE_PROFILE_KEY,
          JSON.stringify(result.body)
        );
        return result;
      });
  }

  inviteUser(userToInvite: UserInputDto): Promise<void> {
    return this.apiService
      .post<void>('/users/invite', userToInvite)
      .then((_) => {
        this.snackBarService.saveSuccessful();
      });
  }

  logout(): Promise<void> {
    return this.apiService.delete<void>('/users/logout', undefined, false);
  }

  getUserProfile(): Promise<User | void> {
    const profile = this.loadProfileFromSession();
    if (!profile) {
      return this.getProfile().then((data) => {
        if (data) {
          localStorage.setItem(
            this.LOCAL_STORAGE_PROFILE_KEY,
            JSON.stringify(data)
          );
        }
        return data;
      });
    }

    return Promise.resolve(profile);
  }

  loadProfileFromSession(): User | null {
    return JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_PROFILE_KEY));
  }

  removeProfileFromSession(): void {
    localStorage.removeItem(this.LOCAL_STORAGE_PROFILE_KEY);
  }

  resendInvitation(id: number): Promise<void> {
    return this.apiService.get(`/users/${id}/invite`).then((_) => {
      this.snackBarService.showCustomMessage(
        'Einladung wurde erneut versendet.'
      );
    });
  }

  update(id: number, dto: UserInputDto) {
    return this.apiService.patch<void>('/users/' + id, dto).then((_) => {
      this.snackBarService.saveSuccessful();
    });
  }
}
