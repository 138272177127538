import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { PublicUtilityDashboardOverviewComponent } from 'src/app/public-utility/public-utility-dashboard/components/public-utility-dashboard-overview/public-utility-dashboard-overview.component';
import { MatDividerModule } from '@angular/material/divider';
import { DashboardNumberCardModule } from 'src/app/public-utility/public-utility-dashboard/components/dashboard-number-card/dashboard-number-card.module';
import { DashboardOrganizationsCardModule } from 'src/app/public-utility/public-utility-dashboard/components/dashboard-organizations-card/dashboard-organizations-card.module';
import {GatewaysMapModule} from "../gateways-map/gateways-map.module";

@NgModule({
  declarations: [PublicUtilityDashboardOverviewComponent],
  exports: [PublicUtilityDashboardOverviewComponent],
    imports: [
        CommonModule,
        FlexModule,
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        MatDividerModule,
        DashboardNumberCardModule,
        DashboardOrganizationsCardModule,
        GatewaysMapModule,
    ],
})
export class PublicUtilityDashboardOverviewModule {}
