<div fxLayout="column" class="app-area">
  <!-- HEADER ROW -->
  <div fxLayout="row">
    <div fxFlex="20" fxFlexAlign="start start">
      <h1>Sensoren</h1>
    </div>
    <div fxFlex style="text-align: right">
      <button
        mat-stroked-button
        fxFlexAlign="end end"
        (click)="onNiotaUpdateClicked()"
        [disabled]="!enabled || niotaImportInProgress"
        [class.button-spinner]="niotaImportInProgress"
        style="margin-right: 24px"
      >
        <mat-icon>download</mat-icon>
        <span style="margin-left: 12px">Aktualisieren aus niota</span>
      </button>
      <button
        mat-stroked-button
        fxFlexAlign="end end"
        (click)="onNiotaImportClicked()"
        [disabled]="!enabled || niotaImportInProgress"
        [class.button-spinner]="niotaImportInProgress"
        style="margin-right: 24px"
      >
        <mat-icon>download</mat-icon>
        <span style="margin-left: 12px">Import aus niota</span>
      </button>
      <button
        mat-stroked-button
        fxFlexAlign="end end"
        (click)="onCSVImportClicked()"
        [disabled]="!enabled"
        style="margin-right: 24px"
      >
        <mat-icon>publish</mat-icon>
        <span style="margin-left: 12px">CSV Import</span>
      </button>
    </div>
  </div>

  <!-- Content -->
  <mat-card fxLayout="row wrap" class="square-card mat-elevation-z0">
    <div fxFlex="100" fxLayout="row" dir="rtl">
      <button
        mat-icon-button
        fxFlexAlign="end end"
        (click)="onAddClicked()"
        [disabled]="!enabled"
      >
        <mat-icon>add</mat-icon>
      </button>
      <button
        mat-icon-button
        fxFlexAlign="end end"
        (click)="onListEditClicked()"
        [disabled]="!(enabled && (indeterminate || allSelected))"
      >
        <mat-icon>mode_edit</mat-icon>
      </button>
      <button
        mat-icon-button
        fxFlexAlign="end end"
        (click)="onQrCodeClicked()"
        [disabled]="!(enabled && (indeterminate || allSelected))"
      >
        <mat-icon>qr_code_2</mat-icon>
      </button>
      <button
        mat-icon-button
        fxFlexAlign="end end"
        (click)="onClearFilterClicked()"
        [disabled]="!isFilter()"
      >
        <mat-icon>settings_backup_restore</mat-icon>
      </button>
    </div>
    <div class="table-wrapper">
      <table mat-table style="width: 100%" [dataSource]="matTableDataSource">
        <ng-container matColumnDef="checkbox">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              color="primary"
              [(ngModel)]="allSelected"
              [(indeterminate)]="indeterminate"
              (ngModelChange)="indeterminateCheckBoxClicked()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let element" style="width: 48px">
            <mat-checkbox
              color="primary"
              [(ngModel)]="element.selected"
              (ngModelChange)="selectionChanged()"
              (click)="$event.stopPropagation()"
            ></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="sensors-list-form-field">
              <mat-label>Name</mat-label>
              <input
                matInput
                type="text"
                [(ngModel)]="sensorFilterInput.title"
                (ngModelChange)="filterChangedSubject.next()"
                name="displayName"
              />
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.displayName }}</td>
        </ng-container>

        <ng-container matColumnDef="eui">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field class="sensors-list-form-field">
              <mat-label>EUI</mat-label>
              <input
                matInput
                type="text"
                [(ngModel)]="sensorFilterInput.eui"
                (ngModelChange)="filterChangedSubject.next()"
                name="eui"
              />
            </mat-form-field>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.metaData.eui }}</td>
        </ng-container>

        <ng-container matColumnDef="sensorTypeConfig">
          <th mat-header-cell *matHeaderCellDef>
            <app-filterable-select
              class="sensors-list-form-field"
              [(ngModel)]="sensorFilterInput.sensorTypeConfig"
              label="Sensortyp"
              [objects]="sensorTypeConfigs"
              [objToLabel]="sensorTypeToLabel"
              (ngModelChange)="filterChangedSubject.next()"
            ></app-filterable-select>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.sensorTypeConfig?.displayName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="sourceOfGeneration">
          <th mat-header-cell *matHeaderCellDef>Erzeugungsquelle</th>
          <td mat-cell *matCellDef="let element">
            {{ sourceOfGenerationToLabel(element.sourceOfGeneration) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="organization">
          <th mat-header-cell *matHeaderCellDef>
            <app-filterable-select
              class="sensors-list-form-field"
              [(ngModel)]="sensorFilterInput.organizations"
              label="Organisationen"
              [objects]="organizations"
              [objToLabel]="organizationToLabel"
              (ngModelChange)="filterChangedSubject.next()"
              [multiple]="true"
            ></app-filterable-select>
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-chip-list>
              <mat-chip
                class="blue-chip"
                disabled
                *ngFor="let organization of element.organizations"
                >{{ organization?.name }}</mat-chip
              >
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container matColumnDef="applyChangesInIotPlatform">
          <th mat-header-cell *matHeaderCellDef>
            Änderungen in niota übernehmen
          </th>
          <td mat-cell *matCellDef="let element" style="text-align: center">
            <mat-checkbox
              disabled
              color="primary"
              [checked]="element.applyChangesInIotPlatform"
            ></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="connectionStatus">
          <th mat-header-cell *matHeaderCellDef>Verbindungsstatus</th>
          <td mat-cell *matCellDef="let element">
            <mat-chip-list style="float: left">
              <mat-chip
                class="green-chip"
                disabled
                *ngIf="element.connectionStatus === 'MQTT_AND_NIOTA'"
              >
                <app-niota color="#62C217" style="margin: auto"></app-niota
                >&nbsp;+&nbsp;<app-mqtt
                  color="#62C217"
                  style="margin: auto"
                ></app-mqtt>
              </mat-chip>
              <mat-chip
                class="orange-chip"
                disabled
                *ngIf="element.connectionStatus === 'MQTT_ONLY'"
              >
                <app-mqtt color="#FF891C" style="margin: auto"></app-mqtt
                >&nbsp;-&nbsp;Verbunden
              </mat-chip>
              <mat-chip
                class="orange-chip"
                disabled
                *ngIf="element.connectionStatus === 'NIOTA_ONLY'"
              >
                <app-niota color="#FF891C" style="margin: auto"></app-niota
                >&nbsp;-&nbsp;Verbunden
              </mat-chip>

              <mat-chip
                class="orange-chip"
                disabled
                *ngIf="element.connectionStatus === 'TTN_ONLY'"
              >
                <app-ttn color="#FF891C" style="margin: auto"></app-ttn
                >&nbsp;-&nbsp;Verbunden
              </mat-chip>

              <mat-chip
                class="green-chip"
                disabled
                *ngIf="element.connectionStatus === 'MQTT_AND_TTN'"
              >
                <app-ttn color="#62C217" style="margin: auto"></app-ttn
                >&nbsp;+&nbsp;<app-mqtt
                  color="#62C217"
                  style="margin: auto"
                ></app-mqtt>
              </mat-chip>

              <mat-chip
                class="red-chip"
                disabled
                *ngIf="element.connectionStatus === 'NO_CONNECTION'"
                >Keine Verbindung
              </mat-chip>
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container matColumnDef="latestMeasurementDate">
          <th mat-header-cell *matHeaderCellDef>
            Datum der aktuellsten Messung
          </th>
          <td mat-cell *matCellDef="let element" style="text-align: center">
            <mat-chip-list style="float: left">
              <mat-chip
                class="red-chip"
                disabled
                *ngIf="!element.latestMeasurementDate"
                >keine Daten empfangen
              </mat-chip>
              <mat-chip
                class="green-chip"
                disabled
                *ngIf="
                  !!element.latestMeasurementDate &&
                  isWithinLast24Hours(element.latestMeasurementDate)
                "
              >
                {{
                  element.latestMeasurementDate | date: "dd.MM.yyyy HH:mm:ss"
                }}
              </mat-chip>
              <mat-chip
                class="orange-chip"
                disabled
                *ngIf="
                  !!element.latestMeasurementDate &&
                  isNotWithinLast24Hours(element.latestMeasurementDate)
                "
              >
                {{
                  element.latestMeasurementDate | date: "dd.MM.yyyy HH:mm:ss"
                }}
              </mat-chip>
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              style="float: right"
              [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                (click)="updateSensor(element)"
                [disabled]="!enabled"
              >
                Bearbeiten
              </button>
              <button
                mat-menu-item
                (click)="deleteSensor(element)"
                [disabled]="!enabled"
              >
                Löschen
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let myDataArray; columns: displayedColumns"
          (click)="updateSensor(myDataArray)"
        ></tr>
      </table>
    </div>
    <div fxFlex="100">
      <mat-paginator
        (page)="pageChangeEvent($event)"
        [length]="totalLength"
        [pageSize]="pageSize"
        [pageSizeOptions]="[100]"
        hidePageSize
        showFirstLastButtons
      >
      </mat-paginator>
    </div>
  </mat-card>
</div>
