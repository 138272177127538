import {Injectable} from "@angular/core";
import {ApiService} from "../../../_core/services/api/api.service";
import {PublicUtilityDashboardDataDto} from "../dto/public-utility-dashboard-data.dto";
import {GatewayListOutputDto} from "../dto/gateway-list-output-dto";

@Injectable({
  providedIn: 'root',
})
export class GatewaysService {
  constructor(private apiService: ApiService) {}

  getGateways(
    handleErrors = true
  ): Promise<GatewayListOutputDto | void> {
    return this.apiService.get<GatewayListOutputDto>(
      '/gateways',
      undefined,
      undefined,
      handleErrors
    );
  }
}
