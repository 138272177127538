export enum OperatorBase {
  EQUAL = '=',
  NOT_EQUAL = '!=',
  LESS_THAN = '<',
  GREATER_THAN = '>',
  LESS_THAN_OR_EQUAL = '<=',
  GREATER_THAN_OR_EQUAL = '>=',
  DIFF = 'diff',
}

export class Operator {
  value = OperatorBase;

  public static entries() {
    return [
      OperatorBase.EQUAL,
      OperatorBase.NOT_EQUAL,
      OperatorBase.LESS_THAN,
      OperatorBase.GREATER_THAN,
      OperatorBase.LESS_THAN_OR_EQUAL,
      OperatorBase.GREATER_THAN_OR_EQUAL,
      OperatorBase.DIFF,
    ];
  }

  public static translate(operator: OperatorBase) {
    switch (operator) {
      case OperatorBase.EQUAL:
        return 'gleich';
      case OperatorBase.NOT_EQUAL:
        return 'nicht gleich';
      case OperatorBase.GREATER_THAN:
        return 'größer';
      case OperatorBase.GREATER_THAN_OR_EQUAL:
        return 'größer oder gleich';
      case OperatorBase.LESS_THAN:
        return 'kleiner';
      case OperatorBase.LESS_THAN_OR_EQUAL:
        return 'kleiner oder gleich';
      case OperatorBase.DIFF:
        return 'unterscheidet sich zu';
    }
  }

  public static opposite(operator: OperatorBase) {
    switch (operator) {
      case OperatorBase.EQUAL:
        return OperatorBase.NOT_EQUAL;
      case OperatorBase.NOT_EQUAL:
        return OperatorBase.EQUAL;
      case OperatorBase.GREATER_THAN:
        return OperatorBase.LESS_THAN;
      case OperatorBase.GREATER_THAN_OR_EQUAL:
        return OperatorBase.LESS_THAN_OR_EQUAL;
      case OperatorBase.LESS_THAN:
        return OperatorBase.GREATER_THAN;
      case OperatorBase.LESS_THAN_OR_EQUAL:
        return OperatorBase.GREATER_THAN_OR_EQUAL;
      case OperatorBase.DIFF:
        return OperatorBase.EQUAL;
    }
  }
}
