import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { SensorTypeConfig } from 'src/app/_shared/models/sensor-type-config';
import { SensorTypesService } from 'src/app/public-utility/sensor-types/services/sensor-types.service';
import { ApiService } from 'src/app/_core/services/api/api.service';
import { Sensor } from 'src/app/_shared/models/sensor';
import { SensorService } from 'src/app/_shared/services/sensor.service';
import { SensorStateIndicatorConfigurationOutputDto } from 'src/app/organization/sensor-state-indicator-configuration/dto/sensor-state-indicator-configuration-output.dto';
import { SensorStateIndicatorConfigurationService } from 'src/app/organization/sensor-state-indicator-configuration/services/sensor-state-indicator-configuration.service';

export interface SensorStateIndicatorConfigurationEditResolverResult {
  sensorTypeConfigs: SensorTypeConfig[];
  sensors: Sensor[];
  sensorStateIndicatorConfiguration: SensorStateIndicatorConfigurationOutputDto;
}

@Injectable({
  providedIn: 'root',
})
export class SensorStateIndicatorConfigurationEditResolver
  implements
    Resolve<void | SensorStateIndicatorConfigurationEditResolverResult> {
  constructor(
    private sensorTypesService: SensorTypesService,
    private sensorService: SensorService,
    private sensorStateIndicatorConfigurationService: SensorStateIndicatorConfigurationService,
    private apiService: ApiService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<void | SensorStateIndicatorConfigurationEditResolverResult> {
    const promises = [];
    promises.push(this.sensorTypesService.getSensorTypeConfigs(false));
    promises.push(this.sensorService.getSensors(0, 100, false));
    promises.push(
      this.sensorStateIndicatorConfigurationService.getById(route.params.id)
    );
    return Promise.all(promises)
      .then((result) => {
        return {
          sensorTypeConfigs: result[0],
          sensors: result[1],
          sensorStateIndicatorConfiguration: result[2],
        };
      })
      .catch((err) => {
        this.apiService.handleError(err);
        throw err;
      });
  }
}
