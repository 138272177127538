import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_core/services/authentication/authentication.service';
import { Roles } from 'src/app/_core/models/roles';
import { UserGroupService } from 'src/app/users/services/user-group.service';
import { UserGroupOutputDto } from 'src/app/users/dtos/user-group-output.dto';
import { UserGroup } from 'src/app/users/models/user-group';
import { UserGroupFilterInputDto } from 'src/app/users/dtos/user-group-filter.input.dto';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-user-group-list',
  templateUrl: './user-group-list.component.html',
  styleUrls: ['./user-group-list.component.scss'],
})
export class UserGroupListComponent implements OnInit {
  matTableDataSource: MatTableDataSource<UserGroup> = new MatTableDataSource<UserGroup>();
  displayedColumns = ['name', 'userCount', 'options'];
  userGroupFilter: UserGroupFilterInputDto = {};
  filterChangedSubject: Subject<void> = new Subject<void>();
  enabled = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userGroupService: UserGroupService
  ) {}

  ngOnInit(): void {
    this.matTableDataSource.data = this.activatedRoute.snapshot.data.userGroupList;

    const currentUserSession = this.authenticationService.getCurrentUserSession();
    // @ts-ignore
    this.enabled = [
      Roles.ORGANIZATION_OWNER,
      Roles.ORGANIZATION_EDITOR,
    ].includes(currentUserSession?.role);

    this.filterChangedSubject.pipe(debounceTime(300)).subscribe(() => {
      this.userGroupService
        .getList(true, this.userGroupFilter)
        .then((results) => {
          this.matTableDataSource.data = results;
        });
    });
  }

  onAddClicked() {
    this.router.navigateByUrl('/organization/user-groups/create');
  }

  openEdit(element: UserGroupOutputDto) {
    const userSession = this.authenticationService.getCurrentUserSession();
    // @ts-ignore
    if (
      [
        Roles.ORGANIZATION_OWNER,
        Roles.ORGANIZATION_EDITOR,
        Roles.ORGANIZATION_VIEWER,
      ].includes(userSession.role)
    ) {
      this.router.navigateByUrl('/organization/user-groups/edit/' + element.id);
    }
  }

  deleteUserGroup(toDelete: UserGroupOutputDto) {
    this.userGroupService.delete(toDelete.id).then((_) => {
      this.refreshUserGroupList();
    });
  }

  private refreshUserGroupList() {
    this.userGroupService
      .getList(true, this.userGroupFilter)
      .then((userGroupList) => {
        this.matTableDataSource.data = userGroupList;
      });
  }

  onClearFilterClicked() {
    this.userGroupFilter = {};
    this.filterChangedSubject.next();
  }

  isFilter() {
    return !!this.userGroupFilter.name;
  }
}
